import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { loginAction } from "./auth.actions";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";
import { RedirectAuthComponent } from "./AuthComponent";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

const history = createBrowserHistory();

const required = (value) => (value ? undefined : "Required");
// Field for input
const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => (
  <div style={{ width: "100%" }}>
    <input {...input} placeholder={label} type={type} />
    {touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
);

renderField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

const LoginPageView = ({ handleSubmit, error, submitting }) => {
  const [display, showPassword] = useState("password");
  const handleShowHide = (display) => {
    if (display === "password") {
      return "text";
    }
    if (display === "text") {
      return "password";
    }
  };

  const getButtonText = (display) => {
    if (display === "password") {
      return "Show Password";
    }
    if (display === "text") {
      return "Hide Password";
    }
  };

  return (
    <div>
      <Header />
      <main id="login-page">
        <section className="photo-header login-photo">
          <h1>Log In has moved</h1>
          <img src="images/site/login-icon.svg" />
        </section>

        <section className="white">
          <p className="centered">
            Please log in at{" "}
            <a href="https://waterscenterst.org/login">
              https://waterscenterst.org/login
            </a>{" "}
            or <a href="https://waterscenterst.org/join">click here</a> to
            create an account.
          </p>
        </section>
        <section className="grey" style={{ display: "none" }}>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={submitting}>
              <div className="form-field">
                <label htmlFor="uid">Username or Email</label>
                <Field
                  name="uid"
                  component={renderField}
                  type="text"
                  validate={[required]}
                />
              </div>
              <div className="form-field" id="pass-row">
                <label htmlFor="password">Password</label>
                <Field
                  id="input"
                  name="password"
                  component={renderField}
                  type={display}
                  validate={[required]}
                />
                <button
                  type="button"
                  className="blue"
                  onClick={() => showPassword(handleShowHide(display))}
                >
                  {getButtonText(display)}
                </button>
              </div>
              <span className="forgotpass">
                <a href="/forgot-password">Forgot password?</a>
              </span>
              <button className="blue" type="submit">
                Login
              </button>
            </fieldset>
          </form>
        </section>
        <section className="white">
          <p className="centered">
            By using this service, you agree to our{" "}
            <a href="/terms">Terms and Conditions</a> and{" "}
            <a href="/our-privacy-policy">Privacy Policy</a>.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};
const LoginPageForm = reduxForm({
  form: "LoginPage",
  onSubmit: loginAction,
})(LoginPageView);

LoginPageView.propTypes = {
  handleSubmit: PropTypes.any,
  error: PropTypes.any,
  submitting: PropTypes.any,
};

export const LoginPage = connect(
  (state, props) => props,
  (dispatch) => ({
    onSubmit: async (d) => {
      const result = await loginAction(d);
      dispatch(result);
      return history.goBack();
    },
  }),
)(RedirectAuthComponent(LoginPageForm));
