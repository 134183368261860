import * as React from "react";

export const Section02 = () => (
  <div className="content">
    <h3>Water Challenges</h3>
    <img
      className="lesson-img"
      src="/images/resources/water-activity/water-challenge.png"
      alt="Water Challenge"
    />
    <p>
      For each challenge, start with all the extra water in the inflow “cloud.”
      Before beginning, graph the goal (what the water should do over time).
      Then, graph what actually happens in a different color. After completing a
      challenge, continue with the next one. Feel free to repeat any challenge
      to improve results.
    </p>
    <div className="challenge">
      <div className="challenge-info">
        <h4>Challenge #1</h4>
        <p>
          Start with 200 ml of water in the cylinder, then raise the water from
          200 ml to 800 ml while water is always going in and always going out.
        </p>
      </div>
      <img
        className="results-graph"
        src="/images/resources/water-activity/results-graph.svg"
        alt="Water Activity Graph"
      />
    </div>
    <div className="challenge">
      <div className="challenge-info">
        <h4>Challenge #2</h4>
        <p>
          Start with 800 ml of water in the cylinder, then lower the water from
          800 to 200 ml, while water is always going in and always going out.
        </p>
      </div>
      <img
        className="results-graph"
        src="/images/resources/water-activity/results-graph.svg"
        alt="Water Activity Graph"
      />
    </div>
    <div className="challenge">
      <div className="challenge-info">
        <h4>Challenge #3</h4>
        <p>
          Start with 500 ml of water in the cylinder, then keep the water at the
          500 ml level for at least 30 seconds, while water is always going in
          and always going out.
        </p>
      </div>
      <img
        className="results-graph"
        src="/images/resources/water-activity/results-graph.svg"
        alt="Water Activity Graph"
      />
    </div>
    <div className="challenge">
      <div className="challenge-info">
        <h4>Challenge #4</h4>
        <p>
          Create the water level shown on the graph while water is always going
          in and always going out.
        </p>
      </div>
      <img
        className="results-graph"
        src="/images/resources/water-activity/results-graph-4.svg"
        alt="Water Activity Graph"
      />
    </div>
    <h3>Reflection</h3>
    <p>Match the graph to the description below.</p>
    <div className="reflection-graphs">
      <img
        className="reflection-graph"
        src="/images/resources/water-activity/botg-a.svg"
        alt="BOTG A"
      />
      <img
        className="reflection-graph"
        src="/images/resources/water-activity/botg-b.svg"
        alt="BOTG B"
      />
      <img
        className="reflection-graph"
        src="/images/resources/water-activity/botg-c.svg"
        alt="BOTG C"
      />
    </div>
    <ol>
      <li>Inflow &lt; Outflow</li>
      <li>Inflow &gt; Outflow</li>
      <li>Inflow = Outflow</li>
    </ol>
    <div className="challenge">
      <div className="challenge-info wide">
        <h4>Challenge #5</h4>
        <p>
          Start with 500 ml in the cylinder. Predict on the graph: What will
          happen if you adjust the inflow and outflow as shown? Notice the
          outflow stays the same while the inflow starts out slow and gets
          faster over time. Use the graph on the right to predict and then
          record what actually happens over time.
        </p>
      </div>
      <div className="res-graphs">
        <img
          className="res-graph"
          src="/images/resources/water-activity/challenge-5.svg"
          alt="Water Activity Graph"
        />
        <img
          className="res-graph right"
          src="/images/resources/water-activity/challenge-5-results.svg"
          alt="Water Activity Graph"
        />
      </div>
    </div>
    <div className="challenge">
      <div className="challenge-info wide">
        <h4>Challenge #6: Create your own challenge</h4>
        <p>
          Create a challenge similar to #5 that graphs what you will do with the
          flows and predict what will happen to the stock. Make sure to label
          the inflow and outflow lines.
        </p>
      </div>
      <div className="res-graphs">
        <img
          className="res-graph"
          src="/images/resources/water-activity/challenge-6.svg"
          alt="Water Activity Graph"
        />
        <img
          className="res-graph right"
          src="/images/resources/water-activity/challenge-6-results.svg"
          alt="Water Activity Graph"
        />
      </div>
    </div>
    <h3>Reflection</h3>
    <p>
      Consider that the water in the cylinder is a stock and the water going in
      and out are flows. What else in your experience is similar to the stock of
      water? How do the flows work in that system?
    </p>
  </div>
);
