import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Identify a situation where you might be able to gain some clarity by
      changing your perspective. Apply the following questions to the situation
      to help you in seeking additional perspectives.
    </p>
    <h4>
      How does my point of view influence my understanding of the situation?
    </h4>
    <Field
      name="view-point"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How might a different point of view inform my understanding of the
      situation?
    </h4>
    <Field
      name="different-point"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Who could I approach to help me gain new perspectives on the situation?
      What strategies would help me identify helpful, new perspectives?
    </h4>
    <Field
      name="gain-new"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      If I truly understood that other person’s perspective, what would I notice
      about my current situation?
    </h4>
    <Field
      name="current-situation"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How do different points of view influence my understanding of the system?
    </h4>
    <Field
      name="influence"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
