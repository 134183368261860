import * as React from "react";
import cn from "classnames";
import Dropzone from "react-dropzone";
import { apiCallAsync } from "components/shared/api";
import { getSessionId, getSessionAuth } from "components/shared/session";
import PropTypes from "prop-types";

async function saveFile(f) {
  console.log("drop!", f);
  return apiCallAsync("/files", f[0], "POST", true);
}

export function FileUpload(props) {
  const { user } = getSessionAuth();
  // TODO: probably we should set auth stuff on a cookie and make alot of this easier
  const {
    accept,
    image,
    className,
    label,
    name,
    input: { onChange, value },
    meta: { error, touched },
  } = props;
  const onDrop = (f) => saveFile(f).then(onChange);
  const authToken = getSessionAuth().accessToken;
  const sessionId = getSessionId();

  const findAuth = () => {
    if (!user) {
      return "none";
    }
  };
  return (
    <div
      style={{ display: findAuth() }}
      className={cn(
        className,
        error && touched && "error",
        value && "has-value",
      )}
    >
      <h4>{props.instructions}</h4>
      {label && <label htmlFor={name}>{label}</label>}
      <Dropzone
        accept={image ? "image/*" : accept}
        onDrop={onDrop}
        className="drop-box"
        name={name}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={isDragActive ? "active drop-box" : "drop-box"}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <span>Drop or click to select file</span>
          </div>
        )}
      </Dropzone>
      {error && touched ? error : ""}
      {value && (
        <div className="file-value">
          {image && (
            <img
              src={`/api/files/${value.id}?access_token=${encodeURIComponent(authToken)}&session_id=${encodeURIComponent(sessionId)}`}
              style={{ maxWidth: "500px", maxHeight: "400px" }}
            />
          )}
        </div>
      )}
    </div>
  );
}
FileUpload.propTypes = {
  accept: PropTypes.any,
  image: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.any,
  input: PropTypes.any,
  instructions: PropTypes.string,
  meta: PropTypes.any,
};
