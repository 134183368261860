import * as React from "react";
import { FileUpload } from "components/shared/file-upload";
import { Field } from "redux-form";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Use the Tragedy of the Commons archetype to create your own story. You can
      either copy and draw your archetype on paper, or use the{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-template-so.pdf"
        target="blank"
        rel="noopener"
      >
        template
      </a>{" "}
      from Practice Exercise #2.
    </p>
    <img
      className="lesson-img left"
      src="/images/courses/habits/10-time-delays/Tragedy-of-the-Commons-Template.png"
      alt="Tragedy of the Commons Template"
    />
    <p>
      Imagine that the resource is your time, which you can choose to measure in
      hours per day, energy, or attention. In the boxes labeled Activity A and
      Activity B, place things that are important to you and take significant
      amounts of time. For example, work, parenting, fitness, hobbies, etc.
      Describe for yourself the benefits you receive from those activities.
      Consider how this creates reinforcing feedback, causing you to do more of
      the initial activities. Use these questions to help you complete the
      diagram:
    </p>
    <h4>
      What is the overall benefit you are receiving? (It could be something like
      personal fulfillment, financial stability or improved physical condition.)
    </h4>
    <Field
      name="overall-benefit"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Over time, how do the limits of your time affect the benefit you gain from
      the individual activities?
    </h4>
    <Field
      name="over-time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How does the total time spent impact your overall performance and sense of
      well-being?
    </h4>
    <Field
      name="total-time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      As you draw from the “resource limit” (this is your time), what is the
      impact on the activities you have identified?
    </h4>
    <Field
      name="resource-limit"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Are there subtle changes you could make to improve how you are using the
      resource of time?
    </h4>
    <Field
      name="subtle-changes"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      Do you want to take a closer look at how you spend your time? Focus on a
      single aspect of your use of time, for instance, time spent at work. In
      the boxes marked Activity A and Activity B (you may need to add boxes
      Activity C, Activity D, etc.), identify specific aspects of your job that
      demand your time and attention. For example, managing personnel,
      responding to email, accounting, etc.
    </p>
    <Field
      name="activityTime"
      component={FileUpload}
      image
      instructions="After completing your archetype, upload your drawing or template below."
    />
  </div>
);
