import * as React from "react";
import { Field } from "redux-form";

const Systems = ["well-being", "workplace", "school", "community", "family"];

export const Exercise01 = () => (
  <div className="main">
    <div className="content">
      <h3>Practice the Habit</h3>
      <p>
        Choose one of the five sample systems from the Getting Started section
        of this course and provide examples of that system’s defining
        characteristics.{" "}
        <em>
          Notice that when you hover over each system icon, it changes color.
          Your selection will turn from grey to a specified color after you
          click on it.
        </em>
      </p>
      <h4>My practice system is:</h4>
      <div className="system-select">
        {Systems.map((s) => (
          <div key={s} className={`system-select-item ${s}`}>
            <Field
              name="systemSelect"
              id={`ss-${s}`}
              component="input"
              type="radio"
              value={s}
            />
            <label htmlFor={`ss-${s}`}>{s}</label>
          </div>
        ))}
      </div>
      <h4>What is the goal or purpose of my sample?</h4>
      <Field
        name="sample"
        component="textarea"
        className="reflection-input-large"
        placeholder="Enter your response here."
      />
      <h4>
        What are some of the parts or elements of my system? What is one way the
        elements are arranged to carry out the purpose of the system?
      </h4>
      <Field
        name="carry"
        component="textarea"
        className="reflection-input-large"
        placeholder="Enter your response here."
      />
      <h4>
        How might my sample system change over time in response to feedback?
      </h4>
      <Field
        name="response"
        component="textarea"
        className="reflection-input-large"
        placeholder="Enter your response here."
      />
    </div>
  </div>
);
