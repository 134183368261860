import * as React from "react";
import PropTypes from "prop-types";

export const NavButtons = ({ nav }) => (
  // Hide entire nav bar if it's the start of a resource with no additional sections (mainly for templates)
  <div
    style={{
      display:
        (nav.sectionIndex === 0 && !nav.next) ||
        (nav.sectionIndex === 0 && nav.next.type === "resource")
          ? "none"
          : "flex",
    }}
    className="nav-container"
  >
    {nav.prev && (
      // Hide button if first section of the resource
      <a
        style={{ display: nav.sectionIndex === 0 ? "none" : "block" }}
        className="nav-button prev"
        href={
          nav.lessonIndex === 0 ? `/courses/${nav.course.path}` : nav.prev.url
        }
      >
        {nav.lessonIndex === 0 ? "Course Home" : "Previous " + nav.prev.type}
      </a>
    )}
    {nav.next && (
      // Hide button if next type is resource
      <a
        style={{ display: nav.next.type === "resource" ? "none" : "block" }}
        className="nav-button next"
        href={nav.next.url}
      >
        {nav.next.end && nav.course
          ? "Course Feedback"
          : `Next ${nav.next.type}`}
      </a>
    )}
  </div>
);

NavButtons.propTypes = {
  nav: PropTypes.object,
};
