import React from "react";
import PropTypes from "prop-types";

export const SectionHeader = ({ resource }) => (
  <div className="section-header">
    <div className="section-content">
      <h2>{(resource && resource.name) || "No title!"}</h2>
    </div>
    <div className="resource-icons">
      {resource.icons.map((i) => (
        <img
          key={i.id}
          className="resource-icon"
          src={i.source}
          alt="Resource Icon"
        />
      ))}
    </div>
  </div>
);
SectionHeader.propTypes = {
  resource: PropTypes.object,
};
