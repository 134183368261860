import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Abstract:</h3>

    <p>
      Teachers and school administrators are challenged with the complex task of
      providing engaging and relevant learning opportunities for students, while
      simultaneously managing federal and state-mandated standards of student
      achievement. High-stakes testing and 21st century workforce skills, and
      the challenge of preparing students for an unpredictable future lead
      educators to the investigation of new methods that address this complex
      instructional dilemma.
    </p>

    <p>
      The mission of the Systems Thinking in School Project is to increase the
      capacity of educators todeliver academic and lifetime benefits to students
      through the effective application of systems thinking concepts, habits and
      tools in classroom instruction and school improvement. This paper will
      describe the impact of the Systems Thinking in Schools project on 21st
      century student learning and achievement.
    </p>

    <p>
      <strong>Author:</strong> Waters Center for Systems Thinking
    </p>
  </div>
);
