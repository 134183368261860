import React from "react";
import PropTypes from "prop-types";

export const FilterBar = ({ handleTagChange }) => {
  const allTags = [
    { title: "Categories", tags: require("lib/tags/categories") },
    { title: "Habits", tags: require("lib/tags/habits") },
    { title: "Tools", tags: require("lib/tags/tools") },
    { title: "Archetypes", tags: require("lib/tags/archetypes") },
    { title: "Age Groups", tags: require("lib/tags/ageGroups") },
    { title: "Audience", tags: require("lib/tags/audience") },
    { title: "Language", tags: require("lib/tags/language") },
  ];

  return (
    <div>
      <div className="filter-bar group">
        <h2>Filter Resources</h2>
        <p>Click the checkboxes below to narrow your search.</p>
        {allTags.map((section, index) => (
          <div key={index} className="filter-section">
            <h3>{section.title}</h3>
            {section.tags.map((tag) => (
              <div key={tag.id} className="checkbox-field">
                <input
                  type="checkbox"
                  id={tag.id}
                  onClick={() => handleTagChange(tag.id)}
                />
                <label htmlFor={tag.id}>{tag.value}</label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

FilterBar.propTypes = {
  handleTagChange: PropTypes.func,
};
