import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img med-img"
      src="/images/courses/habits/13-accumulations/course-img.png"
      alt="Accumulations"
    />
    <p>
      What does it mean to pay attention to accumulations? The word
      “accumulation” is not typically used in everyday conversations. Let’s use
      the illustration to the right and the birdbath scenario below to help
      understand why accumulations are important to the understanding of
      systems.
    </p>
    <p>
      What factors are affecting the changing water level in this birdbath? If
      we consider the water as the accumulation in this system, the water level
      is being affected by several factors including the inflow from the hose,
      the heat from the sun resulting in evaporation, the droplets splashing
      over the side of the birdbath, and the birds taking water away by
      drinking.
    </p>
    <p>
      Elements in systems that change over time are called accumulations. Some
      accumulations, like water in the birdbath, are very concrete and easy to
      measure, while others are more abstract, like the woman's effort at
      keeping a comfortable water level for the birds in the birdbath. The very
      nature of the word accumulation suggests change. Accumulations are things
      that change. They are the nouns of systems.
    </p>
    <p>
      Examples in other systems of concrete accumulations include the number of
      animals in a forest, the number of students in a school or the amount of
      gas in a car’s tank.
    </p>
    <p>
      Examples of abstract accumulations in day-to-day systems include your
      changing level of fear while watching a horror movie or the amount of
      stress experienced at different times during a workday or fiscal quarter.
      These abstract accumulations may be hard to quantify numerically, but the
      rate and nature of the change can be observed or felt. This type of
      accumulation is often grounded in perception.
    </p>
  </div>
);
