// Habits Courses
import * as HabitsCourse01 from "components/courses/course-content/habits/01-introduction";
import * as HabitsCourse02 from "components/courses/course-content/habits/02-connections";
import * as HabitsCourse03 from "components/courses/course-content/habits/03-big-picture";
import * as HabitsCourse04 from "components/courses/course-content/habits/04-perspectives";
import * as HabitsCourse05 from "components/courses/course-content/habits/05-mental-models";
import * as HabitsCourse06 from "components/courses/course-content/habits/06-patterns";
import * as HabitsCourse07 from "components/courses/course-content/habits/07-assumptions";
import * as HabitsCourse08 from "components/courses/course-content/habits/08-structure";
import * as HabitsCourse09 from "components/courses/course-content/habits/09-circular-nature";
import * as HabitsCourse10 from "components/courses/course-content/habits/10-time-delays";
import * as HabitsCourse11 from "components/courses/course-content/habits/11-consequences";
import * as HabitsCourse12 from "components/courses/course-content/habits/12-considers-issue";
import * as HabitsCourse13 from "components/courses/course-content/habits/13-accumulations";
import * as HabitsCourse14 from "components/courses/course-content/habits/14-leverage";
import * as HabitsCourse15 from "components/courses/course-content/habits/15-successive-approximation";

// Tools courses
import * as ToolsCourse01 from "components/courses/course-content/tools/01-behavior-over-time-graphs";
import * as ToolsCourse02 from "components/courses/course-content/tools/02-ladder-of-inference";
import * as ToolsCourse03 from "components/courses/course-content/tools/03-stock-flow";
import * as ToolsCourse04 from "components/courses/course-content/tools/04-iceberg";
import * as ToolsCourse05 from "components/courses/course-content/tools/05-causal-links";
import * as ToolsCourse06 from "components/courses/course-content/tools/06-connection-circles";
import * as ToolsCourse07 from "components/courses/course-content/tools/07-cld-reinforcing";
import * as ToolsCourse08 from "components/courses/course-content/tools/08-cld-balancing";
import * as ToolsCourse09 from "components/courses/course-content/tools/09-bringing-loops-together";

// Archetypes courses
import * as ArchetypesCourse01 from "components/courses/course-content/archetypes/01-fixes-that-backfire";
import * as ArchetypesCourse02 from "components/courses/course-content/archetypes/02-success-to-the-successful";
import * as ArchetypesCourse03 from "components/courses/course-content/archetypes/03-limits-to-success";
import * as ArchetypesCourse04 from "components/courses/course-content/archetypes/04-escalation";
import * as ArchetypesCourse05 from "components/courses/course-content/archetypes/05-shifting-the-burden";
import * as ArchetypesCourse06 from "components/courses/course-content/archetypes/06-drifting-goals";
import * as ArchetypesCourse07 from "components/courses/course-content/archetypes/07-tragedy-of-the-commons";
import * as ArchetypesCourse08 from "components/courses/course-content/archetypes/08-accidental-adversaries";
import * as ArchetypesCourse09 from "components/courses/course-content/archetypes/09-repression-revolution";

import { CourseFeedback } from "components/courses/course-parts/course-feedback";
import { NavLevel, getCurrentNavSetup } from "components/shared/navigation";
import { addFormState } from "components/shared/answers";

export const courseList = addFormState(
  [
    {
      group: "habits",
      path: "01-introduction",
      name: "Habits Course #1: Beginning Your Systems Thinking Journey",
      image: "/images/courses/habits/01-introduction/course-img.png",
      summary:
        "Are you ready to begin your journey to becoming a systems thinker? This course helps prepare you for the learning that will take place in the Thinking Tools Studio by providing important definitions and reflection opportunities.",
      time: "1/2 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Identify the five basic life systems you will find throughout all of the TTS courses",
        },
        {
          id: 2,
          content:
            "Understand what we mean by “systems thinking” and a “system”",
        },
        {
          id: 3,
          content:
            "Define different elements, changes and goals within your system of interest",
        },
      ],
      lessons: [
        {
          path: "getting-started",
          name: "Lesson #1: Getting Started",
          navName: "Lesson #1",
          sections: [
            {
              path: "1",
              component: HabitsCourse01.Lesson01s01,
            },
            {
              path: "2",
              component: HabitsCourse01.Lesson01s02,
              form: true,
            },
            {
              path: "3",
              component: HabitsCourse01.Lesson01s03,
            },
          ],
        },
        {
          path: "systems-thinking",
          name: "Lesson #2: What Is Systems Thinking?",
          navName: "Lesson #2",
          sections: [
            {
              path: "1",
              component: HabitsCourse01.Lesson02s01,
              form: true,
            },
            {
              path: "2",
              component: HabitsCourse01.Lesson02s02,
              form: true,
            },
          ],
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: My Sample System",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse01.Exercise01,
          form: true,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: My Organization as a System",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse01.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "02-connections",
      name: "Habits Course #2: Makes Meaningful Connections Within and Between Systems",
      image: "/images/courses/habits/02-connections/course-img.png",
      summary:
        "This course will help you identify important connections in systems, allowing you to deepen your understanding of systems as a whole and the parts within them. You will also learn how different systems connect to one another and practical ways to use this knowledge to produce new ideas and thoughts. This includes creating visual representations of causal connections.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content: "Make meaningful connections within and between systems",
        },
        {
          id: 2,
          content:
            "Identify important connections within your own system of interest",
        },
        {
          id: 3,
          content: "Begin to understand complex cause and effect relationships",
        },
      ],
      lessons: [
        {
          path: "meaningful-connections",
          name: "Lesson #1: Makes Meaningful Connections",
          navName: "Lesson #1",
          component: HabitsCourse02.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Mapping Causal Connections",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse02.Exercise01,
          form: true,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Defining Systems and Their Connecting Parts",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse02.Exercise02,
          form: true,
        },
        {
          path: "transfer-connections",
          name: "Lesson #2: Making Transfer Connections",
          navName: "Lesson #2",
          component: HabitsCourse02.Lesson02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Making Connections Between Systems",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse02.Exercise03,
          form: true,
        },
        {
          path: "relationship-connections",
          name: "Lesson #3: Relationship Connections",
          navName: "Lesson #3",
          component: HabitsCourse02.Lesson03,
          form: true,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Personal Reflection",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse02.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "03-big-picture",
      name: "Habits Course #3: A Systems Thinker Seeks to Understand the Big Picture",
      image: "/images/courses/habits/03-big-picture/course-img.png",
      summary:
        "In this course, you will learn what it means to see the “big picture” of a system. You will begin to understand how this view, in conjunction with attention to detail, can bring about a broader perspective and understanding of systems as a whole, goals, actions, boundaries of influence and more.",
      time: "1 Hour",
      objectives: [
        { id: 1, content: "Understand what it means to see the “big picture”" },
        {
          id: 2,
          content: "Identify the big picture of your system of interest",
        },
        {
          id: 3,
          content:
            "Learn system boundaries and how to balance boundaries within a broad view of a system",
        },
      ],
      lessons: [
        {
          path: "big-picture-views",
          name: "Lesson #1: Big Picture Views",
          navName: "Lesson #1",
          component: HabitsCourse03.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Seeing the Big Picture",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse03.Exercise01,
          form: true,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Seeking and Balancing My Broader View",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse03.Exercise02,
          form: true,
        },
        {
          path: "system-boundaries",
          name: "Lesson #2: System Boundaries and the Big Picture",
          navName: "Lesson #2",
          component: HabitsCourse03.Lesson02,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Sample System Boundaries",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse03.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "04-perspectives",
      name: "Habits Course #4: A Systems Thinker Changes Perspectives to Increase Understanding",
      image: "/images/courses/habits/04-perspectives/course-img.png",
      summary:
        "In this course, you will learn how systems thinkers aim to see situations, experiences, viewpoints, and more, through the eyes of others. By doing so, we can see beyond our own deeply-held viewpoints and increase our understanding.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content: "Practice seeing/telling from a different perspective",
        },
        {
          id: 2,
          content:
            "Reflect on your own deeply-held beliefs and consider how they influence your perspective",
        },
        {
          id: 3,
          content:
            "Learn about dialogue as a discipline and the difference between “hearing” and “listening”",
        },
      ],
      lessons: [
        {
          path: "perspective-taking",
          name: "Lesson #1: Perspective Taking",
          navName: "Lesson #1",
          component: HabitsCourse04.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Story Perspectives",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse04.Exercise01,
          form: true,
        },
        {
          path: "sufis-tale",
          name: "Lesson #2: A Sufi’s Tale",
          navName: "Lesson #2",
          component: HabitsCourse04.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Changing Perspectives",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse04.Exercise02,
          form: true,
        },
        {
          path: "dialogue",
          name: "Lesson #3: Dialogue",
          navName: "Lesson #3",
          component: HabitsCourse04.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Sample System Perspectives",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse04.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "05-mental-models",
      name: "Habits Course #5: A Systems Thinker Considers How Mental Models Affect Current Reality and the Future",
      image: "/images/courses/habits/05-mental-models/course-img.png",
      summary:
        "Where do our viewpoints come from? Why do we place more or less value on certain things compared to others? In this course, you will learn about mental models and how they shape our views and actions, in addition to how surfacing our own mental models and those of others can deepen our understanding of system structure.",
      time: "1 Hour",
      objectives: [
        { id: 1, content: "Develop your own mental model awareness" },
        {
          id: 2,
          content:
            "Understand how the Iceberg framework helps us to surface mental models and how these mental models reinforce our beliefs and actions",
        },
        {
          id: 3,
          content: "Explore the impact of mental models within systems",
        },
      ],
      lessons: [
        {
          path: "mental-models",
          name: "Lesson #1: Mental Models",
          navName: "Lesson #1",
          component: HabitsCourse05.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Your Mental Model Awareness",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse05.Exercise01,
          form: true,
        },
        {
          path: "systems-thinking-iceberg",
          name: "Lesson #2: Systems Thinking Iceberg",
          navName: "Lesson #2",
          component: HabitsCourse05.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Exploring Mental Model Influence",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse05.Exercise02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Mental Models and the Iceberg",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse05.Exercise03,
          form: true,
        },
        {
          path: "mental-model-play",
          name: "Lesson #3: Serious Mental Model Play",
          navName: "Lesson #3",
          component: HabitsCourse05.Lesson03,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Introducing Mental Models to Others",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse05.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "06-patterns",
      name: "Habits Course #6: A Systems Thinker Observes How Elements Within Systems Change Over Time, Generating Patterns and Trends",
      image: "/images/courses/habits/06-patterns/course-img.png",
      summary:
        "Systems thinkers pay close attention to patterns and trends — even when they may not be immediately obvious. In this course, you will learn how to measure change and identify patterns and trends. By doing so, you can better understand your system of interest and get closer to your goals and desired outcomes.",
      time: "1 Hour",
      objectives: [
        { id: 1, content: "Observe change over time in systems" },
        {
          id: 2,
          content:
            "Recognize change, patterns and trends in your own life and system of interest",
        },
        {
          id: 3,
          content:
            "Make patterns and change visual by creating behavior-over-time-graphs (BOTGs)",
        },
        { id: 4, content: "Explain (“tell the story”) of your BOTGs" },
      ],
      lessons: [
        {
          path: "change-over-time",
          name: "Lesson #1: Change Over Time",
          navName: "Lesson #1",
          component: HabitsCourse06.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Reflecting on Change in Your Life",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse06.Exercise01,
          form: true,
        },
        {
          path: "patterns-trends",
          name: "Lesson #2: Making Patterns and Trends Visible with Behavior-Over-Time Graphs",
          navName: "Lesson #2",
          component: HabitsCourse06.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Perceptual Trends and Generic Behavior-Over-Time Graphs",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse06.Exercise02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Applying BOTGs to Your Personal Well-being",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse06.Exercise03,
          form: true,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Patterns and Trends in Your Work and Life",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse06.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "07-assumptions",
      name: "Habits Course #7: A Systems Thinker Surfaces and Tests Assumptions",
      image: "/images/courses/habits/07-assumptions/course-img.png",
      summary:
        "We all have beliefs and opinions — but what do we do when those beliefs are met with conflicting evidence? In this course, you will learn how to use listening strategies and visual tools to test your assumptions and weigh them against other viewpoints.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Consider multiple viewpoints on an issue, particularly when each view is supported by evidence",
        },
        { id: 2, content: "Weigh each side of a controversial claim" },
        { id: 3, content: "Surface and test your own assumptions" },
        {
          id: 4,
          content:
            "Use the Ladder of Inference to surface your beliefs and recognize how they influence your actions",
        },
        {
          id: 5,
          content: "Use behavior-over-time graphs to test your assumptions",
        },
      ],
      lessons: [
        {
          path: "investigating-beliefs",
          name: "Lesson #1: Investigating Beliefs",
          navName: "Lesson #1",
          component: HabitsCourse07.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Topics with Conflicting Information",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse07.Exercise01,
          form: true,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Surface and Test Your Own Assumption",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse07.Exercise02,
          form: true,
        },
        {
          path: "testing-assumptions",
          name: "Lesson #2: Testing Assumptions and the Power of Listening",
          navName: "Lesson #2",
          component: HabitsCourse07.Lesson02,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Reflective Quotations",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse07.Exercise03,
          form: true,
        },
        {
          path: "ladder-of-inference",
          name: "Lesson #3: The Ladder of Inference",
          navName: "Lesson #3",
          component: HabitsCourse07.Lesson03,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Increasing Your Awareness with the Ladder of Inference",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse07.Exercise04,
          form: true,
        },
        {
          path: "use-the-ladder-of-inference",
          name: "Lesson #4: Practices to Help You Use the Ladder of Inference",
          navName: "Lesson #4",
          component: HabitsCourse07.Lesson04,
        },
        {
          path: "exercise-5",
          name: "Practice Exercise #5: Second Time Around",
          navName: "Exercise #5",
          type: "exercise",
          component: HabitsCourse07.Exercise05,
          form: true,
        },
        {
          path: "botg-graphs",
          name: "Lesson #5: Behavior-Over-Time Graphs",
          navName: "Lesson #5",
          component: HabitsCourse07.Lesson05,
        },
        {
          path: "exercise-6",
          name: "Practice Exercise #6: Using BOTGs to Test Your Assumptions",
          navName: "Exercise #6",
          type: "exercise",
          component: HabitsCourse07.Exercise06,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "08-structure",
      name: "Habits Course #8: A Systems Thinker Recognizes that a System’s Structure Generates its Behavior",
      image: "/images/courses/habits/08-structure/course-img.png",
      summary:
        "In this course, you will take an in-depth look at the design of systems and learn to recognize structures within your own systems of interest. Through examples and practice exercises, you will learn how to alter the design of systems to produce desirable outcomes and get you closer to reaching your goals.",
      time: "1 Hour",
      objectives: [
        { id: 1, content: "Explore and understand system structures" },
        {
          id: 2,
          content:
            "Recognize structures in your system of interest and how adjusting structures may influence outcomes",
        },
        {
          id: 3,
          content:
            "Become aware of what it means to be a “system designer” and think of ways to implement structures that will create change",
        },
      ],
      lessons: [
        {
          path: "system-structure",
          name: "Lesson #1: Exploring Tangible System Structures",
          navName: "Lesson #1",
          component: HabitsCourse08.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Navigating Traffic System Structures",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse08.Exercise01,
          form: true,
        },
        {
          path: "hard-to-see",
          name: "Lesson #2: System Structures that Are Sometimes Hard to See",
          navName: "Lesson #2",
          component: HabitsCourse08.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Recognizing Structures in Your System",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse08.Exercise02,
          form: true,
        },
        {
          path: "system-design",
          name: "Lesson #3: Structure as System Design",
          navName: "Lesson #3",
          component: HabitsCourse08.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Becoming a Structural Designer",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse08.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "09-circular-nature",
      name: "Habits Course #9: A System Thinker Identifies the Circular Nature of Complex Cause and Effect Relationships",
      image: "/images/courses/habits/09-circular-nature/course-img.png",
      summary:
        "In this course, you will take a deep-dive into the causal nature of systems. You will learn the role of feedback in cause and effect relationships, and how this can create either a balancing or reinforcing scenario. You will also learn how to use causal loop diagrams to identify relationships within systems.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Understand and identify circular cause and effect relationships as feedback",
        },
        {
          id: 2,
          content: "Recognize how and when feedback reinforces behavior",
        },
        {
          id: 3,
          content:
            "Use causal loop diagrams to show causal relationships that exist between elements in a system",
        },
        {
          id: 4,
          content:
            "Know the difference between reinforcing and balancing causal relationships",
        },
      ],
      lessons: [
        {
          path: "circular-cause-and-effect",
          name: "Lesson #1: Circular Cause and Effect Relationships as Feedback",
          navName: "Lesson #1",
          component: HabitsCourse09.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Feedback that Causes Change",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse09.Exercise01,
          form: true,
        },
        {
          path: "causal-loop-diagrams",
          name: "Lesson #2: Causal Loop Diagrams",
          navName: "Lesson #2",
          component: HabitsCourse09.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Simple Loop Exercises",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse09.Exercise02,
          form: true,
        },
        {
          path: "reinforcing-feedback",
          name: "Lesson #3: Reinforcing Feedback",
          navName: "Lesson #3",
          component: HabitsCourse09.Lesson03,
        },
        {
          path: "balancing-feedback",
          name: "Lesson #4: Balancing Feedback",
          navName: "Lesson #4",
          component: HabitsCourse09.Lesson04,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Drawing Simple Causal Loops",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse09.Exercise03,
          form: true,
        },
        {
          path: "loops-to-increase-understanding",
          name: "Lesson #5: Using Feedback Loops to Increase Understanding",
          navName: "Lesson #5",
          component: HabitsCourse09.Lesson05,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Feedback Loop Practice",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse09.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "10-time-delays",
      name: "Habits Course #10: A Systems Thinker Recognizes the Impact of Time Delays When Exploring Cause and Effect Relationships",
      image: "/images/courses/habits/10-time-delays/course-img.png",
      summary:
        "Have you ever made a quick decision based on wanting instant gratification, only to experience unwanted consequences at a later time? In this course, you will come to understand why and how systems thinkers explore cause and effect relationships while recognizing the impact time can have on their decisions and actions.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Recognize everyday time delays and time delays in your own life",
        },
        { id: 2, content: "Identify time delays in causal loop scenarios" },
        {
          id: 3,
          content: "The impact (both positive and negative) of time delays ",
        },
        {
          id: 4,
          content:
            "Understand the Tragedy of the Commons Archetype as a demonstration of the effect of time delays",
        },
      ],
      lessons: [
        {
          path: "time-delays",
          name: "Lesson #1: Everyday Time Delays",
          navName: "Lesson #1",
          component: HabitsCourse10.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Time Delays in Your Life",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse10.Exercise01,
          form: true,
        },
        {
          path: "causal-loops",
          name: "Lesson #2: Time Delays in Causal Loops",
          navName: "Lesson #2",
          component: HabitsCourse10.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Applying the Tragedy of the Commons to Fishing",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse10.Exercise02,
        },
        {
          path: "impact-of-time-delays",
          name: "Lesson #3: The Impact of Time Delays",
          navName: "Lesson #3",
          component: HabitsCourse10.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Your Time as a Valued Resource",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse10.Exercise03,
          form: true,
        },
        {
          path: "stepping-back",
          name: "Lesson #4: Slowing Down and Stepping Back to Move Forward",
          navName: "Lesson #4",
          component: HabitsCourse10.Lesson04,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Effects of Time Delays",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse10.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "11-consequences",
      name: "Habits Course #11: A Systems Thinker Considers Short-term, Long-term and Unintended Consequences of Actions",
      image: "/images/courses/habits/11-consequences/course-img.png",
      summary:
        "How do you handle decision-making? Do you consider the short and long-term consequences of decisions? What happens when you make a decision for an immediate pay-off, but there are unintended consequences? In this course, you will learn the strategies systems thinkers use to ensure decisions and actions are carefully thought through to produce desired results.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Consider short and long-term consequences in various scenarios",
        },
        {
          id: 2,
          content:
            "Understand “fixes that backfire” and apply this understanding to casual loop diagrams",
        },
        {
          id: 3,
          content: "Weigh benefits and tradeoffs when making decisions",
        },
      ],
      lessons: [
        {
          path: "short-and-long-term-consequences",
          name: "Lesson #1: Short and Long-term Consequences",
          navName: "Lesson #1",
          component: HabitsCourse11.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Short-term Pain for Long-term Gain",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse11.Exercise01,
          form: true,
        },
        {
          path: "unintended-consequences",
          name: "Lesson #2: Unintended Consequences",
          navName: "Lesson #2",
          component: HabitsCourse11.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Fixes that Backfire",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse11.Exercise02,
          form: true,
        },
        {
          path: "benefits-and-trade-offs",
          name: "Lesson #3: Weighing Consequential Benefits and Trade-offs",
          navName: "Lesson #3",
          component: HabitsCourse11.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Revisiting Your Fixes that Backfire Scenario",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse11.Exercise03,
          form: true,
        },
        {
          path: "possible-consequences",
          name: "Lesson #4: Widening Your View to Possible Consequences",
          navName: "Lesson #4",
          component: HabitsCourse11.Lesson04,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Looking Ahead",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse11.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "12-considers-issue",
      name: "Habits Course #12: A Systems Thinker Considers an Issue Fully and Resists the Urge to Come to a Quick Conclusion",
      image: "/images/courses/habits/12-considers-issue/course-img.png",
      summary:
        "In this course, you will learn the process systems thinkers integrate for fully considering an issue. By taking the time to think things through, the more likely a high-quality outcome will be achieved.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Practice identifying short and long-term consequences of actions when making a decision",
        },
        {
          id: 2,
          content:
            "Be aware of potential pressures that could lead to reaching conclusions",
        },
        {
          id: 3,
          content:
            "Apply considering an issue fully and the benefits and tradeoffs of decisions to systems of interest",
        },
      ],
      lessons: [
        {
          path: "solving-problems",
          name: "Lesson #1: Deliberately Addressing Challenges and Solving Problems",
          navName: "Lesson #1",
          component: HabitsCourse12.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: The Decisions You Make in a Day",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse12.Exercise01,
          form: true,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Considering Future Actions",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse12.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "13-accumulations",
      name: "Habits Course #13: A Systems Thinker Pays Attention to Accumulations and Their Rates of Change",
      image: "/images/courses/habits/13-accumulations/course-img.png",
      summary:
        "In this course, you will become familiar with elements in systems that change over time and how you can affect these elements by taking (or avoiding) certain actions.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Understand and identify accumulations in your system of interest",
        },
        {
          id: 2,
          content:
            "Recognize how rates of change in a system affect accumulation",
        },
        {
          id: 3,
          content:
            "Identify the connection between accumulations and reinforcing feedback",
        },
        {
          id: 4,
          content:
            "Learn the basics of a stock-flow map and use it to demonstrate behavior in your system of interest",
        },
      ],
      lessons: [
        {
          path: "exploring-accumulations",
          name: "Lesson #1: Exploring Accumulations",
          navName: "Lesson #1",
          component: HabitsCourse13.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Identifying Accumulations — Things that Change in a System",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse13.Exercise01,
          form: true,
        },
        {
          path: "bathtub-thinking",
          name: "Lesson #2: Bathtub Thinking",
          navName: "Lesson #2",
          component: HabitsCourse13.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Your Important Accumulations",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse13.Exercise02,
          form: true,
        },
        {
          path: "understanding-through-example",
          name: "Lesson #3: Deepening Your Understanding Through Example",
          navName: "Lesson #3",
          component: HabitsCourse13.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Trust as an Accumulation",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse13.Exercise03,
          form: true,
        },
        {
          path: "beyond-bathtubs",
          name: "Lesson #4: Beyond Bathtubs: Other Metaphors for Accumulations",
          navName: "Lesson #4",
          component: HabitsCourse13.Lesson04,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Filling and Depleting Your Bucket",
          navName: "Exercise #4",
          type: "exercise",
          component: HabitsCourse13.Exercise04,
          form: true,
        },
        {
          path: "feedback-and-accumulations",
          name: "Lesson #5: Reinforcing Feedback and Accumulations",
          navName: "Lesson #5",
          component: HabitsCourse13.Lesson05,
        },
        {
          path: "stock-flow-maps",
          name: "Lesson #6: Stock-flow Maps",
          navName: "Lesson #6",
          component: HabitsCourse13.Lesson06,
        },
        {
          path: "exercise-5",
          name: "Practice Exercise #5: Accumulations and Stock-flow Maps",
          navName: "Exercise #5",
          type: "exercise",
          component: HabitsCourse13.Exercise05,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "14-leverage",
      name: "Habits Course #14: A Systems Thinker Uses Understanding of System Structure to Identify Possible Leverage Actions",
      image: "/images/courses/habits/14-leverage/course-img.png",
      summary:
        "In previous courses, you learned how a system’s structure generates its behavior. In this course, go one step further by using knowledge of system structure to identify points of leverage. You will practice drawing casual loop maps as a way to identify areas of leverage.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content: "Understand what we mean by “leverage” related to systems",
        },
        { id: 2, content: "Determine leverage actions in various scenarios" },
        { id: 3, content: "Use a causal loop map to identify leverage" },
      ],
      lessons: [
        {
          path: "leverage",
          name: "Lesson #1: Leverage",
          navName: "Lesson #1",
          component: HabitsCourse14.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Finding Leverage",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse14.Exercise01,
          form: true,
        },
        {
          path: "case-study",
          name: "Lesson #2: Case study — A Leadership Team Seeking Leverage",
          navName: "Lesson #2",
          component: HabitsCourse14.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Understanding the Team’s Causal Loops",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse14.Exercise02,
          form: true,
        },
        {
          path: "ways-to-look-for-leverage",
          name: "Lesson #3: Other Ways to Look for Leverage",
          navName: "Lesson #3",
          component: HabitsCourse14.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Mapping and Reflecting",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse14.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "habits",
      path: "15-successive-approximation",
      name: "Habits Course #15: A Systems Thinker Checks Results and Changes Actions If Needed: “Successive Approximation”",
      image:
        "/images/courses/habits/15-successive-approximation/course-img.png",
      summary:
        "We’re all on a journey of continuous improvement — whether it’s for our work, personal life, or the pursuit of acquiring a new skill, the act of improvement is a process. In this course, you will learn about successive approximation and the steps systems thinkers take when establishing a goal.",
      time: "1 Hour",
      objectives: [
        { id: 1, content: "Understand the successive approximation process" },
        {
          id: 2,
          content:
            "Identify gaps/plateaus and their influence on successive approximation",
        },
        {
          id: 3,
          content: "Use a behavior-over-time graphs to track desired change",
        },
      ],
      lessons: [
        {
          path: "successive-approximation-as-a-process",
          name: "Lesson #1: Successive Approximation as a Process",
          navName: "Lesson #1",
          component: HabitsCourse15.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Delivering Benefits",
          navName: "Exercise #1",
          type: "exercise",
          component: HabitsCourse15.Exercise01,
          form: true,
        },
        {
          path: "gaps-and-plateaus",
          name: "Lesson #2: Gaps and Plateaus",
          navName: "Lesson #2",
          component: HabitsCourse15.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: My Challenge",
          navName: "Exercise #2",
          type: "exercise",
          component: HabitsCourse15.Exercise02,
          form: true,
        },
        {
          path: "everyday-successive-approximation",
          name: "Lesson #3: Everyday Successive Approximation",
          navName: "Lesson #3",
          component: HabitsCourse15.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Intentional Awareness",
          navName: "Exercise #3",
          type: "exercise",
          component: HabitsCourse15.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "01-behavior-over-time-graphs",
      name: "Tools Course #1: Behavior-Over-Time Graphs (BOTGs)",
      image:
        "/images/courses/tools/01-behavior-over-time-graphs/course-img.svg",
      summary:
        "In this course, you will learn to draw behavior-over-time graphs to show patterns and trends, in addition to telling the story of a graph as a way to explain your assumptions about a system of interest.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content: "Identify elements of a system that are changing over time",
        },
        {
          id: 2,
          content: "Use BOTGs to represent and explain dynamic systems",
        },
        { id: 3, content: "Observe a system in motion" },
        { id: 4, content: "Label a graph’s axes in a variety of ways" },
      ],
      lessons: [
        {
          path: "making-things-visible",
          name: "Lesson #1: Making Thinking Visible",
          navName: "Lesson #1",
          component: ToolsCourse01.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1:  Drawing BOTGs",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse01.Exercise01,
          form: true,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Balloon Dynamics",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse01.Exercise02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Using Behavior-Over-Time Graphs to Tell a Story",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse01.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "02-ladder-of-inference",
      name: "Tools Course #2: The Ladder of Inference",
      image: "/images/courses/tools/02-ladder-of-inference/course-img.svg",
      summary:
        "In this course, you will learn about mental models and their impact on systems. You will also learn how to use the Ladder of Inference to recognize the reinforcing nature of our beliefs/actions, and how to use this tool to come to sound conclusions.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Identify the deep-seated beliefs, values, and viewpoints that influence our decisions and actions.",
        },
        {
          id: 2,
          content:
            "Understand how the Ladder of Inference works and the significance of each step of the ladder",
        },
        {
          id: 3,
          content:
            "Use the Ladder of Inference as a tool to test and surface your own assumptions in various scenarios",
        },
      ],
      lessons: [
        {
          path: "defining-mental-models",
          name: "Lesson #1: Defining Mental Models",
          navName: "Lesson #1",
          component: ToolsCourse02.Lesson01,
          form: true,
        },
        {
          path: "using-the-ladder",
          name: "Lesson #2:  Using the Ladder of Inference",
          navName: "Lesson #2",
          component: ToolsCourse02.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Testing Your Own Assumptions Using the Ladder of Inference",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse02.Exercise01,
          form: true,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Change in Perspective to Increase Understanding",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse02.Exercise02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Going Down the Ladder",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse02.Exercise03,
          form: true,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Applying the Ladder of Inference",
          navName: "Exercise #4",
          type: "exercise",
          component: ToolsCourse02.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "03-stock-flow",
      name: "Tools Course #3: Stock-Flow Mapping",
      image: "/images/courses/tools/03-stock-flow/course-img.svg",
      summary:
        "In this course, you will learn to recognize and map key accumulations that change over time in a system. Through practice exercises, you will become comfortable with drawing a simple stock-flow map to help you analyze a system of interest.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content: "Define the term “accumulation” as it relates to a system",
        },
        {
          id: 2,
          content: "Understand the basic components of a stock-flow map",
        },
        { id: 3, content: "Label the components of a stock-flow map" },
        {
          id: 4,
          content:
            "Build your own stock-flow map to analyze your own system of interest",
        },
      ],
      lessons: [
        {
          path: "accumulation",
          name: "Lesson #1: What is an Accumulation?",
          navName: "Lesson #1",
          component: ToolsCourse03.Lesson01,
          form: true,
        },
        {
          path: "basic-components",
          name: "Lesson #2:  Basic Components of a Stock-Flow Map",
          navName: "Lesson #2",
          sections: [
            {
              path: "1",
              component: ToolsCourse03.Lesson02s01,
              form: true,
            },
            {
              path: "2",
              component: ToolsCourse03.Lesson02s02,
            },
          ],
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Money in the Bank",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse03.Exercise01,
        },
        {
          path: "stock-and-flows",
          name: "Lesson #3: The Difference Between Stocks and Flows",
          navName: "Lesson #3",
          component: ToolsCourse03.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Building a Stock-Flow Map",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse03.Exercise02,
          form: true,
        },
        {
          path: "important-question",
          name: "Lesson #4: Important Questions to Ask When Drawing Stock-Flow Maps",
          navName: "Lesson #4",
          component: ToolsCourse03.Lesson04,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Going Deeper with Your Stock-Flow Map",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse03.Exercise03,
          form: true,
        },
        {
          path: "increasing-interdependence",
          name: "Lesson #5: Increasing Interdependence — Building Maps with Multiple Stocks",
          navName: "Lesson #5",
          component: ToolsCourse03.Lesson05,
          form: true,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: A Stock-Flow View of Building Employee Engagement",
          navName: "Exercise #4",
          type: "exercise",
          component: ToolsCourse03.Exercise04,
          form: true,
        },
        {
          path: "exercise-5",
          name: "Practice Exercise #5: Building a Multiple Stock Map",
          navName: "Exercise #5",
          type: "exercise",
          component: ToolsCourse03.Exercise05,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "04-iceberg",
      name: "Tools Course #4: The Iceberg",
      image: "/images/courses/tools/04-iceberg/course-img.svg",
      summary:
        "In this course, you will use the systems thinking iceberg to deepen your understanding of how system structure generates patterns, trends and outcomes.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content: "Understand the different levels/elements of the iceberg",
        },
        {
          id: 2,
          content:
            "Apply the elements of the iceberg to your system of interest",
        },
        {
          id: 3,
          content:
            "Recognize the other tools of systems thinking that can be used at each level of the iceberg",
        },
        {
          id: 4,
          content:
            "Create your own iceberg to address a persistent challenge in your system",
        },
        {
          id: 5,
          content: "Connect the iceberg to the Habits of a Systems Thinker",
        },
      ],
      lessons: [
        {
          path: "the-iceberg",
          name: "Lesson #1: The Iceberg as a Dynamic Framework",
          navName: "Lesson #1",
          component: ToolsCourse04.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Reflecting on Challenges Using the Iceberg Framework",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse04.Exercise01,
          form: true,
        },
        {
          path: "iceberg-as-a-tool",
          name: "Lesson #2: The Iceberg as a Tool",
          navName: "Lesson #2",
          component: ToolsCourse04.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Creating an Iceberg",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse04.Exercise02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: The Iceberg and Habits of a Systems Thinker",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse04.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "05-causal-links",
      name: "Tools Course #5: Causal Links",
      image: "/images/courses/tools/05-causal-links/course-img.svg",
      summary:
        "In this course, you will learn about the importance of causal connections and their influence on systems. You will be able to tell the story of causal relationships and apply this to elements within your own system of interest.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Identify key elements in a system that change over time and have causal connections ",
        },
        {
          id: 2,
          content:
            "Explain why attention to causal connections is important to understanding systems",
        },
        {
          id: 3,
          content:
            "Make and label causal links that show interdependence in a system",
        },
        {
          id: 4,
          content:
            "Tell the story of causal relationships that change in the same way and those that have opposite or inverse causal relationships",
        },
      ],
      lessons: [
        {
          path: "understanding-causal-interdependencies",
          name: "Lesson #1: Understanding Causal Interdependencies ",
          navName: "Lesson #1",
          component: ToolsCourse05.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Trends and Possible Causes",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse05.Exercise01,
          form: true,
        },
        {
          path: "drawing-causal-links",
          name: "Lesson #2: Drawing Causal Links",
          navName: "Lesson #2",
          component: ToolsCourse05.Lesson02,
        },
        {
          path: "causal-links-same",
          name: "Lesson #3: Causal Links that Change in the Same Direction  ",
          navName: "Lesson #3",
          component: ToolsCourse05.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Making Causal Connections that Change in the Same Direction",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse05.Exercise02,
          form: true,
        },
        {
          path: "causal-links-opposite",
          name: "Lesson #4: Causal Links that Change in the Opposite Direction",
          navName: "Lesson #4",
          component: ToolsCourse05.Lesson04,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Making Causal Connections that Change in the Opposite Direction",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse05.Exercise03,
          form: true,
        },
        {
          path: "other-strategies",
          name: "Lesson #5: Other Causal Link Drawing Strategies",
          navName: "Lesson #5",
          component: ToolsCourse05.Lesson05,
        },
      ],
    },
    {
      group: "tools",
      path: "06-connection-circles",
      name: "Tools Course #6: Causal Connection Circle Mapping",
      image: "/images/courses/tools/06-connection-circles/course-img.svg",
      summary:
        "In this course, you will apply your knowledge of causal links from the previous course to learn and create connection circles and causal connections maps as a way to identify interdependencies in systems.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content: "Identify key system elements that change over time",
        },
        {
          id: 2,
          content: "Make causal links that connect elements in a system",
        },
        {
          id: 3,
          content: "Label the nature of causality between causal pairs",
        },
        {
          id: 4,
          content:
            "Create a causal connection circle that shows the interdependencies in a system",
        },
        { id: 5, content: "See possible impacts on a system" },
        {
          id: 6,
          content: "Identify impacts that could serve as leverage actions",
        },
      ],
      lessons: [
        {
          path: "review",
          name: "Lesson #1: Review of Causal Links",
          navName: "Lesson #1",
          component: ToolsCourse06.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Telling the Story of a Causal Connection",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse06.Exercise01,
          form: true,
        },
        {
          path: "from-causal-links",
          name: "Lesson #2: From Causal Links to Connection Circles",
          navName: "Lesson #2",
          component: ToolsCourse06.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Making a Causal Connection Map",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse06.Exercise02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Ways to Use Your Causal Connection Circle Map",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse06.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "07-cld-reinforcing",
      name: "Tools Course #7: Causal Loop Diagrams Part 1: Reinforcing Feedback",
      image: "/images/courses/tools/07-cld-reinforcing/course-img.svg",
      summary:
        "Causal loop diagrams show feedback relationships that make up systems. In this course, you will learn about reinforcing feedback and how to draw it as one of the two types of causal loops.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Identify reinforcing feedback in your day-to-day life and work",
        },
        {
          id: 2,
          content: "Draw reinforcing feedback loops that tell causal stories",
        },
        { id: 3, content: "Tell the story of drawn reinforcing loops" },
      ],
      lessons: [
        {
          path: "meaning-reinforcing-feedback",
          name: "Lesson #1: The Meaning of Reinforcing Feedback",
          navName: "Lesson #1",
          component: ToolsCourse07.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Applying Real Life Occurrences to Reinforcing Scenarios",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse07.Exercise01,
          form: true,
        },
        {
          path: "drawing-reinforcing-loops",
          name: "Lesson #2: Drawing Reinforcing Causal Loops",
          navName: "Lesson #2",
          component: ToolsCourse07.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Drawing, Labeling and Storytelling with Causal Connections",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse07.Exercise02,
          form: true,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: Putting It All Together and Drawing a Loop ",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse07.Exercise03,
          form: true,
        },
        {
          path: "suggestions-drawing",
          name: "Lesson #3: Suggestions for Drawing Loops",
          navName: "Lesson #3",
          component: ToolsCourse07.Lesson03,
        },
        {
          path: "exercise-4",
          name: "Practice Exercise #4: Improving Variable Labels",
          navName: "Exercise #4",
          type: "exercise",
          component: ToolsCourse07.Exercise04,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "08-cld-balancing",
      name: "Tools Course #8: Causal Loop Diagrams Part 2: Balancing Feedback",
      image: "/images/courses/tools/08-cld-balancing/course-img.svg",
      summary:
        "As you learned in the previous course, causal loops are visual diagrams that show feedback relationships within systems. In this course, you will learn about balancing feedback, which is one of two types of causal loops.",
      time: "1 Hour",
      objectives: [
        {
          id: 1,
          content:
            "Identify balancing feedback in your day-to-day life and work",
        },
        {
          id: 2,
          content: "Draw balancing feedback loops that tell causal stories",
        },
        { id: 3, content: "Tell the story of drawn balancing loops" },
      ],
      lessons: [
        {
          path: "meaning-balancing-feedback",
          name: "Lesson #1: The Meaning of Balancing Feedback",
          navName: "Lesson #1",
          component: ToolsCourse08.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Identifying Balancing Scenarios",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse08.Exercise01,
          form: true,
        },
        {
          path: "drawing-balancing-loops",
          name: "Lesson #2: Drawing Balancing Causal Loops",
          navName: "Lesson #2",
          component: ToolsCourse08.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Draw Your Own Balancing Loop",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse08.Exercise02,
          form: true,
        },
        {
          path: "exploring-gaps",
          name: "Lesson #3: Exploring Gaps In Our Lives",
          navName: "Lesson #3",
          component: ToolsCourse08.Lesson03,
        },
        {
          path: "exercise-3",
          name: "Practice Exercise #3: A Team Approach to Identifying Gaps and Drawing Balancing Loops",
          navName: "Exercise #3",
          type: "exercise",
          component: ToolsCourse08.Exercise03,
          form: true,
        },
      ],
    },
    {
      group: "tools",
      path: "09-bringing-loops-together",
      name: "Tools Course #9: Causal Loop Diagrams Part 3: Bringing Reinforcing and Balancing Loops Together",
      image: "/images/courses/tools/09-bringing-loops-together/course-img.svg",
      summary:
        "In this course, you will explore various ways to use both reinforcing and balancing feedback loops to map a system of interest. It is highly recommended that you begin this course with a basic understanding of both reinforcing and balancing feedback.  We encourage you to first take these two courses as prerequisites (Tools courses #7 and #8).",
      time: "1 Hour",
      objectives: [
        { id: 1, content: "Extend your understanding of feedback loops" },
        {
          id: 2,
          content:
            "Read through and tell the stories represented in existing causal maps that have both reinforcing and balancing feedback",
        },
        {
          id: 3,
          content:
            "Apply your understanding of both reinforcing and balancing loops to your own causal maps",
        },
        { id: 4, content: "Determine loop dominance in casual maps" },
        { id: 5, content: "Realize the role of delays in causal maps" },
      ],
      lessons: [
        {
          path: "stories-of-complex-systems",
          name: "Lesson #1: Causal Loops as Stories of Complex Systems",
          navName: "Lesson #1",
          component: ToolsCourse09.Lesson01,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Revisit Your Scenario ",
          navName: "Exercise #1",
          type: "exercise",
          component: ToolsCourse09.Exercise01,
          form: true,
        },
        {
          path: "strategies-help-close-loop",
          name: "Lesson #2: Strategies to Help Close the Loop",
          navName: "Lesson #2",
          component: ToolsCourse09.Lesson02,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Creating Your Own Causal Map",
          navName: "Exercise #2",
          type: "exercise",
          component: ToolsCourse09.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "01-fixes-that-backfire",
      name: "Archetypes Course #1: Fixes that Backfire",
      image: "/images/courses/archetypes/01-fixes-that-backfire/course-img.svg",
      summary:
        "In this course, you will learn about the Fixes that Backfire archetype. You will identify ways to recognize this pattern in your own system of interest and strategies to avoid unintended consequences that may arise as a result.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize and apply the Fixes that Backfire archetype to situations in your work and in your world",
        },
        {
          id: 2,
          content:
            "Use the archetype to identify ways to prevent succumbing to the pattern",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse01.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse01.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse01.Exercise01,
          form: true,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          component: ArchetypesCourse01.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse01.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "02-success-to-the-successful",
      name: "Archetypes Course #2: Success to the Successful",
      image:
        "/images/courses/archetypes/02-success-to-the-successful/course-img.svg",
      summary:
        "In this course, you will learn to recognize and tell the Success to the Successful story. Through practice exercises, you will become comfortable with drawing a Success to the Successful archetype connected to a system of interest.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/success-to-the-successful-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/success-to-the-successful-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize and apply the Success to the Successful archetype to situations in your work and in your world",
        },
        {
          id: 2,
          content:
            "Use the Success to the Successful archetype as a tool to minimize competition for resources, to encourage collaboration and to seek equity",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse02.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse02.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse02.Exercise01,
          form: true,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          component: ArchetypesCourse02.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse02.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "03-limits-to-success",
      name: "Archetypes Course #3: Limits to Growth/Success",
      image: "/images/courses/archetypes/03-limits-to-success/course-img.svg",
      summary:
        "This archetype is referred to as both Limits to Growth and Limits to Success, depending on the story you’re telling. For brevity’s sake in writing, we’ll use the title Limits to Success, but may refer to both success and growth in examples. This course will help you identify the Limits to Success archetype and how to avoid constraints that hinder desired outcomes.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/limits-to-growth-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/limits-to-growth-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize and apply the Limits to Success archetype to situations in your work and in your world.",
        },
        {
          id: 2,
          content:
            "Identify how a system’s constraints may limit ongoing success.",
        },
        {
          id: 3,
          content:
            "Use the Limits to Success archetype to identify future problems.",
        },
      ],
      lessons: [
        {
          path: "story-of-this-archetype",
          name: "Lesson #1: The Story of this Archetype",
          navName: "Lesson #1",
          component: ArchetypesCourse03.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse03.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse03.Exercise01,
        },
        {
          path: "rewrite-the-story",
          name: "Lesson #3: Rewrite the Story",
          navName: "Lesson #3",
          component: ArchetypesCourse03.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2: Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse03.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "04-escalation",
      name: "Archetypes Course #4: Escalation",
      image: "/images/courses/archetypes/04-escalation/course-img.svg",
      summary:
        "In this course, you will learn to identify escalation scenarios in your own systems of interest and explore strategies to break and prohibit the cycle from occurring.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize escalating dynamics when two competing people or groups perceive or experience threatening actions and react with equally threatening responses that intensify over time.",
        },
        {
          id: 2,
          content:
            "Develop strategies to break out of escalating dynamic cycles.",
        },
        {
          id: 3,
          content:
            "Utilize the archetype to identify ways to avoid this potentially vicious dynamic.",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse04.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse04.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse04.Exercise01,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          sections: [
            {
              path: "1",
              component: ArchetypesCourse04.Lesson03s01,
            },
            {
              path: "2",
              component: ArchetypesCourse04.Lesson03s02,
            },
          ],
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse04.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "05-shifting-the-burden",
      name: "Archetypes Course #5: Shifting the Burden",
      image: "/images/courses/archetypes/05-shifting-the-burden/course-img.svg",
      summary:
        "In this course, you will learn to identify the Shifting the Burden archetype and the important difference between symptomatic and fundamental solutions and how they can impact a problem symptom.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize and apply the Shifting the Burden archetype to situations in your work and in your world.",
        },
        {
          id: 2,
          content:
            "Use the archetype to identify ways to prevent succumbing to the pattern.",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse05.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse05.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse05.Exercise01,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          component: ArchetypesCourse05.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse05.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "06-drifting-goals",
      name: "Archetypes Course #6: Drifting Goals",
      image: "/images/courses/archetypes/06-drifting-goals/course-img.svg",
      summary:
        "In this course, you will learn to identify ways to prevent giving in to pressure to lower a goal rather than wait to see if actions that have been taken would have eventually gotten you to your original goal.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/drifting-goals-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/drifting-goals-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize and apply the Drifting Goals archetype to situations in your work and in your life.",
        },
        {
          id: 2,
          content:
            "Use the archetype to identify ways to prevent succumbing to the pattern.",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse06.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse06.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse06.Exercise01,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          component: ArchetypesCourse06.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse06.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "07-tragedy-of-the-commons",
      name: "Archetypes Course #7: Tragedy of the Commons",
      image:
        "/images/courses/archetypes/07-tragedy-of-the-commons/course-img.svg",
      summary:
        "In this course, you will learn to recognize and apply The Tragedy of the Commons archetype. Through practice exercises, you will become comfortable with drawing the Tragedy of the Commons archetype and identify ways to avoid succumbing to the pattern.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize and apply the Tragedy of the Commons archetype to situations in your work and in your world.",
        },
        {
          id: 2,
          content:
            "Use the archetype to identify ways to prevent succumbing to the pattern.",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse07.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse07.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse07.Exercise01,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          component: ArchetypesCourse07.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse07.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "08-accidental-adversaries",
      name: "Archetypes Course #8: Accidental Adversaries",
      image:
        "/images/courses/archetypes/08-accidental-adversaries/course-img.svg",
      summary:
        "In this course, you will learn to recognize the accidental adversary dynamic when two people or groups are amicably working together toward a common goal and then, the actions of one person or group challenges and undercuts the success of another.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/accidental-adversaries-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/accidental-adversaries-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize the accidental adversary dynamic when two people or groups are amicably working together toward a common goal and then, the actions of one person or group challenges and undercuts the success of another.",
        },
        {
          id: 2,
          content:
            "Develop strategies to break out of an accidental adversary dynamic cycle.",
        },
        {
          id: 3,
          content:
            "Utilize the archetype to identify ways to avoid this potentially vicious dynamic.",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse08.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse08.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse08.Exercise01,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          component: ArchetypesCourse08.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse08.Exercise02,
          form: true,
        },
      ],
    },
    {
      group: "archetypes",
      path: "09-repression-revolution",
      name: "Archetypes Course #9: Repression and Revolution",
      image:
        "/images/courses/archetypes/09-repression-revolution/course-img.svg",
      summary:
        "In this course, you will learn to identify, apply and prevent the Repression and Revolution archetype. This archetype occurs when official policies are perceived as repressive and members of an oppressed group join together to act defiantly.",
      time: "1 Hour",
      downloads: [
        {
          id: 1,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-summary-so.pdf",
          label: "Archetype Summary",
        },
        {
          id: 2,
          url: "https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-template-so.pdf",
          label: "Archetype Template",
        },
      ],
      objectives: [
        {
          id: 1,
          content:
            "Recognize and apply the Repression and Revolution archetype to situations in your work and in your life.",
        },
        {
          id: 2,
          content:
            "Use the archetype to identify ways to prevent succumbing to the pattern.",
        },
      ],
      lessons: [
        {
          path: "here-is-the-story",
          name: "Lesson #1: Here is the Story",
          navName: "Lesson #1",
          component: ArchetypesCourse09.Lesson01,
        },
        {
          path: "case-in-point",
          name: "Lesson #2: Case in Point",
          navName: "Lesson #2",
          component: ArchetypesCourse09.Lesson02,
        },
        {
          path: "exercise-1",
          name: "Practice Exercise #1: Drawing it Out",
          navName: "Exercise #1",
          type: "exercise",
          component: ArchetypesCourse09.Exercise01,
        },
        {
          path: "rewrite-the-plot",
          name: "Lesson #3: Rewrite the Plot",
          navName: "Lesson #3",
          component: ArchetypesCourse09.Lesson03,
        },
        {
          path: "exercise-2",
          name: "Practice Exercise #2:  Telling Your Story",
          navName: "Exercise #2",
          type: "exercise",
          component: ArchetypesCourse09.Exercise02,
          form: true,
        },
      ],
    },
  ],
  ["lessons", "sections"],
  "courses",
  CourseFeedback,
);

export function getCoursesNav({ course, lesson, section }, isEnd = false) {
  return getCurrentNavSetup(
    [
      NavLevel(course, () => courseList, "course"),
      NavLevel(lesson, (c) => c.lessons, "lesson"),
      NavLevel(section, (l) => l.sections, "section"),
    ],
    "course-feedback",
    isEnd,
  );
}
