import * as React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { saveAnswers } from "./answers.actions";

// don't use capital letter here because this is a function, not a component
const getButtonText = (props, isForm) => {
  if (props.next && props.next.end && props.course) {
    console.log(props.course);
    return "Complete Course";
  }
  if (props.next && props.next.end && props.resource) {
    console.log(props.resource);
    return "Complete Resource";
  }
  if (isForm) {
    return "Save & Continue";
  }
  if (props.isCompleted) {
    return "Continue";
  }
  return "Mark Complete";
};

const FormComponentDisplayWrapper = (InnerComponent, isForm) => (props) => (
  <form onSubmit={props.handleSubmit}>
    <fieldset disabled={props.isLoading || props.isSaving}>
      <InnerComponent />
      <label id="phone">
        Phone (Required)
        <Field component="input" type="text" name="phone" />
      </label>
      <div className="complete-button">
        <button type="submit" className="form-submit">
          {getButtonText(props, isForm)}
        </button>
      </div>
    </fieldset>
  </form>
);

const FormComponentStateWrapper = (formId) =>
  connect(
    ({ answers }, ownProps) => {
      const data = answers.data[formId] || {};
      return {
        ...ownProps,
        isSaving: answers.isSaving,
        isLoading: answers.isLoading || !answers.isFresh,
        isCompleted: !!data.isCompleted,
        initialValues: data,
      };
    },
    (dispatch, ownProps) => ({
      onSubmit: async (d) => {
        await dispatch(
          saveAnswers(formId, {
            ...d,
            isCompleted: true,
          }),
        );
        ownProps.next === undefined
          ? (window.location.href = "/resources/end")
          : (window.location.href = `/${ownProps.next.url}`);
      },
    }),
  );

// we have to wrap creating the form component, because we need the id of the form to create it
export function createFormComponent(id, component, isForm) {
  const formComponent = reduxForm({
    form: id,
    enableReinitialize: true,
  })(FormComponentDisplayWrapper(component, isForm));
  return FormComponentStateWrapper(id)(formComponent);
}
