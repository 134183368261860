import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>
      Think of a time when you shared your mental model about something you care
      about. What did you learn about yourself when you shared it?
    </h4>
    <Field
      name="care-about"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How can you invite others to share their mental models?</h4>
    <Field
      name="invite-others"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How do you respond when mental models differ?</h4>
    <Field
      name="models-differ"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Think about the last meeting you attended or facilitated. It could be a
      work-related meeting or a community-based gathering. What was your
      impression of the meeting?
    </h4>
    <Field
      name="work-related"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How did people hear and interpret what was being said?</h4>
    <Field
      name="people-hear"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How did the facilitator provide participants with opportunities to voice
      their interpretations?
    </h4>
    <Field
      name="participants"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How might you interpret silence?</h4>
    <Field
      name="interpret-silence"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
