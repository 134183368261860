import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Materials:</h3>

    <ul>
      <li>
        Two versions of the “Goldilocks and the Three Bears” story (Dusty Locks
        and the Three Bears by Randy Cecil; Somebody and the Three Blairs by
        Marilyn Tolhurst)
      </li>

      <li>Markers</li>

      <li>Paper</li>
    </ul>

    <h3>Visual:</h3>

    <img
      className="lesson-img left"
      src="/images/resources/character-comparison/character-visual.png"
    />

    <h3>Lesson Structure:</h3>

    <h4>Prerequisite Skills:</h4>

    <ul>
      <li>Students are able to attend to a story read aloud.</li>

      <li>
        Students have experience answering questions during group activities.
      </li>

      <li>
        Students understand that there are different characters in a story and
        are able to follow one character.
      </li>
    </ul>

    <h4>Essential Questions:</h4>

    <ul>
      <li>What did the character think?</li>

      <li>How would you react in the situation?</li>
    </ul>

    <h3>Procedure:</h3>

    <h4>Day 1:</h4>

    <div className="figure-info">
      <img
        className="figure"
        src="/images/resources/character-comparison/figure1.svg"
      />

      <p>
        Introduce students to the book Dusty Locks and the Three Bears by Randy
        Cecil. Tell students that while you read the story you would like them
        to pretend that they are Dusty Locks. As Dusty Locks they will need to
        think about what she saw or heard when the bears came home, what she
        thought about it, and what she did. Then read the story. As you read the
        story, remind students that they should be thinking like Dusty Locks.
        (Make sure to point this out right before the bears wake up Dusty
        Locks.) After reading the story, have students complete the ladder of
        inference from bottom to top. (Figure 1,) When the ladder is complete
        ask students if they would have reacted the same way as Dusty Locks.
        Write down their responses.
      </p>
    </div>

    <h4>Day 2:</h4>
    <div className="figure-info">
      <img
        className="figure"
        src="/images/resources/character-comparison/figure2.svg"
      />

      <p>
        Repeat the activity from yesterday using the book, Somebody and the
        Three Blairs. This time tell students that they will be Somebody (the
        Bear). Tell them that they should be thinking about what Somebody heard
        or saw, thought or felt, and what he did. As you read the story, remind
        students what to think about. At the end, complete another ladder of
        inference (Figure 2) and ask students if they would make the same
        decision that Somebody made. Now that both ladders are complete, you can
        compare the two. Discuss why students agreed or disagreed with the
        characters. Ask students why they reacted differently as different
        characters. Ex.: “When you were Dusty Locks and you saw the bears you
        wanted to break the window and run, but when you were Somebody (the
        Bear) and you woke up to see the family you decided to say, ‘Hi.’ Why
        did you react so differently?”
      </p>
    </div>

    <h3>Possible Extensions:</h3>

    <ul>
      <li>
        Create two BOTGs. Have students analyze the level of fear each character
        had throughout the story. Pay special attention to the amount of fear
        the character has when she/he gets caught.
      </li>

      <li>
        Use the ladder of inference to compare additional versions of the
        “Goldilocks and the Three Bears” story.
      </li>
    </ul>

    <p>
      <strong>Lesson's Author:</strong> Jen Parker
    </p>
  </div>
);
