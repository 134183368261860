import * as React from "react";
import { Field } from "redux-form";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      One way to make connections is to find similarities in patterns, trends
      and structures.
    </p>
    <h4>
      How is the spread of a disease, as in an epidemic, similar to the
      spreading of a rumor on social media?
    </h4>
    <Field
      name="rumor"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How is the rise and fall of the stock market similar to the ups and downs
      of mood swings?
    </h4>
    <Field
      name="mood"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How is a suspenseful movie that ends with a satisfying resolution like a
      workplace challenge?
    </h4>
    <Field
      name="resolution"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
