import * as React from "react";
import { ToggleImages } from "components/shared/toggle-images";

export const Lesson01 = () => (
  <div className="content">
    <p>You might be in a “limits to success” scenario when …</p>

    <div className="quote">
      <p className="quote-text">
        You continue to make the same efforts to produce successful results, yet
        performance begins to diminish.
      </p>
    </div>

    <img
      className="lesson-img large-img block"
      src="/images/courses/archetypes/03-limits-to-success/limits-to-growth.svg"
      alt="Limits to Success"
    />

    <h3>The Story of this Archetype</h3>

    <div className="arch-components">
      <div className="arch-p-container">
        <p>
          <em>
            As you read each part of the story, press the “Next Part of Story”
            button below the graphic to see the story of the archetype unfold.
          </em>
        </p>

        <p>
          <strong>Part 1:</strong> The Limits to Success archetype story begins
          with efforts to increase performance.
        </p>

        <p>
          <strong>Part 2:</strong> Note the “S” label on the arrowhead to
          indicate an increase in performance which is caused by an increase in
          effort. Both variables are changing in the same direction.
        </p>

        <p>
          <strong>Part 3:</strong> A system’s initial efforts lead to an
          increase in performance. The success reinforces efforts and, in turn,
          the system experiences an increase in successful performance. A
          reinforcing loop is created and is noted by the “R” in the center of
          the loop. You can learn more about reinforcing feedback in{" "}
          <a href="/courses/07-cld-reinforcing">
            Tools Course #7, Causal Loop Diagrams Part 1: Reinforcing Feedback.
          </a>
        </p>

        <p>
          <strong>Part 4:</strong> However, almost all growth eventually
          encounters a constraint of some sort. The constraint leads to or
          results in a limiting action in the system. So, as performance grows,
          a limiting action increases.
        </p>

        <p>
          <strong>Part 5:</strong> Over time the limiting action causes the
          performance to slow down, plateau or decline. Reinforcing growth
          defers to a balancing process as limits within the system are
          approached. The balancing loop is noted with a B in the center of the
          loop. You can learn more about balancing feedback in{" "}
          <a href="/courses/08-cld-balancing">
            Tools Course #8, Causal Loop Diagrams Part 2: Balancing Feedback.
          </a>
        </p>
      </div>

      <div className="arch-build-container">
        <ToggleImages
          images={[
            {
              id: 1,
              image:
                "/images/courses/archetypes/03-limits-to-success/ltgs-step1.svg",
            },
            {
              id: 2,
              image:
                "/images/courses/archetypes/03-limits-to-success/ltgs-step2.svg",
            },
            {
              id: 3,
              image:
                "/images/courses/archetypes/03-limits-to-success/ltgs-step3.svg",
            },
            {
              id: 4,
              image:
                "/images/courses/archetypes/03-limits-to-success/ltgs-step4.svg",
            },
            {
              id: 5,
              image:
                "/images/courses/archetypes/03-limits-to-success/ltgs-step5.svg",
            },
          ]}
        />
      </div>
    </div>

    <h3>Putting it all together</h3>

    <p>
      In the Limits to Success archetype, the system’s performance increases as
      a result of initial efforts. The variables of effort and performance
      reinforce each other through a period of sustained growth. But the
      sustained growth will eventually run into a constraint that will slow the
      growth. This is when the reinforcing process of growth meets the balancing
      process of the constraining action. The constraint manifests itself in a
      balancing loop. The balancing loop shifts the dominance of the archetype
      from the reinforcing loop to the balancing loop.
    </p>

    <p>
      Constraints are often naturally occurring resistance and can be caused by
      limits inside or outside of the system. When limiting actions begin to
      slow growth, the tendency is to increase the initial efforts. But the
      system has reached a limit and will “push back” causing performance to
      continue to lag.
    </p>

    <div className="exerpt">
      <p>
        <strong>Reflection:</strong> Consider a time when an initiative was
        extremely successful but gradually plateaued. What was the initial
        reaction to the slow down?
      </p>
    </div>

    <p>
      Continue to Lesson 2 for examples of stories that fit the Limits to
      Success archetype.
    </p>
  </div>
);
