import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img med-img"
      src="/images/courses/habits/07-assumptions/course-img.png"
      alt="Surfaces and Tests Assumptions"
    />
    <p>
      In the illustration shown, two people are having different reactions to
      the graph depicting an upward trend. Let's assume the graph shows growing
      team performance. There are endless examples for what this graph could
      represent. The man is happy about the uptick in team performance, as it
      may mean an increase in compensation or a bonus. The woman sees it
      differently. She experiences anxiety when viewing the upward trend in team
      performance because she fears management will now have higher expectations
      of staff who are already carrying very heavy workloads. Who has the more
      accurate picture? They won’t really know until they test them with one
      another.
    </p>
    <h3>Investigating Beliefs</h3>
    <p>
      Systems thinkers articulate their belief, in this example, feeling
      positive or negative, and then look for evidence to support or refute this
      belief. Perhaps the upward trend in team performance is able to deliver
      both benefits (increased compensations) and setbacks (unmanageable
      workloads), but the two systems thinkers pictured here will only be able
      to make that determination after they share and test their beliefs. It
      takes courage to make your thinking visible to others. Whether you are
      stating an opinion on a community issue or sharing your personal
      impression of a social media post, surfacing the assumptions you hold can
      be risky. The risk involves the reaction of others who may hold a
      different belief about the same topic or experience.
    </p>
    <p>
      Research provides an example of surfacing and testing assumptions. Testing
      assumptions is fundamental to the research process. Research begins with a
      hypothesis. From there, an experimental design is developed, data are
      collected and analyzed, and then conclusions are drawn.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      As a specific example, there have been a number of studies that have
      investigated the benefits and trade-offs of having single-gender, or
      all-girl and all-boy, schools. The studies are driven by the assumption
      that boys and girls may perform better in single-gender schools, as
      mixed-gender schools may offer an increase in distractions between boys
      and girls or influence the nature of participation in class. These
      specific assumptions inform the collection and review of statistical data.
    </p>
    <p>
      The data from a single study can present one view. But what happens when
      conflicting studies and results both support and refute the hypothesis? A
      systems thinker takes on a wider view of analysis, increasing the
      likelihood that all assumptions and supporting data are surfaced, and
      makes efforts to test beyond single-sample studies or short-term
      investigations. Using research is one way to surface and test assumptions.
    </p>
    <div className="quote">
      <p className="quote-text">
        “The greatest compliment that was ever paid me was when one asked me
        what I thought, and attended to my answer. It takes two to speak the
        truth — one to speak and another to hear.”
      </p>
      <p className="quote-author">
        {" "}
        — Henry David Thoreau, essayist and philosopher
      </p>
    </div>
    <p>
      When you see conflicting messaging around statements supported by
      research, how do you test and weigh the value of each message? For
      example, suppose you like to drink a glass of red wine with dinner; you
      read a study’s findings that your practice is good for your health, so you
      support the finding and continue to drink wine. However, you also read a
      study that describes the negative effect of alcohol use on your health.
      Does that information cause you to change your behavior?
    </p>
    <p>
      How do you consider both viewpoints that are claiming to be supported by
      evidence? How do you test your own assumptions and the assumptions that
      arise from what you read and listen to in media reports and from experts
      in the field? In our internet-driven world, quick access to information
      makes this Habit extremely important to how we weed through evidence that
      leads to the formation of our beliefs. How can we develop a habit that
      helps us manage and test our own assumptions?
    </p>
  </div>
);
