import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <h3>Opportunity for Advancement</h3>

    <p>
      Two recently hired employees are being considered for advancement.
      Samantha and Cara are equally qualified. Both have degrees from good
      universities, a couple of years of solid experience and are motivated and
      enthusiastic. As their manager, you encourage both employees to consider
      the promotion and observe each of their work product to see which one
      demonstrates the most ability. Both are assigned a new project. Cara takes
      to the project immediately and is doing well on the job. Samantha is out
      with the flu, so she has a late start, and is a little behind. You
      encourage Samantha, but as Cara successfully completes assignments, you
      assign her more “valuable” work projects that allow her to continue to
      shine. Samantha is working well on her project, but you have not assigned
      her any additional work as you are focused on Cara’s success. After a
      couple of months, you promote Cara.
    </p>

    <h3>Work/Family Balance</h3>

    <p>
      The balance between professional life and personal life is an age-old
      challenge. Mac is successful in his profession and enjoys time with his
      family. When Mac devotes more time to work, his status and success at work
      benefit. Unfortunately, this leaves limited time for his family, which
      creates tension. His family isn’t exactly thrilled with him and the lack
      of attention and quality time they are receiving from him. Wanting to
      avoid the tension at home, and appreciating his opportunities for success
      at work, Mac spends even more time there.
    </p>

    <h3>Student Success</h3>

    <p>
      Principal Stenson is observing a third-grade class. The teacher is doing a
      good job using appropriate instructional practices, but the principal
      notices something. Reflecting that she has been at the school for five
      years and has observed all of these students for four years now, Principal
      Stenson notices that it seems to be the same children being called on,
      participating successfully, and receiving acknowledgement for their work.
      Some of the other students struggle with some minor behavioral issues,
      don’t raise their hands that much to participate, nor are they called upon
      to participate.
    </p>
  </div>
);
