import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Consider a familiar children’s story or fable (other than "The Three
      Little Pigs"). What if you retold that story from a different perspective?
      How might the story be different? Take a minute to jot down some thoughts.
    </p>
    <h4>
      What story did you choose? Describe how that story would change when told
      from another perspective.
    </h4>
    <Field
      name="choose-story"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How multiple perspectives help you discover new insights in your own life
      experiences?
    </h4>
    <Field
      name="new-insights"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
