import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <img
      className="lesson-img"
      src="/images/courses/habits/04-perspectives/lesson-two-img.png"
      alt="Sufi"
    />
    <p>
      The Sufi tale of “Elephant and the Blind Men" also illustrates the
      importance of multiple perspectives. In this folktale, the blind villagers
      encounter an elephant and seek to determine what it is from their
      individual, singular vantage points. The one who touches the ear thinks
      the elephant to be a large fan. Feeling the trunk, another villager
      describes a hollow pipe that is both powerful and destructive. The legs
      seem to be pillars providing great support. All the individual
      perspectives are incorrect. It is only when combining all the diverse
      perspectives that the villagers can hope to determine the true nature of
      the elephant.
    </p>
    <p>
      There are times our deeply-held viewpoints can interfere with the way we
      listen to others. Our viewpoints can also limit our ability to see
      perspectives other than our own. There is comfort in holding on to our own
      interpretations of events, people, ideas, etc., resulting in incomplete
      and inaccurate interpretations of systems. This holding-on tendency causes
      systems to become siloed, and results in missed opportunities of joining
      together and forming a collective understanding of the whole.
    </p>
    <p>
      This Habit goes beyond the recognition that people see things differently.
      It asks us to actively change our own perspectives; to get into other
      people’s shoes and see the system from a lens created from different
      experiences, backgrounds and cultures.
    </p>
  </div>
);
