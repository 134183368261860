import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/qXurV5l3BMw?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
    <p>
      This Open Studio Webinar, which occurred June 2, 2022, was facilitated by
      Alan Ticotsky.
    </p>
    <p>
      Join us as we explore ways to use systems thinking to help students
      understand complex environmental issues, ask good questions, maintain hope
      for a sustainable future, and seek solutions for problems. Alan Ticotsky,
      author of <em>Now What, A Call to Action</em>, was the guest presenter who
      shared some of his work and facilitated dialogue as we considered what
      action we could take going forward.
    </p>
  </div>
);
