import * as React from "react";
import { FileUpload } from "components/shared/file-upload";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <img
      id="connection-img"
      src="/images/courses/habits/02-connections/connections.svg"
      alt="Connections"
    />
    <p>
      There are several ways to map the causal interrelationships of systems.
      One simple way is by making a causal connection circle. See the{" "}
      <a href="/courses/06-connection-circles">
        {" "}
        Connection Circle Tools Course
      </a>{" "}
      for more detail, but to give you a sense of this tool, let’s think about a
      farming system.
    </p>
    <p>
      Begin by placing some of the key parts of the system in a circle. A few
      have been provided to get you started, but feel free to add additional
      parts.
    </p>
    <p>
      After you select key parts of your story, you are ready to make some
      causal connections. Here are some to get you started. The more plants
      there are, the more fruit is produced. The more fruit, the more seeds are
      available for future planting. The more predators (insects, deer, rabbits)
      there are, the fewer plants.
    </p>
    <h4>What additional connections can you add?</h4>
    <Field
      name="connections"
      component="textarea"
      className="reflection-input"
      placeholder="Enter your response here."
    />
    <Field
      name="causalArrows"
      component={FileUpload}
      image
      instructions="Copy the connection circle with some additional
      elements and causal arrows on a blank piece of paper. Take a photo and upload it
      below."
    />
    <p>
      In addition to simple paper-pencil mapping, here are online tools that
      offer different ways of creating visual representations of systems.
    </p>
    <ul>
      <li>
        Kumu
        <a href="https://kumu.io" target="_blank" rel="noopener noreferrer">
          {" "}
          https://kumu.io{" "}
        </a>
        is a data visualization platform that helps you organize complex
        information into interactive relationship maps.
      </li>
      <li>
        Pletica
        <a
          href="https://www.plectica.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          www.plectica.com{" "}
        </a>
        uses a map-building process to help you visualize and connect
        information. Maps can be constructed individually or you can build them
        as groups in real-time, with everyone’s ideas captured in a shared
        place.
      </li>
    </ul>
  </div>
);
