import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      A school’s leadership team was developing an improvement plan based on low
      student achievement data. The team was made up of a teacher from each
      grade level (6th, 7th and 8th grades), the principal and the school
      counselor. This team scheduled a series of meetings to consider a plan of
      improvement. Each meeting summary included some team dialogue, causal maps
      and reflection questions they used to get additional input from people
      outside of their team.
    </p>
    <h4>Team Members</h4>
    <ul>
      <li>Ms. Prieto, principal</li>
      <li>Mr. Chan, school counselor</li>
      <li>Mr. Gallego, 8th grade science teacher</li>
      <li>Ms. Holmes, 7th grade language arts teacher</li>
      <li>Ms. Taylor, 6th grade social studies teacher</li>
    </ul>
    <h3>LEADERSHIP TEAM MEETING 1</h3>
    <p>
      The meeting opened up with questions posed by Ms. Prieto: “Let’s begin
      sharing what is currently working well at our school. What are some of the
      positives we see going on?”
    </p>
    <img
      id="loop-one"
      src="/images/courses/habits/14-leverage/loop-one.svg"
      alt="Loop One"
    />
    <p>
      Much of the responses involved the value of teachers getting together to
      improve working relationships. There was an overwhelming sense that the
      climate of the school was healthy and that teachers generally liked one
      another and valued their teaching positions. The team concluded that when
      teachers spent time together outside of the classroom, they were able to
      build relationships. The value of improved relationships increased their
      desire to get together. See causal loop map #1 that summarizes the
      conversation.
    </p>
    <p>
      Toward the end of the meeting, Mr. Chan posed these questions: “What
      actually happens when teachers get together? What do they talk about?” The
      team decided that they would collect some information that would help them
      answer these questions and be ready to share responses during the next
      meeting.
    </p>
    <h3>LEADERSHIP TEAM MEETING 2</h3>
    <p>
      The discussion began with team members sharing the variety of responses to
      Mr. Chan’s questions from the previous meeting. They talked about the
      types of conversations teachers have when they get together. Responses
      included:
    </p>
    <ul>
      <li>How they balance their teaching and planning time</li>
      <li>How to best address parent concerns</li>
      <li>Helpful approaches to developing lesson plans</li>
      <li>Personal life outside of school</li>
    </ul>
    <p>
      Ms. Prieto, the principal, talked about the importance of teacher
      collaboration and how conversations needed to be more focused on teaching
      and learning and about the sharing of ideas impacting the classroom. They
      all agreed that collaboration was an expectation that needed to be more
      intentional.
    </p>
    <img
      id="loop-two"
      src="/images/courses/habits/14-leverage/loop-two.svg"
      alt="Loop Two"
    />
    <p>
      The team revisited their loop from meeting #1 and decided to expand their
      simple loop about teacher time together. They began to define teacher
      collaboration. The reinforcing loop (R) helps show the value of teacher
      collaboration and its impact on teacher buy-in to the collaborative
      process. See causal loop map #2.
    </p>
    <p>
      Ms. Taylor, 6th grade social studies teacher, brought up her concern about
      time, “Despite the value of getting together, there is only so much time
      available. When time is limited, teachers are not as eager to collaborate,
      resulting in less buy-in.” They all agreed that buy-in had to do with the
      degree that teachers valued collaboration time. If collaboration was
      useful, then teachers valued it and bought in to the process. If they
      found it a waste, they were reluctant to participate in collaborative
      sessions.
    </p>
    <p>
      They realized that despite the value of getting together, there was only
      so much time available. When time is limited, teachers are not as eager to
      collaborate, resulting in less buy-in (See label B1 in causal loop map
      #3). Time to collaborate became the focus of conversations about school
      improvement strategies.
    </p>
    <p>
      Their causal map #3 shows the give and take between the value of time
      spent collaborating (R1) and the reality that there is only so much time
      available to meet (B1). The expectation to collaborate and the time spent
      collaborating resulted in a concern about available time. The time loop
      (B1) caused a decrease in the buy-in and perceived value of collaboration.
    </p>
    <img
      id="loop-three"
      src="/images/courses/habits/14-leverage/loop-three.svg"
      alt="Loop Three"
    />
    <p>
      What they learned from their map was that teacher collaboration and time
      available were important aspects of school improvement. They left the
      meeting feeling like attention to available time for collaboration and the
      quality of collaboration sessions were key ingredients to their school
      improvement plan.
    </p>
    <h3>LEADERSHIP TEAM MEETING 3: BREAKTHROUGH</h3>
    <p>
      The team began the meeting reviewing causal loop map #3 which included
      both a reinforcing and balancing loop. What was not anticipated was a
      question from Mr. Gallego, 8th grade science teacher. He asked, “As we
      look at our map what seems to be missing?” He then responded to his own
      question, “Collaboration should be the means to focus on the students.
      Students are not even on our map.”
    </p>
    <p>
      The team then realized that the map was incomplete and that they failed to
      recognize key aspects of their system, namely students, who were left out
      of their planning conversation. Their drawing was focused on an adult
      system. This realization helped them reflect on the significant amount of
      time they had spent talking about adult issues. Ms. Holmes, 7th grade
      teacher, built on Mr. Gallego’s remarks.
    </p>
    <img
      id="loop-four"
      src="/images/courses/habits/14-leverage/loop-four.svg"
      alt="Loop Four"
    />
    <p>
      “We left the kids out of our map. We have been focusing too much on the
      adults. No wonder we haven’t moved the needle on student achievement. We
      just assumed if we improved the quality of adult collaboration that the
      students would benefit. Improving adult relationships may be important,
      but we also need leverage actions in our plan that focus on the students.”
    </p>
    <p>
      Returning to their causal map, the team began to add key student
      variables. This process helped them focus on student work as examples of
      learning, in addition to instructional strategies and evidence of student
      learning that were observable in classrooms. They determined that leverage
      was more about how collaboration should inspire change in the classroom.
      See causal loop map #4. This revised map was only the beginning. In
      subsequent meetings, they decided to involve students in the modification
      and ongoing development of the map. They realized that students should be
      involved in the process of “seeing system structure.” The addition of
      student voice eventually became a key aspect of leverage in their school
      improvement plan.
    </p>
  </div>
);
