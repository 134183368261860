import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { SectionLayout } from "./SectionLayout";
import PropTypes from "prop-types";

// This layout gets used for all lessons in a course (basically everything but intro)
export const LessonLayout = ({ course, lesson, match }) =>
  lesson.component ? (
    // if lesson has a single component, use that,
    // otherwise do routes for all the sections in the lesson
    <SectionLayout
      component={lesson.component}
      course={course}
      lesson={lesson}
    />
  ) : (
    <Switch>
      {lesson.sections.map((p) => (
        <Route
          key={p.path}
          path={`${match.url}/${p.path}`}
          render={() => (
            <SectionLayout
              component={p.component}
              course={course}
              lesson={lesson}
              section={p}
            />
          )}
        />
      ))}

      {/* for invalid paths, redirect to the first section of the lesson */}
      <Redirect to={`${match.url}/${lesson.sections[0].path}`} />
    </Switch>
  );
LessonLayout.propTypes = {
  course: PropTypes.object,
  lesson: PropTypes.object,
  match: PropTypes.any,
};
