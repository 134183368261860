import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-double"
        src="/images/resources/fixes-that-backfire/fixes-that-backfire-summary-so.svg"
        alt="Fixes that Backfire Summary"
      />

      <img
        className="template-double"
        src="/images/resources/fixes-that-backfire/fixes-that-backfire-template-so.svg"
        alt="Fixes that Backfire Template"
      />
    </div>
  </div>
);
