import * as React from "react";
import Helmet from "react-helmet";
// import { ToggleText } from 'components/shared/toggle-text'
// import { ToggleJsx } from 'components/shared/toggle-jsx'
// import { EventRegisterForm } from 'components/events/EventRegisterForm'
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const OnlineWorkshops = () => (
  <div>
    <Header />
    <div className="flow-container open-studio">
      <Helmet>
        <title>Online Workshops - Thinking Tools Studio</title>
        <meta
          name="description"
          content="Online systems thinking workshops focused on an area of interest."
        />
        <meta
          property="og:title"
          content="Studio Series Events - Thinking Tools Studio"
        />
        <meta
          property="og:description"
          content="Online systems thinking workshops focused on an area of interest."
        />
        <meta
          property="og:image"
          content="https://thinkingtoolsstudio.org/images/site/studio-series-logo-tagline.png"
        />
        <meta
          property="og:url"
          content="https://thinkingtoolsstudio.org/events/studioseries"
        />
        <meta
          name="twitter:image"
          content="https://thinkingtoolsstudio.org/images/site/studio-series-logo-tagline.png"
        />
        <meta
          name="twitter:image:alt"
          content="Online Workshops brought to you by the Waters Center for Systems Thinking"
        />
      </Helmet>
      <section className="photo-header studio-series">
        <h1>Online Workshops</h1>

        <img src="images/site/ow-icon.svg" />

        <h2>Online learning to expand your systems thinking journey</h2>
      </section>

      <section className="white stacked">
        <h3>
          Online Workshop registration has moved to the Waters Center website.
          Please visit the{" "}
          <a href="https://waterscenterst.org/workshops">
            Online Workshops page
          </a>{" "}
          there to see upcoming events and registration.
        </h3>

        {/* <p>
          Are you looking to deepen your systems thinking knowledge and skills? We offer online systems thinking workshops focused on specific areas of interest.
        </p>

        <p>
          Online Workshops include 12 hours of synchronous learning. Each participant in the United States will be mailed a set of Habits of a Systems Thinker cards.
        </p>

        <p>
          More information, price, and how to register can be found below.
        </p>

        <h3>
          Upcoming Workshops
        </h3> */}

        {/* <div className='studio-series-container'>

          <h4 className='centered'>
            <em>Leading for Impactful Change</em>
          </h4>

          <p className='centered'>
            <strong>
              Two consecutive days,  June 25 – 26, 2021 (2 sessions total)<br />
              Time: 25th: 2:00 p.m. – 6:00 p.m. PDT, 26th: 8:00 a.m. – 4:00 p.m. PDT
            </strong> (<a href='https://www.thetimezoneconverter.com/' target='_blank' rel='noopener noreferrer'>Time converter found here</a>)<br />
            <strong>Cost: $300/person</strong> (includes a set of Habits of a Systems Thinker cards for participants in the US)<br />
            $275/person for teams of 4-6<br />
            $250/person for teams of 7 or more<br />
            Contact <a href='mailto:events@waterscenterst.org'>events@waterscenterst.org</a> for team discount instructions.
          </p>

          <p>
            <em>Space is limited! Group size limited to 30 learners.</em>
          </p>

          <p>
            This workshop will help you develop your systems thinking capacity to lead impactful, sustainable change in your organization, university, company, community or team.
          </p>
          <ToggleJsx
            showText='Read more'
            hideText='Read less'
            jsx={
              <div>
                <p>
                  This workshop will benefit anyone who has a desire to:
                </p>

                <ul className='colorpoints'>
                  <li>
                    Develop a deep system understanding that inspires reflective practice, day-to-day decision-making and a vision for what is possible.
                  </li>

                  <li>
                    Break down silos and build high-functioning cross sector networks.
                  </li>

                  <li>
                    Build an internal capacity to develop systems thinkers at all levels.
                  </li>

                  <li>
                    Develop a culture where members actively trust, are willing to fail and learn, and commit to the value of “we are better together.”
                  </li>

                  <li>
                    Skillfully select and apply systems thinking tools, processes and methodologies to address specific organizational needs or challenges.
                  </li>
                </ul>

                <p>
                  Participants will gain skills that develop:
                </p>

                <ul className='colorpoints'>
                  <li>
                    A deep understanding of the system from various perspectives and positions within the organization
                  </li>

                  <li>
                    A shared language that includes timely open-ended questions that generate robust dialogue and new insights
                  </li>

                  <li>
                    Opportunities to apply systems thinking language, habits and tools to specific scenarios relevant to leadership roles and responsibilities
                  </li>
                </ul>
              </div>
            }
          />
        </div> */}

        {/* <div className='studio-series-container'>

          <h4 className='centered'>
            <em>Introduction to Systems Thinking</em>
          </h4>

          <p className='centered'>
            <strong>
              Tuesdays, June 29 – August 3, 2021 (6 sessions total)<br />
              Time: 2:00 p.m. – 4:00 p.m. PDT
            </strong> (<a href='https://www.thetimezoneconverter.com/' target='_blank' rel='noopener noreferrer'>Time converter found here</a>)<br />
            <strong>Cost: $300/person</strong> (includes a set of Habits of a Systems Thinker cards)<br />
            $275/person for teams of 4-6<br />
            $250/person for teams of 7 or more<br />
            Contact <a href='mailto:events@waterscenterst.org'>events@waterscenterst.org</a> for team discount instructions.
          </p>

          <p>
            <em>Space is limited! Group size limited to 30 learners.</em>
          </p>

          <p>
            Are you new to systems thinking? Are you familiar with some concepts, but looking for more practice and ideas for application? This Introduction to Systems Thinking workshop will benefit anyone looking to learn the foundations of systems thinking and how to apply this knowledge to their work and life.
          </p>

          <p>
            Each week will focus on a different aspect of systems thinking. Sessions will include in-depth practice and experience with the Habits of a Systems Thinker and systems thinking tools that are helpful in planning, communication, collaboration, problem-solving and decision-making. They include Behavior-over-time Graphs, Ladder of Inference, Systems Thinking Iceberg, Connection Circles, Causal Loops and Stock-flow maps.
          </p>
          <ToggleJsx
            showText='Read more'
            hideText='Read less'
            jsx={
              <div>
                <p>
                  Learning outcomes include, but are not limited to:
                </p>

                <ul className='colorpoints'>
                  <li>
                    Learners will develop knowledge of and skills in the use of systems thinking concepts, Habits and tools
                  </li>

                  <li>
                    Through interactive, inquiry-based instruction, learners will experience, practice, and apply systems thinking concepts, Habits and tools to personal
                    and professional goals
                  </li>

                  <li>
                    Learners will use systems thinking tools to address problem-solving and decision making and to identify high-leverage interventions
                  </li>

                  <li>
                    Learners will develop and share a plan of application relevant to their work
                  </li>
                </ul>
              </div>
            }
          />
        </div>

        <p>
          <em>Check back later for additional events!</em>
        </p> */}
      </section>

      {/* <section className='grey stacked'> */}

      {/* <h1 className='register-title'>
        Online Workshop Registration
      </h1>

      <h3>
        Step 1: Choose Workshop
      </h3>

      <p>
        Choose an upcoming workshop from the dropdown below, and click Submit. Then, proceed to Step 2.
      </p>

      <EventRegisterForm
        eventType='Online Workshop'
        promptView='none'
      />

      <h3>
        Step 2: Payment
      </h3>

      <p>
        To complete your registration, click "Buy Now" on the registration product below which will redirect you to our Shopify page where you can complete the payment with your preferred method.
      </p>

      <div id='product-component-1611349093440' className='buybutton' />

      <p>
        <em>For group rate instructions or to pay via PO or check, please contact <a href='mailto:romy.banks@waterscenterst.org'>romy.banks@waterscenterst.org</a> to complete the process.</em>
      </p>

      <p>
        <strong>Please Note: Your registration is not complete until the payment is made.</strong>
      </p> */}

      {/* { */}
      {/* <![CDATA[ */}
      {/* (function () {
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
        if (window.ShopifyBuy) {
          if (window.ShopifyBuy.UI) {
            ShopifyBuyInit()
          } else {
            loadScript()
          }
        } else {
          loadScript()
        }
        function loadScript () {
          var script = document.createElement('script')
          script.async = true
          script.src = scriptURL;
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script)
          script.onload = ShopifyBuyInit
        }
        function ShopifyBuyInit () {
          // eslint-disable-next-line no-undef
          var client = ShopifyBuy.buildClient({
            domain: 'waters-center-for-systems-thinking.myshopify.com',
            storefrontAccessToken: 'bdbc1703f4da230e7b807c0624997a03'
          })
          // eslint-disable-next-line no-undef
          ShopifyBuy.UI.onReady(client).then(function (ui) {
            ui.createComponent('product', {
              id: '6152924594362',
              node: document.getElementById('product-component-1611349093440'),
              moneyFormat: '%24%7B%7Bamount%7D%7D',
              options: {
                product: {
                  styles: {
                    product: {
                      '@media (min-width: 601px)': {
                        'max-width': 'calc(25% - 20px)',
                        'margin-left': '20px',
                        'margin-bottom': '50px'
                      }
                    },
                    button: {
                      'font-family': 'Montserrat, sans-serif',
                      'font-weight': 'bold',
                      'font-size': '16px',
                      'padding-top': '16px',
                      'padding-bottom': '16px',
                      ':hover': {
                        'background-color': '#dd841e'
                      },
                      'background-color': '#f69321',
                      ':focus': {
                        'background-color': '#dd841e'
                      },
                      'border-radius': '0px'
                    },
                    quantityInput: {
                      'font-size': '16px',
                      'padding-top': '16px',
                      'padding-bottom': '16px'
                    }
                  },
                  buttonDestination: 'checkout',
                  text: {
                    button: 'Buy Now'
                  },
                  googleFonts: [
                    'Montserrat'
                  ]
                },
                productSet: {
                  styles: {
                    products: {
                      '@media (min-width: 601px)': {
                        'margin-left': '-20px'
                      }
                    }
                  }
                },
                modalProduct: {
                  contents: {
                    img: false,
                    imgWithCarousel: true,
                    button: false,
                    buttonWithQuantity: true
                  },
                  styles: {
                    product: {
                      '@media (min-width: 601px)': {
                        'max-width': '100%',
                        'margin-left': '0px',
                        'margin-bottom': '0px'
                      }
                    },
                    button: {
                      'font-family': 'Montserrat, sans-serif',
                      'font-weight': 'bold',
                      'font-size': '16px',
                      'padding-top': '16px',
                      'padding-bottom': '16px',
                      ':hover': {
                        'background-color': '#dd841e'
                      },
                      'background-color': '#f69321',
                      ':focus': {
                        'background-color': '#dd841e'
                      },
                      'border-radius': '0px'
                    },
                    quantityInput: {
                      'font-size': '16px',
                      'padding-top': '16px',
                      'padding-bottom': '16px'
                    }
                  },
                  googleFonts: [
                    'Montserrat'
                  ],
                  text: {
                    button: 'Add to cart'
                  }
                },
                cart: {
                  styles: {
                    button: {
                      'font-family': 'Montserrat, sans-serif',
                      'font-weight': 'bold',
                      'font-size': '16px',
                      'padding-top': '16px',
                      'padding-bottom': '16px',
                      ':hover': {
                        'background-color': '#dd841e'
                      },
                      'background-color': '#f69321',
                      ':focus': {
                        'background-color': '#dd841e'
                      },
                      'border-radius': '0px'
                    }
                  },
                  text: {
                    total: 'Subtotal',
                    notice: 'Discount codes are added at checkout.',
                    button: 'Checkout'
                  },
                  googleFonts: [
                    'Montserrat'
                  ]
                },
                toggle: {
                  styles: {
                    toggle: {
                      'font-family': 'Montserrat, sans-serif',
                      'font-weight': 'bold',
                      'background-color': '#f69321',
                      ':hover': {
                        'background-color': '#dd841e'
                      },
                      ':focus': {
                        'background-color': '#dd841e'
                      }
                    },
                    count: {
                      'font-size': '16px'
                    }
                  },
                  googleFonts: [
                    'Montserrat'
                  ]
                }
              }
            })
          })
        }
      })() */}
      {/* ]]> */}
      {/* } */}

      {/* </section> */}

      {/* <section className='white stacked'>
        <h3>
          FAQ
        </h3>
        <ToggleText
          showText='More information about Online Workshops +'
          hideText='More information about Online Workshops -'
          text={[{ id: 1, text: 'Waters Center Online Workshops focus on a particular area of interest suitable for those new to systems thinking to seasoned practitioners.' }, { id: 2, text: 'Workshops include 12 hours of synchronous learning with Waters Center for Systems Thinking facilitator(s) and learners from around the globe.' }, { id: 3, text: 'Participants will build their systems thinking capacity and relationships among group members. It is our hope that the connections made continue to grow even after the workshop is complete.' }]}
        />

        <ToggleText
          showText='Is there a fee? +'
          hideText='Is there a fee? -'
          text={[{ id: 1, text: 'The cost is $300/person (includes 12 hours of collaborative, online workshop time, resources and supplies that will be mailed to you). Team discounts are available.' }]}
        />

        <ToggleText
          showText='How do I register? +'
          hideText='How do I register? -'
          text={[{ id: 1, text: 'To register, you must first be signed in to your Thinking Tools Studio Account. In the dark gray section above, follow Step 1 to choose the session you wish to register for from the dropdown and click Submit. Then, to complete your registration, follow Step 2 to complete the payment.' }, { id: 2, text: 'We will notify you when a new session is open for registration via email. If you have not already, be sure to sign up to the Thinking Tools Studio to receive these updates.' }]}
        />

        <ToggleText
          showText='Certificate of Completion and Online Accolade +'
          hideText='Certificate of Completion and Online Accolade -'
          text={[{ id: 1, text: 'Participants will receive a Certificate of Completion provided by the Waters Center for Systems Thinking. Participants will also receive an accolade of completion graphic that can be used on social media channels, in email signatures and elsewhere.' }]}
        />

        <p>
          Need help with events? <a href='mailto:events@waterscenterst.org'>Email us</a> at events@waterscenterst.org
        </p>

        <div className='studio-series-cancellation'>
          <h4>
            Online Workshop Cancellation, Substitutions & Refund Policy
          </h4>

          <ul>
            <li>
              Payment is required in full prior to the start of the Online Workshop.
            </li>

            <li>
              Full refund will be given if cancellation is received at least 14 days prior to the start of the Online Workshop.
            </li>

            <li>
              No refund given if cancellation is received less than 14 days prior to the Online Workshop, but you will receive a credit toward a future Online Workshop or you may send a substitute in your place in lieu of a credit.
            </li>

            <li>
              Online Workshops are subject to cancellation if a minimum of 20 participants is not reached. In this case, a full refund will be given.
            </li>
          </ul>
        </div>
      </section> */}
    </div>
    <Footer />
  </div>
);
