import * as React from "react";

export const Lesson03s01 = () => (
  <div className="content">
    <img
      className="lesson-img"
      src="/images/courses/archetypes/04-escalation/student-teacher.png"
      alt="Student and Teacher"
    />

    <p>
      This is a real-life example in which the Escalation archetype was used to
      help address a conflict between an 8th grade student, Trey, and his Math
      teacher, Ms. Hess. There was a disturbing pattern of behavior growing
      between Trey and his teacher. To address the ongoing conflict, the school
      counselor tried to intervene and help provide strategies for both Trey and
      Ms. Hess to mediate the situation. Despite those efforts, Ms. Hess and
      Trey’s problems continued: she submitted behavior referrals and he angrily
      increased his attempts to frustrate and upset Ms. Hess.
    </p>

    <p>
      At one point, Trey’s father, an attorney, asked for a meeting with the
      principal. He was upset with Ms. Hess and the growing pattern of conflict
      and insisted that his child was being treated unfairly. He demanded that
      Trey be transferred to a different math class. The principal recognized
      that Trey and Ms. Hess were in the midst of the Escalation archetype and
      she knew that Trey had learned this same tool in his social studies class
      while studying the Cold War.
    </p>

    <p>
      The father, student and principal met together to discuss the situation.
      The principal gave Trey a laminated copy of the escalation template and
      asked him to explain to his father what was going on. It went something
      like this,
    </p>

    <p>
      “Oh, we used this in social studies. Dad, this is just like the Cold War,
      but of course I am the United States and Ms. Hess is the Soviet Union. She
      sees me doing something that she thinks is bad, like making faces behind
      her back, and then calls me out and makes me move my seat. She keeps
      watching me and making me paranoid. She is out to get me and wants to show
      that she has the upper hand, and it makes me mad because it’s not fair.
      Other kids make fun of her, too. So, even when she moves me, I don’t do it
      on purpose, but other kids look at me because they think it’s funny when I
      imitate her. I make a face and everyone laughs. I get all their attention
      because they think I’m funny which really get Ms. Hess mad. So, guess what
      she does? She sends me to the office. It’s not my fault at all ‘cuz it
      wasn’t me who was laughing at her. That’s just one example, but this kind
      of thing happens all the time.”
    </p>

    <p>
      After a short period of silence, the father looked at the principal and
      said, “I think I am more informed now about what is going on. I was only
      looking at this from Trey’s perspective. Clearly, there is more to the
      story. I’m still upset with the teacher that she can’t better manage the
      situation, but it seems that both are at fault.” Then a surprising
      connection came next. He continued, “I am a divorce attorney. I can’t tell
      you how many times I hear similar situations like this between angry
      spouses. Can I have a copy of that template? I think I want to use it in
      my practice. Now, what are we going to do here?”
    </p>

    <p>
      The principal then said, “Let’s ask Trey. In social studies when you used
      the Escalation archetype to learn about the Cold War, did you ever talk
      about ways to break the escalating cycle of weapon stockpiling by the U.S.
      and Soviet Union?”
    </p>

    <p>
      Trey responded, “I am not sure if this really happened, but the only way
      to break the escalation figure 8 is if one or both parties decides to stop
      their actions to gain power and threaten one another or to create a
      truce.”
    </p>

    <p>
      The principal then asked, “What might that mean for next steps with you
      and Ms. Hess?” After some conversation that connected social studies with
      this troubling situation, a plan was agreed to ask the counselor to be the
      intermediary to help develop a truce-like contract with Trey and Ms. Hess.
      The truce would be monitored by the counselor as a “neutral party” to see
      if they could work together to put an end to this spiraling dynamic.
      Trey’s father agreed that it was worth a try and a good problem-solving
      experience for his son.
    </p>

    <div className="exerpt">
      <p>
        <strong>Fun fact:</strong> The principal in this story was Tracy Benson,
        current Waters Center for Systems Thinking President. The names were
        changed to protect the identities of all parties.
      </p>
    </div>
  </div>
);
