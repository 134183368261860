import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Introduction to Unit Plan Template</h3>

    <p>The unit plan template is divided into four sections:</p>

    <ul>
      <li>Basic Unit Information</li>

      <li>Stage 1 – Desired Results</li>

      <li>Stage 2 – Assessment Evidence</li>

      <li>Stage 3 – Learning Plan</li>
    </ul>

    <p>See blue header above to download the Unit Plan Temlpate.</p>
  </div>
);
