import * as React from "react";
import { Field } from "redux-form";

const Systems = ["well-being", "workplace", "school", "community", "family"];

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img"
      src="/images/courses/tools/03-stock-flow/lesson-one-img.png"
      alt="Bathtub"
    />
    <div className="quote">
      <p className="quote-text">
        “What a pure blessing it was to have a bath in a tub alone in a room
        where all you had to do was pump the water, not tote buckets. Then all
        you had to do was pull out the cork, not tote more buckets to the back
        porch — that kind of thing is easy to take lightly until you don't have
        it.”
      </p>
      <p className="quote-author"> — Nancy E. Turner, Sarah's Quilt</p>
    </div>
    <p>
      Few places in this world still require one to work as hard as Sarah Prine,
      the industrious, Arizona Territory pioneer woman, to draw a bath;
      nevertheless, regulating the faucet and the drain to fill a bathtub until
      the tub has just the right amount of water for a perfect bath is still an
      ideal metaphor for understanding stocks, flows, and their rates of change.
      So much so, that stock-flow mapping is sometimes referred to as “bathtub
      thinking.”
    </p>
    <p>
      Systems thinkers call accumulations (like the level of water in the
      bathtub) stocks. Stock-flow maps are a systems thinking tool that make
      bathtub thinking — the act of getting the accumulation to the desired
      level — visible.
    </p>
    <p>Accumulations can be concrete or abstract.</p>
    <p>
      Take a look at some examples of concrete stocks or accumulations that
      change over time in systems:
    </p>
    <ul>
      <li>Money in the bank</li>
      <li>Number of calories consumed each day</li>
      <li>Number of steps (Fitbit or step tracker)</li>
      <li>Unread emails in your inbox</li>
      <li>Cars in the parking lot</li>
      <li>Dirty laundry in the hamper</li>
      <li>Number of wins in a season for your favorite team</li>
      <li>Amount of water in the birdbath</li>
    </ul>
    <p>
      Stocks can also be about less concrete things that are sometimes hard to
      measure. For example:
    </p>
    <ul>
      <li>Level of stress</li>
      <li>Acts of kindness</li>
      <li>Joy in the workplace</li>
      <li>Trust among members of your team</li>
      <li>Commitment of team members</li>
      <li>Perceived quality of a school or district</li>
    </ul>
    <p>
      For more information on accumulation, view the Habits{" "}
      <a href="/courses/13-accumulations">Accumulations course</a>.
    </p>
    <h3>Reflection</h3>
    <h4>Choose a system of interest:</h4>
    <div className="system-select">
      {Systems.map((s) => (
        <div key={s} className={`system-select-item ${s}`}>
          <Field
            name="systemSelect"
            id={`ss-${s}`}
            component="input"
            type="radio"
            value={s}
          />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      ))}
    </div>
    <h4>
      Identify five to seven important accumulations for your system of
      interest.
    </h4>
    <Field
      name="identify"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
