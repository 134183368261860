import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <img
      className="large-img"
      src="/images/resources/levels-of-commitment/levels-of-commitment.png"
      alt="Making Connections"
    />
  </div>
);
