import * as React from "react";
import { Field } from "redux-form";

export const Lesson02s02 = () => (
  <div className="content">
    <h3>Defining a System</h3>
    <img
      className="lesson-img"
      src="/images/courses/habits/01-introduction/solar-system.jpg"
      alt="Solar System"
    />
    <p>
      A system is a group of interacting, interrelated, and interdependent parts
      or components that form a complex and unified whole. Ecological systems
      and human social systems are living systems; human-made systems such as
      cars and washing machines are nonliving systems. Most systems thinkers
      focus their attention on living systems, especially human social systems.
      However, many systems thinkers are also interested in how human social
      systems affect the larger ecological systems in our planet.
    </p>
    <p>
      Systems have several defining characteristics. Let’s explore the
      characteristics of a system using a classroom as a working system example.
      A classroom system can be viewed as a home school experience, or nested in
      a traditional public, private or charter school setting.
    </p>
    <h4>
      Every system has a purpose or goal within a larger system. Example: One
      purpose of a classroom system is to provide students with learning
      opportunities so that they can develop skills that will help them be
      successful in life. What are some other purposes of school, district and
      other alternative educational systems?
    </h4>
    <Field
      name="purpose"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      All systems have parts that are arranged in a specific way for the system
      to carry out its purpose. Example: What happens when classroom systems
      expect all children to learn the same way at the same pace, with no
      consideration of prior learning, language or skills? How can classroom
      systems be arranged to meet the diverse needs of all learners?
    </h4>
    <Field
      name="learners"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Systems change over time in response to feedback. The word feedback plays
      a central role in systems thinking. Feedback is information that returns
      to its original source such that it influences that initial source’s
      subsequent actions. The cause generated an effect and the effect feeds
      back to influence the initial causal source. Example: A teacher presents a
      lesson and observes evidence that some children are learning and some are
      confused. Examples of evidence could include enthusiastic responses,
      puzzled faces, blank pieces of paper or busy scribbling. The evidence a
      teacher observes feeds back to what he decides to do next in the lesson.
      Should the teacher move on, clarify by reteaching or think of another way
      to explain a concept?
    </h4>
    <Field
      name="scribbling"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      Think of a situation when you were struggling to learn something new. You
      needed something different than what the instructor was providing. What
      adjustments would have helped you to be more successful? How did the
      adjustments help ease your struggle?
    </p>
    <p>Tell your story by filling in the blanks.</p>
    <div className="fill-in">
      <p>As I struggled to learn</p>
      <Field name="learn" component="input" className="blank" />
      <p>, it caused my instructor to adjust and help me by</p>
      <Field name="adjust" component="input" className="blank" />
      <p>.</p>
      <p>
        After receiving this help from my instructor, those adjustments fed back
        and decreased my struggling over time.
      </p>
    </div>
  </div>
);
