import * as React from "react";
import { Field } from "redux-form";
import { FileUpload } from "components/shared/file-upload";
import { ToggleImage } from "components/shared/toggle-image";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Archetype</h3>

    <p>
      To begin,{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-blank-so.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        download and print a blank “fixes that backfire” template.
      </a>
    </p>

    <p>
      Next, select one or more of the three examples presented in Lesson 2: Case
      in Point.
    </p>

    <p>
      Now it is time to practice the Fixes that Backfire archetype. Fill in each
      of the boxes on your blank archetype template and then use it to retell
      the story.
    </p>

    <Field
      name="fixesThatBackfire-template"
      component={FileUpload}
      image
      instructions="Once you have filled in the template, take a photo and upload it below."
    />

    <p>
      There may be different ways to tell a story using the archetype framework,
      but in order to provide you some feedback, one version of each of the
      scenarios is available to you. After creating your version of the
      archetype diagram, click on the appropriate link below to see one version
      of the story.
    </p>

    <div className="archetype-examples">
      <div>
        <ToggleImage
          src="/images/courses/archetypes/01-fixes-that-backfire/chapped-lips.svg"
          className="med-img"
          alt="Chapped Lips"
          showText="Chapped Lips"
          hideText="Hide Example"
        />
      </div>

      <div>
        <ToggleImage
          src="/images/courses/archetypes/01-fixes-that-backfire/procrastination.svg"
          className="med-img"
          alt="Procrastination"
          showText="Procrastination"
          hideText="Hide Example"
        />
      </div>

      <div>
        <ToggleImage
          src="/images/courses/archetypes/01-fixes-that-backfire/sick-leave.svg"
          className="med-img"
          alt="Absenteeism"
          showText="Absenteeism"
          hideText="Hide Example"
        />
      </div>
    </div>
  </div>
);
