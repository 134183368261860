import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Abstract:</h3>

    <p>
      This resource reinforces the importance of thought-provoking questions
      when in coaching and collaborative settings.
    </p>

    <p>Download link can be found in the blue header at the top of the page.</p>
  </div>
);
