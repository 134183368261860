import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/v8YFMA4kBko?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
    <p>
      This Open Studio Webinar, which occurred January 6, 2022, was facilitated
      by David Stroh and Michael Goodman, whose global experiences using systems
      thinking span more than five decades. Their work includes teaching,
      facilitating, and consulting in the social, public, and private sectors.
      Viewers of this recording will:
    </p>
    <ul>
      <li>
        Learn about the change framework in David’s best-selling book Systems
        Thinking for Social Change: A Practical Guide for Solving Complex
        Problems, Avoiding Unintended Consequences, and Achieving Lasting
        Results (Chelsea Green, 2015)
      </li>
      <li>
        Get answers to commonly asked questions about the practice of applied
        systems thinking
      </li>
      <li>
        Explore possible case studies related to: building systems thinking
        capacity in an international environmental organization, increasing
        economic mobility in a major city, and addressing tensions in education
        governance
      </li>
    </ul>
    <p>
      For more information about David, Michael, and applied systems thinking,
      go to their website at{" "}
      <a
        href="https://appliedsystemsthinking.com"
        target="blank"
        rel="noopener noreferrer"
      >
        https://appliedsystemsthinking.com
      </a>
    </p>
  </div>
);
