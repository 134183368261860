import * as React from "react";

export const Section02 = () => (
  <div className="content">
    <h1>Lesson Structure</h1>

    <h3>Introduction</h3>

    <p>
      Lead a discussion about how students feel about the current classroom
      climate. Ask the following questions: How are you feeling about our
      classroom? Is our classroom a happy place? Are you noticing anything
      different? Are we getting along? What is happening to keep us from getting
      along? How do you feel when your friends make “unhappy choices”? What can
      we do to help make our classroom a happier place? How can we show our
      friends that we care?
    </p>

    <p>
      Tell your students that we will be starting a unit to help create a more
      caring classroom environment where everyone will feel safe and happy.
      Introduce the read alouds you chose for the behavior-over-time graphs.
      Introduce all the books at one time to build anticipation and excitement
      for what is to come.
    </p>

    <img
      src="images/resources/caring-classroom/botg-1.png"
      className="lesson-img med-img"
    />

    <h3>
      Read Aloud #1: <em>The Invisible Boy</em> by Trudy Ludwig – DAY 1 / BOTG
      #1
    </h3>

    <p>
      <em>
        (Please choose appropriate texts for your class to target specific
        behaviors.)
      </em>
    </p>

    <h4>
      Behavior Graphed: “HOW THOUGHTFUL THE CLASS WAS TO BRIAN AND JUSTIN”
    </h4>

    <p>
      Read the story to your students. I like to make these read alouds “movie
      reads,” meaning I read the stories to them without pausing to question or
      comment so they can enjoy the story without distraction. Students are able
      to construct their own meaning. Questioning takes place at the end of the
      story. After reading, lead a discussion on how students are feeling about
      the story. Ask questions like: What did you notice about student behavior?
      How do you think Brian felt? How did he feel “invisible”? Why did he feel
      “invisible”? Have any of you ever felt “invisible” (encourages
      self-connection)? Why do you think the students in the class treated Brian
      this way? Did anything change when Justin moved to town? How did the class
      treat Justin? How did Justin treat Brian? How did Brian treat Justin? When
      did the action of being thoughtful appear?
    </p>

    <p>
      Bring out the chart paper for the BOTG that will correlate to this story.
      I prefer to pre-draw the X and Y-axes, unlabeled, and pre-label the title
      of the graph <em>The Invisible Boy</em> by Trudy Ludwig across the top.{" "}
      <strong>Label the X-axis with your students</strong> as{" "}
      <em>beginning, middle,</em> and <em>end</em> to represent time in the
      story. Talk about levels of thoughtfulness throughout the story.{" "}
      <strong>Label the Y-axis together as well.</strong> You can{" "}
      <strong>
        hold a conversation to determine what words to use to represent
        different levels of thoughtfulness in the story.
      </strong>{" "}
      We decided on <em>very thoughtful, thoughtful,</em> and{" "}
      <em>inconsiderate</em> (see the visual). The Y-axis represents the
      thoughtfulness of the class.
    </p>

    <p>
      You will create two plot lines on this graph: one to represent how
      thoughtful the class was to Brian and the other to represent how
      thoughtful the class was to Justin. Each line should be drawn with a
      different color marker. The lines should be drawn as students share their
      thinking and reasoning. After you have drawn the two lines, compare and
      contrast the two lines. How are they the same? How are they different?
      What do you notice about the graph? Encourage students to utilize the
      vocabulary from the behavior-over-time graph:{" "}
      <em>very thoughtful, thoughtful,</em> and <em>inconsiderate</em>.
    </p>

    <p>
      Following the book discussion, connect thoughtfulness to your classroom
      climate. Ask: Have you noticed friends being thoughtful? How? How can we
      be more thoughtful? What have you done to show thoughtfulness?
    </p>

    <img
      src="images/resources/caring-classroom/botg-2.png"
      className="lesson-img med-img"
    />

    <h3>
      Read Aloud #1 Continued: <em>The Invisible Boy</em> by Trudy Ludwig – DAY
      2 / BOTG #2
    </h3>

    <p>
      <em>(Or book of your choice)</em>
    </p>

    <h4>Behavior Graphed: “BRIAN’S HURT FEELINGS”</h4>

    <p>
      Reread the story to your students. After you read the story, focus
      discussion on Brian’s feelings. How did Brian feel? What made him feel
      this way? Did his feelings change throughout the story? How? Why? Have you
      ever had hurt feelings like Brian? When? What happened? What happened to
      make you feel better?
    </p>

    <p>
      Bring out chart paper with the correlating BOTG. Have your X and Y-axes
      pre-drawn but unlabeled. Pre-label the title: <em>The Invisible Boy</em>{" "}
      by Trudy Ludwig. Decide as a class how to represent time across the
      X-axis. We chose <em>beginning, middle,</em> and <em>end</em>. Decide as a
      class what vocabulary to use to represent Brian’s hurt feelings along the
      Y-axis. My kindergarteners chose <em>extremely hurt, hurt,</em> and{" "}
      <em>not hurt (happy)</em> (see the visual). Discuss Brian’s feelings
      throughout the story. The line should be drawn as students share their
      thinking and reasoning.
    </p>

    <p>
      When the line has been drawn, discuss the graph. What do you notice about
      the graph? How did Brian’s feelings change? How did the line change as his
      feelings changed? Encourage students to utilize the vocabulary from the
      behavior-over-time graph: <em>extremely hurt, hurt,</em> and{" "}
      <em>happy</em>. Following the book discussion, connect hurt feelings to
      your classroom climate. Have you ever noticed that any of your friends’
      feelings were hurt? What can we do in our classroom to keep from hurting
      our friends’ feelings? When someone hurts your feelings, what can you do?
      When you hurt someone’s feelings, what should you do?
    </p>

    <img
      src="images/resources/caring-classroom/botg-3.png"
      className="lesson-img med-img clear"
    />

    <h3>
      Read Aloud: <em>The Most Magnificent Thing</em> by Ashley Spires – DAY 3 /
      BOTG #3
    </h3>

    <p>
      <em>(Or book of your choice)</em>
    </p>

    <h4>Behavior Graphed: “THE LITTLE GIRL’S ANGER”</h4>

    <p>
      “Movie read” the story to your students. Remember, a movie read is for
      enjoyment, without interrupting for questioning. Allow your students to
      build meaning as they listen to the story. After you read the story, focus
      discussion on the little girl’s feelings. Students will identify how angry
      she became. Ask the following questions: What made her angry? Why do you
      think this made her feel angry? Did she become angrier and angrier?
    </p>

    <p>
      Bring out chart paper with the correlating behavior-over-time graph as
      before, entitled <em>The Most Magnificent Thing</em> by Ashley Spires.
      Decide as a class how to represent time across the X axis. We chose{" "}
      <em>beginning, middle,</em> and <em>end</em>. Decide as a class what
      vocabulary to use to represent the little girl’s anger along the Y-axis.
      My kindergarteners chose <em>explosively angry, miffed,</em> and{" "}
      <em>not angry (pleased)</em> (see the visual). The line should be drawn as
      students share their thinking and reasoning. When the line has been drawn,
      discuss the graph: What do you notice about the graph? How did the little
      girl’s feelings change? How did the line change? How did the line change
      as her feelings changed? Encourage students to utilize the vocabulary from
      the behavior-over-time graph: <em>explosive, miffed, pleased</em>.
    </p>

    <p>
      Following the book discussion, connect anger to your classroom climate.
      Has anyone in our class ever made you feel angry? Explosive? Miffed? How
      did you handle your feelings? What can we do in our classroom if someone
      makes us feel angry? What should we not do if someone makes us feel angry?
    </p>

    <img
      src="images/resources/caring-classroom/botg-4.png"
      className="lesson-img med-img clear"
    />

    <h3>
      Read Aloud: <em>The Golden Rule</em> by Ilene Cooper – DAY 4 / BOTG #4
    </h3>

    <p>
      <em>(Or book of your choice)</em>
    </p>

    <h4>Behavior Graphed: “THE LITTLE BOY’S CONFUSION”</h4>

    <p>
      “Movie read” this story to your students. Allow your students to build
      meaning as they listen to the story. After you read the story, focus
      discussion on the little boy’s feelings. Students will identify how the
      little boy asked many questions. Ask your students why they think the
      little boy asked so many questions. You should guide your questioning so
      that they realize that the little boy felt confused. Ask the following
      questions: Why was the little boy confused? How did he finally come to
      understand the golden rule?
    </p>

    <p>
      Bring out chart paper with the correlating behavior-over-time graph as
      before, entitled <em>The Golden Rule</em> by Ilene Cooper. Decide as a
      class how to represent time across the X axis. We chose{" "}
      <em>beginning, middle,</em> and <em>end</em>. Decide as a class what
      vocabulary to use to represent the little boy’s confusion along the
      Y-axis. My class chose <em>baffled, confused, understood</em> (see the
      visual). Discuss the little boy’s level of confusion throughout, drawing
      the line on the graph as students share their thinking and reasoning. When
      the line has been drawn, discuss the graph: What do you notice about the
      graph? Did the little boy’s feelings change over time? How? How did the
      line change? How did the line change as the little boy’s feelings changed?
      Encourage students to utilize the vocabulary from the behavior-over-time
      graph: <em>baffled, confused, understood</em>.
    </p>

    <p>
      Following the book discussion, connect the feeling of confusion to the
      classroom climate. Have you ever felt confused? Why? What happened? How
      did you make sense of it? Have you ever felt baffled? Why? What happened?
      Did you ever make sense of it? What is the Golden Rule? How can we use
      that rule in our classroom? Give examples.
    </p>

    <h3>Stock-Flow Map Lesson – DAY 5</h3>

    <img
      src="images/resources/caring-classroom/stockflow-1.png"
      className="lesson-img med-img clear"
    />

    <p>
      Have the behavior-over-time graphs your class created within this unit
      displayed in close proximity to your stock-flow lesson space. You want
      your students to be able to revisit them as you work on the stock-flow
      map.
    </p>

    <p>
      The only portion of the stock-flow map that I prepare ahead of time is the
      title, Stock-Flow Map, at the top of the chart paper. Drawing the outline
      of the stock-flow map with students this age is crucial to their
      understanding of what the map means (the flow of the map, the stock,
      etc.).
    </p>

    <p>
      Tell your students that you will now create a Stock-Flow Map focusing on
      “Our Caring Classroom” as the stock. Sketch out the map and “think out
      loud” as you draw each part. Talk about what you are drawing and what it
      means. For example, when you draw the Inflow with a plus sign, talk about
      how the converters on the left-hand side will add to/contribute to the
      caring classroom. The Stock in the middle is “Our Caring Classroom.” The
      Outflow (with a take away symbol) on the right side will show converters
      that take away from the caring classroom (see the visual). Remind your
      students that the converters are the things we list that influence change.
      They affect the inflow and outflow, and they can affect other converters.
      In this case, the converters will be feelings or behaviors. Before you
      begin adding converters to your map, revisit the behavior-over-time graphs
      previously completed. Bring up several talking points from the discussions
      that took place when creating these BOTGs.
    </p>

    <p>
      Bring attention to the Inflow (+) side of the map. Ask students to
      brainstorm behaviors that would “add to” / “contribute to” / ”increase”
      Our Caring Classroom. List these converters as they share. Show that these
      converters affect the Inflow by drawing arrows from the converters to the
      faucet / Inflow (+). Tell your students that you will add to this map over
      time, as situations arise in the classroom. Now, bring attention to the
      Outflow (-) side of the map. It is VERY important that you use this
      terminology with your students: Inflow, Outflow, Stock, Converter, etc.
      Ask students to brainstorm behaviors that “take away from” / “decrease”
      Our Caring Classroom. List these converters as they share. Show that these
      converters affect the Outflow by drawing arrows from the converters to the
      drain / Outflow (-). ALLOW DISCUSSION TO TAKE PLACE. This is a lesson that
      you can plan a time allotment for but you’ll probably need to be flexible.
      You do not want to rush the discussions that grow from this activity.
    </p>

    <img
      src="images/resources/caring-classroom/stockflow-2.png"
      className="lesson-img med-img clear"
    />

    <p>
      <strong>
        Throughout the day and continued to the next day or so, find several
        10-minute blocks of time to stop and revisit the map.
      </strong>{" "}
      Add student thinking to the map as needed. When situations arise in the
      classroom that naturally contribute to this lesson, stop and add to the
      map. My class added so much to this particular stock-flow map that I felt
      like we needed to reorganize it and clean it up (See original and
      reorganized stock-flow maps.). You may or may not feel like you need to
      complete this part of the lesson plan. It is optional. However, if you do
      not complete the next step of reorganizing,{" "}
      <strong>
        make sure you add arrows to show what converters the Stock affects. The
        arrows should go from the stock out to the converters.
      </strong>
    </p>

    <p>
      Display the original stock-flow map beside the blank chart paper labeled
      with only “Stock-Flow Map” at the top. Again, as you sketch out components
      of the map, think out loud about what each component represents and means.
      Lead a discussion about how some of the behaviors (converters) listed are
      similar. Begin to sort behaviors together according to similarity. Give
      each group of behaviors a subtitle. For example, unkind words, rudeness,
      and being stingy can all go under the subtitle: Hurting Feelings (see
      Stock-Flow Map visual).{" "}
      <strong>
        Keep in mind that there is no one right way to organize the converters.
        The decision on how to organize them should be based on student
        discussion.
      </strong>{" "}
      After the behaviors (converters) have been sorted and organized, add the
      arrows back in to show how the converters affect the Inflow and Outflow.{" "}
      <strong>
        Now, lead a discussion on what converters the Stock affects.
      </strong>{" "}
      Draw the arrows to show student thinking. These arrows should go from the
      stock out to the converters.
    </p>

    <p>
      You will display your graphs and maps so that you can refer to them as
      classroom management tools. Your students will also refer to them to
      self-regulate or to help remind friends of behavioral choices.
    </p>

    <h1>Extension Activities</h1>

    <img
      src="images/resources/caring-classroom/good-friend.png"
      className="lesson-img med-img clear"
    />

    <h3>ACTIVITY #1: “WHAT IS A GOOD FRIEND?” BOOK</h3>

    <p>
      Introduce this activity as a fun extension of the in-depth discussion
      about behaviors and feelings that your class conducted over the last
      several days. Revisit the final stock-flow map and point out a few of the
      behaviors on the Inflow side that “add to” Our Caring Classroom. Show the
      cover of the book they will author. Read the title to them, “What is a
      Good Friend?” Ask them to think about that for a couple of minutes. Have
      students Turn and Talk to share ideas of what makes a good friend. Show
      the lined page to them. Explain that the purpose of this activity is for
      them to write a book about how to be a good friend. They will decorate the
      cover first. They will then add one page at a time to their book. Each
      page should depict a behavior that makes someone a good friend. For
      example, A good friend makes you smile. Then, they will illustrate their
      writing on each page. You want your students to add at least 4 pages with
      examples of how to be a good friend to their book. Staple their pages
      together when they are finished. This activity can be incorporated into
      Journal Writing, Writer’s Workshop, Literacy Centers, or an Early Finisher
      Activity.
    </p>

    <h3>ACTIVITY #2: FRIENDSHIP MIX</h3>

    <p>
      For this activity, you will need enough of the following for your class to
      each have one or two spoonfuls: pretzels, Chex mix, marshmallows, M&Ms,
      white chocolate chips, milk chocolate chips, Teddy Grahams, Goldfish (Feel
      free to add ingredients or substitute ingredients). Prepare a chart
      entitled: What is in Our Mix and Why. Have the ingredients listed but
      spread out so you will have room to write an explanation of why that
      ingredient is included in the Friendship Mix.
    </p>

    <p>
      Introduce the activity by explaining that each food item was chosen
      because it represents a way or ways to be a good friend. Go one ingredient
      at a time and discuss how that ingredient represents how to be a good
      friend. For example, fish swim in groups or schools. They stick together,
      just like friends do. (See chart and picture of mix.) As students share
      their thinking, record it on the anchor chart. When the chart is complete,
      hang it up. Talk about how each ingredient adds something great, just like
      each of your students do. When we put all of these ingredients together,
      we create the perfect Friendship Mix. I type this up and make copies for
      each student to take home and share with their families.
    </p>

    <p>
      Place bowls of each ingredient around the room and place a plastic spoon
      in each bowl. Give each student a sealable bag. Students will walk around
      and add ingredients to their bag. After they have added all of the
      ingredients, they will zip it up and mix it up to create the perfect
      Friendship Mix. You can let this serve as the daily snack or send it home
      with a copy of the Friendship Mix Recipe.
    </p>

    <div className="habits-row">
      <img
        src="images/resources/caring-classroom/mix-recipe.png"
        className="lesson-img med-img"
      />

      <img
        src="images/resources/caring-classroom/mix-bag.png"
        className="lesson-img med-img"
      />

      <img
        src="images/resources/caring-classroom/mix-page.png"
        className="lesson-img med-img"
      />
    </div>

    <p>
      Lesson’s Author: Marlee Clifton, Kindergarten Teacher, Auburn Early
      Education Center, Auburn City Schools
    </p>
  </div>
);
