import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <p>Here is an example to help you think about accumulations.</p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      A school principal leads a school where student enrollment is determined
      largely by parental choice. Keeping a sufficient number of students
      enrolled in school is critical to the school’s ability to maintain funding
      and keep the doors open.
    </p>
    <p>
      The principal works hard to attract students by offering tours, holding
      recruitment events at preschools and even advertising in local businesses
      in the attendance area. She is encouraged by the number of students coming
      to visit and the resulting number of students who register for her school.
      However, the actual number of students enrolled continues to decrease. The
      school is failing to retain the students it has enrolled. Some students
      are leaving due to family moves or other unavoidable circumstances, but
      many more are choosing to leave because a competing school nearby offers
      smaller class sizes. Too much attention paid to the inflow (students
      enrolling) without equal attention paid to the outflow (students leaving)
      results in a decreasing accumulation or stock (student enrollment).
    </p>
    <p>
      The number of students enrolled in a school is a tangible stock. It is
      easy to count. But accumulations can also be less tangible, such as
      kindness, respect or trust.
    </p>
    <p>
      Let’s further investigate trust as a key stock in a system. Identifying
      the factors that contribute to building trust and those that decrease
      trust can explain the bigger picture of organizational culture or family
      dynamics. The relationship between the influences that build and those
      that take away from an accumulation like trust helps identify why a trend
      is increasing or decreasing. If the factors contributing to trust are more
      dominant than those that are diminishing trust, trust will actually grow
      over time. However, the reverse is also true.
    </p>
  </div>
);
