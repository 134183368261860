import * as React from "react";
import { courseList } from "components/courses";
import { ToggleText } from "components/shared/toggle-text";
import { getSessionAuth } from "components/shared/session";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";
import Helmet from "react-helmet";

export const ArchetypesCatalog = () => {
  const { user } = getSessionAuth();

  const getAuth = (user) => ({
    divClass: !user ? "is-disabled" : "",
    buttonClass: !user ? "block" : "none",
  });

  return (
    <div>
      <Header />
      <div className="main">
        <Helmet>
          <title>Systems Archetypes Courses - Thinking Tools Studio</title>
          <meta
            name="description"
            content="Learn about and apply systems thinking archetypes."
          />
          <meta
            property="og:title"
            content="Systems Archetypes Courses - Thinking Tools Studio"
          />
          <meta
            property="og:description"
            content="Learn about and apply systems thinking archetypes."
          />
          <meta
            property="og:image"
            content="https://thinkingtoolsstudio.org/images/site/archetypes.svg"
          />
          <meta
            property="og:url"
            content="https://thinkingtoolsstudio.org/courses/archetypes"
          />
          <meta
            name="twitter:image"
            content="https://thinkingtoolsstudio.org/images/site/archetypes.svg"
          />
          <meta
            name="twitter:image:alt"
            content="Archetypes of systems thinking"
          />
        </Helmet>

        <div className="content">
          <h1 className="course-home-title">Systems Archetypes Courses</h1>

          <img className="course-home-img" src="/images/site/archetypes.svg" />

          <p>
            An archetype, as defined in the dictionary, is a pattern or model of
            a person or thing that serves as a recurring example. In literature,
            we have archetypical heroes such as the “One for all and all for
            one!” band of Three Musketeers. There are also archetypical symbols
            that appear repeatedly in literature, such as water symbolizing life
            or renewal.
          </p>

          <p>
            In these courses, you will learn about systems archetypes —
            multi-loop, causal loop diagrams that represent behavior seen
            commonly in complex systems.
          </p>

          <p>
            Just as literary archetypes help us increase perspective on the
            layered meaning of a story, the systems archetypes and their
            accompanying visual templates provide us lenses with which to view
            systems more clearly. Archetypes can help us analyze a situation,
            identify potential storylines that are unfolding, explore their
            implications and gain some initial understanding of an issue under
            study. Looking at an issue through the lenses of archetypes reminds
            us that there are multiple ways of perceiving any issue—and all of
            these ways can contribute insights into the issue.
          </p>

          <p>
            Each of the systems archetypes presents a specific lens through
            which to view a system because each archetype tells a specific
            archetypical story. The interdependencies within an archetype’s
            structure mirror those within a story’s structure. A story’s
            setting, characters’ traits, historical period, among other
            components create the story’s dynamic plot and its theme, i.e. the
            meaning of the story. Likewise, a systems archetype depends on the
            components of feedback, delays, and loop dominance to convey the
            dynamics within its particular story.
          </p>

          <p>
            After exploring the systems archetypes courses and the underlying
            stories associated with them, you will not only understand how
            archetypical they are, you will also appreciate their connections to
            the Habits of a Systems Thinker, and to many key aspects of systems
            thinking. In fact, one of the connections that the systems
            archetypes have to the Habits is that you’ll need to change your
            perspective at times as you are reading the visual representations
            of a given archetype! The visual representation of a given archetype
            can be horizontal/landscape, as in the first example showing
            elements X,Y, and Z of a system that interdependently cause changes
            to occur directly or indirectly on each other. Or, as is the case in
            the second example, the same archetype can be presented vertically,
            as a portrait. As you change your perspective to read the archetype,
            you’ll see that whether portrait or landscape, the picture captured
            tells the same story.
          </p>

          <p>
            <strong>
              Press the button below to review definitions of terms that you
              will encounter throughout the Archetype courses offered in the
              Thinking Tools Studio.
            </strong>{" "}
            Take some time to familiarize yourself with these definitions and
            refer back to this page as needed when going through the different
            Archetypes. If you have taken the Habits and tools courses, you will
            notice these terms are woven throughout.
          </p>

          <div className="arch-definitions">
            <ToggleText
              showText="Show Definitions"
              hideText="Hide Definitions"
              text={[
                {
                  id: 1,
                  text: "Causal Loop Diagram:",
                },
                {
                  id: 2,
                  text: "A causal loop diagram shows feedback within a system. Feedback refers to circular causal relationships inherent within a system. Causal loop diagrams help one understand and communicate the interactions that determine the dynamics of a system.",
                },
                {
                  id: 3,
                  text: "Delay:",
                },
                {
                  id: 4,
                  text: "Systems often have time delays between cause-and-effect elements. Links within causal loops and archetypes that include delays are designated with two hash marks (//).",
                },
                {
                  id: 5,
                  text: "Feedback:",
                },
                {
                  id: 6,
                  text: "“Feedback is said to occur when the effect of a cause re-affects the next iteration of the cause. The size of a population is the cause affecting, as feedback, the amount of the next change of population.” –An Introduction to Systems Thinking, (STELLA, isee systems, inc.)",
                },
                {
                  id: 7,
                  text: "“... process whereby an initial cause ripples through a chain of causation ultimately to re-affect itself …” –Introduction to Computer Simulation, A System Dynamics Modeling Approach, Roberts, Anderson, Deal, Garet, Shaffer, (1983)",
                },
                {
                  id: 8,
                  text: "Interdependencies:",
                },
                {
                  id: 9,
                  text: "Components of a system affect each other; the dynamics of the system are determined by the effects of the interdependencies, rather than by the components of the system themselves.",
                },
                {
                  id: 10,
                  text: "Leverage:",
                },
                {
                  id: 11,
                  text: "Leverage is a way of \"seeing where actions and changes in structures can lead to significant, enduring improvements.\" –The Fifth Discipline, The Art and Practice of the Learning Organization, Senge, Doubleday. (1990)",
                },
                {
                  id: 12,
                  text: "Loop Dominance:",
                },
                {
                  id: 13,
                  text: "In a system that has multiple loops operating simultaneously, one loop may have a stronger impact than other loop(s) within the system. This loop dominance can shift over time. A loop that is initially more powerful can eventually become weaker relative to other loops. The Fixes-that-Fail (Fixes-that-Backfire) is one example in which loop dominance shifts over time.",
                },
                {
                  id: 14,
                  text: "Systems Archetypes:",
                },
                {
                  id: 15,
                  text: "Systems archetypes are multi-loop, causal loop diagrams that represent behavior seen commonly in complex systems. They can be lenses with which to view systems more clearly. Archetypes can help us analyze a situation, identify potential story lines at work, explore their implications and thus gain some initial understanding of the issue under study.",
                },
                {
                  id: 16,
                  text: "System Structure:",
                },
                {
                  id: 17,
                  text: "System structure includes the elements of the system and the manner in which those elements are organized and interrelated.",
                },
              ]}
            />
          </div>

          <p>
            You are invited to explore the archetypes within these courses and
            then consider how you might help colleagues or students use them to
            explore complex systems and perhaps uncover leverage points within
            the organization, within the systems they are studying, and within
            their lives.{" "}
            <strong>
              It is recommended that as a prerequisite to Archetypes courses you
              take Tools courses #7-9 to familiarize yourself with feedback
              loops.
            </strong>
          </p>

          <div className="course-cards">
            {/* For selected group, display list of courses */}
            {courseList
              .filter((c) => c.group === "archetypes")
              .map((c) => (
                <div key={c.path} className="card-content">
                  <img className="card-img" src={c.image} alt="Course Image" />
                  <div className="course-info">
                    <h2>
                      <a
                        className={getAuth(user).divClass}
                        href={`/courses/${c.path}`}
                      >
                        {c.name}
                      </a>
                    </h2>
                    <p className="card-summary">{c.summary}</p>
                    <p className="card-time">
                      <b>Estimated Time:</b> {c.time}
                    </p>
                    <a href="https://waterscenterst.org/login">
                      <button
                        style={{ display: getAuth(user).buttonClass }}
                        className="auth-button"
                      >
                        Log in or Sign Up to View This Content
                      </button>
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
