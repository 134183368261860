import * as React from "react";
import { ProgressBar } from "components/courses/course-parts/progress-bar";
import { LessonHeader } from "components/courses/course-parts/lesson-header";
import { getCoursesNav } from "components/courses/courseList";
import { DownloadBar } from "components/courses/course-parts/download-bar";
import PropTypes from "prop-types";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

// This layout gets used for all sections in a lesson
// It is also used for single section lessons (like exercises), for now.  We may want to change this
export const SectionLayout = (props) => {
  const { course, lesson, section } = props;
  const nav = getCoursesNav({ course, lesson, section });

  return (
    <div>
      <Header margin="margin" />
      <div className="progress-margin">
        <LessonHeader course={course} lesson={lesson} />
        <DownloadBar course={course} />
        {/* display actual page */}
        <props.component {...nav} />
        <ProgressBar nav={nav} />
      </div>
      <Footer margin="margin" />
    </div>
  );
};
SectionLayout.propTypes = {
  margin: PropTypes.bool,
  course: PropTypes.object,
  lesson: PropTypes.object,
  section: PropTypes.object,
};
