import * as React from "react";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const Support = () => (
  <div>
    <Header />
    <div className="main">
      <div className="content">
        <h1>Support</h1>
        <h2>We’re here to help!</h2>
        <p>
          Are you having technical issues or do you have a question regarding
          the functionality of the Thinking Tools Studio? Email us at{" "}
          <a href="mailto:tech@waterscenterst.org">tech@waterscenterst.org</a>.
        </p>
        <p>
          For general inquiries, contact{" "}
          <a href="mailto:info@waterscenterst.org">info@waterscenterst.org</a>.
        </p>
        <p>We do our best to respond to all emails within 24 hours.</p>
      </div>
    </div>
    <Footer />
  </div>
);
