import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <p>
      Causal maps help illustrate a system’s interdependencies and structure.
      Visual maps also help identify areas of impact and places to look for
      leverage. When looking at a causal map, there are a number of strategies
      to help identify areas of leverage and ways to intervene in a system.
    </p>
    <ul>
      <li>
        Look for loops that are friends and foes. Which loops do we want to
        maintain? Which do we want to see changed or removed?
      </li>
      <li>
        Look for the elements that have the greatest number of connections to
        other system elements, or the element that has the most number of arrow
        tails. Consider that a change in that one element will have a
        significant ripple effect to other aspects of the system.
      </li>
      <li>Consider how to strengthen, weaken or break a causal link.</li>
      <li>Add a loop that will positively impact the whole.</li>
      <li>
        Periodically ask: what is missing? What have we not yet considered?
      </li>
    </ul>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      The following example does not explicitly use causal loops to map system
      structure and highlight areas of leverage. As you read through this
      scenario, try to practice by drawing a loop or two describing this team’s
      process for addressing a company challenge. What does it look like when an
      understanding of a system’s structure is used to identify possible
      leverage actions? This example taps into the various ways people in
      different roles view the system and the challenges at hand.
    </p>
    <p>
      XYZ manufacturing company desperately needed to lower production costs,
      but for ideological reasons wanted to maintain the manufacturing of their
      product in their home country. Several division managers gathered to
      surface options for next steps. After a lengthy discussion about the
      desire to lower production costs and a recap of both financial and
      operational conditions, team members began generating ideas.
    </p>
    <h3>XYZ MANUFACTURING TEAM IDEAS</h3>
    <div className="team-ideas">
      <div className="team-member">
        <img
          className="team-img"
          src="/images/courses/habits/14-leverage/suzanne.png"
          alt="Suzanne"
        />
        <p className="member-name">Suzanne, Senior Manufacturing Engineer</p>
        <p className="member-text">
          We could invest in advanced automation that would reduce labor costs,
          but that would take an initial investment that we don’t have. The
          investment could pay off over time with reduced labor, but our
          employee morale could suffer because of lay-offs.
        </p>
      </div>
      <div className="team-member">
        <img
          className="team-img"
          src="/images/courses/habits/14-leverage/gabe.png"
          alt="Suzanne"
        />
        <p className="member-name">
          Gabe, Director of Manufacturing Operations
        </p>
        <p className="member-text">
          Another option would be to look for ways to trim production time, but
          the trade-off could jeopardize product quality. Cutting corners that
          impact the quality of our product could result in dissatisfied
          customers and over time decrease demand for our products.
        </p>
      </div>
      <div className="team-member">
        <img
          className="team-img"
          src="/images/courses/habits/14-leverage/hector.png"
          alt="Suzanne"
        />
        <p className="member-name">Hector, Productions Manager</p>
        <p className="member-text">
          We could research the use of less expensive materials, which could
          save costs but impact product quality. This strategy could generate
          the same results as cutting production time. It is important that we
          maintain product quality.
        </p>
      </div>
      <div className="team-member">
        <img
          className="team-img"
          src="/images/courses/habits/14-leverage/daniel.png"
          alt="Suzanne"
        />
        <p className="member-name">
          Daniel, Product Distribution and Warehouse Supervisor
        </p>
        <p className="member-text">
          Careful consideration of the system that produces our product is one
          way to go, but what if XYZ focuses on eliminating nonessential steps
          in packaging of products? We could save some money post-production by
          altering packaging and distribution practices. With a little research
          I think we could streamline costs at this backend stage without
          sacrificing product quality or delivery time.
        </p>
      </div>
    </div>
    <p>
      The management team appreciated Daniel’s perspective and decided to move
      in that direction by exploring ways to trim down packaging costs. As a
      result, the company was able to achieve reduced costs, maintain the
      current production locale, and do so with minimal disruption to its
      manufacturing process. In this case, reducing packaging costs served as
      leverage for the company’s desire to reduce production costs.
    </p>
  </div>
);
