import * as React from "react";
import { Field } from "redux-form";

const Systems = ["well-being", "workplace", "school", "community", "family"];

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Select a sample system that reminds you of a time when you and other
      people experienced the same incident, decision, workshop, meeting or other
      specific event, yet saw it in different ways.
    </p>
    <h4>My practice system is:</h4>
    <div className="system-select">
      {Systems.map((s) => (
        <div key={s} className={`system-select-item ${s}`}>
          <Field
            name="systemSelect"
            id={`ss-${s}`}
            component="input"
            type="radio"
            value={s}
          />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      ))}
    </div>
    <h4>Describe the event or happening.</h4>
    <Field
      name="event"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      To make connections with the Sufi’s tale, describe the "elephant" everyone
      experienced and the various ways people described what they saw.
    </h4>
    <Field
      name="tale"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How did the different perspectives influence what happened?</h4>
    <Field
      name="what-happened"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How did people interact with those who had different points of view?
    </h4>
    <Field
      name="people-interact"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Thinking back to this time, how could dialogue have helped people maintain
      openness to varying perspectives?
    </h4>
    <Field
      name="openness"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What strategies would you use to help people gain common understanding of
      the event?
    </h4>
    <Field
      name="common-event"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      A favorite exercise described in the{" "}
      <a
        href="https://www.amazon.com/Systems-Thinking-Playbook-Exercises-Capabilities/dp/1603582584/ref=sr_1_1?ie=UTF8&qid=1533687750&sr=8-1&keywords=systems+thinking+playbook"
        target="_blank"
        rel="noopener noreferrer"
      >
        Systems Thinking Playbook
      </a>
      , by Meadows & Booth Sweeney, is “Circles in the Air.” In a very simple
      way, the exercise helps people experience the power of perspective.
    </p>
  </div>
);
