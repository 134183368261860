import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <img
      src="images/resources/word-up/student-work.png"
      className="lesson-img med-img"
    />

    <h3>Lesson Content:</h3>

    <ul>
      <li>English Language Arts – Vocabulary/Writing</li>

      <li>History</li>

      <li>Math</li>
    </ul>

    <p>
      This lesson can be used to introduce vocabulary words and/or enrich
      students’ vocabulary to show appropriate situations to use vocabulary.
      Extensions include writing prompts to have students utilize the words.
    </p>

    <p>
      This lesson is centered around a video about the terrorist attacks in the
      U.S. on Sept. 11th, 2001, so it can also be related to history if desired.
      It was conducted on 9/11 to make learning real for students while teaching
      them an important historical event, but the lesson is still applicable to
      use on other days for both the historical content and the vocabulary
      development. Math was also integrated into the lesson to figure elapsed
      time (years).
    </p>

    <h3>Goal/Purpose:</h3>

    <ul>
      <li>The learners will gain a deepened understanding of word meanings.</li>

      <li>
        The learners will identify appropriate situations to use suitable word
        choices to create vivid language when writing.
      </li>

      <li>
        The learners will utilize behavior-over-time graphs (BOTGs) to plot word
        choices based on a given situation.
      </li>

      <li>The learners will understand the events of 9/11/01. (optional)</li>

      <li>
        The learners will use math to determine elapsed time (years passed).
        (optional)
      </li>
    </ul>

    <h3>ST Concepts, Habits, and Tools:</h3>

    <ul>
      <li>BOTG</li>

      <li>Can also relate to Habits (See Possible extensions)</li>
    </ul>

    <h3>Materials:</h3>

    <ul>
      <li>Video capability (SMARTBoard or comparable system)</li>

      <li>Paper, pencil</li>

      <li>Chart paper & markers (optional) – depending on size of class</li>
    </ul>

    <h3>Lesson Structure:</h3>

    <ol>
      <li>
        Watch video clip from 9/11:{" "}
        <a
          href="https://www.911memorial.org/learn/students-and-teachers/2019-anniversary-schools-webinar"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.911memorial.org/learn/students-and-teachers/2019-anniversary-schools-webinar
        </a>
        <ul>
          <li>
            The first part of the clip is a timeline/overview of 9/11 – show
            this part based on your class needs/background.
          </li>

          <li>
            It is recommended to show from the beginning of the clip to the
            12:40 marker. Start at the 4:00 marker to omit the beginning and get
            to what is important for the lesson.
          </li>
        </ul>
      </li>

      <li>
        Set up the BOTG for students who do not have background of using BOTGs.
        For students who are very familiar, have them create the actual BOTGs on
        their own.
      </li>

      <li>
        The video is a timeline of out-of-town visitor Frank’s experience on
        9/11 with a firefighter saving him at a hotel beside the Twin Towers.
      </li>

      <li>
        Graph Frank’s fear from the beginning of the attack to when he was able
        to get out of the hotel. There may be a difference in graphs between
        grade levels. Older grades may recognize his fear would go down after he
        escaped the hotel, but he would still be fearful (this can be a great
        point of discussion with a BOTG).
        <div className="habits-row">
          <img
            src="images/resources/word-up/botg-3rd-grade.png"
            className="lesson-img med-img"
          />

          <img
            src="images/resources/word-up/botg-4th-grade.png"
            className="lesson-img med-img"
          />
        </div>
        <ul>
          <li>
            Take the word fear and create a list of synonyms (may have to
            scaffold if students don’t know or remember synonyms). Give students
            words to add to the list as needed.
          </li>

          <li>
            After you have a word list, have students place words on the BOTG’s
            Y-axis based on Frank’s fear in the video. Place the words on the
            Y-axis with the word that expresses the least fear at the bottom and
            the most fear at the top.
          </li>

          <li>
            Follow-up discussion: using different words based on the situation –
            they all mean fear, but horrified carries much more weight than
            nervous.
          </li>
        </ul>
      </li>

      <li>
        The lesson can be extended by having the students reflect on what the
        firefighters may have been feeling on the way to the towers. Have
        students do a quick write and encourage them to use words from their
        BOTG. EL students may be able to draw pictures to represent emotions
        instead of writing sentences. See sample work below:
        <br />
        <img src="images/resources/word-up/el-work.png" className="med-img" />
      </li>

      <li>
        A math connection is figuring out how old you were when the attack
        happened after figuring out how many years ago 2001 was. You could do
        multiple extensions here with elapsed time, number of deaths based on
        video, etc.
      </li>
    </ol>

    <h3>Possible extensions:</h3>

    <ul>
      <li>
        Connect to Habits of a Systems Thinker
        <ul>
          <li>
            Have students select Habits that correlate with Frank and/or a
            firefighter
          </li>

          <li>Use as an opportunity to introduce or dig deeper into a Habit</li>
        </ul>
      </li>

      <li>
        Write about the BOTG – how did Frank’s emotions change as the events of
        9/11 unfolded?
      </li>

      <li>
        Write about a personal connection to the graph – Student question: When
        is a time when you have experienced fear? – Choose your word to match
        your fear level for your event. Students could even create a BOTG to
        match the writing or as a pre-write activity or extension.
      </li>
    </ul>

    <p>
      <strong>Lesson’s Author:</strong> Amber M. Goolsby
    </p>
  </div>
);
