import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <p>
      Behavior-over-time graphs (BOTGs) are simple tools that illustrate
      patterns and trends. Basically, a BOTG can show through a quick drawing of
      a graph how something changes over time. Time is always on the “x,” or
      horizontal axis and the element that is changing is on the “y,” or
      vertical axis. BOTGs can show trends using numerical data, for example
      when a child with diabetes keeps track of her changing blood sugar levels
      (see below).
    </p>
    <div className="full-width-img">
      <img
        className="lesson-img left"
        src="/images/courses/habits/06-patterns/lesson-two-img.png"
        alt="BOTG"
      />
    </div>
    <h3>Quality of Communication</h3>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      Perceptual impressions of change also provide valuable information about a
      system. These examples show two views of the quality of communication for
      an organization’s leadership team. Each leader’s graph may appear slightly
      different, and the perception of the change tells a story of personal
      experience.
    </p>
    <p>
      The stories of the graphs provide key indicators of what is generating the
      ups and downs of the trend lines. Efforts to make perceptual change
      visible is a good first step to sharing honest opinions about the current
      state of the system and clarifying the evidence that people attach to
      important variables like quality of communication.
    </p>
    <div className="communication-examples">
      <div className="example-one">
        <img
          src="/images/courses/habits/06-patterns/example-graph-one.png"
          alt="BOTG"
        />
        <div className="example-content">
          <img
            className="lesson-img left"
            src="/images/courses/habits/06-patterns/person-one.png"
            alt="Person"
          />
          <p>
            “My view of our communication is that it was poor at best at the
            start of the year. We improved a bit when we decided to meet once a
            week, but then around May and June, people got too busy and stopped
            showing up. We assumed that we all knew what each other was doing.
            These unchecked assumptions caused problems, and it was a good thing
            that Jack brought this up in late June. He helped us get on track
            and our communication continued to improve.” - Elsa
          </p>
        </div>
      </div>
      <div className="example-two">
        <img
          src="/images/courses/habits/06-patterns/example-graph-two.png"
          alt="BOTG"
        />
        <div className="example-content">
          <img
            className="lesson-img left"
            src="/images/courses/habits/06-patterns/person-two.png"
            alt="Person"
          />
          <p>
            “I thought we were communicating fairly well in the beginning of the
            year. People were responding to text messages and emails on a
            regular basis. I also saw a dip in June, but it was minor. June is
            just our busy month, so we should expect communication to suffer a
            bit. I’m sure Jack helped, but I think we just need to expect things
            to get a little crazy each June.” - Marcus
          </p>
        </div>
      </div>
    </div>
    <p>
      When individuals in a group each graph their perceptions of how an
      essential system element has changed over time, each person can quickly
      observe the diversity of how various people see the system. Individuals
      each “tell the story” of his or her graph while providing narrative
      evidence. This process of drawing and explaining graphs from an individual
      point of view is a visual way to achieve changing perspectives to increase
      understanding. Revisit the{" "}
      <a href="/courses/04-perspectives/">
        Changes Perspectives to Increase Understanding
      </a>{" "}
      course to help connect these two Habits. To deepen your understanding and
      practice of BOTGs, explore the{" "}
      <a href="/courses/01-behavior-over-time-graphs">BOTG course</a> in the
      Tools section of TTS.
    </p>
    <p>
      In the case of trend lines that show numerical data or lines that show
      patterns informed by perception, one might ask when viewing a change over
      time, “Is the trend growing or declining, leveling off or oscillating?”
      The shape of the change becomes the story of the change. When individuals
      create BOTGs, they not only visually describe the nature of the change,
      but they also document the rationale for the shape of the pattern or trend
      over time. For example, a story of a graph might go like this: “In the
      beginning, the line goes up because … and then levels off because … and
      eventually goes back down because…”
    </p>
    <div className="quote">
      <p className="quote-text">
        “Time is a dressmaker specializing in alterations.”
      </p>
      <p className="quote-author"> — Faith Baldwin, author</p>
    </div>
    <h3>Current and Future Trends</h3>
    <img
      className="lesson-img"
      src="/images/courses/habits/06-patterns/clients-graph.png"
      alt="Clients Graph"
    />
    <p>
      BOTGs show how an element has changed in the past, but it can also show
      what a predicted change might look like. The Social Systems Lab at the
      Brown School of Social Work at Washington University has developed a
      process or script to help groups map and model systems of interest. One of
      their scripts focuses on the use of BOTGs to show change over time in the
      past, and the hopes and fears about how that same element might change in
      the future.
    </p>
    <p>
      By incorporating the future into observations about patterns and trends of
      essential system elements, systems thinkers can work together to envision
      a wide range of anticipated results. When BOTGs are created by small
      groups of people, conversations surface mental models about the past and
      future, and these conversations can move people to a fuller understanding
      of their system.
    </p>
    <p>
      This example BOTG tracks the number of clients a start-up company is
      developing over time. After “today” the blue line tracks the “hope,” and
      the orange line tracks the “fear.” In this example, the graph helps
      surface and test people’s assumptions about client growth. As people share
      their explanations for their predictions, the two future scenarios would
      include the factors that could cause both growth and decline.
    </p>
    <h3>Preschool Trends</h3>
    <img
      className="lesson-img"
      src="/images/courses/habits/06-patterns/crown-graph.png"
      alt="Crown Stories"
    />
    <p>
      One of the most powerful examples of how BOTGs help people see and
      understand patterns and trends comes from a preschool class of
      four-year-olds. Mrs. Lee’s class was reading a series of picture book
      stories and making simple graphs of changes over time. Elements included
      levels of happiness, levels of fear of the main characters, changes in the
      number of animals or amount of plants in a garden. After the teacher read
      each story aloud, the class would collectively draw a BOTG of the changes
      in the key element and then discussed the graph. The teacher would then
      hang the graph on a classroom wall.
    </p>
    <p>
      After a few weeks following this routine, a child looked at the series of
      graphs that had been drawn from different books and noticed that some of
      the trend lines were similar. She noticed several up-and-down lines of
      change from several different stories. She proudly pointed out this
      similarity and named those stories that had up-and-down graphs “crown
      stories.” The graphs looked like a queen’s crown and that clever label
      helped categorize a pattern.
    </p>
    <img
      className="lesson-img"
      src="/images/courses/habits/06-patterns/caterpillar-graph.png"
      alt="Caterpillar Graph"
    />
    <p>
      That young girl’s revelation inspired the thinking of her classmates as
      they started naming other alike-looking graphs as “slides,” “stairs,”
      “tables” and “smiles.” Now when listening to stories, the four-year-olds
      actively seek patterns and name them accordingly. Because the children
      have internalized the understanding that change can have shape and
      pattern, they use their own labels to identify the generic nature of
      patterns and trends they see and experience. Are we trending toward a
      generation of systems thinkers? Let’s hope so!
    </p>
  </div>
);
