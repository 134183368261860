import * as React from "react";
import Helmet from "react-helmet";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const CoursesHome = () => (
  <div>
    <Header />
    <div className="flow-container open-studio">
      <Helmet>
        <title>Systems Thinking Courses</title>
        <meta
          name="description"
          content="Expand your systems thinking knowledge with interactive courses con the Habits, tools, and archetypes of systems thinking."
        />
        <meta property="og:title" content="Systems Thinking Courses" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:image:alt" content="" />
      </Helmet>
      <section className="photo-header courses-photo">
        <h1>Systems Thinking Courses</h1>

        {/* <img src='images/site/forum-icon-white.svg' /> */}
      </section>

      <section className="white courses-overview-container">
        <div className="habitscourses">
          <h1>Habits Courses</h1>

          <img src="images/site/habitcards-all.png" />

          <p>
            The Habits of a Systems Thinker help learners understand how systems
            work and how actions taken can impact results seen over time.
            Thinking Tools Studio features a course for each of the 14 Habits
            plus a “Getting Started” course.
          </p>

          <div className="button-container">
            <a href="/courses/habits">
              <button>View Habits Courses</button>
            </a>
          </div>
        </div>

        <div className="toolscourses">
          <h1>Tools Courses</h1>

          <img src="images/site/tools.png" />

          <p>
            You will find courses on a variety of tools in the Thinking Tools
            Studio. Some tools have been split up into multiple courses to
            ensure learners are given the time needed to understand these
            multi-faceted, often complex tools and their underlying concepts.
          </p>

          <div className="button-container">
            <a href="/courses/tools">
              <button>View Tools Courses</button>
            </a>
          </div>
        </div>

        <div className="archetypescourses">
          <h1>Archetypes Courses</h1>

          <img src="images/site/archetype-feature.svg" />

          <p>
            Systems archetypes and their accompanying visual templates provide
            us lenses with which to view systems more clearly. Thinking Tools
            Studio features 9 Archetypes courses.
          </p>

          <div className="button-container">
            <a href="/courses/archetypes">
              <button>View Archetypes Courses</button>
            </a>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
);
