import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <img
      className="lesson-img small-img"
      src="/images/shared/habit-cards/shadow/connections.png"
      alt="Making Connections"
    />
    <h3>Introduction</h3>
    <p>
      Analogies — they come in many forms, but they all depend on making
      meaningful connections.
    </p>
    <h4>Consider:</h4>
    <h3 className="analogy">
      Dog is to puppy as cat is to kitten. Dance is to walk and sing is to talk.
    </h3>
    <h3 className="analogy">Sly as a fox. Stubborn as a mule.</h3>
    <div className="quote">
      <p className="quote-text">
        “Explaining a joke is like dissecting a frog. You understand it better,
        but the frog still dies in the process.”
      </p>
      <p className="quote-author"> – E.B. White</p>
    </div>
    <p>
      Analogies are a great way to explore and better understand the Habits of a
      Systems Thinker.
    </p>
    <h3>Using Objects to Make Connections</h3>
    <ul>
      <li>
        First, you will need to collect materials for this exercise. When
        gathering materials, it is best to select a variety of random objects.
        It is desirable to have some objects that have apparent connection(s) to
        one or more of the Habits, but it is equally desirable to have objects
        that require participants to think deeply in order to make a
        connection(s). You will want 4 to 7 objects for each group of three to
        five participants. The photo shown provides some examples of objects,
        but the possibilities are endless.
      </li>
      <img
        className="lesson-img"
        src="/images/resources/host-analogies/section-one-img-2.jpg"
        alt="Materials"
      />
      <li>
        Once you have your materials, provide each group with a collection of
        random objects and a set of Habits cards. Habits cards are available for
        purchase{" "}
        <a
          href="https://waters-center-for-systems-thinking.myshopify.com/"
          target="blank"
          rel="noopener"
        >
          here
        </a>
        .
      </li>
      <li>
        Ask participants to identify as many connections as possible to the
        objects in their collection.
      </li>
      <li>
        Allow individuals to share insights with the entire group as time
        permits.
      </li>
      <li>
        Keep in mind that a single object can generate multiple connections to a
        single Habit as well as to multiple Habits.
      </li>
    </ul>
    <p>
      The possibility for meaningful connections is limitless. Your participants
      will likely surprise you with the connections they are able to make. Find
      some interesting objects and a willing group of participants, and record
      some of the amazing connections that people make.
    </p>

    <h3>Potential Connections</h3>
    <p>To get you started, consider the objects in the photo shown.</p>
    <p>
      The question mark object could connect to the Habit, “Surfaces and tests
      assumptions” because to really understand your own assumptions or that of
      another person, you may have to ask some questions.
    </p>
    <p>
      The question mark object could also connect to the Habit, “Considers an
      issue fully and resists the urge to come to a quick conclusion” because
      when you pause to consider, you are asking yourself,{" "}
      <em>What else should I be thinking about?</em>
    </p>
    <p>
      The flower could connect to the Habit, “Observes how elements within
      systems change over time, generating patterns or trends” because a flower
      starts out as a seed, sprouts, and matures as a plant before producing
      flowers.
    </p>
  </div>
);
