import * as React from "react";
import Helmet from "react-helmet";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const Donate = () => (
  <div>
    <Header />
    <div className="flow-container">
      <Helmet>
        <title>Donate - Thinking Tools Studio</title>
        <meta
          name="description"
          content="Donate to the Thinking Tools Studio"
        />
        <meta property="og:title" content="Donate - Thinking Tools Studio" />
        <meta
          property="og:description"
          content="Donate to the Thinking Tools Studio"
        />
        <meta
          property="og:image"
          content="https://thinkingtoolsstudio.org/images/site/donate-bg.jpg"
        />
        <meta
          property="og:url"
          content="https://thinkingtoolsstudio.org/donate"
        />
        <meta
          name="twitter:image"
          content="https://thinkingtoolsstudio.org/images/site/donate-bg.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Donate: Ensuring access to systems thinking resources"
        />
      </Helmet>

      <section className="photo-header donate">
        <h1>Donate</h1>

        <img src="images/site/donate-icon.svg" />

        <h2>Ensuring access to systems thinking resources</h2>
      </section>

      <section className="white centered">
        <p>
          Thank you for supporting the Thinking Tools Studio, brought to you by
          the Waters Center for Systems Thinking.
        </p>

        <p>
          <strong>
            We are committed to ensuring all people have access to systems
            thinking courses and resources.
          </strong>
        </p>

        <p>
          <strong>
            To continue to offer this complimentary service, we need support
            from donors like you.
          </strong>
        </p>
      </section>

      <section className="grey split">
        <div className="w60">
          <p>
            We appreciate any and all donation amounts to the Thinking Tools
            Studio. Your donation will help us achieve:
          </p>
          <ul className="colorpoints">
            <li>Regularly-added resources and courses</li>

            <li>Up-to-date and new features</li>

            <li>Quality control of the Studio</li>

            <li>Ongoing customer support</li>

            <li>
              Access for users working individually or as part of a team,
              throughout the world
            </li>
          </ul>
        </div>

        <div className="w25">
          <img src="../images/site/tts-icon-lblue.svg" />
        </div>
      </section>

      <section className="white">
        <p className="centered">
          To donate with PayPal, click the button below:
        </p>

        <div className="paypal-donate big">
          <a
            href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RZVMF2XQAXX32&source=url"
            target="blank"
            rel="noopener"
          >
            <button className="blue">Donate</button>
          </a>
        </div>

        <p>
          The Waters Center for Systems Thinking is a registered 501(c)(3)
          non-profit in the United States, and all gifts to us are tax
          deductible in the United States to the fullest extent allowed by law.
          Our Tax ID number is 83-2041181.
        </p>

        <p>
          For questions regarding donations, please email:{" "}
          <a href="mailto:donate@waterscenterst.org">
            donate@WatersCenterST.org
          </a>
          .<br />
          For specific Thinking Tools Studio features or courses you would like
          to sponsor, please email:{" "}
          <a href="mailto:donate@waterscenterst.org">
            donate@WatersCenterST.org
          </a>
          .
        </p>

        <p>
          Are you interested in learning more about the Waters Center for
          Systems Thinking and supporting our work with schools, organizations
          and communities?{" "}
          <a
            href="https://waterscenterst.org/our-impact/"
            target="blank"
            rel="noopener"
          >
            Click here
          </a>
          .
        </p>
      </section>
    </div>
    <Footer />
  </div>
);
