import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Abstract:</h3>

    <p>
      Classroom management is one component of the instructional process that is
      all encompassing. It can make or break an effective classroom and is
      profoundly influenced by individual beliefs, attitudes and perspectives
      that impact our actions. By practicing the Habits of a Systems Thinker we
      can understand how a system works and how actions taken can impact the
      results seen over time. Practicing the Habits allows a teacher to
      understand her classroom. This includes an awareness of how it functions
      as a system and how management or actions taken can impact results seen
      over time.
    </p>

    <p>
      <strong>Author:</strong> Mary Quinnan
    </p>
  </div>
);
