import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      Choose a scenario below and use the ladder of inference to examine the
      mental models you would hold if you were in the situation. Be prepared to
      answer questions at the end of your examination. It may help you to
      download the{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/ladder-with-questions.pdf"
        target="blank"
        rel="noopener"
      >
        ladder template
      </a>{" "}
      for this exercise.
    </p>
    <ol>
      <li>
        You have a scheduled meeting with your supervisor to discuss a project
        you recently started. You have data to review that you will be sharing
        at the meeting in the afternoon. On the way to the meeting, you drop
        your materials on the ground and gather them up quickly. You are a
        little late and rush into the administrative office. The supervisor’s
        door is closed and the administrative assistant is working on her
        computer and fails to look up and greet you.
      </li>
      <li>
        You are facilitating a group of principals looking at district data. The
        data reflect significant gaps in where children should be. One person
        refutes the data saying, “That data doesn’t show what goes on in my
        school.” Another claims the new state legislature contributed to the
        dips in scores.
      </li>
      <li>
        You are a consultant facilitating a group of regional managers looking
        at their performance data. The data reflect under-performing regions
        based on quarterly targets. One manager refutes the data saying, “That
        data doesn’t show the whole picture of my region’s performance.” He
        claims that another manager’s competitive and somewhat unethical actions
        are compromising the performance of neighboring regions, contributing to
        lackluster outcomes.
      </li>
      <li>
        In the midst of planning an upcoming meeting, you consult with a trusted
        colleague. During that conversation, she attends to her cell phone
        twice, responds to an email, and writes herself a reminder on a sticky
        note. When you ask her for clarification about an exercise you plan to
        include in the meeting, she responds, “Sure. Go ahead. I think you’ve
        got it covered.”
      </li>
    </ol>
    <h3>Questions: Climbing Up the Ladder</h3>
    <p>
      Respond to the questions below as if you were the player in the scenario
      you chose. The questions will help surface the mental models you might
      have if you were to experience the scenario. It may be helpful to use the
      template referenced above to climb yourself up the ladder.
    </p>
    <h4>
      Imagine you are a player in the scenario and think about what details you
      might be noticing. List them below.
    </h4>
    <Field
      name="details"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What various meanings might you attach to these details?</h4>
    <Field
      name="detailsMeaning"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What are some beliefs you could develop based on the meaning you attached
      to what you noticed?
    </h4>
    <Field
      name="beliefs"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What possible actions could you take?</h4>
    <Field
      name="action"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>Bonus Exercise: More practice needed?</h4>
    <p>Choose another scenario and respond to the questions.</p>
  </div>
);
