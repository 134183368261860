import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Materials:</h3>

    <ul>
      <li>
        A variety of articles on curiosity – the first two were used for this
        lesson, but the others are listed as additional resources that could be
        used as well (or could be substituted depending on the audience/needs):
        <br />
        <a
          href="http://www.lifehack.org/articles/productivity/4-reasons-why-curiosity-is-important-and-how-to-develop-it.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          “Four Reasons Why Curiosity is Important and How to Develop It”
        </a>{" "}
        by Donald Latumahina
        <br />
        <a
          href="https://www.authentichappiness.sas.upenn.edu/newsletters/authentichappinesscoaching/curiosity"
          target="_blank"
          rel="noopener noreferrer"
        >
          “Curious about Curiosity”
        </a>{" "}
        by Ben Dean, PhD
        <br />
        <a
          href="https://www.kqed.org/mindshift/42518/how-to-spark-curiosity-in-children-by-embracing-uncertainty"
          target="_blank"
          rel="noopener noreferrer"
        >
          “How to Embrace Curiosity in Children Through Embracing Uncertainty”
        </a>{" "}
        by Linda Flanagan
        <br />
        <a
          href="http://ideas.time.com/2013/04/15/how-to-stimulate-curiosity/"
          target="_blank"
          rel="noopener noreferrer"
        >
          “How to Stimulate Curiosity”
        </a>{" "}
        by Annie Murphy Paul
        <br />
        <a
          href="https://www.teachthought.com/learning/5-learning-strategies-that-make-students-curious/"
          target="_blank"
          rel="noopener noreferrer"
        >
          “Five Learning Strategies that Make Students Curious”
        </a>{" "}
        by Terry Heick
        <br />
        <a
          href="https://www.edweek.org/ew/articles/2014/06/04/33shonstrom.h33.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          “How Can Teachers Foster Curiosity?”
        </a>{" "}
        by Erik Shonstrom
        <br />
        <a
          href="http://www.ascd.org/publications/educational-leadership/feb13/vol70/num05/The-Case-for-Curiosity.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          “The Case for Curiosity”
        </a>{" "}
        by Susan Engel
        <br />
      </li>

      <li>
        There is an accompanying{" "}
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/did-curiosity-kill-the-cat.pptx"
          target="_blank"
          rel="noopener noreferrer"
        >
          PowerPoint presentation
        </a>{" "}
        that can be used for this lesson.
      </li>

      <li>
        Students need materials to draw out their connection circles and causal
        loops – blank paper, colored pencils and/or markers are helpful.
      </li>
    </ul>

    <h3>Visuals:</h3>

    <p>Samples of students’ causal loop diagrams</p>

    <div className="student-loops">
      <img src="/images/resources/curiosity/student-loop-1.png" />

      <img src="/images/resources/curiosity/student-loop-2.png" />

      <img src="/images/resources/curiosity/student-loop-3.png" />

      <img src="/images/resources/curiosity/student-loop-4.png" />
    </div>

    <h3>Lesson Structure:</h3>

    <h4>Prerequisite skills:</h4>

    <p>
      Students do not need to have any exposure to the systems tools before this
      lesson. This can be an introduction to systems thinking, connection
      circles, and causal loops.
    </p>

    <h4>Lesson Outline:</h4>

    <p>Accompanying PowerPoint matches the steps below.</p>

    <ol>
      <li>
        Activator: Post discussion questions and have students respond in small
        groups, then briefly discuss as a class: What is curiosity? What does it
        mean to be curious? Is curiosity a good thing? Why/why not? How do we
        develop curiosity? What would make a person more/less curious?
      </li>

      <li>
        Show two perspectives on curiosity and discuss how/when curiosity might
        be a “good thing” and how/when it might be a “bad thing.”
      </li>

      <li>
        Post/discuss guiding questions and process for reading the articles:
        students focus on developing an understanding of what curiosity is and
        what elements impact it – either causing it to increase or causing it to
        decrease.
      </li>

      <li>
        Read articles on curiosity and record information. This can be done as a
        class (articles are included in the PPt) or students could read the
        articles on their own or in small groups and annotate as they read.
      </li>

      <li>
        Post/discuss driving question: What set of interactions drives these
        changes? Introduce the concept of the iceberg (very briefly) and explain
        that we’re focusing on the structures level in order to examine how
        different elements related to curiosity interact with each other as a
        system.
      </li>

      <li>
        Explain connection circles and go through steps. Put example on the
        board and show how several elements might interact in various ways. Make
        sure to stress importance of finding direct causal relationships. Also
        make sure to distinguish two types of directionality – one element
        causing an increase in the other or one element causing a decrease in
        the other.
      </li>

      <li>
        Give students time to work on connection circles alone or in small
        groups (end of first 50-minute period).
      </li>

      <li>
        Begin the second work period by reviewing what was done the previous
        day. If possible, project some of the connection circles created by
        students and go through them as a class – looking for any parts that
        need clarifying/adjusting.
      </li>

      <li>
        Introduce causal loop diagrams – quickly go through reinforcing versus
        balancing loops and look at examples for each.
      </li>

      <li>
        Walk students through the process of pulling causal loops out of their
        connection circles using a simple sample (on PPt slide). Draw the loops
        on the board as students explain them. Start with the simple reinforcing
        loop, then go through the more complicated balancing loop. Point out
        that the two loops can be joined together because they have a common
        element.
      </li>

      <li>
        Answer questions and add more explanation as needed. Once students seem
        ready, let them work on making their own loops alone or in small groups.
        Circulate and provide guidance, asking questions as needed to help
        clarify their thinking.
      </li>

      <li>
        Have students share their loops – project them or have them draw them on
        the board so everyone can see them. Have students “tell the story” of
        their loops. Help them modify/adjust their loops as needed. They may
        need help with wording, with labeling the pluses and minuses, and with
        identifying direct causation.
      </li>

      <li>
        Give students the opportunity to revise loops before turning in final
        products. Or, this can be a practice before having them go through the
        same process with a similar topic.
      </li>

      <p>
        <strong>Lesson's Author:</strong> Brian Bindschadler, ELA teacher at
        Orange Grove Middle School, Tucson, Arizona
      </p>
    </ol>
  </div>
);
