import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-single"
        src="/images/resources/iceberg-with-graphics/iceberg-graphics-questions.svg"
        alt="Iceberg: Template with Graphics and Questions"
      />
    </div>
  </div>
);
