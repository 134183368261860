import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <section className="white">
      <p>
        Part 1: (9 mins)
        <br />
        <strong>Applying a Behavior-Over-Time Graph in Early Childhood</strong>
        <br />
        From 2017-2020, the Waters Center “Systems Thinking in School
        Communities” project, funded by the W.K. Kellogg Foundation, worked to
        develop a model to scale the value of a systems thinking approach to
        school improvement, community engagement and student empowerment in
        preschool and elementary grades. In this video, Peggy Gingerich, early
        childhood teacher, 3 to 5 years, Head Start, Wolfe County, Middle
        Kentucky, presents an early childhood lesson. Peggy uses a
        behavior-over-time graph (BOTG) with the book “5 Cheeky Monkeys.”
        Peggy’s lesson was focused on communication and critical thinking skills
        and several student artifacts are reviewed.
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/14vLEik2ACk?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        Part 2: (5 mins)
        <br />
        <strong>
          Implementing Systems Thinking in an Early Childhood Classroom – A
          Teacher’s Reflection
        </strong>
        <br />
        From 2017-2020, the Waters Center “Systems Thinking in School
        Communities” project, funded by the W.K. Kellogg Foundation, worked to
        develop a model to scale the value of a systems thinking approach to
        school improvement, community engagement and student empowerment in
        preschool and elementary grades. In this video, Peggy Gingerich, early
        childhood teacher, 3 to 5 years, Head Start, Wolfe County, Middle
        Kentucky, reflects on her own systems thinking learning journey. Watch
        as she takes viewers through her first year of applying systems thinking
        in her early childhood classroom, her growth as an educator and her
        collaboration with others.
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/y_NMQjsofS8?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>
  </div>
);
