import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>
      Think of a time when you were experiencing a challenging situation that
      appeared hopeless. Describe that time.
    </h4>
    <Field
      name="think-time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What strategies did you use to remedy the situation? What possible
      leverage actions did you consider?
    </h4>
    <Field
      name="remedy"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How would a deeper understanding of the system that produced the challenge
      improve your ability to determine leverage?
    </h4>
    <Field
      name="improve-ability"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
