import * as React from "react";
import { Field } from "redux-form";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Systems archetypes help you anticipate potential traps before taking
      action; this recognition can help you navigate and become unstuck. They
      also help you see when you are in the middle of a messy situation. Revisit
      your Fixes that Backfire scenario from Practice Exercise #2 and respond to
      the following reflection questions:
    </p>
    <h4>
      Is my need to respond quickly to a problem greater than the importance of
      investigating potential unintended consequences? Explain.
    </h4>
    <Field
      name="respond-quickly"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Who will be impacted by the quick fix? What will the impact look like in
      the short-run and the long-run?
    </h4>
    <Field
      name="short-run"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What are possible consequences of saving time by implementing the quick
      fix?
    </h4>
    <Field
      name="saving-time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Consider and describe a solution that might take more time to implement
      but minimize chances of it backfiring.
    </h4>
    <Field
      name="more-time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How might unintended consequences create new problems that need fixing?
      Describe possible new problems that could be the result of unintended
      consequences?
    </h4>
    <Field
      name="need-fixing"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
