import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <section className="white">
      <p>
        <strong>
          Personal Reflection with the Habits of a Systems Thinker
        </strong>
        <br />
        Featuring Wesley Marshall
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/MYU1ZCp-2eQ?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        <strong>Mental Models and School Staff</strong>
        <br />
        Featuring Teresa Rowan
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/J0r9-aq_RDg?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        <strong>
          PUsing Work During Covid to Bridge Planning for SY ‘21-‘22
        </strong>
        <br />
        Featuring Kat Bolasky
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/WpAS83hKS0Y?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        <strong>
          Collaborative Use of Habits and Alignment with Systems Thinking Tools
        </strong>
        <br />
        Featuring Anna Ampania
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/hagfx6SdlGk?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        <strong>Mental Models and Student Discipline</strong>
        <br />
        Featuring Will Pope
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Sv3FcYxzTQg?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        <strong>Creating Structure to Generate Educator Behavior</strong>
        <br />
        Featuring Bonnie Short
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/vTWay2tIyN4?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        <strong>Connections Within and Between Systems</strong>
        <br />
        Featuring Leslie Pring, SNS
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/jlc_VhbtDwE?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>

    <section className="white">
      <p>
        <strong>Changing Perspectives</strong>
        <br />
        Featuring Ronda Davis
      </p>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/e5aad3TkpdM?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="center"
      />
    </section>
  </div>
);
