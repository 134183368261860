module.exports = [
  { id: "l-en", value: "English" },
  { id: "l-de", value: "Deutsch" },
  { id: "l-es", value: "Español" },
  { id: "l-fr", value: "Français" },
  { id: "l-it", value: "Italiano" },
  { id: "l-nl", value: "Nederlands" },
  { id: "l-pt", value: "Português" },
  { id: "l-fi", value: "suomi" },
  { id: "l-tr", value: "Türkçe" },
  { id: "l-ja", value: "日本語" },
  { id: "l-fa", value: "فارسی" },
];
