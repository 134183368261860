import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <h3>Sibling Rivalry</h3>

    <p>
      Consider two teenage sisters, Julie and Erin, competing for attention from
      their very busy working parents. Julie consistently achieves success in
      all she does, resulting in ongoing positive attention from her parents.
      Erin feels threatened by her sister’s natural talents and accomplishments
      and opts to get attention in more negative ways with inappropriately
      sarcastic humor, teasing and taunting. Erin succeeds in getting parental
      attention that typically involves one-on-one discussions about her
      behaviors and even outings to help redirect her attitude and behavior.
      Julie resents the special time that Erin gets despite her challenging
      behaviors. Julie continues to compete for attention by sharing her many
      successes in school and extracurricular activities, while Erin continues
      to act up in ways that require parental attention.
    </p>

    <h3>The Auction</h3>

    <p>
      Max and Joel are car enthusiasts who don’t know one another. They each
      have compiled a list of prized, antique cars that they would like to add
      to their collections. On a recent weekend morning, they both eagerly show
      up at a classic car auction, knowing the number 1 car on their lists will
      be auctioned off. The bidding war begins and Max offers the first bid.
      Joel counters with a higher amount which generates an even higher bid from
      Max. As the price escalates, they each are threatened by their competitor,
      knowing with fearful certainty that one will ultimately win and the other
      will lose the bidding war for the prized antique car.
    </p>

    <h3>Arms Race</h3>

    <p>
      There are many examples of escalation that exist both globally and
      regionally. Countries stockpile weapons to build for both defensive and
      offensive purposes which then threaten other countries to do the same to
      ensure that one cannot overpower the other.
    </p>
  </div>
);
