import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <p>
      If you find yourself in a “fixes that backfire” scenario, you may want to
      find a way out that results in a long-term solution and minimizes
      undesirable consequences. This is often referred to as the fundamental
      solution. Breaking a “fixes that backfire” cycle requires an
      acknowledgement that the fix was short-term.
    </p>

    <a href="/courses/04-perspectives">
      <img
        className="lesson-img small-img"
        src="/images/shared/habit-cards/shadow/perspectives.png"
        alt="Changes Perspectives to Increase Understanding"
      />
    </a>

    <p>
      When drawing out and talking through a Fixes that Backfire archetype,
      think carefully about the problem symptom. Accurately identifying the
      appropriate problem symptom is an important part of telling the story and
      can be helpful in better understanding your current situation. You may
      find it helpful to consider the perspectives of different characters in
      your story. Changing your perspective on the situation may increase your
      understanding of the situation.
    </p>

    <p>
      Try to think of the problem apart from the quick fix solution. Perhaps
      your fix was more of a reaction than a response to the problem symptom and
      being more intentional could help lessen the problem symptom without
      creating an undesirable, unintended consequence.
    </p>

    <p>
      Dig a bit deeper into the full story line. Seek to better understand the
      situation, rather than blaming. Ask, what is driving the problem symptom?
      Is the problem symptom the result of a previous quick-fix action?
    </p>

    <p>
      When a solution begins to feel like it may be a quick fix, seek to
      generate multiple alternative solutions. Attempt to minimize the effects
      of the unintended consequences.
    </p>

    <h3>
      Rewriting the Story in Advance: Preventing Undesirable Unintended
      Consequences
    </h3>

    <a href="/courses/12-considers-issue">
      <img
        className="lesson-img small-img"
        src="/images/shared/habit-cards/shadow/considers-issue.png"
        alt="Considers an Issue Fully"
      />
    </a>

    <p>
      The Fixes that Backfire archetype is a valuable tool to use before a fix
      is implemented. If you sense a problem symptom at work, in your family, or
      with your personal well-being, pause and consider the potential
      consequences of any “solutions” you may be about to try. What are the
      potential consequences? Are those consequences something you want? Not all
      unintended consequences are undesirable consequences, which is why it is
      important to take time to consider the issue fully.
    </p>

    <p>
      Ask stakeholders to consider the possible unintended consequences of a
      proposed fix. What are the unintended consequences of the proposed fix and
      what trade-offs should we consider?
    </p>

    <p>
      Both short- and long-term consequences and unintended consequences should
      figure into the discussion. An informed decision that considers all of the
      trade-offs will minimize the chance that the fix will backfire.
    </p>
  </div>
);
