import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>
      Consider the causal loop map stories this team used to better understand
      the work of their collaborative team. How specifically did the loop
      stories contribute to the leadership team’s breakthrough?
    </h4>
    <Field
      name="map-stories"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      As you consider the mapping process the school leadership team used to
      increase their understanding of their system so that they could identify
      areas of leverage, how do their maps, insights and leverage actions apply
      to a corporate setting, government agency or any other system seeking
      improvement?
    </h4>
    <Field
      name="mapping-process"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
