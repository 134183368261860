import * as React from "react";

export const Lesson04 = () => (
  <div className="content">
    <p>
      Immediate improvements are often the desired result of any new program or
      innovation. As a result, some great ideas are lost because decisions are
      made without allowing for a time delay between the innovation and the
      assessment of results. However, innovation often results in a “performance
      dip,” which is the period at the beginning of a major change when people
      are learning how to do something new, and as a result, performance may
      temporarily go down.
    </p>
    <img
      className="lesson-img"
      src="/images/courses/habits/10-time-delays/lesson-four-img.jpg"
      alt="Airplane"
    />
    <p>
      For example, when companies merge, they usually do so to increase profits;
      however, the increase in profits may not always be immediately apparent.
      For example, when two airlines come together, there are many
      considerations. The newly merged company has to determine correct
      staffing, meld the two mileage rewards programs, establish new routes, and
      so on.
    </p>
    <p>
      {" "}
      In most cases, there will be significant time delays before the desired
      increase in profits is fully realized. In some cases, the costs associated
      with the merger may actually cause a short-term decrease in the airline’s
      profitability. This delay does not dissuade companies from merging.
      Instead, the delay is an expected part of doing business, and it increases
      the need to monitor trends affecting profitability and make appropriate
      adjustments along the way.
    </p>
    <p>
      A systems thinker recognizes the need to monitor the results, considers
      the impact of time delays, and makes minor adjustments before throwing out
      a potentially valuable idea.
    </p>
  </div>
);
