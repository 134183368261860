import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      src="images/courses/tools/05-causal-links/globe.png"
      className="lesson-img small-img"
      alt="globe"
    />

    <h3>Introduction</h3>

    <p>
      Begin by reading the following excerpt and notice all of the words and
      phrases Dr. Martin Luther King Jr. used to highlight the importance of
      causal interdependencies.
    </p>

    <p>
      <strong>A Christmas Sermon on Peace</strong>
      <br />
      Dr. King first delivered this sermon on Christmas Eve, 1967 at Ebenezer
      Baptist Church, where he served as co-pastor.
    </p>

    <div className="quote">
      <p className="quote-text">
        It really boils down to this: that all life is interrelated. We are all
        caught in an inescapable network of mutuality, tied into a single
        garment of destiny. Whatever affects one directly, affects all
        indirectly. We are made to live together because of the interrelated
        structure of reality. Did you ever stop to think that you can't leave
        for your job in the morning without being dependent on most of the
        world? You get up in the morning and go to the bathroom and reach over
        for the sponge, and that's handed to you by a Pacific islander. You
        reach for a bar of soap, and that's given to you at the hands of a
        Frenchman. And then you go into the kitchen to drink your coffee for the
        morning, and that's poured into your cup by a South American. And maybe
        you want tea: that's poured into your cup by a Chinese. Or maybe you're
        desirous of having cocoa for breakfast, and that's poured into your cup
        by a West African. And then you reach over for your toast, and that's
        given to you at the hands of an English-speaking farmer, not to mention
        the baker. And before you finish eating breakfast in the morning, you've
        depended on more than half of the world. This is the way our universe is
        structured, this is its interrelated quality. We aren't going to have
        peace on earth until we recognize this basic fact of the interrelated
        structure of all reality.
      </p>
    </div>
    <p>
      The ability to make causal connections and identify interdependencies in
      systems in order to see the big picture is what systems thinking is all
      about. Systems thinkers are drawn to connections between parts and can get
      frustrated when systems are fragmented or siloed. This limited view can
      generate misconceptions and ineffective problem-solving and
      decision-making. This course will help build your skill in identifying
      causal links in systems where you live, work and learn. Being able to see
      interdependencies, explore causal relationships and anticipate effects of
      actions takes intentional practice. Again, everything happens for a
      reason, so when things go well, it is good to know why. On the other hand,
      when situations go awry, it is equally important to understand the causal
      attributes that lead to disappointing results.
    </p>
    <h3>Let’s get started with causal links.</h3>
    <p>
      First, we want to stay focused on things that change over time and not
      merely events that cause things to happen. Think about moments in school
      when you were asked to list the events that caused particular things in
      history to occur. What were the events that caused the Civil War in the
      United States? What were the events that caused the fall of the Roman
      Empire? These surface level questions can become much more informative and
      relevant when we replace the word “events” with “trends.” Seeing trends
      versus a focus on events will deepen your understanding of systems and
      will build your practice as a systems thinker.
    </p>
    <p>
      Here is a story that illustrates this point. A violent act occurred in a
      community in the southwestern U.S. An unidentified active shooter targeted
      a politician and her supporters during a public event in the parking lot
      of a grocery store. Several people were killed and many others were
      seriously wounded, including the politician. The community was greatly
      impacted by this horrific event. In schools across the region, educators
      were fully prepared to provide support and counseling for children when
      they came to school on Monday. In one classroom, where the Habits of a
      Systems Thinker were actively practiced, a young 7-year old girl was
      talking about what happened. Her systems thinking wisdom was applied to
      this horrible event as she said, “We need to pay attention to this trend
      and change the direction of the line (she was actually pointing to a BOTG
      at the time) so that people become more kind.” This young girl was able to
      look beyond the horrific event and proactively consider the importance of
      the causal factors in a system that could possibly prevent this one-time
      occurrence from becoming a trend. We need this kind of thinking in our
      leaders, our schools, our governments, our businesses and our communities.
    </p>
    <p>
      That said, patterns and trends and causality are the focus of this course.
    </p>
    <p>Why is this important?</p>
    <p>
      As you deepen your understanding of events — either those that are
      troublesome or those that are fortunate, systems thinkers take the time to
      quickly ask two questions:
    </p>
    <div id="q-a">
      <p>
        <em>“Is this a one-time event or is this a pattern?”</em> and
      </p>
      <p>
        <em>
          “If this is a recurring event, i.e. something that’s trending, what
          could be causing this pattern?”{" "}
        </em>
      </p>
    </div>
    <p>
      Without this brief moment of reflection, you can potentially arrive at a
      misunderstanding that could cause bigger problems or be viewed as a missed
      opportunity to capitalize on good things to come. Let’s look at a
      workplace example.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      Lance was a supervisor who noticed that Janet arrived late to an important
      early morning meeting. Lance took a moment to quickly reflect on Janet’s
      past behaviors and considered the question, “Is this a one-time event or a
      pattern?” He realized that this was not the first late arrival and that
      this tendency had become a pattern over time.
    </p>
    <p>
      Without causal reflection, Lance quickly considered his possible
      reactions:
    </p>
    <ol>
      <li>
        Do nothing, but he became aware that deep down inside, he labeled Janet
        as “the one who is always late.”
      </li>
      <li>
        Respond to Janet’s lateness by asking her to please put more effort into
        being on time in the future.
      </li>
    </ol>
    <p>
      With causal reflection, Lance could suspend his initial labeling judgement
      and consider possible causes for the lateness. He might even ask what
      support Janet might need to get to meetings on time. After all, Janet is a
      trusted, high-functioning employee, and there may be good, justifiable
      reasons for the pattern of her late arrival to early-morning meetings.
    </p>
    <p>Causal factors could include:</p>
    <ul>
      <li>
        Access to dependable transportation (late buses, avoidance of expensive
        parking, or car trouble)
      </li>
      <li>
        Competing needs at home that relate to childcare, or care of an aging
        relative
      </li>
      <li>Sleep deprivation because of workplace stress</li>
    </ul>
    <p>
      Taking time to uncover causal evidence that can lead you to a greater
      understanding of what is occurring in your work or life is an important
      practice of a systems thinker.
    </p>
  </div>
);
