import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <img
      className="lesson-img"
      src="/images/courses/habits/10-time-delays/lesson-three-img.jpg"
      alt="Car"
    />
    <p>
      When using cause and effect relationships to realize the impact of time
      delays, it is important to consider the absence of a positive consequence
      as well as the effects of a negative one. For example, we may sometimes do
      the wrong thing simply because the right action does not produce an
      immediate positive consequence. The benefits of car maintenance including
      oil changes, tire rotation and fluid checks, when done regularly, increase
      the life of a vehicle; however, this good habit is not immediately
      reinforced, so the absence of a long-term view may delay regular
      maintenance.
    </p>
    <p>
      In addition, there are circumstances that won’t tolerate time delays. In
      these circumstances, failure to take action can lead to the escalation of
      a situation. Imagine you are enjoying an evening with friends when your
      neighbors come over and ask you to turn down the television. You
      acknowledge the request, but plan to comply at the end of your movie.
      Irritated by what they perceive as your unwillingness to take action, they
      call the police and file a formal complaint. In this scenario, what you
      perceive as a reasonable delay is taken by your neighbor as an
      unacceptable one, leading to escalation of the situation.
    </p>
    <img
      className="lesson-img"
      src="/images/courses/habits/10-time-delays/lesson-three-img-2.jpg"
      alt="Calendar"
    />
    <p>
      It is not only tangible accumulations like pastureland and fish that are
      subject to the effect of time delays. For example, your time is a limited
      resource. When you fail to monitor the impact of the multiple things
      demanding the attention of your time, you may find that eventually the
      gain from each activity is also decreasing.
    </p>
  </div>
);
