import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Think about an accumulation that is important to you and use the bathtub
      metaphor to focus on the rates of change that are affecting the
      accumulation. For example, if you were to choose points on your credit
      score, this becomes the stock, as represented by water in the bathtub.
    </p>
    <p>
      The inflow (faucet) are things that increase your credit score, such as
      paying bills on time or earning additional credit. The outflow (drain) are
      things that decrease your credit score, such as too much debt or
      defaulting on a loan.
    </p>
    <img
      className="template"
      src="/images/courses/habits/13-accumulations/bathtub-template.jpg"
      alt="Bathtub Template"
    />
    <div className="template-questions">
      <div className="template-pair">
        <p className="diagram-identifier">A.</p>
        <h4>
          Describe your important accumulation or stock in the space inside the
          tub:
        </h4>
      </div>
      <Field
        name="stock-in"
        component="textarea"
        className="reflection-inline"
        placeholder="Enter your response here."
      />
      <div className="template-pair">
        <p className="diagram-identifier">B.</p>
        <h4>Identify one thing that helps increase the stock:</h4>
      </div>
      <Field
        name="one-thing"
        component="textarea"
        className="reflection-inline"
        placeholder="Enter your response here."
      />
      <div className="template-pair">
        <p className="diagram-identifier">C.</p>
        <h4>Identify one thing that helps decrease the stock:</h4>
      </div>
      <Field
        name="the-stock"
        component="textarea"
        className="reflection-inline"
        placeholder="Enter your response here."
      />
    </div>
  </div>
);
