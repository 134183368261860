import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <p>
      The word “gap” can oftentimes connote a perceived deficiency or negative
      state of a system: gaps between those who have financial means and those
      who are poor; academic achievement gaps related to students who are not
      achieving at their own grade level; and opportunity gaps where some groups
      of people have substantially more opportunities than others because of
      where they live, their race, ethnicity or gender. The goal gap balancing
      feedback loop can help describe any of the above scenarios. It can also be
      a helpful tool to help illustrate learning, improvement and the
      realization of a vision.
    </p>

    <p>
      Suppose a community has a vision for high-quality care and education for
      all of its young children ages birth-to-five. Some are in preschools,
      others in home care, and a good number are in homes of working parents who
      cannot afford increasingly expensive day care, so children often have
      little continuity in their day-to-day environments. Knowing that this age
      range is the most important for cognitive and social-emotional
      development, high quality learning and care conditions are critical for
      the sound development of early learners.
    </p>

    <p>
      The balancing feedback loop below can begin to describe this vision and
      the gap between where education and care conditions are now and how we
      would like it to be for young children. Various strategies to intervene
      and improve the current situation for early learners can be identified
      using other tools of systems thinking (e.g. iceberg, stock-flow mapping,
      causal loop archetypes) and tracked for their impact on progress in the
      community.
    </p>

    <img
      className="lesson-img large-img block"
      src="/images/courses/tools/08-cld-balancing/balancing-example.svg"
      alt="Balancing Example"
    />
  </div>
);
