import * as React from "react";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const PrivacyPolicy = () => (
  <div>
    <Header />
    <div className="main">
      <div className="privacy-container">
        <section className="photo-header privacy">
          <h1>Privacy Policy</h1>
          <img src="images/site/privacy-icon.svg" />
        </section>
        <section className="white">
          <h4 className="centered">
            About the Waters Center for Systems Thinking and our Privacy Policy
          </h4>
          <p>
            The Waters Center for Systems Thinking, a not-for-profit 501(c)(3)
            organization, feels strongly about protecting your privacy. We
            understand how important privacy is to you, and we are committed to
            creating a safe and secure environment for learners. Our Waters
            Center for Systems Thinking (“Waters Center,” “us,” or “we”) Privacy
            Policy applies to thinkingtoolsstudio.org, waterscenterst.org, our
            mobile applications, and online services (collectively, our
            “Service”), which are all owned and operated by the Waters Center
            for Systems Thinking Inc. This Privacy Policy describes how the
            Waters Center collects and uses the information you provide on our
            Service, and describes the choices available to you regarding our
            use of your personal information and how you can access and update
            this information.
          </p>
          <p>
            Our Privacy Policy describes what information we gather from our
            members and others who visit and use our Service, how we use that
            information, and what we do to protect it. By visiting or using our
            Service, you consent to the information handling practices described
            in this Privacy Policy.
          </p>
        </section>
        <section className="grey tableofcontents">
          <h4>Table of Contents</h4>
          <ol>
            <li>
              <a href="our-privacy-policy/#section1">
                What information do we collect?
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section2">
                How we use the information we collect
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section3">
                How we share or transfer data
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section4">
                Marketing and sponsored content
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section5">
                Third parties and online advertising
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section6">
                Your choice and options relating to data collection and use
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section7">
                How to access, update, or delete your personal information
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section8">
                Our approach to data security
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section9">Children</a>
            </li>
            <li>
              <a href="our-privacy-policy/#section10">International visitors</a>
            </li>
            <li>
              <a href="our-privacy-policy/#section11">
                European Union data protection
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section12">Links to other sites</a>
            </li>
            <li>
              <a href="our-privacy-policy/#section13">
                Changes and updates to this Privacy Policy
              </a>
            </li>
            <li>
              <a href="our-privacy-policy/#section14">
                Contacting Waters Center for Systems Thinking
              </a>
            </li>
          </ol>
        </section>

        <section className="white" id="section1">
          <h4>1. What information do we collect?</h4>
          <p>
            <strong>
              We take the information you provide to us very seriously, and we
              strive to put you in control of decisions around your information.
            </strong>
          </p>
          <p>
            You can use many features of the Service without signing up for an
            account or providing any other personal information. If you create
            an account on the Service, or communicate with us, you may provide
            to Waters Center what is generally called “Personal Information” or
            “Personal Data,” such as your full name, email address or other
            information that can be used to identify you. Information which
            cannot be used to identify you as an individual is “de-identified”
            or non-personal information.
          </p>
          <p>
            We collect information, including personal information, in a variety
            of ways which may vary according to your use of the Service and your
            account settings. The categories of personal information we collect
            may include:
          </p>
          <ul className="bullets">
            <li>Contact and profile information</li>
            <li>Location information</li>
            <li>Donation and applicant data</li>
            <li>Information about your browser or device</li>
            <li>
              Non-personal information which may be linked to your Personal
              Information
            </li>
          </ul>
          <p>
            Below, we describe examples of the categories of information we
            collect from and about you when you use our Service:
          </p>
          <p>
            <strong>
              Information you provide to us and information we receive about you
              from others:
            </strong>
          </p>
          <h5>Contact and profile information</h5>
          <p>
            When you create an account on the Service, or communicate with us,
            we may collect personal information including your name and email
            address. After signing up, you may also choose to provide additional
            information in your profile, such as your nickname, location (city)
            and other personal or demographic information. In addition, we may
            ask you for personal information at other times, such as when you
            contact our technical support team, send us an email, complete a
            user survey, or otherwise communicate with the Service.
          </p>
          <h5>Location information</h5>
          <p>
            We may collect and use information about your location (such as your
            country) or infer your approximate location based on your IP
            address, but we don’t collect the precise geolocation of you or your
            device. You may be able to change the settings on your computer or
            mobile device to prevent it from providing us with any location
            information.
          </p>
          <h5>Donation and applicant information</h5>
          <p>
            If you donate to Waters Center or Thinking Tools Studio via PayPal,
            we may obtain your name and mailing address to send a thank you
            note.
          </p>
          <h5>Usage and device information</h5>
          <p>
            To provide a personalized learning and high-quality experience for
            our users, we may use various technologies that automatically record
            certain technical information from your browser or device when you
            visit our website, read our emails, use our Service or otherwise
            engage with us. This information is typically collected through a
            variety of tracking technologies, including cookies, web beacons,
            Flash objects, log files, and similar technology (collectively,
            “tracking technologies”). These tracking technologies cookies
            collect information about how you use the Service (e.g., the pages
            you view, the links you click, and other actions you take on the
            Service), information about your browser and online usage patterns
            (e.g., Internet Protocol (“IP”) address, browser type, browser
            language, referring / exit pages and URLs, pages viewed, whether you
            opened an email, links clicked), and information about the device(s)
            you use to access the Service (e.g., mobile device identifier,
            mobile carrier, device type, model and manufacturer, mobile device
            operating system brand and model, and whether you access the Service
            from multiple devices). We may collect analytics data, or use
            third-party analytics tools such as Google Analytics, to help us
            measure traffic and usage trends for the Sites and to understand
            more about the demographics of our users. You can learn more about
            Google’s practices at{" "}
            <a
              href="http://www.google.com/policies/privacy/partners"
              target="blank"
              rel="noopener"
            >
              http://www.google.com/policies/privacy/partners
            </a>
            , and view its currently available opt-out options at{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="blank"
              rel="noopener"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>
            . We may also work with third party partners to employ technologies,
            including the application of statistical modeling tools, which
            permit us to recognize and contact you across multiple devices.
            Although we do our best to honor the privacy preferences of our
            users, we are unable to respond to Do Not Track signals set by your
            browser at this time.
          </p>
          <p>
            We use or may use the data collected through tracking technologies
            to better display our website, to save you time, to provide better
            technical support, for promotional purposes, and to track website
            usage. For example, cookies help us to:
          </p>
          <ol>
            <li>
              Keep track of whether you are signed in or have previously signed
              in so that we can display all the features that are available to
              you.
            </li>
            <li>
              Track website usage for various purposes including sales,
              marketing, and billing.
            </li>
            <li>
              Temporarily save responses to prompts until a user account is
              established.
            </li>
          </ol>
          <p>
            Most browsers are initially set up to accept cookies, but you can
            reset your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some features and services
            (particularly those that require you to sign-in) may not function
            properly if your cookies are disabled. You may also set your email
            options to prevent the automatic downloading of images that may
            contain technologies that would allow us to know whether you have
            accessed our email and performed certain functions with it.
          </p>
          <p>
            Deleting cookies does not delete Local Storage Objects (LSOs) such
            as Flash objects and HTML5. You can learn more about Flash objects –
            including how to manage privacy and storage settings for Flash
            cookies – on Adobe’s website or by clicking{" "}
            <a
              href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html"
              target="blank"
              rel="noopener"
            >
              here
            </a>
            . Various browsers may offer their own management tools for removing
            HTML5 LSOs. Please consult your browser’s “Help” function to learn
            more.
          </p>
        </section>
        <section className="white" id="section2">
          <h4>2. How we use the information we collect</h4>
          <p>
            <strong>
              The Waters Center and The Thinking Tools Studio use this
              information in the following ways:
            </strong>
          </p>
          <h5>To provide and enhance our Service</h5>
          <p>
            We use the information you provide or that we collect to operate,
            maintain, enhance, and provide all of the features of our Service.
          </p>
          <h5>To personalize your experience</h5>
          <p>
            We use the information to personalize your experience while using
            the Service, including on various devices you may use to access the
            Service. For instance, Thinking Tools Studio remembers your recent
            activity so we can recommend the most appropriate content for you on
            your next visit and provide personalized learning experiences.
          </p>
          <h5>To communicate with you</h5>
          <p>
            We use your information to communicate with you about your account
            and respond to inquiries. We may also use your Personal Information
            to provide you with information about features, services and other
            offerings that may be of interest to you.
          </p>
          <h5>To understand, improve, and develop our Service</h5>
          <p>
            We use all of the information that you provide or that we collect
            from users to understand and analyze the usage trends, learning
            behaviors, and preferences of our users, to improve the way the
            Service works and looks, and to create new features and
            functionality.
          </p>
          <p>
            We may also use information to maintain, develop, support and
            improve our Service and other products and services.
          </p>
          <h5>
            To enable your participation in Waters Center partnership
            arrangements
          </h5>
          <p>
            We use your information to enable your participation in programs or
            features we may offer in partnership with third parties, to the
            extent you wish to participate in such programs.
          </p>
          <p>
            For personal data subject to the European Union General Data
            Processing Regulations (“GDPR”), we rely on several legal bases to
            process the data. These legal bases include where:
          </p>
          <ul className="bullets">
            <li>
              The processing is necessary to perform our contractual obligations
              in our Terms of Service or other contracts with you (such as to
              provide you the Service as described in our Terms of Service).
            </li>
            <li>
              You have given your prior consent, which you may withdraw at any
              time (such as for marketing purposes or other purposes we obtain
              your consent for from time to time).
            </li>
            <li>
              The processing is necessary to comply with a legal obligation, a
              court order or to exercise or defend legal claims.
            </li>
            <li>
              The processing is necessary for the purposes of our legitimate
              interests, such as in improving, personalizing, and developing the
              Service, marketing the Service, such as new features or products
              that may be of interest, and promoting safety and security as
              described above.
            </li>
          </ul>
          <p>
            If you have any questions about or would like further information
            concerning the legal basis on which we collect and use your personal
            information, please contact us using the contact details provided at
            the bottom of this document.
          </p>
        </section>
        <section className="white" id="section3">
          <h4>3. How we share or transfer data</h4>
          <p>
            Waters Center takes great care to protect the information you
            provide us. We do not rent or sell Personal Information that we
            collect from users with third parties.
          </p>
          <p>
            We will disclose Personal Information when you post content that is
            visible to others on the Service, when you consent or instruct us to
            share your Personal Information with third parties, or when we have
            a legitimate business or legal need to share your information. We
            may also disclose anonymous or aggregate information that does not
            reasonably identify you as an individual. To learn more, see the
            additional description below.
          </p>
          <h5>
            When information is visible to others on the Service by default
          </h5>
          <p>
            Certain features and functions of the Service share or make your
            information accessible to others. As with most online services, once
            you make your Personal Information available to others online, it
            may be collected and used by the recipients or any other website
            visitor without restriction. You may wish to use a nickname or alias
            in your profile if you do not want your personal information shared
            with others in this way. For example, your personal information may
            be visible to others in the following circumstances:
          </p>
          <ul className="bullets">
            <li>
              <strong>
                Information you post to public spaces of the Service.
              </strong>{" "}
              When you post a question or answer to the Service, your profile,
              which could include your name, short biography, and city-level
              location, as well as the content of your posted comment, is
              visible to others on the Service.
            </li>
            <li>
              <strong>Information visible to Waters Center.</strong> When your
              account is connected to some of our Services, such as connection
              with a Coach or our certification program, Waters Center will be
              able to see your profile information and your activities on the
              Service, such as which courses you have completed, the questions
              and responses you post, and other usage information. Coaches may
              also be able to see your name (not only your username) as well as
              your email address, if you include that information in your
              account profile.
            </li>
          </ul>
          <h5>With your consent or according to your instructions</h5>
          <p>
            You may provide consent or authorization to share your Personal
            Information with third party applications or services in several
            ways. Please note that these third parties are not governed by
            Waters Center or the terms of this Privacy Policy. We recommend that
            you review the privacy policy of any third party application or
            service before authorizing access to your account information.
          </p>
          <ul className="bullets">
            <li>
              We may share data with social networks at your request. Some of
              our users choose to connect their account with social networks.
              This functionality is entirely optional. If you have explicitly
              agreed that we may do so, we may disclose your Personal
              Information through a social network or similar service (like
              Facebook, Twitter or Google). To control the information that you
              share, you have the option of modifying your Waters Center or
              social network settings or disconnecting your social network
              account from your Waters Center account. You may also choose to
              share content with others by email, or by posting Waters Center
              content to social media sites such as Facebook or Twitter. These
              third parties may use your information as described in their own
              privacy policies.
            </li>
          </ul>
          <h5>We may share anonymous or aggregate data with others.</h5>
          <p>
            We may use data which has been de-identified and/or aggregated for
            product development, research, analytics and other purposes,
            including for the purpose of analyzing, improving, or marketing our
            Services.
          </p>
          <h5>
            Other instances where we may share or disclose information for legal
            or business purposes
          </h5>
          <ul className="bullets">
            <li>
              We will share data with employees and service providers. Waters
              Center may share information with our employees and trusted
              vendors, third party service providers and other individuals to
              provide services or products for us or on our behalf, which could
              include analytics, hosting, marketing and similar services. When
              we share personal information with third party service providers
              or vendors, these third parties are contractually obligated to
              maintain the security and confidentiality of that personal
              information.
            </li>
            <li>
              Other instances Waters Center may release Personal Information if
              we have a good faith belief that access, use, preservation, or
              disclosure of such information is reasonably necessary to (a)
              satisfy any applicable law, regulation, legal process, or
              enforceable governmental request; (b) enforce applicable Terms of
              Service, including investigation of potential violations thereof;
              (c) investigate and defend ourselves against any third-party
              claims or allegations; (d) detect, prevent or otherwise address
              fraud, security or technical issues; (e) protect the rights,
              property, or personal safety of Waters Center, our users, or the
              public; or (f) as required or permitted by law.
            </li>
          </ul>
        </section>
        <section className="white" id="section4">
          <h4>4. Marketing and sponsored content</h4>
          <h5>Messages from Waters Center and Thinking Tools Studio</h5>
          <p>
            We may, from time to time, send you emails regarding our products
            and services.
          </p>
          <h5>Sponsored content</h5>
          <p>
            Waters Center does not display any third party advertisements on the
            Service. From time to time, we may permit third parties to sponsor
            content displayed on our Service. For example, for-profit
            organizations may wish to sponsor new content. Sponsored content
            will always be labeled (e.g., “Sponsored by ___”). Waters Center
            does not share any of our users’ Personal Information with these
            sponsors without explicit consent, and these sponsors do not have
            the ability to track or collect information about our site visitors
            or users. As a non-profit organization, Waters Center relies on our
            sponsors, donors, and other contributors to provide funding
            necessary to provide the free Service to our users.
          </p>
          <p>
            Please note: From time to time, we may display YouTube video content
            created by third parties and not by Waters Center. While Waters
            Center-created video content does not display video advertisements,
            third party content may include advertising which we cannot control.
          </p>
        </section>
        <section className="white" id="section5">
          <h4>5. Third parties and online advertising</h4>
          <h5>Interest-based advertising</h5>
          <p>
            Waters Center does not display any targeted advertising on our
            Service. However, we participate in interest-based advertising and
            use third party advertising companies to serve you targeted
            advertisements on other websites, apps or services, including on
            Facebook and other social networks, or on other devices you may use.
            These third party ad networks use tracking technologies to recognize
            your browser or device and to collect information about your visit
            to our Service to provide you customized content, advertising and
            commercial messages which may be more relevant to your interests, as
            well as to provide advertising-related services such as reporting,
            attribution, analytics and market research.
          </p>
          <p>
            To learn about interest-based advertising and how you may be able to
            opt-out of some of this advertising, you may wish to visit the
            Network Advertising Initiative’s online resources, at{" "}
            <a
              href="http://www.networkadvertising.org/choices"
              target="blank"
              rel="noopener"
            >
              http://www.networkadvertising.org/choices
            </a>
            , and/or the DAA’s resources at{" "}
            <a
              href="http://www.aboutads.info/choices"
              target="blank"
              rel="noopener"
            >
              http://www.aboutads.info/choices
            </a>
            . You may also manage certain advertising cookies by visiting the
            EU-based Your Online Choices at{" "}
            <a
              href="http://www.youronlinechoices.eu/"
              target="blank"
              rel="noopener"
            >
              http://www.youronlinechoices.eu/
            </a>
            . You may also be able to limit interest-based advertising through
            the settings on your mobile device by selecting “limit ad tracking”
            (iOS) or “opt-out of interest based ads” (Android). You may also be
            able to opt-out of some – but not all – interest-based ads served by
            mobile ad networks by visiting{" "}
            <a
              href="http://youradchoices.com/appchoices"
              target="blank"
              rel="noopener"
            >
              http://youradchoices.com/appchoices
            </a>{" "}
            and downloading the mobile AppChoices app.
          </p>
          <p>
            Please note that opting-out of receiving interest-based advertising
            through the NAI’s and DAA’s online resources will only opt-out a
            user from receiving interest-based ads on that specific browser or
            device, but the user may still receive interest-based ads on his or
            her other devices. You must perform the opt-out on each browser or
            device you use.
          </p>
          <h5>YouTube</h5>
          <p>
            Waters Center uses YouTube to display video content on the Service.
            YouTube incorporates tracking technologies, which may be present in
            the YouTube videos embedded on the Service, which may collect
            information from your browser when you view the video on the
            Service, including device identifiers and/or cookie IDs. This
            information is collected directly and automatically by YouTube and
            its partners, and Waters Center does not participate in these data
            transmissions. Waters Center does not provide any Personal
            Information, such as user names, to YouTube.
          </p>
        </section>
        <section className="white" id="section6">
          <h4>
            6. Your choice and options relating to data collection and use
          </h4>
          <p>
            At Waters Center, we use Personal Information as needed for the
            purposes for which it was collected or where you have consented to
            our use of your Personal Information. We take reasonable steps to
            ensure that the Personal Information we store and use is accurate,
            complete, and up-to-date. If you discover that Personal Information
            or other data pertaining to you is inaccurate, incomplete, or
            out-of-date, please update your account information or contact us as
            outlined below.
          </p>
          <h5>You can choose not to provide us with Personal Information.</h5>
          <p>
            You may always decline to provide your Personal Information to
            Waters Center. Registration is not required to access most of our
            online resources. If you decline to sign up, however, Waters Center
            will not be able to provide to you certain features and
            functionalities found on our Service. You may later enable or access
            those features by providing us with the necessary Personal
            Information.
          </p>
          <p>
            You can choose to provide only information which does not reasonably
            identify you to others. We recommend you select a username which is
            not related to your actual name, for example. You can also decline
            to provide any optional data in your profile.
          </p>
          <h5>You can disconnect us from Integrated Services.</h5>
          <p>
            You may revoke Waters Center’s access to your account on any
            Integrated Service, such as Google or Facebook, at any time by
            updating the appropriate settings in the account preferences of the
            respective Integrated Service. You should check your privacy
            settings on each Integrated Service to understand and change the
            information sent to us through each Integrated Service. Please
            review each Integrated Service’s terms of use and privacy policies
            carefully before using their services and connecting to our Service.
          </p>
          <h5>You can unsubscribe from email communications.</h5>
          <p>
            Waters Center may, from time to time, send you email regarding our
            products and services, or products and services we think you may
            enjoy. Only Waters Center (or our vendors or service providers
            operating on our behalf) will send you these emails. You can choose
            not to receive these emails by clicking the unsubscribe link in any
            e-mail. Please note that you are not permitted to unsubscribe or
            opt-out of non-promotional messages regarding your account, such as
            account verification, changes or updates to features of the Service,
            or technical or security notices.
          </p>
        </section>
        <section className="white" id="section7">
          <h4>7. How to access, update, or delete your personal information</h4>
          <h5>How to access or update your information</h5>
          <p>
            We want you to have access to your information, so that you can help
            keep it as accurate as possible. If you sign up and provide Waters
            Center with Personal Information, you may update, correct, or delete
            your account and information at any time by reviewing your profile
            information and preferences on your account settings page.
          </p>
          <h5>How to delete your information</h5>
          <p>
            To request deletion of your Personal Information at any time, email{" "}
            <a href="mailto:info@waterscenterst.org">info@waterscenterst.org</a>
            .
          </p>
          <p>
            We may not be able to delete data in all instances, such as
            information retained in technical support logs and other business
            records. We will not be required to delete any information which has
            been de-identified or disassociated with personal identifiers such
            that it can no longer be used to reasonably identify a particular
            individual.
          </p>
          <p>
            Unless we receive a deletion request, we will retain your
            information for as long as your account is active or as is
            reasonably useful for operational purposes. For example, we may
            retain certain data as necessary to prevent fraud or future abuse,
            for recordkeeping or other legitimate business purposes, or if
            required by law. We may also retain information which has been
            de-identified or aggregated such that it can no longer reasonably
            identify a particular individual. All retained Personal Information
            will remain subject to the terms of this Privacy Policy.
          </p>
        </section>
        <section className="white" id="section8">
          <h4>8. Our approach to data security</h4>
          <h5>Data security is important to you, and to us.</h5>
          <p>
            To protect your privacy and security, we take reasonable steps to
            verify your identity before granting you account access or making
            corrections to your information. For example, we may ask you to
            provide certain Personal Information to confirm your identity, and
            we may require that you create and use a password to access certain
            parts of our Service. You should create and maintain a strong
            password to help ensure the security of your account.
          </p>
          <h5>
            We try to ensure that our Service and information sent to us are
            safe, but no security measures are perfect.
          </h5>
          <p>
            Waters Center uses certain physical, managerial, and technical
            safeguards designed to preserve the integrity and security of your
            Personal Information and other information we maintain in connection
            with our Service. We cannot, however, guarantee the security of any
            or all of the information you transmit to Waters Center, and you do
            so at your own risk. Once we receive your transmission of
            information, Waters Center makes commercially reasonable efforts to
            ensure the security of our systems. When you enter sensitive
            information, we encrypt the transmission of that information using
            secure socket layer technology (SSL) or similar technologies.
            However, please note that this is not a guarantee that such
            information may not be accessed, disclosed, altered, or destroyed by
            breach of any of our physical, technical, or managerial safeguards.
            In the event that any information under our control is compromised
            as a result of a breach of security, we will take reasonable steps
            to investigate the situation and will notify you of the security
            incident in accordance with applicable laws and regulations.
          </p>
        </section>
        <section className="white" id="section9">
          <h4>9. Children</h4>
          <p>
            The Services are intended for users over the age of 18 and are not
            directed at children under the age of 13. If we become aware that we
            have collected personal information (as defined by the Children’s
            Online Privacy Protection Act) from children under the age of 13, or
            personal data (as defined by the EU GDPR) from children under the
            age of 16, we will take reasonable steps to delete it as soon as
            practicable.
          </p>
        </section>
        <section className="white" id="section10">
          <h4>10. International visitors</h4>
          <p>
            Our Service is operated and managed on servers located within the
            United States. If you choose to use our Service from the European
            Union or other regions of the world with laws governing data
            collection and use that differ from United States law, then you
            acknowledge that Waters Center will transfer your personal
            information to the United States for the purpose of performing the
            Service according to our contract (e.g., our Terms of Service) and
            for any other purpose for which you provide explicit, informed
            consent.
          </p>
        </section>
        <section className="white" id="section11">
          <h4>11. European Union data protection</h4>
          <p>
            Residents in the European Union are entitled to certain rights with
            respect to personal information that we hold about them:
          </p>
          <ul className="bullets">
            <li>
              <strong>Right of access and portability</strong> The right to
              obtain access to your personal information, along with certain
              related information, and to receive that information in a commonly
              used format and to have it transferred to another data controller;
            </li>
            <li>
              <strong>Right to rectification</strong> The right to obtain
              rectification of your personal information without undue delay
              where that personal information is inaccurate or incomplete;
            </li>
            <li>
              <strong>Right to erasure</strong> The right to obtain the erasure
              of your personal information without undue delay in certain
              circumstances, such as where the personal information is no longer
              necessary in relation to the purposes for which it was collected
              or processed;
            </li>
            <li>
              <strong>Right to restriction</strong> The right to obtain the
              restriction of the processing undertaken by us on your personal
              information in certain circumstances, such as where the accuracy
              of the personal information is contested by you, for a period
              enabling us to verify the accuracy of that personal information;
              and
            </li>
            <li>
              <strong>Right to object</strong> The right to object, on grounds
              relating to your particular situation, to the processing of your
              personal information, and to object to processing of your personal
              information for direct marketing purposes, to the extent it is
              related to such direct marketing.
            </li>
          </ul>
          <p>
            You may also have the right to make a complaint to the relevant
            Supervisory Authority. A list of Supervisory Authorities is
            available here:{" "}
            <a
              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
              target="blank"
              rel="noopener"
            >
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            . If you need further assistance regarding your rights, please
            contact us using the contact information provided below and we will
            consider your request in accordance with applicable law. In some
            cases our ability to uphold these rights for you may depend upon our
            obligations to process personal information for security, safety,
            fraud prevention reasons, compliance with regulatory or legal
            requirements, or because processing is necessary to deliver the
            services you have requested. Where this is the case, we will inform
            you of specific details in response to your request.
          </p>
        </section>
        <section className="white" id="section12">
          <h4>12. Links to other sites</h4>
          <p>
            The Service may link to and may be linked to by websites operated by
            other entities or individuals. Some of these websites, such as the
            Waters Center Facebook page, may be co-branded with our name or
            logo. This Privacy Policy does not apply to, and we cannot always
            control the activities of, such other third-party websites. You
            should consult the respective privacy policies of those third-party
            websites.
          </p>
        </section>
        <section className="white" id="section13">
          <h4>13. Changes and updates to this Privacy Policy</h4>
          <p>
            Waters Center may modify or revise this Privacy Policy from time to
            time. Waters Center will notify users of any changes to our Privacy
            Policy by posting the revised Privacy Policy with an updated date of
            revision on our Service. If we change this Policy in a material
            manner, we will do our best to notify you of the changes by posting
            a notice on our website. Your continued use of the Service following
            the effective date of such update constitutes your acceptance of the
            revised Policy.
          </p>
        </section>
        <section className="white" id="section14">
          <h4>14. Contacting Waters Center for Systems Thinking</h4>
          <p>Please contact Waters Center with any questions or comments.</p>
          <p>
            By email:{" "}
            <a href="mailto:info@waterscenterst.org">info@waterscenterst.org</a>
          </p>
          <p>
            By mail: Waters Center for Systems Thinking, 7090 N Oracle Rd, Suite
            178-127, Tucson, AZ 85704-4383
          </p>
        </section>
      </div>
    </div>
    <Footer />
  </div>
);
