import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Think through a recent day in your life and the many decisions you made —
      some decisions that needed little thinking and were made instinctively,
      and others that required careful thought.
    </p>
    <h4>
      As you reflect on your recent experiences, think of a time when you made a
      snap decision you later regretted. What practices would help you in the
      future when schedules are busy and there is little time to think about
      decisions?
    </h4>
    <Field
      name="recent-experiences"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How do you know when you have taken the right amount of time to make a
      decision?
    </h4>
    <Field
      name="right-amount"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
