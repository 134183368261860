import * as React from "react";
import { Field } from "redux-form";
import { ToggleText } from "components/shared/toggle-text";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>

    <p>
      Many of the Habits of a Systems Thinker apply to the iceberg. Each level
      calls for a specific ways of thinking about a system and each level of the
      iceberg below the water line can connect with specific Habits.
    </p>

    <p>
      Reflect on each iceberg level and identify additional Habits that relate
      to that level of thinking and understanding. Explain why you chose those
      Habits. Please note: possible hints are provided in this exercise, yet
      your rationale for each is important to clarify the connections you make
      between the Habits and each iceberg level. The Habits listed as hints are
      not the only possibilities. You could make a case for almost any of the
      Habits as they are all interrelated.
    </p>

    <h3>Patterns & Trends</h3>

    <img
      className="lesson-img habit-img"
      src="images/shared/habit-cards/shadow/change-over-time.png"
      alt="patterns"
    />

    <p>
      Habit:{" "}
      <em>
        <a href="/courses/06-patterns">
          Observes how elements within systems change over time, generating
          patterns and trends.
        </a>
      </em>
    </p>

    <p>
      What is changing over time? How are elements (structures) changing and
      what is causing those changes?
    </p>

    <h4>What other Habits might help you think about patterns and trends?</h4>

    <Field
      name="patterns"
      component="textarea"
      className="reflection-input"
      placeholder="Enter your response here."
    />

    <ToggleText
      showText="Show Hints"
      hideText="Hide Hints"
      text={[
        { id: 1, text: "Changes perspectives to increase understanding" },
        { id: 2, text: "Surfaces and tests assumptions" },
        {
          id: 3,
          text: "Pays attention to accumulations and their rates of change",
        },
      ]}
    />

    <h3>Structure</h3>

    <img
      className="lesson-img habit-img"
      src="images/shared/habit-cards/shadow/structure.png"
      alt="structure"
    />

    <p>
      Habit:{" "}
      <em>
        <a href="/courses/08-structure">
          Recognizes that a system’s structure generates its behavior.
        </a>
      </em>
    </p>

    <p>
      How does the organization and interaction of elements create the behavior
      we see? How do we focus on causes?
    </p>

    <h4>What other Habits might help you think about a system’s structure?</h4>

    <Field
      name="structure"
      component="textarea"
      className="reflection-input"
      placeholder="Enter your response here."
    />

    <ToggleText
      showText="Show Hints"
      hideText="Hide Hints"
      text={[
        {
          id: 1,
          text: "Makes meaningful connections within and between systems",
        },
        {
          id: 2,
          text: "Identifies the circular nature of complex cause and effect",
        },
        {
          id: 3,
          text: "Uses understanding of system structure to identify possible leverage actions",
        },
      ]}
    />

    <h3>Mental Models</h3>

    <img
      className="lesson-img habit-img"
      src="images/shared/habit-cards/shadow/mental-models.png"
      alt="mental models"
    />

    <p>
      Habit:{" "}
      <em>
        <a href="/courses/05-mental-models">
          Considers how mental models affect current reality and the future.
        </a>{" "}
      </em>
    </p>

    <p>
      How do current mental models affect our efforts? How do current mental
      models advance our desired results? How do we develop an awareness of
      mental models and help others see the influence mental models have on our
      decision making?
    </p>

    <h4>What other Habits might help you think about mental models?</h4>

    <Field
      name="mentalmodels"
      component="textarea"
      className="reflection-input"
      placeholder="Enter your response here."
    />

    <ToggleText
      showText="Show Hints"
      hideText="Hide Hints"
      text={[
        { id: 1, text: "Changes perspectives to increase understanding" },
        { id: 2, text: "Seeks to understand the big picture" },
        { id: 3, text: "Surfaces and tests assumptions" },
      ]}
    />
  </div>
);
