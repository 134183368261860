import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Abstract:</h3>

    <h4>
      The Comprehensive Planning and Implementation Guide for School
      Administrators
    </h4>

    <p>
      This book offers the steps, methods, and strategies a team of professional
      educators successfully used in one school to develop Total Quality
      Education (TQE). The school is Orange Grove Middle School in the Catalina
      Foothills School District in Tucson, Arizona. The key strategies for
      accomplishing this success are spelled out in detail. Administrators,
      teachers, board members, and parents will find this account immensely
      helpful in integrating the many theories and practices provided by books
      in this series into one school's perspective.
    </p>

    <p>
      <strong>Authors:</strong> Mary Sheetz, Tracy Benson
    </p>
  </div>
);
