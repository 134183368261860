import * as React from "react";
import { Field } from "redux-form";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>Consider this example:</h4>
    <p>
      A new non-profit recognizes that more name recognition could be a leverage
      to increase donations. The cost of traditional media is a financial
      stretch, so they decide that one way to spread the word about their good
      work is by restructuring the board to include additional members from more
      diverse backgrounds. A stated responsibility of board members would be to
      utilize their own social media connections to build name recognition for
      the non-profit.
    </p>
    <p>
      The non-profit recognized that the individual networks of their board
      members were structures they could leverage to achieve their goal of
      greater name recognition. Even when you fully appreciate the role of a
      system’s structure, it can be tricky to identify which structures need to
      be changed in order to achieve your desired result. Changes in structure
      that are too rapid or too dramatic can produce unintended consequences and
      take you farther away from the results you want to achieve.
    </p>
    <p>Now it’s your turn to become a structural designer...</p>
    <h4>
      Identify a system of which you are a part and one that you have been
      wanting to change or positively impact. For example, a classroom, a work
      team, a community organization, etc.
    </h4>
    <Field
      name="identify-system"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Describe the current reality of this system. What are the structures? What
      behaviors are they currently producing?
    </h4>
    <Field
      name="current-reality"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Envision your desired results. What behaviors would you like to see
      produced?
    </h4>
    <Field
      name="desired-results"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Reflect on your current knowledge about what could produce these desired
      results. What are best practices that you have learned about or viewed
      firsthand that could work?
    </h4>
    <Field
      name="current-knowledge"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Combine your current theories of what structures you think will work with
      the unique aspects of the system you want to impact. As a designer,
      describe the structures that you could implement to effect change. What
      existing structures are helping/hindering that effort?
    </h4>
    <Field
      name="current-theories"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Recognizing that a system’s structure generates its behavior and
      eliminates the need for blame, ask yourself: when things go wrong, how can
      I focus on internal causes rather than dwell on external blame?
    </h4>
    <Field
      name="structure-generates"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Change can be instantaneous, but it rarely produces instantaneous results.
      Set a timeline to reassess progress toward achieving your desired result.
    </h4>
    <Field
      name="instantaneous"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      If you are interested in furthering your learning about structural design,
      consider investigating a process referred to as Design Thinking. The
      popularity of Design Thinking has led to many interpretations and
      applications. A Google search will provide you with a full array of
      articles and blogs. We recommend these resources produced by the original
      developers of Design Thinking.
    </p>
    <p>
      <a
        href="https://www.ideou.com/pages/design-thinking"
        target="blank"
        rel="noopener"
      >
        IdeoU, Design Thinking
      </a>
    </p>
    <a
      href="https://dschool.stanford.edu/resources-collections/a-virtual-crash-course-in-design-thinking"
      target="blank"
      rel="noopener"
    >
      Stanford University’s d.school, A Crash Course in Design Thinking
    </a>
  </div>
);
