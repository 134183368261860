import * as React from "react";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const CourseEnd = () => (
  <div>
    <Header />
    <div className="content">
      <div>
        <h1>Thank you!</h1>
        <h3>
          We value your feedback and support of the Thinking Tools Studio.
        </h3>
      </div>
      <div className="end-nav">
        <div className="nav-button">
          <a href="courses/habits">Back to the Habits courses</a>
        </div>
        <div className="nav-button">
          <a href="courses/tools">Back to the Tools courses</a>
        </div>
        <div className="nav-button right">
          <a href="courses/archetypes">Back to the Archetypes courses</a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
