import * as React from "react";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const Terms = () => (
  <div>
    <Header />
    <div className="main">
      <div className="terms-container">
        <section className="photo-header terms">
          <h1>Terms of Use</h1>
          <img src="images/site/terms-icon.svg" />
        </section>
        <section className="white">
          <h4 className="centered">
            Waters Center for Systems Thinking, Inc. Terms of Service
          </h4>
          <p>Last Updated: August 2019</p>
          <p>
            Welcome, and thank You for Your interest in Waters Center for
            Systems Thinking, Inc., a 501(c)(3) organization (“
            <strong>Waters Center</strong>” or “<strong>We</strong>” or “
            <strong>Us</strong>”), which operates the Websites located at
            waterscenterst.org and thinkingtoolsstudio.org (the “
            <strong>Website</strong>”). The following Terms of Service are a
            legal contract between You (“<strong>You</strong>”) and Waters
            Center regarding Your use of the Website. Visitors and users of the
            Website are referred to individually as “<strong>User</strong>” and
            collectively as “<strong>Users</strong>".
          </p>
          <p>
            Please read the following Terms of Service carefully.{" "}
            <strong>
              By signing up for, accessing, browsing, or using the Website, You
              acknowledge that You have read, understood, and agree to be bound
              by the following Terms and Conditions, including the Waters Center
              Privacy Policy and any additional guidelines (as defined below)
            </strong>{" "}
            (collectively, the “<strong>Terms</strong>”).
          </p>
        </section>

        <section className="grey tableofcontents">
          <h4>Table of Contents</h4>
          <ol>
            <li>
              <a href="terms/#section1">Eligibility; Accounts</a>
            </li>
            <li>
              <a href="terms/#section2">Privacy Policy</a>
            </li>
            <li>
              <a href="terms/#section3">Other Guidelines</a>
            </li>
            <li>
              <a href="terms/#section4">Modification of the Terms</a>
            </li>
            <li>
              <a href="terms/#section5">
                User Content License Grant; Representations and Warranties
              </a>
            </li>
            <li>
              <a href="terms/#section6">Digital Millennium Copyright Act</a>
            </li>
            <li>
              <a href="terms/#section7">Proprietary Materials; Licenses</a>
            </li>
            <li>
              <a href="terms/#section8">Prohibited Conduct</a>
            </li>
            <li>
              <a href="terms/#section9">
                Third-Party Sites, Products and Services; Links
              </a>
            </li>
            <li>
              <a href="terms/#section10">Termination</a>
            </li>
            <li>
              <a href="terms/#section11">Indemnification</a>
            </li>
            <li>
              <a href="terms/#section12">Disclaimers; No Warranties</a>
            </li>
            <li>
              <a href="terms/#section13">Limitation of Liability and Damages</a>
            </li>
            <li>
              <a href="terms/#section14">
                Miscellaneous (including Dispute Resolution and Arbitration)
              </a>
            </li>
          </ol>
        </section>

        <section className="white" id="section1">
          <h4>1. Eligibility; Accounts</h4>
          <p>
            <strong>
              The Website is not available to (a) any users previously suspended
              or removed from the Website by Waters Center or (b) any persons
              under the age of 18 whose registration has not been approved by a
              legal parent or guardian.
            </strong>{" "}
            By clicking the “I Agree” button or by otherwise using or signing up
            an account for the Website, You represent (a) that You have not been
            previously suspended or removed from the Website by Waters Center;
            (b) that You are either (i) at least 18 years of age or (ii) Your
            parent and/or guardian has consented to Your use of the Website; and
            (c) that Your registration and Your use of the Website is in
            compliance with any and all applicable laws and regulations.
          </p>

          <h5>1.1 Account</h5>
          <p>
            In order to use certain features of the Website, You must sign up
            for an account. You may be asked to provide a password in connection
            with Your account. You are solely responsible for maintaining the
            confidentiality of Your account and password, and You agree to
            accept responsibility for all activities that occur under Your
            account or password. You agree that the information You provide to
            Waters Center, whether at registration or at any other time, will be
            true, accurate, current, and complete. You also agree that You will
            ensure that this information is kept accurate and up-to-date at all
            times. If You have reason to believe that Your account is no longer
            secure (e.g., in the event of a loss, theft or unauthorized
            disclosure or use of Your account ID or password), then You agree to
            immediately notify Waters Center at info@waterscenterst.org. You may
            be liable for the losses incurred by Waters Center or others due to
            any unauthorized use of Your Website account.
          </p>

          <h5>1.2 Integrated Service</h5>
          <p>
            Waters Center may permit You to sign up for the Website through, or
            otherwise associate Your Waters Center account with, certain third
            party social networking or integrated services, such as Facebook
            Connect and Google (“Integrated Service”). By signing up for the
            Website using (or otherwise granting access to) an Integrated
            Service, You agree that Waters Center may access Your Integrated
            Service’s account information, and You agree to any and all terms
            and conditions of the Integrated Service regarding Your use of the
            Website via the Integrated Service. You agree that any Integrated
            Service is a Reference Site (as defined below) and You are solely
            responsible for Your interactions with the Integrated Service as a
            result of accessing the Website through the Integrated Service.
            Waters Center does not control the practices of Integrated Services,
            and You are advised to read the Privacy Policy and terms and
            conditions of any Integrated Service that You use to understand
            their practices.
          </p>
        </section>
        <section className="white" id="section2">
          <h4>2. Privacy Policy </h4>
          <p>
            Your privacy is important to Waters Center. Please carefully read
            the Waters Center <a href="/our-privacy-policy">Privacy Policy</a>,
            hereby incorporated into the Terms, for information relating to
            Waters Center’s collection, use, and disclosure of Your personal
            information.
          </p>
        </section>
        <section className="white" id="section3">
          <h4>3. Other guidelines</h4>
          <p>
            When using the Website, You will be subject to any additional posted
            guidelines or rules applicable to specific services and features
            which may be posted from time to time (the “Guidelines”). All such
            Guidelines are hereby incorporated by reference into the Terms.
          </p>
        </section>
        <section className="white" id="section4">
          <h4>4. Modification of the Terms</h4>
          <p>
            Waters Center reserves the right, at our discretion, to change,
            modify, add, or remove portions of the Terms at any time. Please
            check the Terms and any Guidelines periodically for changes. Your
            continued use of the Website after the posting of changes
            constitutes Your binding acceptance of such changes. For any
            material changes to the Terms, Waters Center will make reasonable
            effort to provide notice to You of such amended Terms, such as by an
            email notification to the address associated with Your account or by
            posting a notice on the Website, and such amended terms will be
            effective against You on the earlier of (i) Your actual notice of
            such changes and (ii) thirty days after Waters Center makes
            reasonable attempt to provide You such notice. Disputes arising
            under these Terms will be resolved in accordance with the version of
            the Terms in place at the time the dispute arose.
          </p>
        </section>
        <section className="white" id="section5">
          <h4>5. User Content License Grant; Representations and Warranties</h4>
          <h5>5.1 User Content</h5>
          <p>
            Waters Center may permit the posting and/or publishing by You and
            other Users of notes, questions, comments, ratings, reviews, images,
            videos and other audio-visual materials and communications
            (collectively, “User Content”) You understand that whether or not
            such User Content is published, Waters Center does not guarantee any
            confidentiality with respect to any submissions.
          </p>
          <h5>5.2 License Grant to Waters Center</h5>
          <p>
            By posting, submitting or distributing User Content on or through
            the Website, You hereby grant to Waters Center a worldwide,
            non-exclusive, transferable, assignable, fully paid-up,
            royalty-free, perpetual, irrevocable right and license to host,
            transfer, display, perform, reproduce, distribute, prepare
            derivative works of, use, make, have made, sell, offer for sale,
            import, and otherwise use Your User Content, in whole or in part, in
            any media formats and through any media channels (now known or
            hereafter developed).
          </p>
          <h5>5.3 License Grant to Users</h5>
          <p>
            User Postings. By posting, submitting or distributing User Postings
            through the Website, You hereby grant to each User of the Website a
            non-exclusive license to access and use Your User Postings in any
            manner permitted or made available by Waters Center on or through
            the Website.
          </p>

          <h5>5.4 User Content Representations and Warranties</h5>
          <p>
            You are solely responsible for Your User Content and the
            consequences of posting, creating, or publishing them. You represent
            and warrant that: (1) You are the creator and owner of or have the
            necessary licenses, rights, consents, and permissions to use and to
            authorize Waters Center and Waters Center’s Users to use and
            distribute Your User Content as necessary to exercise the licenses
            granted by You in these Terms and in the manner contemplated by
            Waters Center and these Terms; (2) Your User Content does not and
            will not: (a) infringe, violate, or misappropriate any third-party
            right, including any copyright, trademark, patent, trade secret,
            moral right, privacy right, right of publicity, or any other
            intellectual property or proprietary right or (b) slander, defame,
            libel, or invade the right of privacy, publicity or other property
            rights of any other person; and (3) Your User Content does not
            contain any viruses, adware, spyware, worms, or other malicious
            code.
          </p>

          <h5>5.5 Access to Your User Content</h5>
          <p>
            Waters Center may permit Users to share their User Content with a
            select group of other Users, or make their User Content public for
            all (even non-Website users) to view. You acknowledge and agree
            that, although Waters Center may provide certain features intended
            to allow You to restrict some User Content You create from others,
            Waters Center does not guarantee that such User Content will never
            be accessible by others. In the event of unauthorized access, Waters
            Center will use reasonable efforts to notify You pursuant to Section
            14.1 below. Waters Center hereby disclaims any and all liability
            with respect to any unauthorized access to any restricted User
            Content.
          </p>

          <h5>5.6 User Content Disclaimer</h5>
          <p>
            You understand that when using the Website You will be exposed to
            User Content from a variety of sources, and that Waters Center is
            not responsible for the accuracy, usefulness, or intellectual
            property rights of or relating to such User Content. You further
            understand and acknowledge that You may be exposed to User Content
            that is inaccurate, offensive, indecent or objectionable, and You
            agree to waive, and hereby do waive, any legal or equitable rights
            or remedies You have or may have against Waters Center with respect
            thereto. Waters Center does not endorse any User Content or any
            opinion, recommendation or advice expressed therein, and Waters
            Center expressly disclaims any and all liability in connection with
            User Content.
          </p>
        </section>
        <section className="white" id="section6">
          <h4>6. Digital Millennium Copyright Act</h4>
          <p>
            It is Waters Center’s policy to respond to notices of alleged
            copyright infringement that comply with the Digital Millennium
            Copyright Act. For more information, please go to{" "}
            <a href="terms/#section15">
              Waters Center’s DMCA Notification Guidelines
            </a>
            . Waters Center will promptly terminate without notice Your access
            to the Website if You are determined by Waters Center to be a
            “repeat infringer.” A repeat infringer is a User who has been
            notified by Waters Center of infringing activity violations more
            than twice and/or who has had User Content or any other
            user-submitted content removed from the Website more than twice.
          </p>
        </section>
        <section className="white" id="section7">
          <h4>7. Proprietary Materials; Licenses</h4>
          <h5>7.1 Proprietary Materials</h5>
          <p>
            The Website is owned and operated by Waters Center. The visual
            interfaces, graphics, design, compilation, information, computer
            code (including source code or object code), software, services,
            content, educational videos and exercises, and all other elements of
            the Website (the “Website Materials”) are protected by United States
            and international copyright, patent, and trademark laws,
            international conventions, and other applicable laws governing
            intellectual property and proprietary rights. Except for any User
            Content provided and owned by Users and except as otherwise set
            forth in this Section 7, all Website Materials, and all trademarks,
            service marks, and trade names, contained on or available through
            the Website are owned by or licensed to Waters Center, and Waters
            Center reserves all rights therein and thereto not expressly granted
            by these Terms.
          </p>
          <h5>7.2 Licensed Educational Content</h5>
          <p>
            Waters Center may make available on the Website certain educational
            videos, exercises, and related supplementary materials that are
            owned by Waters Center or its third-party licensors (the “Licensed
            Educational Content”). Waters Center grants to You a non-exclusive,
            non-transferable right to access and use the Licensed Educational
            Content as made available on the Website by Waters Center solely for
            Your personal, non-commercial purposes. Unless expressly indicated
            on the Website that a particular item of Licensed Educational
            Content is made available to Users under alternate license terms,
            You may not download, distribute, sell, lease, modify, or otherwise
            provide access to the Licensed Educational Content to any third
            party.
          </p>
          <p>
            <strong>(a) Alternate Licenses</strong>
            <br />
            In certain cases, Waters Center or its licensors may make available
            Licensed Educational Content under alternate license terms, such as
            a variant of the Creative Commons License (as defined below) (each,
            an “Alternate License”). Where expressly indicated as such on the
            Website, and subject to the terms and conditions of these Terms, the
            applicable Licensed Educational Content is licensed to You under the
            terms of the Alternate License. By using, downloading, or otherwise
            accessing such Licensed Educational Content, You agree to comply
            fully with all the terms and conditions of such Alternate License.
          </p>

          <p>
            <strong>(b) Creative Commons License </strong>
            <br />
            Unless expressly otherwise identified on the Website with respect to
            a particular item of Licensed Educational Content, any reference to
            the “Creative Commons”, “CC” or similarly-phrased license shall be
            deemed to be a reference to the Creative Commons
            Attribution-NonCommercial-ShareAlike 4.0 United States License
            (available at{" "}
            <a
              href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              target="blank"
              rel="noopener"
            >
              http://creativecommons.org/licenses/by-nc-sa/4.0/
            </a>
            ) (the “Creative Commons License”).
          </p>

          <h5>7.3 Non-Commercial Use</h5>
          <p>
            <strong>
              The Licensed Educational Content are intended for personal,
              non-commercial use only.
            </strong>{" "}
            Without limiting the foregoing, and notwithstanding the terms of any
            Alternate License for such Licensed Educational Content, the
            Licensed Educational Content{" "}
            <strong>
              may not be used, distributed or otherwise exploited for any
              commercial purpose, commercial advantage or private monetary
              compensation, unless otherwise previously agreed in writing by
              Waters Center.
            </strong>
          </p>

          <p>
            <strong>(a) Impermissible Uses </strong>
            <br />
            Without limiting the generality of the foregoing, the following are
            types of uses that Waters Center expressly defines as falling
            outside of “non-commercial” use:
            <br />
            i. the sale or rental of (1) any part of the Licensed Educational
            Content, (2) any derivative works based at least in part on the
            Licensed Educational Content, or (3) any collective work that
            includes any part of the Licensed Educational Content;
            <br />
            ii. providing training, support, or editorial services that use or
            reference the Licensed Educational Content in exchange for a fee;
            and
            <br />
            iii. the sale of advertisements, sponsorships, or promotions placed
            on the Licensed Educational Content, or any part thereof, or the
            sale of advertisements, sponsorships, or promotions on any Website
            or blog containing any part of the Licensed Educational Material,
            including without limitation any “pop-up advertisements.”
          </p>

          <p>
            <strong>(b) Use Characterization </strong>
            <br />
            Whether a particular use of the Licensed Educational Content is
            “non-commercial” depends on the use, not the user. Thus, a use of
            the Licensed Educational Content that does not require that users
            pay fees and that does not provide an entity with a commercial
            advantage is “non-commercial,” even if this use is by a commercial
            entity. Conversely, any use that involves charging users in
            connection with their access to the Licensed Educational Content is
            not “non-commercial,” even if this use is by a non-profit entity. As
            an example, a for-profit corporation’s use of the Licensed
            Educational Content for internal professional development or
            training of employees is permitted, so long as the corporation
            charges no fees, directly or indirectly, for such use. Conversely,
            as another example, a non-profit entity’s use of the Licensed
            Educational Content in connection with an fee-based training or
            educational program is NOT “non-commercial” and is not permitted.
          </p>

          <h5>7.4 Crediting Waters Center</h5>
          <p>
            If You distribute, publicly perform or display, transmit, publish,
            or otherwise make available any Licensed Educational Content or any
            derivative works thereof, You must also provide the following notice
            prominently, along with such Licensed Educational Content or
            derivative work thereof: “©2019 Waters Center for Systems Thinking
            www.WatersCenterST.org and www.ThinkingToolsStudio.org”.
          </p>
        </section>
        <section className="white" id="section8">
          <h4>8. Prohibited Conduct</h4>
          <p>You agree not to:</p>

          <p>
            <strong>8.1</strong> use the Website for any commercial use or
            purpose unless expressly permitted by Waters Center in writing, it
            being understood that the Website and related services are intended
            for personal, non-commercial use only;
          </p>

          <p>
            <strong>8.2</strong> except as expressly permitted under Sections
            5.3 and 7 of these Terms, rent, lease, loan, sell, resell,
            sublicense, distribute or otherwise transfer the licenses for any
            Website Materials;
          </p>

          <p>
            <strong>8.3</strong> post, upload, or distribute any defamatory,
            libelous, or inaccurate User Content or other content;
          </p>

          <p>
            <strong>8.4</strong> post, upload, or distribute any User Content or
            other content that is unlawful or that a reasonable person could
            deem to be objectionable, offensive, indecent, pornographic,
            harassing, threatening, embarrassing, distressing, vulgar, hateful,
            racially or ethnically offensive, or otherwise inappropriate;
          </p>

          <p>
            <strong>8.5</strong> impersonate any person or entity, falsely claim
            an affiliation with any person or entity, or access the Website
            accounts of others without permission, or perform any other
            fraudulent activity;
          </p>

          <p>
            <strong>8.6</strong> delete the copyright or other proprietary
            rights notices on the Website or on any Licensed Educational
            Content, Licensed Educational Code, or User Content;
          </p>

          <p>
            <strong>8.7</strong> assert, or authorize, assist, or encourage any
            third party to assert, against Waters Center or any of its
            affiliates or licensors any patent infringement or other
            intellectual property infringement claim regarding any Licensed
            Educational Content, Licensed Educational Code, or User Content You
            have used, submitted, or otherwise made available on or through the
            Website;
          </p>

          <p>
            <strong>8.8</strong> make unsolicited offers, advertisements,
            proposals, or send junk mail or spam to other Users of the Website
            (including, but not limited to, unsolicited advertising, promotional
            materials, or other solicitation materials, bulk mailing of
            commercial advertising, chain mail, informational announcements,
            charity requests, and petitions for signatures);
          </p>

          <p>
            <strong>8.9</strong> use the Website for any illegal purpose, or in
            violation of any local, state, national, or international law,
            including, without limitation, laws governing intellectual property
            and other proprietary rights, and data protection and privacy;
          </p>

          <p>
            <strong>8.10</strong> defame, harass, abuse, threaten or defraud
            Users of the Website, or collect, or attempt to collect, personal
            information about Users or third parties without their consent;
          </p>

          <p>
            <strong>8.11</strong> remove, circumvent, disable, damage or
            otherwise interfere with security-related features of the Website,
            Licensed Educational Content, or User Content, features that prevent
            or restrict use or copying of any content accessible through the
            Website, or features that enforce limitations on the use of the
            Website, Licensed Educational Content, or User Content;
          </p>

          <p>
            <strong>8.12</strong> reverse engineer, decompile, disassemble or
            otherwise attempt to discover the source code of the Website or any
            part thereof, except and only to the extent that such activity is
            expressly permitted by applicable law notwithstanding this
            limitation;
          </p>

          <p>
            <strong>8.13</strong> modify, adapt, translate or create derivative
            works based upon the Website or any part thereof, except and only to
            the extent expressly permitted by Waters Center herein or to the
            extent the foregoing restriction is expressly prohibited by
            applicable law; or
          </p>

          <p>
            <strong>8.14</strong> intentionally interfere with or damage
            operation of the Website or any User’s enjoyment of it, by any
            means, including without limitation by participation in any
            denial-of-service type attacks or by uploading or otherwise
            disseminating viruses, adware, spyware, worms, or other malicious
            code.
          </p>
        </section>
        <section className="white" id="section9">
          <h4>9. Third-Party Sites, Products and Services; Links</h4>
          <p>
            The Website may include links or references to other Websites or
            services solely as a convenience to Users (“Reference Sites”).
            Waters Center does not endorse any such Reference Sites or the
            information, materials, products, or services contained on or
            accessible through Reference Sites.{" "}
            <strong>
              Access and use of Reference Sites, including the information,
              materials, products, and services on or available through
              Reference Sites is solely at Your own risk.
            </strong>
          </p>
        </section>
        <section className="white" id="section10">
          <h4>10. Termination</h4>
          <h5>10.1 Termination by Waters Center</h5>
          <p>
            Waters Center, at its sole discretion, for any or no reason, and
            without penalty, may terminate any account (or any part thereof) You
            may have with Waters Center or Your use of the Website and remove
            and discard all or any part of Your account, User profile, and User
            Content, at any time. Waters Center may also at its sole discretion
            and at any time discontinue providing access to the Website, or any
            part thereof, with or without notice. You agree that any termination
            of Your access to the Website or any account You may have, or
            portion thereof, may be affected without prior notice, and You agree
            that Waters Center will not be liable to You or any third party for
            any such termination. Any suspected fraudulent, abusive or illegal
            activity may be referred to appropriate law enforcement authorities.
            These remedies are in addition to any other remedies Waters Center
            may have at law or in equity. As discussed herein, Waters Center
            does not permit copyright, trademarks, or other intellectual
            property infringing activities on the Website, and will terminate
            access to the Website, and remove all User Content or other content
            submitted, by any Users who are found to be repeat infringers.
          </p>
          <h5>10.2 Termination by You</h5>
          <p>
            Your only remedy with respect to any dissatisfaction with (i) the
            Website, (ii) any element of these Terms of Service, (iii)
            Guidelines, (iv) any policy or practice of Waters Center in
            operating the Website, or (v) any content or information transmitted
            through the Website, is to terminate the Terms and Your account. You
            may terminate the Terms at any time by deleting Your login account
            with the Website and discontinuing use of any and all parts of the
            Website.
          </p>
        </section>
        <section className="white" id="section11">
          <h4>11. Indemnification</h4>
          <p>
            You agree to indemnify, defend, and hold harmless Waters Center, its
            affiliated companies, contractors, employees, agents and its
            third-party suppliers, licensors, and partners (“Waters Center
            Indemnitees”) from any claims, losses, damages, liabilities, and
            expenses (including legal fees and expenses), arising out of any use
            or misuse of the Website, any violation of the Terms, or any breach
            of the representations, warranties, and covenants made herein by
            You. Waters Center reserves the right, at Your expense, to assume
            the exclusive defense and control of any matter for which You are
            required to indemnify Waters Center, and You agree to cooperate with
            Waters Center’s defense of these claims. Waters Center will use
            reasonable efforts to notify You of any such claim, action, or
            proceeding upon becoming aware of it.
          </p>
        </section>
        <section className="white" id="section12">
          <h4>12. Disclaimers; No Warranties</h4>
          <h5>12.1 No Warranties</h5>
          <p>
            <strong>
              The Website, and all data, information, software, Website
              materials, content, User Content, Reference Sites, services, or
              applications made available in conjunction with or through the
              Website, are provided on an “as is,” “as available,” and “with all
              faults” basis. To the fullest extent permissible pursuant to
              applicable law, Waters Center, and its affiliates and licensors,
              disclaim any and all warranties and conditions, whether statutory,
              express or implied, including, but not limited to, all implied
              warranties of merchantability, fitness for a particular purpose,
              title, and non-infringement. No advice or information, whether
              oral or written, obtained by You from Waters Center or through the
              Website will create any warranty not expressly stated herein.
            </strong>
          </p>
          <h5>12.2 Content</h5>
          <p>
            <strong>
              Waters Center, and its suppliers, licensors, and affiliates, do
              not warrant that the Website or any data, User Content, functions,
              or any other information offered on or through the Website will be
              uninterrupted, or free of errors, viruses or other harmful
              components, and do not warrant that any of the foregoing will be
              corrected.
            </strong>
          </p>
          <h5>12.3 Harm to Your Computer</h5>
          <p>
            <strong>
              You understand and agree that Your use, access, download, or
              otherwise obtaining of content, Website materials, software, or
              data through the Website (including through any api’s) is at Your
              own discretion and risk, and that You will be solely responsible
              for any damage to Your property (including Your computer system)
              or loss of data that results therefrom.
            </strong>
          </p>
          <h5>12.4 Limitations by Applicable Law</h5>
          <p>
            <strong>
              Some states or other jurisdictions do not allow the exclusion of
              implied warranties, so the above exclusions may not apply to You.
              You may also have other rights that vary from state to state and
              jurisdiction to jurisdiction.
            </strong>
          </p>
        </section>
        <section className="white" id="section13">
          <h4>13. Limitation of Liability and Damages</h4>
          <h5>13.1 Limitation of Liability</h5>
          <p>
            <strong>
              Under no circumstances, including, but not limited to, negligence,
              will Waters Center or its affiliates, contractors, employees,
              agents, or third-party partners, licensors, or suppliers be liable
              for any special, indirect, incidental, consequential, punitive,
              reliance, or exemplary damages (including without limitation
              damages arising from any unsuccessful court action or legal
              dispute, lost business, lost revenues or profits, loss of data, or
              any other pecuniary or non-pecuniary loss or damage of any nature
              whatsoever) arising out of or relating to the terms or Your use of
              (or inability to use) the Website or any Reference Site, or any
              other interactions with Waters Center, even if Waters Center or a
              Waters Center authorized representative has been advised of the
              possibility of such damages. Applicable law may not allow the
              limitation or exclusion of liability or incidental or
              consequential damages, so the above limitation or exclusion may
              not apply to You. In such cases, Waters Center’s liability will be
              limited to the fullest extent permitted by applicable law.
            </strong>
          </p>
          <h5>13.2 Limitation of Damages</h5>
          <p>
            <strong>
              In no event will Waters Center’s or its affiliates’, contractors’,
              employees’, agents’, or third-party partners’, licensors’, or
              suppliers’ total liability to You for all damages, losses, and
              causes of action arising out of or relating to the terms or Your
              use of the Website or Your interaction with other Website users
              (whether in contract, tort (including negligence), warranty, or
              otherwise), exceed the amount paid by You, if any, for accessing
              the Website during the twelve months immediately preceding the
              date of the claim or one hundred dollars, whichever is greater.
            </strong>
          </p>
          <h5>13.3 Basis of the Bargain</h5>
          <p>
            <strong>
              You acknowledge and agree that Waters Center has offered the
              Website and entered into the terms in reliance upon the
              disclaimers and the limitations of liability set forth herein,
              that the disclaimers and the limitations of liability set forth
              herein reflect a reasonable and fair allocation of risk between
              You and Waters Center, and that the disclaimers and the
              limitations of liability set forth herein form an essential basis
              of the bargain between You and Waters Center. Waters Center would
              not be able to provide the Website to You on an economically
              reasonable basis without these limitations.
            </strong>
          </p>
        </section>
        <section className="white" id="section14">
          <h4>14. Miscellaneous</h4>
          <h5>14.1 Notice</h5>
          <p>
            Waters Center may provide You with notices, including those
            regarding changes to the Terms, by email, regular mail, postings on
            the Website, or other reasonable means. Notice will be deemed given
            twenty-four hours after email is sent, unless Waters Center is
            notified that the email address is invalid. Alternatively, we may
            give You legal notice by mail to a postal address, if provided by
            You through the Website. In such case, notice will be deemed given
            three days after the date of mailing. Notice posted on the Website
            is deemed given 30 days following the initial posting.
          </p>
          <h5>14.2 Waiver</h5>
          <p>
            The failure of Waters Center to exercise or enforce any right or
            provision of the Terms will not constitute a waiver of such right or
            provision. Any waiver of any provision of the Terms will be
            effective only if in writing and signed by Waters Center.
          </p>
          <h5>14.3 Jurisdiction</h5>
          <p>
            These terms shall be governed by and construed in accordance with
            the laws of the Commonwealth of Pennsylvania. You agree that any
            action at law or in equity arising out of or relating to these terms
            shall be filed only in the state or federal courts of the
            Commonwealth of Pennsylvania and You hereby consent and submit to
            the personal jurisdiction of such courts for the purposes of
            litigating any such action.
          </p>
          <h5>14.4 Severability</h5>
          <p>
            If any provision of the Terms or Guidelines is held to be unlawful,
            void, or for any reason unenforceable, then that provision will be
            limited or eliminated from the Terms to the minimum extent necessary
            and will not affect the validity and enforceability of any remaining
            provisions.
          </p>
          <h5>14.5 Assignment</h5>
          <p>
            The Terms and related Guidelines, and any rights and licenses
            granted hereunder, may not be transferred or assigned by You without
            Waters Center’s prior written consent, but may be assigned by Waters
            Center without consent or any restriction. Any assignment attempted
            to be made in violation of the Terms shall be null and void.
          </p>
          <h5>14.6 Survival</h5>
          <p>
            Upon termination of the Terms, any provision which, by its nature or
            express terms should survive, will survive such termination or
            expiration.
          </p>
          <h5>14.7 Entire Agreement</h5>
          <p>
            The Terms, the Privacy Policy and Guidelines constitute the entire
            agreement between You and Waters Center relating to the subject
            matter herein and will not be modified except in writing, signed by
            both parties, or by a change to the Terms, Privacy Policy or
            Guidelines made by Waters Center as set forth in Section 4 above.
          </p>
          <h5>14.8 Disclosures</h5>
          <p>
            The Website is hosted in the United States, and the services
            provided hereunder are offered by Waters Center for Systems
            Thinking: 7090 N Oracle Rd, Suite 178-127, Tucson, AZ 85704-4383;
            info@waterscenterst.org.
          </p>
        </section>
        <section className="white" id="section15">
          <h4>Digital Millennium Copyright Act Notification Guidelines</h4>
          <h5>Notification of Infringement</h5>
          <p>
            It is the Waters Center’s policy to respond to clear notices of
            alleged copyright infringement. This page explains the information
            that must be included in these notices, as required by the Digital
            Millennium Copyright Act (“DMCA”). Upon receipt of a DMCA compliant
            notice, Waters Center for Systems Thinking, Inc., a 501(c)(3)
            organization (“Waters Center”), will expeditiously remove or disable
            access to the content that is the subject of the notice.
          </p>
          <p>
            If You are a copyright owner or an authorized agent thereof, and You
            wish to file a notice of infringement with Us, then You may submit a
            notice by providing the Waters Center Designated Copyright Agent
            with the following information in writing (please consult Your legal
            counsel or See 17 U.S.C. Section 512(c)(3) to confirm these
            requirements):
          </p>
          <ol>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works on the Waters Center
              Website are covered by a single notification, a representative
              list of such works at that site.
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit Waters Center to locate the material.
            </li>
            <li>
              Information reasonably sufficient to permit Waters Center to
              contact You, such as an address, telephone number, and, if
              available, an electronic mail address at which You may be
              contacted.
            </li>
            <li>
              A statement that You have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law.
            </li>
            <li>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that You are authorized to act on
              behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
          </ol>
          <p>
            Please note that under Section 512(f) of the DMCA, any person who
            knowingly materially misrepresents that material or activity is
            infringing may be subject to liability. If You are unsure whether
            the material available online infringes Your copyright, we suggest
            that You contact an attorney before sending us a notice.
          </p>

          <h5>Counter-Notification</h5>
          <p>
            The administrator of an affected site or the provider of affected
            content may make a counter notification under sections 512(g)(2) and
            (3) of the DMCA. If You elect to send Us a counter notice, to be
            effective it must be a written communication that includes the
            following (please consult Your legal counsel or See 17 U.S.C.
            Section 512(g)(3) to confirm these requirements):
          </p>
          <ol>
            <li>A physical or electronic signature of the subscriber.</li>
            <li>
              Identification of the material that has been removed or to which
              access has been disabled and the location at which the material
              appeared before it was removed or access to it was disabled.
            </li>
            <li>
              A statement under penalty of perjury that the subscriber has a
              good faith belief that the material was removed or disabled as a
              result of mistake or misidentification of the material to be
              removed or disabled.
            </li>
            <li>
              The subscriber’s name, address, and telephone number, and a
              statement that the subscriber consents to the jurisdiction of
              Federal District Court for the judicial district in which the
              address is located, or if the subscriber’s address is outside of
              the United States, for any judicial district in which Waters
              Center may be found, and that the subscriber will accept service
              of process from the person who provided notification under
              subsection (c)(1)(C) or an agent of such person.
            </li>
          </ol>
          <p>
            Please note that under Section 512(f) of the Copyright Act, any
            person who knowingly materially misrepresents that material or
            activity was removed or disabled by mistake or misidentification may
            be subject to liability.
          </p>
          <h5>Designated Copyright Agent</h5>
          <p>
            Waters Center’s Designated Copyright Agent to receive notifications
            and counter-notifications of claimed infringement can be reached as
            follows:
          </p>
          <p>
            <strong>Address:</strong> Waters Center for Systems Thinking, 7090 N
            Oracle Rd, Suite 178-127, Tucson, AZ 85704-4383
          </p>
          <p>
            <strong>Email:</strong> info@waterscenterst.org
          </p>
          <p>
            You acknowledge that if You fail to comply with all of the
            requirements of this section, Your DMCA notice may not be valid.
          </p>
        </section>
      </div>
    </div>
    <Footer />
  </div>
);
