import React, { useState } from "react";
import PropTypes from "prop-types";

export const ImageSlider = (props) => {
  const [slide, setSlide] = useState(props.slides[0]);

  const handleNextSlide = (slideId) => {
    const nextSlide = props.slides.find((slide) => slide.id === slideId + 1);
    return nextSlide || props.slides[0];
  };

  ImageSlider.propTypes = {
    slides: PropTypes.array,
  };

  return (
    <section id="features-slider">
      <h1>Features</h1>

      <div className="slider">
        <div className="stacked">
          <div className="img-container">
            <img src={slide.image} />
          </div>

          <p className="centered">{slide.text}</p>

          <a href={slide.link}>Learn More</a>
        </div>

        <button
          type="button"
          className="toggle-button"
          onClick={() => setSlide(handleNextSlide(slide.id))}
        >
          Next Slide
        </button>
      </div>
    </section>
  );
};
