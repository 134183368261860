import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <p>
      There are two types of feedback that can tell the story of any causal
      relationship in our world. The first we will learn about and practice is
      called reinforcing. One of the best ways to learn about reinforcing
      feedback is through example.
    </p>
    <h3>Reinforcing Playground Problems</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      Three first grade boys were having ongoing problems getting along on the
      playground at school. As they were talking about what typically happens
      when their problems escalate, they realized that each of them was
      contributing to the growing level of unkind behaviors and hurt feelings.
    </p>
    <p>
      Because these children had experience with systems thinking Habits and
      tools, they were able to draw a causal loop on a piece of paper and name
      it reinforcing. The “R” in the middle of the loop labels the loop as
      reinforcing. The loop drawing helped them look at the problem objectively
      with minimal emotion and gave them an opportunity to discuss and
      brainstorm solutions.
    </p>
    <img
      id="mean-words"
      src="/images/courses/habits/09-circular-nature/mean-words.svg"
      alt="Causal Loop Example"
    />
    <p>
      They realized that actions to try to break the loop were only temporary
      solutions like saying “sorry” or playing together as one team. They
      discovered that the only effective resolution was to use nice words to
      generate nice feelings, which shifted the reinforcing loop to one that was
      desirable based on kind interactions.
    </p>
    <p>
      <a href="https://youtu.be/LEVypGV-3xA" target="blank" rel="noopener">
        Click here
      </a>{" "}
      to watch a brief video of the students mentioned in this example talking
      through their causal loop.
    </p>
    <p id="reflection-question">
      To help you identify reinforcing feedback in your life, consider the
      typical language that one might hear when reinforcing dynamics are at
      play.
    </p>
    <div className="quote">
      <p className="quote-text">“Things are getting out of control!”</p>
    </div>
    <div className="quote">
      <p className="quote-text">“I can’t keep up!”</p>
    </div>
    <div className="quote">
      <p className="quote-text">“We are really on a roll now!”</p>
    </div>
    <div className="quote">
      <p className="quote-text">“The change seems to be doubling.”</p>
    </div>
    <div className="quote">
      <p className="quote-text">“It’s spreading like wild fire!”</p>
    </div>
    <p>
      Consider a rapidly spreading rumor, computer virus or enticing piece of
      news. In all of these scenarios, reinforcing feedback illustrates the
      change as compounding and increasing or decreasing at a rapid or even
      exponential rate. What other scenarios can you think of that would suggest
      reinforcing feedback?
    </p>
  </div>
);
