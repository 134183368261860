import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Introduction</h3>
    <img
      className="lesson-img"
      src="/images/resources/legacy-graphing/section-one-img.png"
      alt="Nelson Mandella"
    />
    <p>
      What will be your legacy? Think about the legacy left by some of the
      world’s most famous people. Mother Teresa left a legacy of compassion.
      Nelson Mandela brought about racial reconciliation. Honest Abe, Abraham
      Lincoln, is remembered as a man of great integrity. It is not only the
      famous and influential who leave a legacy. Whatever your roles in life —a
      business person, an educator, a parent, a good golfer or a bartender — you
      too will leave a legacy. What will that legacy be? How has it developed
      over time?
    </p>
    <h3>Using BOTGs for Legacy Graphing: A Step by Step Guide</h3>
    <p>
      This activity is suitable for individual reflection. It can be used to
      help introduce Behavior-over-time graphs (BOTGs) to a group or team. It
      can be a group activity that encourages individuals to consider their
      personal strengths and talents, and then share with their team how these
      talents have developed over time. If you and/or your team haven’t already,
      be sure to take the{" "}
      <a href="/courses/01-behavior-over-time-graphs">BOTG course</a> in the
      Tools section.
    </p>
    <ol>
      <li>
        Choose a quality or skill that addresses the legacy story to be shared.
        This choice will be dependent on the purpose of the activity. Perhaps a
        team is working to develop their communication skills. In this case,
        having each team member reflect on his or her individual development in
        the area of communication could be very beneficial. Perhaps a team is
        looking at the impact of its work. For this scenario, looking at
        individual contributions, each one being unique, may be more beneficial.
      </li>
      <li>
        Next, introduce the concept of building a BOTG. Remember, time always
        goes on the x-axis. If doing this activity with a group, you might want
        to set a common time frame, for example, the last five years, since you
        started at this company, or any other suitable time parameter.
      </li>
      <li>
        The y-axis shows what is changing. To make graphs easy to share and
        compare, consider agreeing on a common scale for the y-axis. The y-axis
        represents the level of whatever variable is being graphed — e.g. “my
        level of integrity,” “my ability to play golf,” etc. Suitable
        descriptors might be “none,” “some,” “a lot,” or a scale of 0 to 10.
      </li>
      <li>
        Once you have decided on the x- and y-axes for your graph, allow
        participants some time to draw their graphs independently.
      </li>
      <li>
        Then, have participants use their graph to tell the story of their line.
        For more information on “telling the story of a line” see the{" "}
        <a href="/courses/01-behavior-over-time-graphs">BOTG course</a>.
      </li>
    </ol>
    <h4>Significant Events</h4>
    <p>
      When describing a legacy, there are oftentimes specific events that have
      caused that quality to increase or decrease. Selecting five to ten life
      events or memorable moments that relate specifically to the quality or
      skill being graphed and placing them in chronological order is an
      effective way to create an x-axis. Regardless of whether these events are
      noted on the graph or not, they should be an important part of telling the
      story of the line.
    </p>
    <h4>Predicting the Future</h4>
    <img
      className="lesson-img"
      src="/images/resources/legacy-graphing/section-one-img-two.png"
      alt="Graph"
    />
    <p>
      Integral to the idea of creating a legacy is that we can continue to grow
      it from this moment forward. When drawing a BOTG, a dotted vertical line
      can be used to represent the present, allowing the line of the graph to
      continue in the anticipated or desired direction of the dynamic shown on
      the graph.
    </p>
    <h4>Classroom Applications</h4>
    <p>
      Legacy graphing can be used as a classroom activity. Following a biography
      unit or after studying a famous person, ask students to select the quality
      that most significantly reflects that person’s legacy. They then choose
      significant events to make up the x-axis. Finally, they can draw a line to
      show how that quality changed over the life of the famous person and tell
      the story of the line. A lot of assessment information can be gathered
      both from what events students choose and what they see as the legacy
      quality for that person.
    </p>
    <p>
      This example is from a second-grade classroom at the end of a unit on
      famous people. The student chose to graph John F. Kennedy’s courage as the
      legacy quality. The bubbles are actual student quotes as they shared the
      story of their line with their peers.
    </p>
    <img
      className="lesson-img left"
      src="/images/resources/legacy-graphing/section-one-img-three.png"
      alt="Student Example"
    />
    <p>
      Students can draw their own legacy graphs and use it as a prewrite for a
      piece of autobiographical text.
    </p>
    <p>
      After reading the poem, “Life Doesn’t Frighten Me at All,” by Maya
      Angelou, this student told her story of overcoming her fear of the dark.
    </p>
    <img
      className="lesson-img left"
      src="/images/resources/legacy-graphing/section-one-img-four.png"
      alt="Student Example"
    />
    <p>
      Legacy graphing has numerous applications and benefits. It is truly a way
      to reflect on the accumulation of a trait or skill that you highly value.
    </p>
  </div>
);
