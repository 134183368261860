import { SubmissionError } from "redux-form";
import { apiCallAsync } from "components/shared/api";

export const ActionTypes = {
  ResetSuccess: "PASSWORD.RESET.SUCCESS",
  ForgotSuccess: "PASSWORD.FORGOT.SUCCESS",
};

export const forgotAction = async (data) => {
  try {
    const r = await apiCallAsync("/auth/forgot-password", data, "POST");
    return { type: ActionTypes.ForgotSuccess, payload: r };
  } catch (ex) {
    let err = "Unknown error";
    try {
      err = JSON.parse(ex.message).message;
    } catch (ex2) {}
    throw new SubmissionError({
      _error: err,
    });
  }
};

export const resetAction = async (data) => {
  try {
    const r = await apiCallAsync("/auth/update-password", data, "POST");
    return { type: ActionTypes.ResetSuccess, payload: r };
  } catch (ex) {
    let err = "Unknown error";
    try {
      err = JSON.parse(ex.message).message;
    } catch (ex2) {}
    throw new SubmissionError({
      _error: err,
    });
  }
};
