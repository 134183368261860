import * as React from "react";
import { Field } from "redux-form";

const Systems = ["well-being", "workplace", "school", "community", "family"];

export const Exercise04 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>Choose a system of interest.</p>
    <h4>My practice system is:</h4>
    <div className="system-select">
      {Systems.map((s) => (
        <div key={s} className={`system-select-item ${s}`}>
          <Field
            name="systemSelect"
            id={`ss-${s}`}
            component="input"
            type="radio"
            value={s}
          />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      ))}
    </div>
    <p>
      Respond to the following questions to help you practice and apply this
      Habit.
    </p>
    <h4>Why would seeing connections be important in my system?</h4>
    <Field
      name="important-connections"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How do connections affect understanding of the whole system?</h4>
    <Field
      name="affect"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How does understanding my system transfer to the understanding of another
      system?
    </h4>
    <Field
      name="another-system"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How can different perspectives of my system bring value to my
      understanding?
    </h4>
    <Field
      name="different-perspectives"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How does my new learning connect to what I already know about my system?
    </h4>
    <Field
      name="learning-connect"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What connections am I actively cultivating to increase my knowledge, my
      relationships and my overall well-being?
    </h4>
    <Field
      name="cultivating-knowledge"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
