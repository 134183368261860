import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Identify either concrete or abstract accumulations for each of the sample
      systems below.
    </p>
    <p>
      Think about the following question to help you pay attention to
      accumulations in your systems: What are instances where something is
      changing (increasing or decreasing) and the speed at which it is changing
      is important to your understanding of the system?
    </p>
    <h4>Examples to get you started include:</h4>
    <div className="reflection">
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-family.svg"
          alt="Family Example"
        />
        <div className="example-info">
          <p>
            Your infant daughter is very ill so you monitor closely the rate at
            which you are able to reduce her temperature.
          </p>
        </div>
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-community.svg"
          alt="Community Example"
        />
        <div className="example-info">
          <p>
            You become aware of a series of thefts in your neighborhood and are
            increasingly concerned about the crime rate.
          </p>
        </div>
      </div>
      <h4>
        Identify a relevant accumulation and its rate of change for each of the
        sample systems below:
      </h4>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-well-being.svg"
          alt="Well Being Example"
        />
        <Field
          name="well-example"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-workplace.svg"
          alt="Workplace Example"
        />
        <Field
          name="workplace-example"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-community.svg"
          alt="Community Example"
        />
        <Field
          name="community-example"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-family.svg"
          alt="Family Example"
        />
        <Field
          name="family-example"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-school.svg"
          alt="School Example"
        />
        <Field
          name="school-example"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
    </div>
  </div>
);
