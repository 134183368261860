import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-double"
        src="/images/resources/drifting-goals/drifting-goals-summary-so.svg"
        alt="Iceberg with Bubbles"
      />

      <img
        className="template-double"
        src="/images/resources/drifting-goals/drifting-goals-template-so.svg"
        alt="Iceberg with Bubbles"
      />
    </div>
  </div>
);
