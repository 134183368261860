import * as React from "react";
import { Field } from "redux-form";
import { FileUpload } from "components/shared/file-upload";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      This practice is best done with pen/pencil and paper and with a partner or
      small group. Select, download and print one of the templates — one
      includes{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/botg-template.pdf"
        target="blank"
        rel="noopener"
      >
        7 BOTG variables
      </a>{" "}
      and the other includes{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/botg-template-10.pdf"
        target="blank"
        rel="noopener"
      >
        10 BOTG variables
      </a>
      . Please note that the numbers next to each BOTG variable do not represent
      a value or priority. They are meant to help people identify variables that
      are discussed when making causal connections.
    </p>
    <ol>
      <li>
        Identify a system of interest that you and your small group share. This
        could be a system presenting a problem or challenge, a system you want
        to improve, or a system whose future desired state you want to envision
        and create.
      </li>
      <Field
        name="system"
        component="textarea"
        className="reflection-input"
        placeholder="Describe the system you would like to map:"
      />
      <li>
        Go through the suggested mapping process by
        <p>
          <b>a.</b> making a list of 5-10 key variables
        </p>
        <p>
          <b>b.</b> drawing BOTGs for each of the variables
        </p>
        <p>
          <b>c.</b> arranging the BOTG variables in a circle
        </p>
        <p>
          <b>d.</b> drawing causal links using arrows and S/+ and O/- labels. Do
          not worry if the map becomes messy because of the large number of
          causal arrows. This is expected.
        </p>
      </li>
      <Field
        name="drawingSamples"
        component={FileUpload}
        image
        instructions="Take a picture and upload your map below."
      />
    </ol>
  </div>
);
