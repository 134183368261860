import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>
      What strategies help me see beyond my day-to-day interactions and
      experiences?
    </h4>
    <Field
      name="strategies"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How can I position myself to notice things I typically do not see?</h4>
    <Field
      name="position"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How can I increase the boundaries of my system to see a larger whole? The
      boundaries could be about a system’s size (e.g. my assigned department
      versus my school, company or region), or they could be about time (e.g. my
      view of this year versus my view of the next five years).
    </h4>
    <Field
      name="my-view"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How is my system of interest nested in a larger context? Can I see the
      effects of the larger system on smaller subsystems? (e.g. Is my child’s
      grumpy behavior at home a condition of what has been going on with other
      children in the neighborhood or something else?)
    </h4>
    <Field
      name="neighborhood-children"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
