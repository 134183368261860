import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img med-img"
      src="/images/courses/habits/10-time-delays/course-img.png"
      alt="Time Delays"
    />
    <p>
      The relationship between cause and effect is often separated by time and
      even space. Effects are seldom immediate; recognizing the potential impact
      of time delays and anticipating the effects of certain actions is
      important to systems thinkers. For example, the onset of tooth decay can
      be a long process. Routinely drinking sugary soda without proper brushing
      could contribute to tooth decay over time. The toothache is not immediate,
      so it may be difficult to see the connection between soda drinking and
      tooth decay. There is a time delay between drinking a soda and sitting in
      the dentist’s chair with a toothache, making the connection between the
      two experiences not immediately obvious. The cause and effect are circular
      (as described in the previous course,{" "}
      <a href="/courses/09-circular-nature">
        Identifies the Circular Nature of Complex Cause and Effect Relationships
      </a>
      ). The consumption of more sugary soda leads to more time in the dentist’s
      chair, but the time delay between drinking the soda and going to the
      dentist may limit the impact of going to the dentist relative to the
      decision to indulge in more sugary drinks.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-well-being.svg"
      alt="Well-being Example"
    />
    <p>
      The use of tobacco products presents a similar scenario. Even though the
      detrimental effects of smoking are well known, nearly nine out of every
      ten smokers try their first cigarette before age 18. If these same young
      people developed symptoms of lung disease the moment they smoked their
      first cigarette, they would be far less likely to continue the use of
      tobacco. A time delay increases the difficulty of seeing a causal
      connection between a behavior and its consequence. When there is a causal
      connection that is not immediate, it can be difficult to connect the cause
      and its effect, even when you intentionally are looking for the
      connection.
    </p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      The impact that early parental influence has on young people as they age
      also demonstrates time delays. Mature adults might notice certain patterns
      of behavior or phrases they use that can suddenly remind them of behaviors
      that once came from their own parents. Recalling those moments lead to
      realizations like, “Oh my goodness, I’m becoming my mother! (or I’m
      becoming my father!).” Parents should be mindful of the impressions they
      make on their children as the effects can likely manifest themselves far
      into adulthood.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      Like parents, teacher influence can also surface far into adulthood with
      gaping time delays that make it difficult to connect impressionable
      experiences to effects felt years later. For example, a parent is noticing
      extreme anxiety during the first back to school night for their new middle
      schooler. The school staff are welcoming and impressive in the program and
      curricula they describe, but there is an underlying level of discomfort
      that is hard to place. Then the parent recalled their own experiences and
      deep-seated memories of negative experiences from years ago. Memories of
      being bullied, getting into trouble, and feelings of being unfairly
      treated were causing an uneasy effect. Overcoming the impact of the
      delayed effects from unhappy childhood experiences can be challenging.
      Both positive and negative experiences from school days can stick with an
      individual and surface again many years later when one’s own children are
      experiencing similar circumstances. Early impressions can be long-lasting
      and time delays can contribute to our lack of awareness regarding the ways
      those influential moments manifest.
    </p>
  </div>
);
