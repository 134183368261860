import * as React from "react";
import { Field } from "redux-form";

export const Lesson03 = () => (
  <div className="content">
    <h3>Increasing Happiness</h3>
    <img
      id="connection-example"
      src="/images/courses/habits/02-connections/example-connection.svg"
      alt="Connection Circle"
    />
    <p>
      In addition to the connections found in nature and in learning, some of
      the most profound connections we make are those connections that affect
      our relationships.
    </p>
    <p>
      "This Emotional Life," a three-part series on PBS, asserts, “Connecting
      with others is the single most important thing we can do for our own
      happiness.” It goes on to say that when we are with other people, we are
      happier. When we are happier, we have better relationships, increasing the
      time we choose to spend with other people. Researchers have found that
      people are happier when they are with another person than when they are
      alone. The positive boost that comes from connecting is the same for both
      introverts and extroverts. There is evidence to suggest that human
      connections are essential for positive well-being. The capacity to make
      connections is deeply ingrained in humans. Systems thinkers apply that
      natural inclination to understand human systems.
    </p>
    <p>
      So regardless of the system being addressed, the ability to make
      connections and transfer information enhances our understanding of
      systems, our ability to learn and our capacity to work within a given
      system. Recognizing and acting on our innate need for human connections
      can lead to greater happiness and personal fulfillment.
    </p>
    <h4>
      Pause to note the human connections that are important in your life.
    </h4>
    <Field
      name="human-connections"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      The relationship causal loop is an example of a complex cause and effect
      relationship. Time spent with someone increases positive emotions,
      boosting happiness, thus improving relationships, which in turn makes you
      want to invest more time in the relationship. Likewise, seeds that grow
      into plants, which produce fruit to make more seeds, is another example of
      this Habit. Identifying such cause and effect relationships is one way to
      make connections.
    </p>
  </div>
);
