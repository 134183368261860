import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <p>
      When engaged in any kind of improvement or change process, paying
      attention to the gap between where you currently are and where you would
      like to be is critical. Efforts to shrink the gap can produce rapid,
      promising results and can also seem hopeless when progress is stymied.
    </p>
    <p>Let’s look at an everyday example.</p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-well-being.svg"
      alt="Well-being Example"
    />
    <p>
      The importance of getting and staying fit can have long-lasting benefits
      to health and wellness. Oftentimes, it is hard to get started, and for
      many, sustaining their efforts can be equally challenging. For those with
      ambitious goals, the gap between current fitness levels and the fitness
      goal (e.g. reduced body fat percentage, improved endurance, lower blood
      pressure) can be overwhelming.
    </p>
    <p>
      When any of these indicators plateau short of the established goal, people
      can easily become discouraged. However, by using the successive
      approximation process, individuals will not view these plateaus as
      failures, but rather opportunities to assess and adjust their fitness
      plan, which will lead to a more attainable path to personal improvement
      and goal achievement.
    </p>
    <p>
      Young people are often encouraged to set goals for themselves and work
      hard toward achievement of their desired outcomes.
    </p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-well-being.svg"
      alt="Well-being Example"
    />
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      In many schools that incorporate systems thinking approaches to teaching
      and learning, students are provided systems thinking strategies for goal
      setting and self-assessment.
    </p>
    <p>
      The strategies help students focus on small chunks of measurable
      achievement with flexible time periods and available support. Each student
      develops individual goals that mark gradual but continuous improvement.
      The goals can be academic or can focus on student social and emotional
      well-being. In this sense, the students are partners in the documentation
      of their own progress measured by successive approximation strategies such
      as{" "}
      <a href="/courses/01-behavior-over-time-graphs">
        behavior-over-time graphs
      </a>
      , as you’ll see in the following “Dragon Problem” example.
    </p>
    <img
      className="lesson-img"
      src="/images/courses/habits/15-successive-approximation/lesson-two-img.jpg"
      alt="Students"
    />
    <p>
      In addition, students are also taught to analyze and identify when
      progress is lacking or when dips in progress occur. They learn to reflect
      on their efforts and implement improvements to their actions to better
      reach their goal. In other words, they use successive approximation to
      deliver benefits. In this process, students become less dependent on
      teacher and parent monitoring. It is never too early for children to
      develop and adopt the Habit of checking results and changing actions when
      needed.
    </p>
    <div className="quote">
      <p className="quote-text">
        “Failure is instructive. The person who really thinks learns quite as
        much from failure as from his successes.”
      </p>
      <p className="quote-author"> — John Dewey, educational psychologist</p>
    </div>
    <h3>Elementary School Dragon Problems</h3>
    <p>
      Elementary school teacher Kathy Lohse wanted to build her students’
      abilities to take on challenges and persist through the difficulties
      encountered when learning new skills. She used the term “Dragon Problem”
      to help children identify those things that they wanted to learn, but that
      they perceived to be too difficult or daunting.
    </p>
    <div className="quote">
      <p className="quote-text">
        “The term ‘Dragon Problem’ came from a book I was using with children
        that helped students solve problems through the acting out of stories.
        One of the stories concerned a dragon that had to overcome a challenge.
        The use of the behavior-over-time graphs (BOTGs) for overcoming
        challenges came up originally with the students analyzing how to tie
        shoes and what were the easy versus hard parts. That was the original
        ‘Dragon Problem.’ We did the BOTG as a group, and then a student worked
        the next day on his own BOTG because he didn’t agree with the class.”
      </p>
      <p className="quote-author"> — Kathy Lohse</p>
    </div>
    <img
      className="lesson-img"
      src="/images/courses/habits/15-successive-approximation/lesson-two-img2.png"
      alt="BOTG"
    />
    <p>
      In this example, more students became interested in identifying other
      “Dragon Problems” to tackle. Examples of some of their problems included
      rope jumping, rock wall climbing and soccer skills. Students, like Joaquin
      in the example BOTG to the right, used behavior-over-time graphs to
      analyze the steps necessary to successfully master their “Dragon Problems”
      and track progress. Joaquin chose rope jumping, and his graph shows how he
      analyzed each step and rated the perceived level of difficulty. He
      discovered that through practice, each step became easier.
    </p>
    <p>
      The more he practiced the sequence he created, the easier it was for him
      to jump rope. BOTGs and successive approximation helped students slay
      their identified “Dragon Problem,” so that over time, the initial
      challenge became an accomplished skill. The successive approximation
      process is also used in day-to-day experiences — oftentimes, we use
      successive approximation without even realizing it, as it comes naturally
      to many people.
    </p>
    <p>
      The following examples illustrate how common successive approximation is
      to all of us.
    </p>
    <h3>New Recipe</h3>
    <p>
      Many of us have favorite food products that become staples in weekly meal
      planning. Have you ever purchased ingredients to make something new that
      you have never tried before? Sometimes it’s a hit, and other times it’s
      disappointing, but the quest to try new things can oftentimes result in a
      new, delicious culinary discovery.
    </p>
    <h3>A New Sport or Hobby</h3>
    <p>
      No matter your age, the excitement of taking on a new sport or hobby
      requires a successive approximation approach. For example, a person
      learning to play golf will typically take lessons and practice on the
      driving range and putting green before playing nine holes for the first
      time. The scores for each round and subsequent earned handicap will help
      the new golfer track progress over time. Practice, practice and more
      practice, along with coaching and feedback, will help the developing
      golfer improve over time. As skill develops, so does appreciation for this
      new venture. The golfer will make a lot of mistakes. The benefit is
      learning how to avoid these mistakes in the future.
    </p>
    <h3>First Job or New Employment</h3>
    <img
      className="lesson-img"
      src="/images/courses/habits/15-successive-approximation/lesson-two-img3.jpg"
      alt="Customer Support"
    />
    <p>
      Many young adults pursue a first job to earn extra cash to help pay for
      things like cell phones, college tuition and independent living expenses.
      Learning the supervisor’s expectations for the position, rules and
      procedures of the workplace and required skills are important steps of
      on-the-job learning. While new employees may not immediately or
      intuitively know how to complete the job tasks, it is important that they
      do their best as they learn. Experience grows and competence develops as
      new employees complete the probationary training period. Job experience
      helps young people learn what they like and dislike about certain
      occupations and those preferences inform future decisions about
      employment. Ultimately, the more work experience people have, the more
      knowledgeable and better equipped they will be to deliver benefits to a
      company and customers.
    </p>
  </div>
);
