import * as React from "react";
import PropTypes from "prop-types";

export function Downloads({ resource }) {
  return (
    <div>
      {!resource.downloads ? null : (
        <div className="download-bar">
          <span>Downloads:</span>
          {resource.downloads.map((o) => (
            <a
              key={o.id}
              className="blue-button"
              target="_blank"
              rel="noopener noreferrer"
              href={`${o.url}`}
            >
              {o.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
Downloads.propTypes = {
  resource: PropTypes.object,
};
