import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <img
      className="med-img lesson-img"
      src="/images/resources/host-matrix/host-matrix.png"
      alt="Habits of a Systems Thinker Reflection Matrix"
    />

    <h3>Introduction:</h3>

    <p>
      Once a group is familiar with systems thinking Habits and tools, it’s time
      to directly apply them to personal and collective goals, tasks, and more
      to achieve desired outcomes and deliver benefits.
    </p>

    <h3>Outcomes:</h3>

    <ul>
      <li>
        Individuals will identify specific opportunities when the Habits of a
        Systems Thinker (HoST) should be practiced to optimize outcomes.
      </li>

      <li>
        The HoST Reflection Matrix will help individuals see the value of
        intentional practice of the Habits of a Systems Thinker more clearly,
        and create a plan for implementation of the Habits.
      </li>
    </ul>

    <h3>Preparation:</h3>
    <ul>
      <li>
        Print the matrix, available to{" "}
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-reflection-sheet.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          download here
        </a>{" "}
        on large paper (11” X 17” or poster size 2’ X 3’).
        <ul>
          <li>
            Or, on a large white board or piece of butcher paper, draw a chart
            that includes the 14 Habits of a Systems Thinker on the left-hand
            column (one for each row).
          </li>

          <li>
            Another alternative is to use a set of{" "}
            <a
              href="https://waters-center-for-systems-thinking.myshopify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Habits cards
            </a>{" "}
            and tape them as a list on the left-hand column of a white board or
            on chart paper.
          </li>
        </ul>
      </li>

      <li>
        Use the top row of the matrix to identify a series of tasks, possible
        decisions, new initiatives, names of team members or possible solutions
        to a problem. You will use a sticky dot voting system, initials, or
        symbol to begin to see which Habits people on the team connect to the
        area of focus.
      </li>
    </ul>

    <h3>General Directions:</h3>

    <ol>
      <li>
        Identify the focus of the Habits of a Systems Thinker Team Reflection
        Matrix. The focus should be written as a label of a column. A list of
        examples are provided in the preparation section above.
      </li>
      <li>
        Using sticky dots or written initials, ask individuals to indicate
        important Habits to practice when considering a focus area. <br />
        Options:
        <ul>
          <li>
            Assign a specific number of dots/squares to initial. You may have
            heard these referred to as “voting dots.” For example, blue dots are
            worth five votes, making them the most important. Yellow dots are
            worth three votes; and red dots are worth one vote.
          </li>

          <li>
            Give people an unlimited number of dots so they can select Habits in
            any category within the matrix.
          </li>

          <li>
            Ask people to color code, with one color representing their own
            opinion, and another color representing the perceived opinion of the
            entire team. That way, you can distinguish between people’s
            perspectives about themselves and the perspectives they hold about
            their team.
          </li>

          <li>
            If sticky dots are not available, ask people to write their initials
            in their “voting squares.”
          </li>
        </ul>
      </li>

      <li>
        Debrief the visual array of dots to see which Habits have the greatest
        number of dots and which Habits have the fewest number of dots. Ask
        people to explain their placements and invite them to add to or delete
        from the array. This could be an opportunity to refer to the ladder of
        inference.
      </li>

      <li>
        Plan to revisit the matrix and use it as a group reflection tool. For
        example, “Our matrix shows that changing perspectives to increase
        understanding is an important Habit for several of the administrative
        assistant’s duties and responsibilities because it had the greatest
        number of dots (votes). How will we investigate a candidate’s strength
        in this area? What other Habits seem to be important before we begin our
        search?”
      </li>

      <li>Create new columns as appropriate and repeat the dot activity.</li>

      <li>
        If you would like to see how a focus area changes over time, use
        multiple copies of the same matrix and repeat dot voting to check for
        changes and shifts in Habit importance.
      </li>
    </ol>

    <h3>Additional Ways to Use the Matrix</h3>

    <p>
      The general directions provide just one example of how to use the HoST
      matrix. Below you will find other examples—perhaps you can think of new
      ways to use the matrix with your team!
    </p>

    <h4>Connection to Tools</h4>

    <img
      className="small-img"
      src="/images/resources/host-matrix/tools-matrix.png"
      alt="Making Connections"
    />

    <p>
      For each systems thinking visual tool (behavior-over-time graphs, causal
      connection circles, ladder of inference, causal loops, causal loop
      archetypes, stock-flow maps, iceberg) ask,
      <em>“Which Habits are we practicing when using this tool?”</em> Provide
      sticky dots or markers so that each participant can offer their choices.
      After voting, use the chart to discuss people’s votes and rationale for
      their selections. See the photo shown for an example of this type of
      application.
    </p>

    <h4>Applying the Habits to Task Analysis</h4>

    <p>
      When considering a project, learning goal or initiative that requires a
      number of tasks, list those tasks along the top row of the matrix and ask,{" "}
      <em>
        “Which Habits will help you/us successfully accomplish each task?”
      </em>
    </p>

    <p>
      Then, place a check mark for those that will help with task completion.
      This process will help people become more intentional about practicing and
      applying the Habits of a Systems Thinker to their learning and work. See
      the photo shown for an example of this type of application.
    </p>

    <img
      className="med-img"
      src="/images/resources/host-matrix/matrix-example.png"
      alt="Filling in the HOST Matrix"
    />
  </div>
);
