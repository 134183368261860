import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { connect } from "react-redux";
import { answersPageStart } from "components/shared/answers/answers.actions";
import { ResourcesHome } from "./resourcesHome";
import { ResourceCatalog } from "components/resources/resource-catalogs";

import { ResourceLayout } from "./ResourceLayout";
import { resourceList } from "./resourceList";
import "./styles.scss";
import PropTypes from "prop-types";

class ResourcesRouterView extends React.Component {
  componentWillMount() {
    this.props.onLoad();
  }

  componentWillUpdate() {
    this.props.onLoad();
  }

  render() {
    const { match } = this.props;

    return (
      <main>
        <Switch>
          <Route path="/resources/overview" component={ResourcesHome} />
          <Route path="/resources/catalog" component={ResourceCatalog} />
          {resourceList.map((r) => (
            // create route for every resource path (e.g. /resources/{resource-path})
            // url parts after the resource path will be handled by the Switch in the ResourceLayout
            <Route
              path={`${match.url}/${r.path}`}
              key={r.path}
              render={(props) => <ResourceLayout {...props} resource={r} />}
            />
          ))}
          {/* if no resource is found, redirect to first one, for now. TODO: handle better? */}
          <Redirect to={`${match.url}/${resourceList[0].path}`} />
        </Switch>
      </main>
    );
  }
}
ResourcesRouterView.propTypes = {
  resource: PropTypes.object,
  match: PropTypes.any,
  onLoad: PropTypes.func,
};

export const ResourcesRouter = connect(
  ({ answers }, ownProps) => ({
    ...ownProps,
    isLoading: answers.isLoading,
  }),
  (dispatch) => ({
    onLoad: () => dispatch(answersPageStart()),
  }),
)(ResourcesRouterView);
