import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Materials</h3>

    <ul>
      <li>
        1 large tub filled with beans (approximately five pounds for every 20
        people)
      </li>
      <li>
        Various utensils (a different utensil for each family group).{" "}
        <em>
          Possibilities include: teaspoon, tweezers, small coffee scoop, tongue
          depressor
        </em>
      </li>
      <li>
        Set of 4 collection cups, per family group — collection cups should be
        clear, plastic, 16 ounces or larger, and labeled for each generation:
        great-grandparent, grandparent, parent, child.{" "}
        <em>
          If you have five people in a family group, add an extra cup and label
          it great-great-grandparent.
        </em>
      </li>
    </ul>

    <img
      className="lesson-img med-img"
      src="/images/resources/bean-game/jar-of-beans.jpg"
      alt="jar of beans"
    />

    <h3>Introduction</h3>

    <p>Now that you have your supplies, it’s time to play!</p>

    <p>
      To begin, you will need to arrange the room to represent the different
      generations within the family that you are simulating.
    </p>

    <h3>Room Arrangement</h3>

    <ul>
      <li>
        4 people per table/group with each person representing a different
        generation (Great-grandparent, grandparent, parent, child)
      </li>

      <li>
        Large table at the front of the room to hold the tub filled with beans
      </li>
    </ul>

    <h3>Essential questions:</h3>

    <p>
      The below are the essential questions that will arise during this
      simulation. You will likely not want to share these until after the
      activity is completed, as this will help to generate group discussion.
    </p>

    <ul>
      <li>
        What effects do the values and actions of one generation have on
        subsequent generations?
      </li>

      <li>
        How do mental models of “needs” based on age and familial role differ,
        and how do they impact the interactions between human populations and
        their environment?
      </li>
    </ul>

    <h3>Instructions:</h3>

    <ol>
      <li>
        The tub of beans represents all the world’s resources one needs to
        survive. Ask participants to share examples of natural resources—it is
        optional to ask participants to differentiate between renewable and
        nonrenewable.
      </li>

      <li>
        Explain how each family is part of a community that relies on the
        natural resource in the tub. The beans represent all kinds of natural
        resources. Each person in each family needs beans to live.
      </li>

      <li>
        There will be four rounds of this game:
        <br />
        The first round is the first generation (great-grandparent). The second
        round is the second generation (grandparent). The third round is the
        third generation (parent). The fourth round is the fourth generation
        (child).
      </li>

      <li>
        During each round, the representative from each family of that
        generation will have the opportunity to extract the natural resources
        (beans) for 10 seconds using their assigned utensil. Explain that when
        extracting resources, family members must keep their collection cups on
        the outside of the tub of beans. During each round family members will
        “Take out as many beans as you care to.”
      </li>

      <li>
        After each round, ask family groups to graph results over time. Family
        members will record results for each generation on a
        behavior-over-time-graph.{" "}
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/BeanGame-BOTG-Template.pdf"
          target="blank"
          rel="noreferrer"
        >
          Download a graph template here
        </a>
        .
      </li>
    </ol>

    <h3>Debrief:</h3>

    <p>
      The below are questions that will help to generate group discussion and
      analysis. This debrief will help to dig into the essential questions
      referenced above.
    </p>

    <p>
      <em>
        What similarities or differences did you notice between generations?
      </em>
      <br />
      <em>What similarities or differences did you notice between families?</em>
      <br />
      <em>
        What, if any, advice did your family pass down through the generations?
      </em>
      <br />
      <em>
        Why would a particular generation or family want to consume as many
        natural resources as possible?
      </em>
      <br />
      <em>What mental models surfaced during the simulation?</em>
      <br />
      <em>
        How did mental models influence people’s behavior during the simulation?
      </em>
      <br />
      <em>
        Why would a particular generation or family want to conserve their
        natural resources?
      </em>
      <br />
      <em>
        What would happen if we use our natural resources faster than they can
        be replaced?
      </em>
      <br />
      <em>Can you think of examples where this has already occurred?</em>
      <br />
    </p>

    <p>
      <strong>Note:</strong> This simulation can be debriefed through a variety
      of lenses in order to best match your context and desired outcomes.
      Possible lenses include sustainability, Habits of a Systems Thinker, and
      social justice.
    </p>

    <h3>Connecting this Simulation to the Systems Thinking Iceberg</h3>

    <img
      className="med-img"
      src="/images/resources/bean-game/iceberg.png"
      alt="Iceberg visual"
    />

    <p>
      Create an Iceberg visual like the one above using the graphs you drew
      during the game along with the{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-template-so.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Tragedy of the Commons archetype
      </a>{" "}
      or a{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/BeanGame-StockFlowTemplate.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        stock-flow map
      </a>{" "}
      (see examples below):
    </p>

    <ol>
      <li>
        On a large white board or sheet of easel paper, post the
        behavior-over-time graphs of each family’s bean accumulation over the
        four rounds, in a horizontal line across the board.
      </li>

      <li>Discuss the trends participants notice in the graphs.</li>

      <li>
        Brainstorm individual events that occurred during the simulation and
        write them on the board directly above the behavior-over-time graphs.
        Possible examples include:
        <ul>
          <li>“The family with the scoop collected the most beans.”</li>

          <li>“I was pushed.”</li>

          <li>“Someone gave me some beans.”</li>

          <li>“I spilled my beans.”</li>
        </ul>
      </li>

      <li>
        If people mention beliefs or mental models that are underlying what
        happened, write these near the bottom of the board. For example, “People
        were greedy.”
      </li>

      <li>
        Talk about what caused those trends by building one of the following in
        between the graphs and mental models.
        <ul>
          <li>
            The Tragedy of the Commons is an archetype that can be used to
            understand what happens in a system with finite resources. Mental
            models that fuel short-term consumption of the resources without the
            realization of the larger picture often can have unintended
            consequences.
            <br />
            <img
              className="med-img"
              src="/images/resources/bean-game/tragedy-commons.svg"
              alt="Tragedy of the Commons"
            />
          </li>

          <li>
            A stock-flow map can also be used to help map the structure of the
            system that occurred during the simulation. Here is one example.
            <br />
            <img
              className="med-img"
              src="/images/resources/bean-game/stockflow.svg"
              alt="Stock-Flow Map"
            />
          </li>
        </ul>
      </li>

      <li>
        Finally, draw a triangle around the diagram and show how you just
        created an iceberg framework, and that by delving below the surface
        events, you were able to explore the dynamics and interdependencies in
        the system.
      </li>
    </ol>
  </div>
);
