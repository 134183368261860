import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-single"
        src="/images/resources/ladder-notice-act/ladder-notice-act.svg"
        alt="Ladder of Inference: Four Rung Ladder with Notice, Act, Believe"
      />
    </div>
  </div>
);
