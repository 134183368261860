import * as React from "react";
import PropTypes from "prop-types";

export function CourseHeader({ course }) {
  const courseGroup = course.group;
  const courseGroupCap =
    courseGroup.charAt(0).toUpperCase() + courseGroup.slice(1);
  return (
    <div className="course-header">
      <div className="header-info">
        <a className="course-back" href={`courses/${courseGroup}`}>
          Back to {courseGroupCap} Courses
        </a>
        <h1>{course && course.name}</h1>
        <p>{course.summary}</p>
        <p>
          <b>Estimated Time:</b> {course.time}
        </p>
        <p>
          <b>Objectives for this course include the abilities to:</b>
        </p>
        <ul>
          {course.objectives.map((o) => (
            <li key={o.id}>{o.content}</li>
          ))}
        </ul>
      </div>
      <img className="header-img" src={course.image} alt="Course Image" />
    </div>
  );
}
CourseHeader.propTypes = {
  course: PropTypes.object,
};
