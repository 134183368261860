import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <img
      className="lesson-img"
      src="/images/courses/habits/08-structure/lesson-three-img.png"
      alt="System Structure"
    />
    <p>
      Seeing how system parts are connected to one another as the system design
      will help you understand why systems produce certain outcomes. The
      following quote is referenced in other courses, but is worth repeating
      here: “Systems are perfectly designed to produce the results they get.” A
      system’s design involves the connections between its elements or parts. To
      better understand this concept, let’s consider the parts of a family
      system. Families are comprised of people and their personalities,
      traditions, rules, values, expectations, living conditions, schedules and
      so on. The design of a family system is determined by the relationships
      between the parts. What is the relationship between a parent’s established
      rules and schedules and a child’s personality? A child may thrive or cause
      havoc with loosely structured rules and day-to-day schedules. On the other
      hand, with a tightly structured routine, a child may appreciate
      predictability or feel constrained and limited in opportunities for
      creative expression.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      Let’s consider a brand-new family system. Tyler and Ryan are about to
      become new parents to baby Jack. To prepare for Jack’s birth, they read
      numerous recommended parenting self-help books and websites. After diving
      into the many sources of information, instead of helping, they found the
      advice about parenting structures conflicting. Should sleep and feeding
      schedules be strictly in place with 2 – 3-hour intervals, or be based on
      the feeding behaviors and needs of the baby? How much time should the baby
      be swaddled, or dressed with arms and legs free to move, or cuddled
      skin-to-skin, or situated on the tummy? Is it OK to use a pacifier when
      the baby is fussy after eating? Or is it not a good idea to use a pacifier
      at such a young age? The new parents learned that the childcare structures
      recommended by the various experts actually made parenting more confusing
      during the first week of Jack’s life. Jack was born late and arrived with
      a birth weight of over 10 pounds and above average length, so oftentimes,
      the recommended structures didn’t always apply. Luckily, their
      pediatrician recognized their frustration and assured them that their
      child needed a parenting system that matched his individual needs, and no
      single “proven” design or set of structures would work for all newborn
      babies. She advised them to make Jack’s needs, not “expert advice,”
      central to their daily schedule. With a sigh of relief, they still
      considered expert advice, but primarily relied on their own structural
      instinct of what was best for their son.
    </p>
    <p>
      Tyler and Ryan’s experience can be applied to many other systems outside
      of parenting. How does the plethora of leadership and organizational
      management books and systems change websites influence leaders responsible
      for transforming organizations?
    </p>
    <p>
      For example, how does the vast array of approaches to school improvement
      help school and district leaders make a plan that positively impacts
      student success? How does the success of a community’s ability to solve
      complex problems inform leaders in other communities trying to address
      their own challenges in their own settings? No two systems are exactly
      alike, so systems thinkers first recognize the distinctive factors that
      make up their systems, while also considering available theories,
      research, suggested processes and anecdotal successes as they identify and
      design structures that will help them realize desired results.
    </p>
    <p>
      This Habit recognizes system structure, and reminds us that each and every
      system has its own individual qualities, history and capabilities.
      Improvement plans need to incorporate customization to meet the needs and
      unique qualities and conditions of schools and communities. Efforts to
      replicate behavior-changing structures should include the full examination
      and consideration of the structures of the system of interest. Human
      systems are complex and call for careful recognition of the connection
      between system structure and system behavior. Commonly prescribed,
      cookie-cutter design structures for improvement are used as a means to
      quickly generate results, but they often backfire or merely deliver quick,
      short-lived results that slide back to previously underperforming
      patterns.
    </p>
  </div>
);
