import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-single"
        src="/images/resources/iceberg-leverage-learning/iceberg-leverage-learning.svg"
        alt="Iceberg with Bubbles"
      />
    </div>
  </div>
);
