import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <p>
      Now that you are caught up on causal links, let’s look at the suggested
      process for making a causal connection circle map. There are also
      variations of this process that will be shared later in this course.
    </p>
    <ol>
      <li>
        Identify a system of interest. You can use this mapping tool to
        represent the current state of a system or the desired state as a vision
        of what is desired or possible.
        <h4>
          Here are some examples to help you consider your own practice system:
        </h4>
        <p>
          &#9679; Our work team or our desired high-functioning, five-star team
        </p>
        <p>
          &#9679; My garden as it is now or the bountiful, thriving garden I
          wish I had
        </p>
        <p>
          &#9679; My classroom or my dream classroom where all students learn,
          thrive and achieve
        </p>
        <p>
          &#9679; My current workplace or an innovative, prosperous start-up
          company that I dream of creating
        </p>
      </li>
      <li>
        Make a list of 5 – 10 variables (things that change over time) that are
        central to the behavior and functioning of your system.
        <p>
          You may want to consider, What’s the challenge? What is changing? How
          is it changing?
        </p>
        <h4>
          Choose elements of the story that satisfy all of these criteria:
        </h4>
        <p>
          &#9679; The elements contribute to present or future conditions and
          dynamics of the system.
        </p>
        <p>&#9679; The elements are nouns or noun phrases.</p>
        <p>
          &#9679; The elements change over time (increase or decrease) at times
          throughout the story of your system.
        </p>
      </li>
      <li>
        For each of your listed variables, draw a behavior-over-time graph
        (BOTG). You can either draw these on small post-its or go to step 4 and
        draw them in a circle on large paper. If you need a quick review of
        BOTGs, revisit the{" "}
        <a href="/courses/01-behavior-over-time-graphs">BOTG course.</a>
      </li>
      <li>
        Arrange the BOTGs in a circle. See example below. Note, based on your
        systems and variables chosen, your BOTGs will differ from the examples
        below.
        <img
          className="med-img"
          src="/images/courses/tools/06-connection-circles/botgs.svg"
          alt="BOTGs"
        />
      </li>
      <li>
        Identify causality: find cause and effect relationships between elements
        that cause other elements to change (increase or decrease). This is
        where you apply your experience with causal links.{" "}
        {/* If you need a review, revisit the <a href="">Causal Link course</a>. */}
        <p>
          <b>a.</b> Draw an arrow from the “cause” element to the “effect”
          element. You may want to ask yourself, “As this element increases,
          what other elements will this change/ effect?”
        </p>
        <p>
          <b>b.</b> To tell the story of the link, on the arrow write a verb or
          action phrase to indicate HOW the cause “acts on” the effect element.
        </p>
        <p>
          <b>c.</b> Label the arrowhead with “S or +” (indicating a direct or
          same direction relationship) or “O or -” (indicating an inverse or
          opposite relationship). See example below.
        </p>
        <img
          className="med-img"
          src="/images/courses/tools/06-connection-circles/botgs-marked.svg"
          alt="BOTGs Marked"
        />
      </li>
      <li>
        As you draw and label arrows, be sure to tell the story of each causal
        connection. Sometimes people write words or short phrases along the
        arrows to tell the causal link story.
      </li>
    </ol>
  </div>
);
