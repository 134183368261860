import * as React from "react";

export const Lesson01s03 = () => (
  <div className="content">
    <h3>Benefits of a Systems Thinking Approach</h3>
    <p>
      As you apply the 14 Habits of a Systems Thinker to the five practice
      systems in this learning pathway, deepening your understanding and making
      connections, we believe the Habits will become valuable tools in your life
      and work. In our experience working with numerous organizations, we have
      found that the Habits are very effective in helping people achieve their
      desired results. The Habits offer both a common language and a shared
      framework for the kinds of thinking that produce meaningful progress.
    </p>
    <p>
      This material works well when people learn together, so we encourage you
      to engage in the practice exercises with others. A systems thinking
      learning journey is collaborative and seldom a solo endeavor. Share your
      learning with others, and use the content in the course to collaborate,
      share perspectives and develop the systems mindset that will optimize your
      ability to manage the complexity of today’s world.
    </p>
    <p>
      We hope you enjoy your systems thinking journey and look forward to
      hearing about your experiences and applied learning.
    </p>

    <h3>Systems Thinking Resources</h3>

    <p>
      Be sure to check out the Resources section of the Thinking Tools Studio
      where you will find templates, lesson plans, facilitation guides,
      assessments, webinars, videos, and articles.
    </p>
  </div>
);
