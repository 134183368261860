import * as React from "react";
import { Field } from "redux-form";
import { ToggleText } from "components/shared/toggle-text";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      Use the text boxes to label the causal links between the pairs and Tell
      the Story of the causal connection. Feel free to use either S/+ or O/-.
    </p>
    <div className="link-pair">
      <div className="field-container">
        <Field
          name="variable-one"
          component="input"
          className="variable-field"
        />
        <ToggleText
          showText="Show Hint"
          hideText="Hide Hint"
          text={[{ id: 1, text: "S/+" }]}
        />
      </div>
      <div className="connection">
        <p>Family fun</p>
        <div className="arrow-container">
          <img
            className="arrow"
            src="/images/courses/tools/06-connection-circles/arrow.svg"
            alt="arrow"
          />
        </div>
        <p>Quality of relationships</p>
      </div>
      <Field
        name="story-one"
        component="textarea"
        className="story-field"
        placeholder="Tell the story:"
      />
    </div>
    <div className="link-pair">
      <div className="field-container">
        <Field
          name="variable-two"
          component="input"
          className="variable-field"
        />
        <ToggleText
          showText="Show Hint"
          hideText="Hide Hint"
          text={[{ id: 1, text: "S/+" }]}
        />
      </div>
      <div className="connection">
        <p>Team effectiveness</p>
        <div className="arrow-container">
          <img
            className="arrow"
            src="/images/courses/tools/06-connection-circles/arrow.svg"
            alt="arrow"
          />
        </div>
        <p>Professional satisfaction</p>
      </div>
      <Field
        name="story-two"
        component="textarea"
        className="story-field"
        placeholder="Tell the story:"
      />
    </div>
    <div className="link-pair">
      <div className="field-container">
        <Field
          name="variable-three"
          component="input"
          className="variable-field"
        />
        <ToggleText
          showText="Show Hint"
          hideText="Hide Hint"
          text={[{ id: 1, text: "O/-" }]}
        />
      </div>
      <div className="connection">
        <p>Relaxation</p>
        <div className="arrow-container">
          <img
            className="arrow"
            src="/images/courses/tools/06-connection-circles/arrow.svg"
            alt="arrow"
          />
        </div>
        <p>Stress</p>
      </div>
      <Field
        name="story-three"
        component="textarea"
        className="story-field"
        placeholder="Tell the story:"
      />
    </div>
    <div className="link-pair">
      <div className="field-container">
        <Field
          name="variable-four"
          component="input"
          className="variable-field"
        />
        <ToggleText
          showText="Show Hint"
          hideText="Hide Hint"
          text={[{ id: 1, text: "S/+" }]}
        />
      </div>
      <div className="connection">
        <p>Emails in inbox</p>
        <div className="arrow-container">
          <img
            className="arrow"
            src="/images/courses/tools/06-connection-circles/arrow.svg"
            alt="arrow"
          />
        </div>
        <p>Time responding to emails</p>
      </div>
      <Field
        name="story-four"
        component="textarea"
        className="story-field"
        placeholder="Tell the story:"
      />
    </div>
  </div>
);
