import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>
      Describe a time you received feedback from another person or source.
    </h4>
    <Field
      name="person-source"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Recall how you reacted to the feedback. If it was positive, did you
      intentionally continue to do things the same way (i.e. the feedback
      reinforced your actions)? If it was constructive criticism, were you
      willing to change or modify?
    </h4>
    <Field
      name="continue"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Can you describe your circular story? Consider where the role of praise
      reinforces, and instructive information causes modification or
      improvement.
    </h4>
    <Field
      name="circular-story"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
