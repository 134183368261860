import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="large-img"
        src="/images/resources/habits-single-page-portuguese/onepage-habits-portuguese.png"
        alt="Habits: Single Page Version: Portuguese Translation"
      />
    </div>
  </div>
);
