import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>
      What structures are you aware of in your own family or work team? How do
      those structures contribute to your family or workplace dynamics?
    </h4>
    <Field
      name="contribute"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>When considering relationships, what other structures come to mind?</h4>
    <Field
      name="considering-relationships"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What is a personal habit that you want to work on? It may be something you
      want to change or something you want to add to your routine.
    </h4>
    <Field
      name="personal-habit"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What current structures are making this addition or change more difficult?
    </h4>
    <Field
      name="current-structures"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How could you adjust those structures in order to increase your chances of
      success with the new habit?
    </h4>
    <Field
      name="adjust-structures"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
