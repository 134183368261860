import * as React from "react";
import { Field } from "redux-form";
import { FileUpload } from "components/shared/file-upload";

export const Exercise04 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      What employer wouldn’t want higher levels of employee engagement? Engaged
      employees are more satisfied with their jobs and in turn produce more for
      the company. In his article, “The Neuroscience of Trust,” Paul J. Zak
      suggests that while incentives may help in the short-term, creating a
      culture of trust is the fundamental solution to achieving greater employee
      engagement. This exercise asks you to use information in the article and
      your own experience to complete a two-stock, stock-flow map that
      illustrates the interdependence of employee engagement.
    </p>
    <h3>Directions</h3>
    <ol>
      <li>
        Read the{" "}
        <a
          href="https://hbr.org/2017/01/the-neuroscience-of-trust"
          target="blank"
          rel="noopener"
        >
          Neuroscience of Trust
        </a>{" "}
        by Paul J. Zak, from the Harvard Business Review.
      </li>
      <li>
        Using a blank sheet of paper, replicate the two stock-flow maps below.
      </li>
      <img
        className="engagement-stock-flow"
        src="/images/courses/tools/03-stock-flow/engagement-stock-flow.svg"
        alt="Employee Engagement Stock Flow Map"
      />
      <li>
        Consider the following as possible converters. Feel free to generate
        your own. Use convertors and connectors to build a map of this article.
        <p>&#9679; Sense of purpose</p>
        <p>&#9679; Relationships</p>
        <p>&#9679; Choice</p>
        <p>&#9679; Goals</p>
        <p>&#9679; Feeling Uninformed</p>
      </li>
      <li>
        Tell the story of your stock-flow map to another person. Gather some
        feedback. Revise your map as appropriate.
        <Field
          name="stockFlowTemplate"
          component={FileUpload}
          image
          instructions="Upload your revised stock-flow map."
        />
      </li>
    </ol>
    <h3>Reflection</h3>
    <h4>Explain how your map shows ways to increase employee engagement.</h4>
    <Field
      name="map"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What insights did you gain by mapping the concepts of employee engagement
      and trust?
    </h4>
    <Field
      name="gain"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
