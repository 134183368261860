import * as React from "react";
import { Field } from "redux-form";

export const Lesson02 = () => (
  <div className="content">
    <p>
      Causal loops, sometimes referred to as feedback loops, are circular arrow
      pictures that tell stories of cause and effect.
    </p>
    <p>
      <strong>There are 3 main parts to causal loops:</strong>
    </p>
    <ul>
      <li>Variables</li>
      <li>Arrows</li>
      <li>Arrowhead and loop labels</li>
    </ul>
    <h3>Simple Loops</h3>
    <p>
      To draw a causal loop, you need at least two variables that have a causal
      relationship where the effect actually feeds back and influences the
      initial cause. Let’s revisit the example of cooking a new recipe for
      dinner guests.
    </p>
    <p>
      This story can be told as a loop in a number of ways. For example, from
      the perspective of the person cooking the meal (you), the person eating
      it, the friend who gave you the recipe to try out and more.
    </p>
    <p>
      The great thing about drawing causal loop diagrams is that there is seldom
      just one way of describing a system and people can draw loops in slightly
      different ways when illustrating and experiencing the same system. (You
      will have a chance to build a causal loop diagram of the dinner scenario
      step-by-step in the coming pages.)
    </p>
    <img
      className="lesson-img med-img"
      src="/images/courses/tools/07-cld-reinforcing/mean-words.svg"
      alt="dinner recipe"
    />
    <p>
      A simple example comes from a group of first grade boys who were having
      ongoing problems on the playground. They were asked to use their systems
      thinking know-how to define their problem and come up with some ways to
      resolve their issues. Review their simple loop and play the video below to
      listen in on their conversation and learn how they decided to address
      their problem.
    </p>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/LEVypGV-3xA"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
    <h3>Loop Variables</h3>
    <p>
      A good way to begin creating a loop is by listing key variables. The very
      nature of a variable is that it should be able to change over time.
    </p>
    <p>
      For the recipe example, the event of serving a new meal is a one-time
      event and not a variable that changes over time, so you need to broaden
      your view of the situation to ensure that your loop relates to other
      similar scenarios. Try to envision this experience as a variable that
      might change over time. As you generate variable labels, simply ask
      yourself,{" "}
      <em>
        Could these things go up or down, increase or decrease, grow or decline
        over time?
      </em>
    </p>
    <h3>Examples</h3>
    <p>
      Consider the following possible variable labels that could depict this
      dinner party scenario. You are encouraged to look beyond the dinner party
      as a one-time event and explore the conditions of the scenario and the
      tendencies that most likely arise.
    </p>
    <p>
      Choose any of the variables from the list below that would help you draw a
      loop that tells this story. You can also list some of your own.
    </p>
    <ul>
      <li>Courage to serve a new recipe</li>
      <li>Level of satisfaction in serving a new recipe</li>
      <li>Willingness to try new recipes</li>
      <li>Fear of serving a new recipe</li>
      <li># of times new recipes are served</li>
      <li>Level of cooking confidence</li>
      <li>Quality of the meal</li>
      <li>Opportunities for guest appreciation</li>
      <li># of guest compliments</li>
      <li>Anticipation of guest reaction</li>
      <li>Level of guest satisfaction</li>
      <li>Amount of food consumed on each plate</li>
      <li>
        <Field
          name="variables"
          component="textarea"
          className="reflection-input"
          placeholder="Enter your variables here."
        />
      </li>
    </ul>
    <p>
      If you are stuck developing variable labels, good phrases to help get you
      started include:
    </p>
    <ul>
      <li>Amount of ___________</li>
      <li>Level of ___________</li>
      <li>Number of ____________</li>
    </ul>
    <h3>Drawing and Labeling Causal Arrows</h3>
    <p>
      Similar to the causal pairs that you experimented with in the causal link
      course, look for cause and effect pairs among your list of key variables.
      Sometimes people like to develop a connection circle as a step before
      drawing causal loops. Revisit the two courses{" "}
      <a href="/courses/05-causal-links" target="blank">
        Causal Links
      </a>{" "}
      and{" "}
      <a href="/courses/06-connection-circles" target="blank">
        Connection Circles
      </a>{" "}
      if you need a refresher.
    </p>
    <p>
      In order to determine where to draw arrows between variables, begin with
      one variable, and ask,{" "}
      <em>When this goes up, what other variable(s) might it affect?</em>
    </p>
    <p>Examples from our list include:</p>
    <div className="causal-link">
      <div className="causal-column">
        <h4>Quality of the meal</h4>
      </div>
      <div className="causal-column">
        <img
          className="arrow"
          src="/images/courses/tools/05-causal-links/arrow.svg"
          alt="arrow"
        />
      </div>
      <div className="causal-column">
        <h4># of guest compliments</h4>
      </div>
    </div>
    <div className="causal-link">
      <div className="causal-column">
        <h4>Level of cooking confidence</h4>
      </div>
      <div className="causal-column">
        <img
          className="arrow"
          src="/images/courses/tools/05-causal-links/arrow.svg"
          alt="arrow"
        />
      </div>
      <div className="causal-column">
        <h4>Courage to serve new recipes</h4>
      </div>
    </div>
    <p>
      As you identify a causal pair, you should also determine the direction of
      the cause and effect relationship and label the arrowheads to show this
      distinction.
    </p>
    <p>
      There are two ways to label arrows. Near the head of the arrow, the letter
      S or a + sign shows that the effect (or 2nd variable) moves in the same
      direction or adds to the cause (or 1st variable).
    </p>

    <div className="causal-link">
      <div className="causal-column">
        <h4>Quality of the meal</h4>
      </div>
      <div className="causal-column">
        <img
          className="arrow"
          src="/images/courses/tools/05-causal-links/arrow.svg"
          alt="arrow"
        />
        <p className="link-label blue">S, +</p>
      </div>
      <div className="causal-column">
        <h4># of guest compliments</h4>
      </div>
    </div>
    <p>
      Here the S label clarifies the relationship:{" "}
      <em>
        When the quality of the meal increases, then the number of guest
        compliments increases.
      </em>{" "}
      Or,{" "}
      <em>
        When the quality of the meal decreases, then the number of guest
        compliments decreases.
      </em>
    </p>
    <p>
      In both scenarios, the virtuous and the vicious, both variables are
      changing over time in the same or additive direction.
    </p>
    <p>
      Decide which label you prefer, S or +, and use one to label the arrowhead.
    </p>
  </div>
);
