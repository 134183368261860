import { createAction } from "redux-actions";
import { apiCallAsync } from "components/shared/api";

// TODO for whole file: I have libraries that handle all the "boilerplate" code
// that currently has to be added here for each api call
// I wanted to demonstrate how it worked normally before I abstracted it away though

// ----------------------------------------------------------------

export const ActionTypes = {
  Load: {
    Start: "ANSWERS.LOAD.START",
    Success: "ANSWERS.LOAD.SUCCESS",
    Fail: "ANSWERS.LOAD.FAIL",
  },
  Save: {
    Start: "ANSWERS.SAVE.START",
    Success: "ANSWERS.SAVE.SUCCESS",
    Fail: "ANSWERS.SAVE.FAIL",
  },
};

const loadStartAction = createAction(ActionTypes.Load.Start);
const loadSuccessAction = createAction(ActionTypes.Load.Success);
const loadFailAction = createAction(ActionTypes.Load.Fail);

function createIdAction(type) {
  // create an action that takes a form id, and the data for that form as parameters
  // the id is set on the metadata for the action, and the data is set as the payload
  return createAction(
    type,
    (id, data) => data,
    (id, data) => ({ id }),
  );
}

const saveStartAction = createIdAction(ActionTypes.Save.Start);
const saveSuccessAction = createIdAction(ActionTypes.Save.Success);
const saveFailAction = createIdAction(ActionTypes.Save.Fail);

export const answersPageStart = () => async (dispatch, getState) => {
  const { answers } = getState();
  if (!answers.isFresh) {
    await dispatch(loadAnswers());
  }
};

// make api call to load answers for all coarses
export const loadAnswers = () => async (dispatch) => {
  // send load action so "loading" animation can start
  dispatch(loadStartAction());
  try {
    const result = await apiCallAsync("/answers");
    // call load success action with data to update the store
    dispatch(loadSuccessAction(result));
  } catch (ex) {
    dispatch(loadFailAction(ex));
    throw ex;
  }
};

// make api call to save single resource/course/lesson/exercise
export const saveAnswers = (id, data) => async (dispatch, getState) => {
  console.log("save answers", id, data);
  data.id = id;
  dispatch(saveStartAction(id, data));
  try {
    const oldValue = getState().answers.data[id];
    // api should return the saved object
    // (should be similar to data, but with any fields updated from the server, like create_time)
    const result = oldValue
      ? await apiCallAsync(`/answers/${id}`, data, "PUT")
      : await apiCallAsync("/answers", data, "POST");
    // update the store with new data for this course/lesson/exercise
    dispatch(saveSuccessAction(id, result));
  } catch (ex) {
    dispatch(saveFailAction(id, ex));
    throw ex;
  }
};
