import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img med-img"
      src="/images/courses/habits/04-perspectives/course-img.png"
      alt="Changes Perspectives"
    />
    <p>
      Adults are often in a position of teaching children. But it is equally
      important to recognize how adults can learn from young people by seeing
      things through their eyes, or perspective, as shown in this illustration.
    </p>
    <div className="quote">
      <p className="quote-text">
        “If there is any one secret of success, it lies in the ability to get
        the other person’s point of view and see things from his angle as well
        as your own.”
      </p>
      <p className="quote-author"> – Henry Ford, industrialist</p>
    </div>
    <p>
      <b>The True Story of the Three Little Pigs</b>, told by A. Wolf, is a
      retelling of the familiar story “The Three Little Pigs,” told from the
      perspective of a wolf. In this adapted story, the wolf explains that he
      really isn’t big and bad. He was merely a neighbor to the pigs who wanted
      to borrow a cup of sugar. He knocked on the door of the first little pig’s
      house, and due to the pig’s failure to use adequate construction, the
      house fell down, accidently killing the pig. It would have been wasteful
      of the wolf not to consume the meat. He goes on to explain what happened
      to each of the remaining pigs. A very different story than the one we
      typically remember, but a thought-provoking example of what it means to
      fully examine a situation from a different perspective.
    </p>
    <img
      className="clear"
      id="show-caring"
      src="/images/courses/habits/04-perspectives/show-caring.svg"
      alt="Changes Perspectives"
    />
    <p>
      Literature often helps us see things from different perspectives; such is
      the case when famous literary character Atticus Finch, in{" "}
      <b>To Kill a Mockingbird</b>, speaks specifically about the importance of
      considering the perspective of another. “‘First of all,’ he said, ‘if you
      can learn a simple trick, Scout, you’ll get along a lot better with all
      kinds of folks. You never really understand a person until you consider
      things from his point of view … until you climb into his skin and walk
      around in it.’”
    </p>
    <p>
      Empathic thinking requires a form of perspective taking that is both
      circular and causal. It can be described as, “I understand you and try to
      feel what you feel; you then feel cared for and return the same sense of
      support to me.”
    </p>

    <h3>Perspective Taking</h3>
    <p>
      Ellen Gallinsky’s book <b>Mind in the Making</b> identifies seven life
      skills that promote learning and achievement in young children.
      Perspective taking is one of those life skills. For children to be
      successful, they have to mature beyond a purely myopic view of the world
      and be able to take into consideration the needs and desires of others. A
      two-year-old grabbing a toy from his friend is a common scenario that
      creates a learning opportunity for the child. When an appropriate behavior
      (in this example, sharing the toy) is modeled and reinforced, the child is
      able to practice a desired behavior, grow socially and emotionally, and
      mature into an individual who gets along well with others.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      Consider this example: Jen is a preschool teacher who recognizes that a
      two-year-old’s tendency to grab a toy away from a playmate is a common
      behavior at this stage of development. She also understands that to help
      him mature beyond this developmental stage, she must intervene by modeling
      and reinforcing the appropriate behavior: sharing the toy. Giving her
      student the opportunity to learn how to take the perspective of another
      person allows him to grow socially and mature into an individual who gets
      along well with others. This scenario offers a very early example of what
      it means to change one’s perspective in order to increase understanding.
    </p>
    <p>
      Changing your perspective remains an important skill well into adulthood.
      It is essential to change your perspective to see the big picture of any
      system. In addition to considering the feelings, points of view and
      concerns of other people, it may also require that you suspend judgment in
      order to have a more complete picture of a particular situation.
    </p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      After making an offer on a new home, Elena and Tom’s realtor recommends an
      independent home inspection in order to protect their interest in their
      purchase. A few days after the inspection is completed, a report is sent
      to both the buyer and the seller. As buyers, Elena and Tom have vastly
      different perspectives on the content of the report than the sellers. When
      presented with a lengthy list of repairs, Tom and Elena expect that the
      seller will assume responsibility to repair each one, even down to the
      broken light switch cover. The seller receives the same report, and since
      there are no major issues or structural concerns, he is confident that the
      work he put in to prepare the house for sale is sufficient. He expects
      that Tom and Elena will be delighted at the worthy investment they are
      about to make in a home.
    </p>
    <p>
      These varying perspectives could result in loss of a sale if both the
      buyer and the seller are unwilling to suspend their own ideas about what
      constitutes a home in good condition and what the responsibility is of
      each party in the sale. Since Tom and Elena are committed to trying to see
      the report through the eyes of the seller, while keeping in mind what is
      important to them, they are able to come to a successful resolution. In
      order to practice changing perspectives, the buyer and the seller must be
      honest about their own perspective, suspend judgment, and consider
      multiple perspectives in order to arrive at a reasonable conclusion about
      what repairs are truly necessary for both a successful sale and a
      responsible purchase.
    </p>
  </div>
);
