import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/uNYMZhVIQtY?rel=0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>

    <p>
      We invite you to join us for the unfolding of scenarios where leaders get in their own way and don’t even know it! Sometimes the desire to come to the rescue and be a “hero” can cause unintended consequences that leave teams or organizations stuck with the same problem they were trying to “fix”– or can even make things worse.
    </p>
    <p>
      In this webinar, Kristina and Ken Tyler, who provide business consulting and services through Flow Fiero LLP, will walk you through their experience of using the archetype “Fixes that Fail” to uncover blind spots and entanglements in leadership. They will share how they have utilized systems thinking tools and habits to help businesses grow beyond the vicious cycles holding them back.
    </p>
  </div>
);
