import * as React from "react";
import { FileUpload } from "components/shared/file-upload";
import { Field } from "redux-form";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Leverage provides an advantage. It is often a small action that nets a
      great result. Consider the following scenarios:
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-community.svg"
      alt="Community Example"
    />
    <p>
      Communities are challenged with business development efforts to grow local
      economies along with the preservation of natural habitats and historical
      landmarks.
    </p>
    <h4>Possible leverage actions may include:</h4>
    <Field
      name="leverage-actions"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <img
      className="system-icon"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      Education engages in heated debates about the appropriate level of
      structure and rigor in its standards and expectations for students.
    </p>
    <h4>Possible leverage actions may include:</h4>
    <Field
      name="possible-leverage"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <img
      className="system-icon"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      A family struggles with the challenges brought on by the need for two
      parents/caregivers working, the cost and quality of childcare and the care
      of aging grandparents.
    </p>
    <h4>Possible leverage actions may include:</h4>
    <Field
      name="may-include"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      Choose one or more of the examples above that relate to you, and begin to
      draw a causal map to help you better understand the structure of the
      system.
    </p>
    <Field
      name="leverageMap"
      component={FileUpload}
      image
      instructions="Take a photo of your drawing and upload it below."
    />
    <h4>
      What leverage actions surface based on your deeper understanding of the
      system?
    </h4>
    <Field
      name="surface-based"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h3>
      In addition to mapping tools, systems thinkers use reflection questions
      throughout the mapping process. Revisit your practice scenario and respond
      to the following reflection questions:
    </h3>
    <h4>What aspects of our system are working well?</h4>
    <Field
      name="aspects-system"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What aspects of our system are producing less than desirable results?
    </h4>
    <Field
      name="system-aspects"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Do we have a big enough picture of our system? Do the elements in our map
      represent what is contributing to our results? What might we be missing?
    </h4>
    <Field
      name="big-enough"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What small changes could improve our results?</h4>
    <Field
      name="small-changes"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How will our proposed changes produce a long-lasting desired effect?
    </h4>
    <Field
      name="desired-effort"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      We may not notice immediate impact, but given time, what are ways we can
      monitor the impact of our leverage action(s)?
    </h4>
    <Field
      name="immediate-impact"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
