import { SubmissionError } from "redux-form";
import { getSessionAuth as setSessionAuth } from "components/shared/session";
import { apiCallAsync } from "components/shared/api";

export const ActionTypes = {
  LoginSuccess: "AUTH.LOGIN.SUCCESS",
  SignupSuccess: "AUTH.SIGNUP.SUCCESS",
};

export const loginAction = async (data) => {
  try {
    const r = await apiCallAsync("/auth/signin", data, "POST");
    setSessionAuth(r);
    return { type: ActionTypes.LoginSuccess, payload: r };
  } catch (ex) {
    let err = "Unknown error";
    try {
      err = JSON.parse(ex.message).message;
    } catch (ex2) {}
    throw new SubmissionError({
      password: "Invalid username or password",
      _error: err,
    });
  }
};

export const signupAction = async (data) => {
  try {
    const r = await apiCallAsync("/auth/signup", data, "POST");
    setSessionAuth(r);
    return { type: ActionTypes.SignupSuccess, payload: r };
  } catch (ex) {
    let err = "Unknown error";
    try {
      err = JSON.parse(ex.message).message;
    } catch (ex2) {}
    throw new SubmissionError({
      _error: err,
    });
  }
};
