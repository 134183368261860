import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-single"
        src="/images/resources/iceberg-3-dimensional/iceberg-3-dimensional.svg"
        alt="Iceberg Template 3D"
      />
    </div>
  </div>
);
