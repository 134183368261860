import * as React from "react";

export const Lesson04 = () => (
  <div className="content">
    <img
      className="lesson-img"
      src="/images/courses/habits/13-accumulations/lesson-four-img.jpg"
      alt="Piggybank"
    />
    <p>Stephen Covey uses the image of an emotional bank account.</p>
    <p>
      Affirmations, compliments and positive interactions act as deposits, while
      hurtful words, unmerited criticism and hate serve as withdrawals. When
      withdrawals outweigh deposits, an individual is left with negative
      feelings and the accumulation of trust decreases.
    </p>
    <p>
      Rath and Clifton expand on this metaphor in the book, "How Full is Your
      Bucket?", available in separate versions for adults and children.
    </p>
    <p>
      Their metaphor of a bucket and a dipper illustrates that even small
      interactions with other people have a profound effect on disposition,
      well-being and productivity. This metaphor focuses on essential
      accumulations that lead to personal happiness and satisfaction, while also
      paying attention to the factors that drain an individual’s personal
      bucket.
    </p>
    <p>
      Let’s explore how bucket filling works with a personal well-being example.
    </p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-well-being.svg"
      alt="Well-being Example"
    />
    <p>
      Your stock of personal well-being can feel as if it’s being reinforced in
      a particular direction. For example, on those days you experience a large
      number of bucket fillers — you have a great hair day, you get an
      encouraging text from a friend, you make it to work early and your boss
      congratulates you on the new account you landed — your bucket is being
      filled and it is easy to pay attention to things that make you feel good.
    </p>
    <p>
      On the other hand, imagine the day when everything goes wrong. For
      example, on days you need to seek out things to reverse the trend of your
      rapidly depleting feeling of well-being.
    </p>
  </div>
);
