import * as React from "react";
import { FileUpload } from "components/shared/file-upload";
import { Field } from "redux-form";

export const Exercise04 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      This practice can be done with another person, a small group or team, a
      classroom of students, or a department of workers. Think about a scenario
      where you perceive that there are different mental models about one shared
      experience or topic. Consider some of the suggested uses of the ladder of
      inference from the previous exercises. For example, if you are a teacher,
      you can apply the tool to a classroom lesson. If you are a manager or
      administrator, you can use the ladder with colleagues.
    </p>
    <p>
      Download the{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/ladder-worksheet.pdf"
        target="blank"
        rel="noopener"
      >
        ladder of inference template
      </a>{" "}
      and make a copy for each participant.
    </p>
    <p>
      Introduce the ladder of inference and walk people through each step
      beginning at the bottom. (Refer to Lesson 2, “How does it work?”)
    </p>
    <p>
      Create your own ladder, writing down notes in each step. Take a photo of a
      ladder depicting one example from this practice experience.
    </p>
    <Field
      name="createLadder"
      component={FileUpload}
      image
      instructions="Upload your ladder below."
    />
    <h4>What was the desired outcome?</h4>
    <Field
      name="outcome"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>Describe the experience of practicing the ladder of inference.</h4>
    <Field
      name="ladder"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What went well?</h4>
    <Field
      name="well"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What would you do differently next time?</h4>
    <Field
      name="time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
