import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Archetype</h3>

    <p>
      When have you encountered or observed a “drifting goals” scenario? Use the
      archetype to reflect on that experience.
    </p>

    <p>
      Feel free to{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/drifting-goals-blank-so.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        download a blank “drifting goals” template
      </a>{" "}
      and use it to tell your story as you respond to the questions.
    </p>

    <h4>
      1. What was the goal of the system, the actual state of the system, and
      the difference between the two, i.e. the gap, at the outset?
    </h4>

    <Field
      name="resource-response-one"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      2. What were one or more corrective actions/strategies that were applied?
      What effects did the strategies have on the actual state of the system?
    </h4>

    <Field
      name="resource-response-two"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      3. How much of a time delay was there before the strategies took effect?
    </h4>

    <Field
      name="resource-response-three"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      4. What were the key elements that went into the decision to lower the
      goal?
    </h4>

    <Field
      name="resource-response-four"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
