import * as React from "react";
import { Field } from "redux-form";

export const Lesson01s02 = () => (
  <div className="content">
    <h3>The Five Systems</h3>
    <p>
      Your understanding of the systems in your life and work will affect your
      decisions, your actions and the way you choose to live. We have identified
      five basic life systems that involve people. In this Thinking Tools
      Studio, each system is represented by a circular icon. These sample
      systems are included throughout the Studio and are used in practice
      exercises and anecdotal examples. To get started, reflect on each of the
      five system types.
    </p>
    <div className="reflection">
      <h4>
        Describe how each of these five systems relate to your life. How and why
        are they applicable and important to you?
      </h4>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-well-being.svg"
          alt="Well Being Example"
        />
        <div className="example-info">
          <h4>Well-being</h4>
          <p>
            Personal well-being as a system involves your physical, emotional
            and social health. It also considers your state of being happy and
            prosperous.
          </p>
        </div>
        <Field
          name="well-being"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-workplace.svg"
          alt="Well Being Example"
        />
        <div className="example-info">
          <h4>Workplace</h4>
          <p>
            Your workplace considers systems that could include paid employment,
            a volunteer position, your life’s calling, or any role you play
            where you make a contribution to others.
          </p>
        </div>
        <Field
          name="workplace"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-community.svg"
          alt="Well Being Example"
        />
        <div className="example-info">
          <h4>Community</h4>
          <p>
            Your community could be a place where you reside or a place where
            you belong. It could be a town, city, an affiliation or network.
            Your community involves relationships that are formed around a
            common purpose.
          </p>
        </div>
        <Field
          name="community"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-family.svg"
          alt="Well Being Example"
        />
        <div className="example-info">
          <h4>Family</h4>
          <p>
            This system considers all of the people you choose to identify as
            your family, both immediate and extended, and may include people of
            all ages.
          </p>
        </div>
        <Field
          name="family"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
      <div className="system-example">
        <img
          className="example-img"
          src="/images/courses/shared/icon-school.svg"
          alt="Well Being Example"
        />
        <div className="example-info">
          <h4>School</h4>
          <p>
            As a place for learning and preparation, school is a system that is
            common to all of us. Your school could be a public, private, charter
            or home setting and could be a system that you attended or any other
            place of learning.
          </p>
        </div>
        <Field
          name="school"
          component="textarea"
          className="reflection-inline"
          placeholder="Enter your response here."
        />
      </div>
    </div>
  </div>
);
