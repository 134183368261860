import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img med-img"
      src="/images/courses/habits/11-consequences/course-img.png"
      alt="Short and Long Term Consequences"
    />
    <p>
      Have you ever found yourself wavering while deciding between the value of
      saving some extra cash or spontaneously spending it on a whim that would
      bring short-term pleasure? The consequences of each can, and perhaps
      should, be weighed and measured. In the illustration shown, a young man
      holds his earnings and pauses to decide between the immediate
      gratification of purchasing goods and the long-term satisfaction of
      savings that have been building over time. He is faced with the questions,
      should I spend or save? Should I reward myself with a short-term treat or
      delay gratification, knowing that the long-term benefits of saving will
      serve me well?
    </p>
    <p>
      The practice of anticipating consequences, both short and long-term, helps
      minimize the chances of decisions backfiring and producing ill effects.
      Taking time to anticipate consequences can minimize the unintended
      consequences that will occur.
    </p>
    <p>
      The famous “Marshmallow test” with young children, conducted by Stanford
      psychologists Walter Mischel and Ebbe B. Ebbesen, drew important
      conclusions regarding delayed gratification and immediate rewards. The
      researchers found that preschool children who delayed the gratification of
      immediately taking and eating a marshmallow were described more than 10
      years later as adolescents who were significantly more competent in a
      number of areas. In a later study, they were even found to have higher SAT
      scores than the instantaneous marshmallow-eaters.
    </p>
    <p>
      More recently, in 2018, conceptually replicated studies conducted by NYU’s
      Tyler Watts and UC Irvine’s Greg Duncan & Hoanan Quan broadened the pool
      of young subjects to include children from diverse backgrounds and
      families. Their findings suggest a child’s tendency to delay gratification
      by holding out for a second marshmallow is shaped in large part by a
      child’s social and economic background. A child’s background, not the
      ability to delay gratification, is what is behind a child’s long-term
      success.
    </p>
    <p>
      The causal complexity that underlies the ability to delay gratification is
      multi-faceted. Other possible explanations cited by Jessica McCrory
      Calarco, in an article in{" "}
      <a
        href="https://www.theatlantic.com/family/archive/2018/06/marshmallow-test/561779/"
        target="blank"
        rel="noopener"
      >
        The Atlantic
      </a>
      , for why poorer children would be less motivated to wait before getting a
      second marshmallow could involve food insecurity and the anxiety hunger
      brings when food or basic needs are scarce.
    </p>
  </div>
);
