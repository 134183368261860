import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <p>
      Now it’s time to take a look at this archetype using your own experience.
      When have you encountered a Limits to Success archetype? Reflect on that
      experience below.
    </p>

    <p>
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/limits-to-growth-blank-so.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        Download a blank “Limits to Success” archetype template
      </a>{" "}
      and use it to tell your story as you respond to the questions below.
    </p>

    <ol>
      <li>
        Identify the reinforcing loop in your Limits to Success archetype.
        <h4>What variable was used to designate success or growth?</h4>
        <Field
          name="loop-limits"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
        <h4>
          What variable was implemented to create an increase in growth/success?
        </h4>
        <Field
          name="create-increase"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
      </li>

      <li>
        Identify the balancing loop in your Limits to Success archetype.
        <h4>What was the constraint?</h4>
        <Field
          name="constraint"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
        <h4>What limiting action did the constraint cause?</h4>
        <Field
          name="constraint-cause"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
        <h4>Tell the story.</h4>
        <Field
          name="story"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
      </li>
    </ol>
  </div>
);
