import * as React from "react";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const Features = () => (
  <div>
    <Header />
    <div className="flow-container">
      <Helmet>
        <title>Features - Thinking Tools Studio</title>
        <meta
          name="description"
          content="See what the Thinking Tools Studio has to offer."
        />
        <meta property="og:title" content="Features - Thinking Tools Studio" />
        <meta
          property="og:description"
          content="See what the Thinking Tools Studio has to offer."
        />
        <meta
          property="og:image"
          content="https://thinkingtoolsstudio.org/images/site/features-bg-2.jpg"
        />
        <meta
          property="og:url"
          content="https://thinkingtoolsstudio.org/features"
        />
        <meta
          name="twitter:image"
          content="https://thinkingtoolsstudio.org/images/site/features-bg-2.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Features: Become a Systems Thinker for Life"
        />
      </Helmet>
      <section className="photo-header features">
        <h1>Features</h1>

        <img src="images/site/features-icon.svg" />

        <h2>Become a systems thinker for life</h2>
      </section>

      <section className="white">
        <h4 className="blue centered">Systems thinking for everyone.</h4>

        <p>
          The Thinking Tools Studio is a digital learning space created by the
          Waters Center for Systems Thinking. Whether you are new to systems
          thinking or a seasoned practitioner, the Studio has a wide array of
          offerings including courses on the Habits, tools and archetypes of
          systems thinking, resources like templates, lesson plans, facilitation
          guides and more, plus opportunities for reflection and practice — all
          free of charge.
        </p>

        <p>
          The Studio is your own personal space for learning and practicing
          systems thinking. We know the amount of systems thinking research,
          reading and resources out there can be overwhelming. The Thinking
          Tools Studio contains practical and accessible learning experiences
          curated by leaders in the field. Plus, new courses, resources and
          other features will be regularly added and updated to ensure your
          learning remains applicable and relevant.
        </p>
      </section>

      <section className="grey stacked">
        <h4 className="orange centered">Habits of a Systems Thinker Courses</h4>

        <img src="images/site/habitcards-all.png" className="med-img" />

        <p>
          The Habits of a Systems Thinker have become a staple in classrooms,
          schools and organizations across the globe looking to learn and
          implement systems thinking. The Habits encourage us to think about how
          systems work and how actions taken can impact results seen over time.
          They encompass a spectrum of strategies that foster problem-solving
          and thoughtful reflection. They encourage flexible thinking and
          appreciation of new, emerging insights and multiple perspectives.
          They’re called “habits” because they are not something to be learned
          and stored away — instead, systems thinkers practice these Habits in
          virtually every situation.
        </p>

        <a href="courses/habits">
          <button className="orange">Take me to the Habits Courses</button>
        </a>
      </section>

      <section className="white stacked">
        <h4 className="blue centered">Tools of a Systems Thinker Courses</h4>

        <img src="images/site/tools.png" className="med-img" />

        <p>
          Systems thinkers use a variety of visual tools to collect, synthesize,
          represent and communicate insights about systems. These tools help
          identify patterns, relationships, interdependencies, change over time,
          areas of leverage and more. The tools make learning visual and remind
          us to slow down when approaching a challenge, both of which are key
          aspects of systems thinking.
        </p>

        <a href="courses/tools">
          <button className="orange">Take me to the Tools Courses</button>
        </a>
      </section>

      <section className="grey stacked">
        <h4 className="orange centered">Systems Thinking Archetypes Courses</h4>

        <img src="images/site/archetype-feature.svg" className="med-img" />

        <p>
          Systems archetypes and their accompanying visual templates provide
          learners lenses with which to view systems more clearly. Archetypes
          help us investigate a situation, identify potential storylines that
          are unfolding, explore their implications and gain some initial
          understanding of an issue. Looking at an issue through the framework
          of archetypes reminds us that there are multiple ways of perceiving
          any issue — and all of these ways can provide meaningful insights.
        </p>

        <a href="courses/archetypes">
          <button className="orange">Take me to the Archetypes Courses</button>
        </a>
      </section>

      <section className="white stacked">
        <h4 className="blue centered">Systems Thinking Resources</h4>

        <p>
          Take your learning to the next level by utilizing an array of
          complimentary downloadable resources, suitable for members of any type
          of system — including educators, business leaders, team members,
          community members and more.
        </p>

        <p>Resources available include:</p>

        <ul className="colorpoints">
          <li>
            <NavLink to="/resources/templates">Templates</NavLink>
          </li>

          <li>
            <NavLink to="/resources/guides">Facilitation Guides</NavLink>
          </li>

          <li>
            <NavLink to="/resources/lessons">Lesson Plans</NavLink>
          </li>

          <li>
            <NavLink to="/resources/assessments">Assessments</NavLink>
          </li>

          <li>
            <NavLink to="/resources/webinars">Webinars & Videos</NavLink>
          </li>

          <li>
            <NavLink to="/resources/webinars">Articles</NavLink>
          </li>
        </ul>
      </section>
    </div>
    <Footer />
  </div>
);
