import { handleActions } from "redux-actions";
import { ActionTypes } from "./feedback.actions";

export const feedbackReducer = handleActions(
  {
    [ActionTypes.SaveFeedbackSuccess]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {
    auth: false,
  },
);
