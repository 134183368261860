import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <p>
      In this lesson, students will work together in teams of 2-3 to design ½ of
      a maze that they will later combine with an opposing team’s maze to make
      one complete maze. The opposing teams will then code their own Sphero Bolt
      (small spherical robot) to navigate from their starting point to the
      finish point on the opposite side of the maze. The starting point of one
      team is also the finishing point of their opponent.
    </p>

    <p>
      During the design phase of the lesson, students will need to share their
      thinking about what a maze should look like and what details they need to
      incorporate. Including roundabouts, multiple paths, and areas that are
      wide enough for two robots to pass each other will make the maze easier to
      traverse and provide more opportunities for teams to complete the maze.
    </p>

    <p>
      Students will record information on the Maze Craze handout which is an
      engineering design process causal loop. Also, at multiple points in the
      lesson, they will record how successful they feel with designing the maze
      and coding the robot on a behavior-over-time graph located on the Maze
      Craze handout.
    </p>

    <img
      src="images/resources/maze-craze/maze1.png"
      className="lesson-img med-img"
    />

    <h3>Length:</h3>

    <p>Three 60-minute blocks</p>

    <ol>
      <li>
        ½ maze design on Maze Craze handout; combining ½ mazes to make complete
        maze; maze design on butcher paper
      </li>

      <li>Begin coding; test code and make corrections</li>

      <li>Finalize code and actual competition; debriefing</li>
    </ol>

    <h3>Goal/Purpose:</h3>

    <p>
      Students will work through the engineering design process focusing on the
      Next Generation Science Standards (NGSS) that deal with solving problems
      through testing possible solutions. Students will deduce the best solution
      through data collection.
    </p>

    <ol>
      <li>
        <strong>NGSS 3-5-ETS1-2 Engineering Design:</strong> Generate and
        compare multiple possible solutions to a problem based on how well each
        is likely to meet the criteria and constraints of the problem.
      </li>

      <li>
        <strong>NGSS 3-5-ETS1-3 Engineering Design:</strong> Plan and carry out
        fair tests in which variables are controlled and failure points are
        considered to identify aspects of a model or prototype that can be
        improved.
      </li>

      <li>
        <strong>NGSS Middle School-ETS1-3 Engineering Design:</strong> Analyze
        data from tests to determine similarities and differences among several
        design solutions to identify the best characteristics of each that can
        be combined into a new solution to better meet the criteria for success.
      </li>

      <li>
        <strong>NGSS High School-ETS1-3 Engineering Design:</strong> Evaluate a
        solution to a complex real-world problem based on prioritized criteria
        and trade-offs that account for a range of constraints, including cost,
        safety, reliability, and aesthetics as well as possible social,
        cultural, and environmental impacts.
      </li>
    </ol>

    <h3>Tools:</h3>

    <img
      src="images/resources/maze-craze/story-structure.svg"
      className="lesson-img med-img"
    />

    <img
      src="images/resources/maze-craze/bad-case-of-stripes.jpg"
      className="lesson-img small-img clear"
    />

    <ol>
      <li>
        Example Causal Loop Diagram: Story Structure resource with a book
        students are familiar with like <em>A Bad Case of Stripes</em>. CLDs
        help students understand cause and effect relationships.
        <ul>
          <li>
            Teams will use a causal loop diagram handout created for the Maze
            Craze challenge to record their thinking throughout the design
            process where each step affects the next step.
          </li>
        </ul>
      </li>

      <li>
        Behavior-over-time Graph
        <ul>
          <li>
            Students will record their level of success with coding and the
            design process at different points before, during, and after the
            challenge.
          </li>

          <li>
            Students will determine the placement and labels for the graph.
          </li>
        </ul>
      </li>
    </ol>

    <h3>Materials (two teams per maze):</h3>

    <ul>
      <li>
        Sphero Bolt robot – 1 per team (can be replaced by any coding robot)
      </li>

      <li>
        iPad – 1 per team (can be any device with Sphero Edu app downloaded)
      </li>

      <li>
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/maze-craze-handout.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Maze Craze handout
        </a>{" "}
        – 1 for each student
      </li>

      <li>
        Butcher paper – 1 sheet (3’ long) per 2 teams – handed out to use the
        last 20 minutes of the first 60 minute block after teams have finished
        planning their combined maze
      </li>

      <li>Markers</li>

      <li>
        Behavior-over-time Graph – either whole group or individual on available
        section of Maze Craze handout
      </li>

      <li>
        Painters Tape – OPTIONAL for older students who are able to make the
        maze by placing the tape on the floor to form their maze
      </li>
    </ul>
  </div>
);
