import * as React from "react";
import { Field } from "redux-form";
import { FileUpload } from "components/shared/file-upload";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>

    <p>
      Let’s take another look at the multi-loop from the previous lesson, shown
      below.
    </p>

    <img
      className="med-img lesson-img block"
      src="/images/courses/tools/09-bringing-loops-together/try-new-things.svg"
      alt="Trying new things loop"
    />

    <p>
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/causal-map-template.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>{" "}
      and print the multi-loop above or simply redraw the loop on paper. In this
      exercise, you will be building on this map. It can (and should be!) be a
      messy process with a lot of different variables and arrows.
    </p>

    <p>
      To begin, identify variables to add to the map. For example, below we have
      added “coaching support” and “encouragement from others. Also notice the
      arrowhead labels coming from these new variables that indicate the
      direction of causality.
    </p>

    <img
      className="large-img lesson-img block"
      src="/images/courses/tools/09-bringing-loops-together/try-new-things-2.svg"
      alt="Trying new things loop"
    />

    <p>
      When identifying your own variables, it may help to ask yourself questions
      like:
    </p>

    <ul>
      <li>
        <em>What builds or diminishes courage?</em>
      </li>

      <li>
        <em>
          What might maximize or limit the potential benefits of trying new
          things?
        </em>
      </li>
    </ul>

    <p>
      Once you have your variables, add them to your map, label your arrowheads,
      and look for opportunities to close the loop.
    </p>

    <Field
      name="fixesThatBackfire-template"
      component={FileUpload}
      image
      instructions="Once you have finished your map, take a photo and upload it below."
    />

    <p>
      If you have difficulty forming new loops in this map, the next lesson
      provides some additional strategies.
    </p>
  </div>
);
