import React from "react";
import { ContentList } from "components/courses/course-parts/content-list";
import { CourseHeader } from "components/courses/course-parts/course-header";
import { getCoursesNav } from "components/courses/courseList";
import PropTypes from "prop-types";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const IntroPage = ({ course }) => {
  const nav = getCoursesNav({ course });
  return (
    <div>
      <Header />
      <div className="main">
        <CourseHeader course={course} />
        <div className="course-start">
          <div id="start-course">
            <a href={nav.next.url}>Get Started</a>
          </div>
        </div>
        <ContentList course={course} />
      </div>
      <Footer />
    </div>
  );
};
IntroPage.propTypes = {
  course: PropTypes.object,
};
