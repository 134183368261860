import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Materials</h3>

    <ul>
      <li>
        <em>Cactus Hotel</em>, by Brenda Z. Guiberson or another picture book
        that describes an ecosystem and its interrelated elements, such as one
        of these:
        <ul>
          <li>
            <em>The Little Creek</em>, by Jennifer Ward
          </li>

          <li>
            <em>Around One Cactus</em>, by Anthony D.Fredericks
          </li>

          <li>
            <em>Desert Giant</em>, by Barbara Bash (also available in Spanish)
          </li>

          <li>
            <em>The Wolves Are Back</em>, by Jean Craighead George
          </li>

          <li>
            <em>A Log’s Life</em>, by Wendy Pfeffer
          </li>
        </ul>
      </li>

      <li>
        Small picture cards of the elements in the chosen ecosystem (
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/CausalConnections-Cutouts.pdf "
          target="blank"
          rel="noreferrer"
        >
          download here for <em>Cactus Hotel</em>
        </a>{" "}
        or create your own)
      </li>

      <li>
        Connection Circles and Feedback Loops instruction sheet (
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/CausalConnections-StudentInstructions.pdf"
          target="blank"
          rel="noreferrer"
        >
          download here
        </a>
        )
      </li>

      <li>
        Causal connections worksheet (
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/CausalConnections-Worksheet.pdf"
          target="blank"
          rel="noreferrer"
        >
          download here
        </a>
        )
      </li>

      <li>Large chart paper, one per class or group</li>

      <li>Two different color markers for each group</li>

      <li>Scissors</li>

      <li>Glue sticks</li>
    </ul>

    <img
      className="lesson-img med-img"
      src="/images/resources/ecosystem-connection-circle/desert-connection-circle.png"
      alt="Desert Connection Circle"
    />

    <h3>Lesson Structure</h3>

    <p>
      The work in this lesson can be carried out as a whole class experience or
      students can work in small groups.
    </p>

    <h4>Session 1:</h4>

    <ol>
      <li>
        Begin by reading aloud the chosen book, in the example of this lesson,{" "}
        <em>Cactus Hotel</em>.
      </li>

      <li>
        List the elements of the ecosystem found in the book on a sheet of chart
        paper and post where all can see it. Elements from <em>Cactus Hotel</em>{" "}
        might include: saguaro fruits, saguaro blossoms, packrat, palo verde,
        ground squirrel, house finch, rain, saguaro, jackrabbit, coyote, birds,
        bees, bats, Gila woodpecker, white-winged dove, decomposers (millipede,
        scorpion, ants, termites), snake. It’s helpful to talk together about
        how each of these is connected to a saguaro as the list is created. For
        example: bats and white-winged doves as important pollinators of saguaro
        blossoms.
      </li>
    </ol>

    <h4>Session 2:</h4>

    <p>
      Using the list of ecosystem elements, as a class, or in small groups,
      students will make linear causal connections between two elements at a
      time. The Connection Circle and Feedback Loops worksheet will help them.
      It provides a sentence frame that will help the students decided if the
      relationship shows change in the same direction or opposite direction.
      Their connections can be recorded on the worksheet. Here are two examples
      from <em>Cactus Hotel</em>:
    </p>

    <ul>
      <li>
        If there are more saguaro blossoms, there will be more saguaro fruits
        (change in the same direction). Students will record this way:
        <div className="causal-link">
          <div className="causal-column">
            <h4>saguaro blossoms</h4>
          </div>

          <div className="causal-column">
            <img
              className="arrow"
              src="/images/resources/ecosystem-connection-circle/arrow.svg"
              alt="arrow"
            />

            <p className="link-label blue">S</p>
          </div>

          <div className="causal-column">
            <h4>saguaro fruits</h4>
          </div>
        </div>
      </li>

      <li>
        If there are more packrats, there will be less saguaro fruit (change in
        the opposite direction). Students will record this way:
        <div className="causal-link">
          <div className="causal-column">
            <h4>packrats</h4>
          </div>

          <div className="causal-column">
            <img
              className="arrow"
              src="/images/resources/ecosystem-connection-circle/arrow.svg"
              alt="arrow"
            />

            <p className="link-label red">O</p>
          </div>

          <div className="causal-column">
            <h4>saguaro fruits</h4>
          </div>
        </div>
      </li>
    </ul>

    <p>Draw as many causal connections as time permits.</p>

    <h4>Session 3:</h4>

    <ol>
      <li>
        Following the directions on the Connection Circle and Feedback Loops
        instructions, working as a whole class or in small groups, glue pictures
        of about 10 of the elements in a circle on a large piece of chart paper.
        With respect to <em>Cactus Hotel</em>, be sure that the saguaro is on
        the chart.
      </li>

      <li>
        Using the causal connections that the class or groups created in the
        previous session, begin adding arrows to the connection circle, using
        one color of marker for all the connections the students labeled as S,
        or change in the same direction, and another color of marker for all the
        connections the students labeled as O, or change in the opposite
        direction. Pay attention to the direction of the arrows, making sure
        that the tail starts at the cause of the change and the head points to
        the effect of that change. Encourage the students working in small,
        independent groups to draw their arrows first in pencil and then go over
        them with markers. This helps make sure the arrows are drawn in the
        correct color for Same and Opposite changes. This will help the students
        identify balancing and reinforcing loops later.
      </li>

      <li>
        <strong>Finding Causal Loops:</strong> Engage the students in searching
        for loops, following arrows in the direction they point while looking
        for those chains that end up where they started, creating a circle/loop.
        Discuss if the loops are balancing or reinforcing. Loops that are all
        drawn in the same color marker will be reinforcing loops. Chains that
        have both colors of marker might be balancing loops if there are an odd
        number of O’s. (There may be more than two elements in a loop.) An
        option is to include time delays if there would be a significant time
        passage between the cause and the effect. Time delays are marked by
        drawing two, short parallel lines across the arrow where a delay occurs.
      </li>

      <li>
        <strong>Finding a keystone species:</strong> Ask the students to look
        for elements in the connection circle that have lots of “tails” or
        arrows coming from those elements. These elements impact many other
        elements in the circle. Cover one of these elements that has lots of
        tails and follow the arrows to see what else would be affected. These
        kinds of elements are important keystones in the ecosystem. In{" "}
        <em>Cactus Hotel</em>, for example, many other desert animals are
        affected by the survival of the saguaro.
      </li>
    </ol>

    <h3>Debrief:</h3>

    <p>Some questions that the students might explore include:</p>

    <ul>
      <li>How does the saguaro impact the other elements of this system?</li>

      <li>
        What would happen if a fire were to wipe out saguaros in a given area?
      </li>

      <li>Which species would be affected first?</li>

      <li>
        Which species might survive the longest without saguaros in the
        ecosystem?
      </li>

      <li>
        What might be done to protect saguaros considering that fires caused by
        buffelgrass are the most serious threat to their survival today?
      </li>
    </ul>

    <p>
      These questions offer opportunities for discussions about keystone species
      and threats from invasive species like buffelgrass.
    </p>
  </div>
);
