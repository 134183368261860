import * as React from "react";

export const Lesson05 = () => (
  <div className="content">
    <ol>
      <li>
        Some people color code their arrows to show same and opposite
        relationships. For example:
        <p className="indent">
          A <span className="blue">blue</span>{" "}
          <img
            src="images/courses/tools/05-causal-links/arrow-b.svg"
            className="inline-arrow"
          />{" "}
          means the variables change in the same or additive direction.
        </p>
        <p className="indent">
          A <span className="red">red</span>{" "}
          <img
            src="images/courses/tools/05-causal-links/arrow-r.svg"
            className="inline-arrow"
          />{" "}
          means the variables change in an opposite or inverse direction.
        </p>
      </li>
      <li>
        Another strategy to “tell the story of the causal connection” is to
        write words or phrases along the arrow step that helps others know the
        story of the connection.
      </li>
      <li>
        As you will see in the causal connection circle map course, a good
        practice when identifying variables in a system that change over time is
        to use very basic behavior-over-time graphs in addition to the variable
        names.
      </li>
    </ol>
    <img
      src="images/courses/tools/05-causal-links/student-example.png"
      className="lesson-img med-img"
      alt="globe"
    />
    <p>
      This example comes from a group of students in a Drama class. This was
      part of a planning and self-assessment session when they were reflecting
      on their effort and skill development.
    </p>
    <p>
      Now you are ready to use your understanding of causal links to begin to
      build causal connection circle maps, which is the focus of the next
      course.
    </p>
  </div>
);
