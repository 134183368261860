import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-single"
        src="/images/resources/iceberg-template-organizational/iceberg-template-organizational.svg"
        alt="Iceberg Template Organizational"
      />
    </div>
  </div>
);
