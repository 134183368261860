import * as React from "react";
import Helmet from "react-helmet";
// import { ToggleText } from 'components/shared/toggle-text'
// import { ToggleJsx } from 'components/shared/toggle-jsx'
// import { EventRegisterForm } from 'components/events/EventRegisterForm'
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const OpenStudio = () => (
  <div>
    <Header />
    <div className="flow-container open-studio">
      <Helmet>
        <title>Open Studio Events - Thinking Tools Studio</title>
        <meta
          name="description"
          content="Free hour-long systems thinking learning sessions."
        />
        <meta
          property="og:title"
          content="Open Studio Events - Thinking Tools Studio"
        />
        <meta
          property="og:description"
          content="Free hour-long systems thinking learning sessions."
        />
        <meta
          property="og:image"
          content="https://thinkingtoolsstudio.org/images/site/open-studio.png"
        />
        <meta
          property="og:url"
          content="https://thinkingtoolsstudio.org/events/openstudio"
        />
        <meta
          name="twitter:image"
          content="https://thinkingtoolsstudio.org/images/site/open-studio.png"
        />
        <meta
          name="twitter:image:alt"
          content="Open Studio brought to you by the Waters Center for Systems Thinking"
        />
      </Helmet>

      <section className="photo-header open-studio-photo">
        <h1>Open Studio Webinars</h1>

        <img src="images/site/open-studio-icon.svg" />

        <h2>brought to you by the Waters Center for Systems Thinking</h2>
      </section>

      <section className="white stacked">
        <h3>
          Open Studio Webinar registration has moved to the Waters Center
          website. Please visit the{" "}
          <a href="https://waterscenterst.org/webinars">Webinars page</a> there
          to see upcoming events and registration.
        </h3>
        {/* <p>
          <strong>Open Studio webinars</strong> consist of hour-long Zoom sessions led by Waters Center staff and special guests. Each webinar will examine a different topic through a systems thinking lens. Open Studio webinars are interactive and include opportunities for dialogue among participants and facilitator(s). Be prepared to have your web camera on and to connect with other systems thinkers from around the world!
        </p>

        <p>
          Open Studio webinars will occur the first Thursday of every month at 9 a.m. PST and 3 p.m. PST unless otherwise stated.
        </p>

        <h3>
          Upcoming Webinars
        </h3>

        <div className='open-studio-container'>
          <h4 className='centered'>
            Thursday, August 5, 2021, 9 a.m. and 3 p.m. PDT<br />
            <em>Learning and Applying Systems Thinking: From Cartoons to Congress</em>
          </h4>

          <p>
            Hear from Morgan Kauffman, co-author of the recently revised book <em>Systems 1: An Introduction to Systems Thinking</em>, as he shares about what led to the revisions of the original <em>Systems 1</em>. He will share how cartoons in the text support learners of all ages build their understanding of systems thinking and how that understanding can be beneficial in shaping public policy in order to produce desired results.
          </p>
        </div>

        <p>
          <em>Check back later for additional events!</em>
        </p>
      </section>

      <section className='grey stacked'>

        <h1>
          Open Studio Registration
        </h1>

        <EventRegisterForm
          eventType='Open Studio'
        />

      </section>

      <section className='white stacked'>
        <h3>
          FAQ
        </h3>
        <ToggleText
          showText='Are Open Studio Sessions free? +'
          hideText='Are Open Studio Sessions free? -'
          text={[{ id: 1, text: 'Yes! We are happy to offer these sessions free of charge. Donations are always appreciated to help us continue our efforts to openly share our resources — no amount is too little.' }]}
        />

        <ToggleText
          showText='How do I sign up? +'
          hideText='How do I sign up? -'
          text={[{ id: 1, text: 'Registration will open for sessions on a rolling basis.  As a TTS user, you will receive an email when registration for sessions open. In addition, you can refer to this page and select the registration option in the dropdown menu above.' }]}
        />
        <p>
          Need help with events? <a href='mailto:events@waterscenterst.org'>Email us</a> at events@waterscenterst.org
        </p>

        <h3>
          Past Open Studio Topics
        </h3>

        <ul className='pasteventslist'>
          <li>
            How Loops Lead to Leverage
          </li>
          <li>
            Addressing Homelessness and Housing Instability through Systems Thinking
          </li>
          <li>
            Key Accumulations in the New Year–Stock-Flow Maps
          </li>
          <li>
            These Are a Few of Our Favorite Things…
          </li>
          <li>
            Taking Action: Using Systems Thinking to Tackle Complex Community Challenges
          </li>
          <li>
            What Can We Learn From Examining Change?
          </li>
          <li>
            Going Beneath the Surface
          </li>
          <li>
            Futures Thinking for More Equitable Education Systems
          </li>
          <li>
            Addressing the Unknown with the Ladder of Inference
          </li>
          <li>
            Engaging Youth in Community Issues Using Systems Thinking
          </li>
          <li>
            Building Bridges and Breaking Down Silos: How Iowa is building systems thinking capacity in Early Childhood Leaders
          </li>
          <li>
            Parents Edition: Systems Thinking for Students at Home
          </li>
          <li>
            Habits of a Systems Thinker: Still the One
          </li>
          <li>
            Live Chat!
          </li>
          <li>
            Systems Thinking Tools Applied to Current Events
          </li>
          <li>
            Accidental Adversaries in Challenging Times
          </li>
          <li>
            Systems Thinking Journey: Learning, Applying and Facilitating
          </li>
          <li>
            Literature Connects
          </li>
          <li>
            #Bettertogether Habits for High-performing Teams
          </li>
          <li>
            Fixing and Shifting without Blame
          </li>
          <li>
            Newly Refreshed Habits of a Systems Thinker
          </li>
        </ul> */}
      </section>
    </div>
    <Footer />
  </div>
);
