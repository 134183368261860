import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img med-img"
      src="/images/courses/habits/12-considers-issue/course-img.png"
      alt="Considers Issue Fully"
    />
    <p>
      Every day we are faced with decisions and choices, which oftentimes,
      deserve careful consideration. It is important to not jump to a
      conclusion, but rather to weigh options and the potential outcomes of our
      choices.
    </p>
    <p>
      By the questions we ask, the perspectives we consider, and the
      consequences of actions we foresee, systems thinkers naturally integrate a
      process for fully considering an issue. Taking time to think things
      through and to consider an issue fully increases the likelihood of a
      high-quality outcome.
    </p>
    <img
      className="lesson-img"
      src="/images/courses/habits/12-considers-issue/lesson-one-img2.png"
      alt="Stopwatch"
    />
    <p>
      “Fast and Lean” is a mantra some companies live by as a way to stay ahead
      of the competition. When adhering to this ideal, speed becomes an ally,
      and taking time to learn, develop and refine ideas becomes a source of
      angst. What happens when a quick conclusion is acted upon only to create
      another problem? Or what about a quick conclusion that results in a
      promising new idea being poorly implemented? Systems thinkers do not
      needlessly slow an organization down. Rather, they offer a perspective
      that encourages the balance between efficiency and effectiveness. In the
      following scenarios, consider the benefits and trade-offs of coming to a
      quick conclusion. As you read through the following scenarios, think about
      this question:
    </p>
    <h4>
      What potential pressures lead to conclusions that could have benefitted
      from additional consideration?
    </h4>
    <h3 className="clear">The Importance of Careful Hiring</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      Deb is head of Human Resources for a large urban hospital. Given a
      shortage of registered nurses, she feels tremendous pressure to jump to a
      quick conclusion when hiring new employees. The prospect of losing
      excellent talent to a competing hospital or having a position go unfilled
      seems to make it worth the risk of hiring the wrong person. However, in
      her haste, Deb hired a nurse without properly checking her references,
      resulting in a new employee whose unethical actions reflected very poorly
      on the hospital.
    </p>
    <p>
      Deb is now more mindful about the importance of carefully considering the
      qualifications and experience of each potential candidate. She also
      recognizes that the time spent thoroughly investigating the backgrounds of
      new employees saves valuable time in the long run. These carefully
      selected employees require less supervision and need less time to become
      oriented to their new positions.
    </p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      Unlike some of the time-bound restraints that Deb faced with a busy urban
      hospital, Sean has his own hiring story. He started a pool cleaning
      company as a sole proprietor working all day to service his growing
      clientele and taking care of the company operations at night. As his
      client base grew, Sean knew it was time to bring on additional personnel.
      He had the knowledge and personality that kept his customers very happy,
      so he was reluctant to hire someone to take care of his clients (even
      though he knew he needed to spend more time on the operations side of the
      business). After careful consideration, Sean found excellent employees who
      were eager to learn and committed to the same high-level of customer
      service that he had been able to provide. As the years passed, his company
      continued to grow. Sean established a process for hiring and training new
      employees. Even with an efficient process in place, each time he hired a
      new employee, Sean carefully considered the timing and implications of
      additional personnel. While a new employee allowed him to serve more
      clients, thereby generating more revenue, it also required the purchase of
      additional trucks and more equipment. It took some time to recoup those
      costs and see the benefit of additional employees. Sean successfully
      practiced the Habit of considering an issue fully in order to experience
      continuous, sustainable growth in his business.
    </p>
    <h3>Fully Considering the Purchase of a New Home</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      Remember Tom and Elena from previous Habits courses? They had to consider
      the seller’s perspective, so they were not too discouraged nor too
      demanding in response to their home inspection report. Long before they
      made an offer and ordered a home inspection, they faced the challenges
      associated with relocation. Elena was offered a great position at a new
      firm, but it required a major move. Once she accepted the position, the
      clock began ticking for them to find a home in the new city. However, they
      had to hold the tension and consider the issue fully in order to ensure
      their family’s needs were met and they could establish priorities for the
      relocation. If Tom and Elena failed to fully consider their decision, they
      could have bought a home that didn’t meet their needs, was in an
      inconvenient location, or was not worth the money they would spend.
      Sometimes homebuyers, like Tom and Elena, feel pressured to buy a home
      that is not suitable for them. This pressure can come from an overzealous
      realtor, a seller’s market where home offers quickly become bidding wars,
      or in this case, the pressure to get settled quickly in order to start a
      new job.
    </p>
    <h3>Thoughtful Parenting</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      Parenting also provides opportunities to apply this Habit. Joseph’s son
      asks to spend the night with a friend. Responding with little
      consideration, Joseph quickly retorts, “No.” Joseph is then subjected to
      an extended period of whining, complaining, bargaining and cajoling.
      Eventually, he realizes there was no real reason for his quick retort and
      changes his mind, granting his son’s request to spend the night. Not only
      has Joseph endured an unpleasant, potentially damaging exchange with his
      son, but he has also communicated that with enough effort the child will
      be able to get his way. How much better would it have been for Joseph to
      have gathered all the facts first, and then made a thoughtful, conscious
      decision that the request was reasonable and said, “Yes” from the
      beginning? Quick-paced schedules and our own patterns of behavior can lead
      us to come to a quick conclusion without considering an issue fully.
    </p>
    <h3>Improving Efficiency</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      Phillip has been chosen to lead a team challenged with improving the
      efficiency of a production. He consciously works to apply this Habit so
      that the team can produce a plan with just the right amount of time
      devoted to reaching an optimal solution. Too much deliberation can
      frustrate individuals causing the quality of his team’s thinking to
      decrease. On the other hand, he wants to assure that his team is not
      agreeing all the time just to avoid conflict, as this too could prevent
      them from arriving at the best solutions.
    </p>
    <p>
      When systems thinkers apply the proper balance between quick fixes and
      carefully considered solutions, they are more likely to arrive at actions
      that achieve desirable outcomes. There are occasions when decisions are
      time-dependent and have to be made quickly. It may be an issue of safety
      or a topic about which stakeholders have already debated the benefits and
      trade-offs repeatedly with little result. In those instances, a skillful
      leader makes a decision and then communicates it clearly.
    </p>
    <div className="quote">
      <p className="quote-text">
        “Embraces ambiguity: hold the tension of paradox and ambiguity, without
        trying to resolve it quickly.”
      </p>
      <p className="quote-author">
        {" "}
        — Linda Booth Sweeney, author of Thinking About Systems: 12 Habits of
        Mind, describes considering an issue fully.
      </p>
    </div>
  </div>
);
