import * as React from "react";

export const Lesson04 = () => (
  <div className="content">
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      When organizations make plans for scale-up, it is important to anticipate
      the balance between the interest in and high demand for product and
      service, with the expectation of consistently high-quality product and
      service. Planning for short and long-term scenarios should incorporate a
      wide variety of customer perspectives.
    </p>
    <p>
      Systems thinkers realize that individual views and understanding of the
      system are limited, and through no one’s fault, incomplete to the point of
      being inaccurate. Having structures in place to surface a wide variety of
      ideas and anticipated impact will maximize the benefits of the decisions
      made. For example, a collaborative design team made up of several
      stakeholder groups that assist with long-term planning is an effective way
      to weigh options and anticipate the impact of actions.
    </p>
    <p>
      When systems thinkers hold a long-term view and identify possible
      unintended consequences, they actively{" "}
      <a href="/courses/03-big-picture">seek a bigger picture</a> than what is
      currently viewed. They actively{" "}
      <a href="/courses/04-perspectives">
        change perspectives to increase understanding
      </a>
      . By extending the time frame and widening the aspect of the system that
      is impacted in the short run, systems thinkers broaden the boundaries of
      what they pay attention to in order to{" "}
      <a href="/courses/12-considers-issue">consider an issue fully</a>. This
      bigger picture helps inform actions. The bolded points above link to other
      related habits courses. Visit these courses to learn more and think about
      the connections between the Habits.
    </p>
    <h3>Reflection: Scaling Up</h3>
    <p>
      Keep in mind, scaling up when working toward a bigger picture is a process
      that can easily backfire. Anticipating the adverse impacts of scaling up
      too quickly or too slowly is essential. The following questions help
      organizational leaders interested in scaling up their business apply the
      systems thinking Habit that considers short, long-term and unintended
      consequences.
    </p>
    <ul>
      <li>
        What would our organization need and look like if our business was 4,
        10, or even 50 times larger than current measures?
      </li>
      <li>
        What are the consequences if we grow too slowly and lose market and
        momentum? What are the consequences if we grow too fast and can’t keep
        up with a growing demand?
      </li>
      <li>What are the indicators that growth is too slow or too fast?</li>
    </ul>
    <img
      className="lesson-img"
      src="/images/courses/habits/11-consequences/lesson-four-img.jpg"
      alt="Business Meeting"
    />
    <p>
      Systems thinkers recognize there are benefits and trade-offs to every
      decision. Because they understand the interdependent complexity of
      systems, it is impossible to do just “one thing” without influencing
      something else. Every action generates impact on the system, and some of
      those impacts do not immediately appear. For example, during the 1980’s,
      who could have predicted the impact of the modern Internet and the role
      technology plays today? Steve Jobs highlighted the long-term impact of
      technology by stating, “I think it has brought the world a lot closer
      together, and will continue to do that. There are downsides to everything;
      there are unintended consequences to everything. The most corrosive piece
      of technology that I’ve ever seen is called television — but then, again,
      television, at its best, is magnificent.”
    </p>
    <p>
      Parents and caregivers are faced with the long-term impact and possible
      unintended consequences of children interacting with technology. For
      example, what are the benefits and trade-offs of children using
      technology? How long is too long for children to be using computers and
      playing video games? What age is appropriate for a cell phone? How should
      children’s use of social media be monitored? As children get older, what
      is the balance between supervision and a teen’s privacy? These questions
      help parents and caregivers set boundaries, make decisions, and consider
      consequences of those actions.
    </p>
  </div>
);
