import * as React from "react";
import { courseList } from "components/courses";
import { getSessionAuth } from "components/shared/session";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";
import Helmet from "react-helmet";

export const ToolsCatalog = () => {
  const { user } = getSessionAuth();

  const getAuth = (user) => ({
    divClass: !user ? "is-disabled" : "",
    buttonClass: !user ? "block" : "none",
  });

  return (
    <div>
      <Header />
      <div className="main">
        <Helmet>
          <title>
            Tools of Systems Thinking Courses - Thinking Tools Studio
          </title>
          <meta
            name="description"
            content="Learn about and apply systems thinking tools."
          />
          <meta
            property="og:title"
            content="Tools of Systems Thinking Courses - Thinking Tools Studio"
          />
          <meta
            property="og:description"
            content="Learn about and apply systems thinking tools."
          />
          <meta
            property="og:image"
            content="https://thinkingtoolsstudio.org/images/site/tools.png"
          />
          <meta
            property="og:url"
            content="https://thinkingtoolsstudio.org/courses/tools"
          />
          <meta
            name="twitter:image"
            content="https://thinkingtoolsstudio.org/images/site/tools.png"
          />
          <meta name="twitter:image:alt" content="Tools of systems thinking" />
        </Helmet>

        <div className="content">
          <h1 className="course-home-title">
            Tools of Systems Thinking Courses
          </h1>
          <img className="course-home-img" src="/images/site/tools.png" />
          <p>
            Systems thinking utilizes a variety of tools that help make thinking
            visual. By using methods to take our thinking and reasoning outside
            of our minds and into a form that we can see and easily share with
            others, we can better understand current behaviors of a system and
            ways to operate within a system to create desired change.
          </p>
          <p>
            The tools and strategies of systems thinking include the ladder of
            inference, behavior-over-time graph, connection circle, stock-flow
            map, iceberg visual, and causal loop diagram. These visual tools are
            used to collect, analyze, synthesize and communicate insights about
            systems.
          </p>
          <p>
            You will find courses on all of these tools in the Thinking Tools
            Studio. Some tools have been split up into multiple courses to
            ensure learners are given the time needed to understand these
            multi-faceted, often complex tools and their underlying concepts.
          </p>
          <p>
            There are a total of nine tools courses in the Studio. We recommend
            you begin by taking the Habits of a Systems Thinker courses before
            starting the tools courses. The Habits and tools are very much
            interconnected, and you will notice many references to the tools in
            the Habits courses and vice versa.
          </p>
          <p>
            Like the Habits courses, you may take the tools courses in any order
            that you’d like, but we recommend going in the order listed below.
          </p>
          <p>
            There are plenty of opportunities for practice and reflection in
            each tools course. Take your time with these practice exercises and
            share the visuals you create with teammates. The ability to talk
            through your creations will propel your understanding of systems
            thinking tools and strategies!
          </p>
          <div className="course-cards">
            {/* For selected group, display list of courses */}
            {courseList
              .filter((c) => c.group === "tools")
              .map((c) => (
                <div key={c.path} className="card-content">
                  <img className="card-img" src={c.image} alt="Course Image" />
                  <div className="course-info">
                    <h2>
                      <a
                        className={getAuth(user).divClass}
                        href={`/courses/${c.path}`}
                      >
                        {c.name}
                      </a>
                    </h2>
                    <p className="card-summary">{c.summary}</p>
                    <p className="card-time">
                      <b>Estimated Time:</b> {c.time}
                    </p>
                    <a href="https://waterscenterst.org/login">
                      <button
                        style={{ display: getAuth(user).buttonClass }}
                        className="auth-button"
                      >
                        Log in or Sign Up to View This Content
                      </button>
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
