import * as React from "react";
import Helmet from "react-helmet";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const About = () => (
  <div>
    <Header />
    <div className="flow-container">
      <Helmet>
        <title>About the Waters Center - Thinking Tools Studio</title>
        <meta
          name="description"
          content="Learn about the organization that created the Thinking Tools Studio"
        />
        <meta
          property="og:title"
          content="About the Waters Center - Thinking Tools Studio"
        />
        <meta
          property="og:description"
          content="Learn about the organization that created the Thinking Tools Studio"
        />
        <meta
          property="og:image"
          content="https://thinkingtoolsstudio.org/images/site/wc-logo-color.svg"
        />
        <meta
          property="og:url"
          content="https://thinkingtoolsstudio.org/about"
        />
        <meta
          name="twitter:image"
          content="https://thinkingtoolsstudio.org/images/site/wc-logo-color.svg"
        />
        <meta
          name="twitter:image:alt"
          content="About the Waters Center: since 1989"
        />
      </Helmet>
      <section className="photo-header about">
        <h1>About the Waters Center</h1>
        <img src="images/site/about-icon.svg" />
        <h2>since 1989</h2>
      </section>
      <section className="white">
        <p>
          For over 30 years, formerly through the Waters Foundation and more
          recently as the Waters Center for Systems Thinking, we have helped
          create positive change and improved performance through systems
          thinking. We are all about delivering benefits and working with others
          to help them do the same – whether it's in a classroom, school,
          district, business or community, systems thinking helps people of all
          ages and walks of life see beyond the heart of a problem to find
          equitable and essential solutions.
        </p>

        <p>
          Our work is recognized worldwide for making systems thinking
          accessible and practical, both for children in classrooms as well as
          executives in boardrooms. We've worked across the U.S. and around the
          globe to develop systems thinkers who will shape our current and
          future world by understanding the complexities of the systems we live
          and work in, and by identifying leverage actions to achieve desired
          results.
        </p>

        <p>
          In addition to the Thinking Tools Studio, we offer a variety of
          services including customized Systems Thinking Adoption and
          Integration Plans (for schools, districts, organizations, etc.),
          systems thinking technical assistance (introductory and customizable),
          professional development workshops, multi-day systems thinking events,
          speaking engagements, and more.
        </p>

        <p>
          For more details on what we offer,{" "}
          <a
            href="https://waterscenterst.org/benefits/our-impact/"
            target="blank"
            rel="noopener"
          >
            click here
          </a>
          .
        </p>

        <p>
          Be sure to{" "}
          <a
            href="https://waterscenterst.org/mail-list-signup/"
            target="blank"
            rel="noopener"
          >
            opt in
          </a>{" "}
          on our website to stay in the loop about Waters Center news and
          events.
        </p>

        <img src="images/site/wc-logo-color.svg" />
      </section>
    </div>
    <Footer />
  </div>
);
