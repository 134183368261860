import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <h3>Dieting</h3>

    <p>
      Daisy decides it’s time to step into a new and healthy lifestyle. She is
      determined to lose weight and begins to carefully research and plan a
      change to her eating habits. Her hard work pays off and Daisy sees a
      steady drop in weight each week. After a while though, the scale is not
      moving and her weight has plateaued! Daisy searches the internet for
      answers and discovers this is a fairly typical experience due to the
      change in her metabolism – she is not burning calories at the same rate as
      she did when she began dieting.
    </p>

    <h3>Blockbuster</h3>

    <p>
      Blockbuster was the first “big box” video rental store and began an
      extremely successful expansion of its number of stores in the United
      States. Profits increased and Blockbuster dominated the market. As
      Blockbuster continued to focus on “brick & mortar” stores they failed to
      consider the impact of changes in the digital market, especially
      innovations in online streaming services. Blockbuster experienced a
      downturn in profits and bumped up efforts to bring customers into their
      stores. Unfortunately, profits continued to decrease and Blockbuster began
      to close stores and eventually went out of business.
    </p>

    <h3>Student Achievement</h3>

    <p>
      An elementary school needed to address low student achievement scores in
      math. The staff worked together to analyze and adopt a research-based math
      program that other schools used to increase student learning. The program
      was a success and student math achievement improved – raising achievement
      scores. But, over the course of three years, scores began to plateau and,
      in some grades, even decline. Teachers reviewed their application of the
      program and stressed the need to strictly adhere to the program. The
      principal followed the same process. Everyone was on edge causing a tense
      working and learning environment.
    </p>
  </div>
);
