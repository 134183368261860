module.exports = [
  { id: "h-bp", value: "Big Picture" },
  { id: "h-cot", value: "Change Over Time" },
  { id: "h-cp", value: "Changes Perspectives" },
  { id: "h-cn", value: "Circular Nature" },
  { id: "h-mm", value: "Mental Models" },
  { id: "h-cif", value: "Considers an Issue Fully" },
  { id: "h-la", value: "Leverage Actions" },
  { id: "h-sgb", value: "Structure Generates Behavior" },
  { id: "h-cc", value: "Considers Consequences" },
  { id: "h-sa", value: "Successive Approximation" },
  { id: "h-sta", value: "Surfaces & Tests Assumptions" },
  { id: "h-td", value: "Time Delays" },
  { id: "h-a", value: "Accumulations" },
  { id: "h-mc", value: "Meaningful Connections" },
];
