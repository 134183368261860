import * as React from "react";
import { Field } from "redux-form";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      Use the stock-flow map you built in Practice Exercise #2 to answer each of
      the following questions in the boxes provided. Don’t hesitate to revise
      your map as you reflect.
    </p>
    <h4>What is the key stock(s) and flow(s) in my map?</h4>
    <Field
      name="key"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Did I use descriptors (for example: better, worse, more, less, etc.) when
      labeling my converters? If yes, revise your labels now.
    </h4>
    <Field
      name="descriptors"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How do converters affect how much is added to or how much is subtracted
      from the stock (accumulation)?
    </h4>
    <Field
      name="converters"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How do converters directly affect the flow(s) or do they connect to
      something else first? Describe the connections.
    </h4>
    <Field
      name="flow"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Look for feedback by asking, “Does the stock affect other parts (flows or
      converters) of the map?” Describe the feedback relationships.
    </h4>
    <Field
      name="feedback"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Is my stock-flow map visually clear? If not, revise your map so that it
      could be better understood by someone else.
    </h4>
    <Field
      name="map"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Stock-flow maps are a work in progress. There is seldom a final version.
      To ensure your stock-flow map is visually clear, share it with another
      person and make revisions based on their questions.
    </h4>
  </div>
);
