import * as React from "react";
import { FileUpload } from "components/shared/file-upload";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Identify a challenge in your life that you would like to address. Your
      challenge could be something you would like to improve, design or learn.
      For example: “I would like to improve my ability to balance work and
      family expectations;” “I would like to design lessons that would motivate
      and engage all of my students;” “I would like to be able to improve my
      golf game and break 100 for 18 holes.”
    </p>
    <h4>Describe your challenge.</h4>
    <Field
      name="your-challenge"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What is one changing element that you could track over time?</h4>
    <Field
      name="one-changing"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What range will help you measure the change (e.g. high, medium, low; hard,
      medium, easy; many, some, few; or numerical tracking, etc.)?
    </h4>
    <Field
      name="help-you"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      On a piece of paper, draw the graph below to develop a behavior-over-time
      graph to help you track your progress. Be sure to label your y-axis
      variable (what is changing?), and your x-axis variable with the unit of
      time.
    </p>
    <img
      className="lesson-img left"
      src="/images/courses/habits/15-successive-approximation/botg-template.png"
      alt="BOTG Template"
    />
    <Field
      name="graph"
      component={FileUpload}
      image
      instructions="Take a photo of your graph and upload it below. Be sure to refer to it to keep track of your progress."
    />
    <h4>
      What indicators will you expect to see as you look for progress? Or, quite
      simply, what will progress look like?
    </h4>
    <Field
      name="indicators"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Describe how you schedule time to pause, assess the effects of your
      current plan, and take necessary action, which may include modifications?
    </h4>
    <Field
      name="time-pause"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      When considering a challenge and desire to change, what other systems
      thinking Habits would be important to apply to your situation?
    </h4>
    <Field
      name="other-systems"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
