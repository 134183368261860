import * as React from "react";
import { ToggleImage } from "components/shared/toggle-image";
import { FileUpload } from "components/shared/file-upload";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      On a blank piece of paper, draw a stock-flow map to represent a simple
      interest-earning bank account.
    </p>
    <ol>
      <li>Draw a stock (rectangle) and add a label.</li>
      <img
        className="stock-template"
        src="/images/courses/tools/03-stock-flow/stock-template.svg"
        alt="Stock Template"
      />
      <li>Draw and label the flows.</li>
      <img
        className="flow-template"
        src="/images/courses/tools/03-stock-flow/flow-template.svg"
        alt="Flow Template"
      />
      <li>
        Identify the converters (variables that cause the stock to increase or
        decrease). Add as many or few as you need in order to show your desired
        system. Draw connectors to show the relationships between the variables.
      </li>
      <img
        className="converter-template"
        src="/images/courses/tools/03-stock-flow/converter-template.svg"
        alt="Converter Template"
      />
      <li>
        Ask yourself, does the amount of money in the bank affect anything else
        shown on the map? If so, add a connector to show that relationship.
      </li>
    </ol>
    <p>
      Click the button below to see an example of a simple interest stock-flow
      map. Remember, there are multiple ways to represent this system, but this
      is one example you can use to help you reflect on your own stock-flow map.
    </p>
    <ToggleImage
      className="stock-flow-example"
      src="/images/courses/tools/03-stock-flow/stock-flow-example.svg"
      alt="Stock Flow Example"
      showText="Show Example"
      hideText="Hide Example"
    />
    <Field
      name="newMap"
      component={FileUpload}
      image
      instructions="Upload your completed stock-flow map below."
    />
  </div>
);
