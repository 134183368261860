import React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="main">
    <div className="content">
      <h3>Practice the Habit</h3>
      <p>
        This exercise works best when done with others in your system. A group
        of 3-5 people is a good size for your first practice. Your small group
        can be a work team, a department, a community system or any small group
        of people that have a system of interest in common.
      </p>
      <p>
        <a
          href="https://ttsfilestore.blob.core.windows.net/ttsfiles/my-org-as-a-system.pdf"
          target="blank"
          rel="noopener"
          download
        >
          Click here
        </a>{" "}
        to download the handout.
      </p>
      <p>Print copies of the handout for each member of your group.</p>
      <p>Introduce the exercise to your group by saying,</p>
      <p>
        “This exercise will help us view the work we do as a system and help us
        better understand our priorities and perspectives. Use the handout to
        express your view of our system (use name of system if applicable).
        After each of you responds to the exercise components, we will share our
        responses and look for similarities and differences in how we view our
        system. This exercise will help us share the various ways we view our
        group’s work.”
      </p>
      <p>
        “Before we begin, let’s look at an example of a system we all have in
        common. Think about a school as a system.”
      </p>
      <h4>Sample Exercise</h4>
      <div className="sample-exercise">
        <img
          className="system-img"
          src="/images/courses/habits/01-introduction/system.jpg"
          alt="My Org As A System"
        />
        <h4>Name of your system: My School</h4>
        <p>
          (Be prepared to share your responses with others in your small group.)
        </p>
        <h4>Name 4 important elements/parts that are in your system.</h4>
        <ol>
          <li>Our Students</li>
          <li>Our Teachers</li>
          <li>Our Facilities</li>
          <li>Our Curriculum</li>
        </ol>
        <h4>What are 3 other systems your system interacts with?</h4>
        <ol>
          <li>Parents</li>
          <li>Funding sources</li>
          <li>Government standards, policies and evaluations</li>
        </ol>
        <h4>What are 2 changes your system is currently experiencing?</h4>
        <ol>
          <li>We have a new principal</li>
          <li>Our enrollment is decreasing</li>
        </ol>
        <h4>Describe 1 goal or purpose of your system.</h4>
        <ol>
          <li>
            We strive to prepare all students by building their skills and
            capabilities to ensure their future success.
          </li>
        </ol>
      </div>
      <h4>Complete the following online handout.</h4>
      <div className="sample-exercise">
        <img
          className="system-img"
          src="/images/courses/habits/01-introduction/system.jpg"
          alt="My Org As A System"
        />
        <h4>
          Name of your system:
          <Field name="name" component="input" className="blank" />
        </h4>
        <p>
          (Be prepared to share your responses with others in your small group.)
        </p>
        <h4>Name 4 important elements/parts that are in your system.</h4>
        <ol>
          <li>
            <Field name="element-one" component="input" className="blank" />
          </li>
          <li>
            <Field name="element-two" component="input" className="blank" />
          </li>
          <li>
            <Field name="element-three" component="input" className="blank" />
          </li>
          <li>
            <Field name="element-four" component="input" className="blank" />
          </li>
        </ol>
        <h4>What are 3 other systems your system interacts with?</h4>
        <ol>
          <li>
            <Field name="system-one" component="input" className="blank" />
          </li>
          <li>
            <Field name="system-two" component="input" className="blank" />
          </li>
          <li>
            <Field name="system-three" component="input" className="blank" />
          </li>
        </ol>
        <h4>What are 2 changes your system is currently experiencing?</h4>
        <ol>
          <li>
            <Field name="change-one" component="input" className="blank" />
          </li>
          <li>
            <Field name="change-two" component="input" className="blank" />
          </li>
        </ol>
        <h4>Describe 1 goal or purpose of your system.</h4>
        <ol>
          <li>
            <Field name="change-three" component="input" className="blank" />
          </li>
        </ol>
      </div>
      <h3>
        After you complete the exercise with your small group, use the questions
        below to reflect on your experience with this exercise.
      </h3>
      <h4>
        What were some similarities and differences in people’s responses as
        they described your organization as a system?
      </h4>
      <Field
        name="described"
        component="textarea"
        className="reflection-input-large"
        placeholder="Enter your response here."
      />
      <h4>
        During the debrief, what insights surfaced as people shared their
        viewpoints?
      </h4>
      <Field
        name="debrief"
        component="textarea"
        className="reflection-input-large"
        placeholder="Enter your response here."
      />
      <h4>
        What possible next steps would you take as a result of this exercise?
      </h4>
      <Field
        name="steps"
        component="textarea"
        className="reflection-input-large"
        placeholder="Enter your response here."
      />
    </div>
  </div>
);
