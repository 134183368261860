import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="large-img"
        src="/images/resources/habits-single-page/habits-single-page-2020.png"
        alt="Habits: Single Page Version"
      />
    </div>
  </div>
);
