import React, { useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

export function ToggleText(props) {
  const [isVisible, setVisible] = useState(false);
  const { showText, hideText, text, ...otherProps } = props;

  return (
    <div className={cn("toggle-text", isVisible && "visible")}>
      <button
        type="button"
        className="toggle-button"
        onClick={() => setVisible(!isVisible)}
      >
        {isVisible ? hideText : showText}
      </button>
      {text.map((l) => (
        <p key={l.id} {...otherProps}>
          {l.text}
        </p>
      ))}
    </div>
  );
}
ToggleText.propTypes = {
  showText: PropTypes.string,
  hideText: PropTypes.string,
  text: PropTypes.array,
};
