import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>

    <p>
      Consider the following statements that are often voiced during balancing
      scenarios.
    </p>

    <p>Describe a situation familiar to you that matches each statement.</p>

    <h4>We are experiencing some subtle ups and downs.</h4>

    <Field
      name="up-down"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>Things are beginning to settle down.</h4>

    <Field
      name="settle"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>We seem to be achieving balance and stability.</h4>

    <Field
      name="balance"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>We are close to closing the gap.</h4>

    <Field
      name="closing"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>There seems to be something holding us back and keeping us stuck.</h4>

    <Field
      name="stuck"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
