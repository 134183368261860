import * as React from "react";

export const Lesson04 = () => (
  <div className="content">
    <p>
      Causal loop stories are all around us. In addition to reinforcing loops
      that represent escalating growth, which you saw in the kindness and
      playground examples, there are also balancing loops, as shown in a hunger
      and eating example below.
    </p>
    <p>
      Sometimes a teeter-totter or balance scale is used as a symbol for
      balancing feedback.
    </p>
    <h3>Balancing Hunger</h3>
    <img
      className="lesson-img"
      src="/images/courses/habits/09-circular-nature/apple-loop.png"
      alt="Hunger Causal Loop"
    />
    <p>
      A simple day-to-day example is to think about your changing level of
      hunger throughout the day. Hunger causes you to want to eat. Once you've
      eaten, that causes your hunger levels to decrease. When hunger levels
      decrease, there is a slow-down in eating. Best systems thinking practice
      is to talk through the causal links in the loop at least two times. Begin
      with one variable and explain what occurs when that variable changes.
    </p>
    <p>
      For example, first time around the loop: As hunger goes up, eating goes
      up. As eating goes up, hunger decreases. Second time around the loop: As
      hunger decreases, eating subsides.
    </p>
    <p>
      As eating subsides, over time, hunger eventually increases. Notice the “B”
      in the center of the loop to the right. A system can elicit balancing
      behavior with a sense of equilibrium or balance becomes the goal-seeking
      dynamic state.
    </p>
    <p>
      To help you identify balancing feedback in your life, consider the typical
      language that one might hear when balancing dynamics are at play.
    </p>
    <div className="quote">
      <p className="quote-text">
        “We are experiencing some subtle ups and downs.”
      </p>
    </div>
    <div className="quote">
      <p className="quote-text">
        “I can sense that things are beginning to settle down.”
      </p>
    </div>
    <div className="quote">
      <p className="quote-text">
        “We seem to be achieving balance and stability.”
      </p>
    </div>
    <div className="quote">
      <p className="quote-text">
        “The system is reaching our goal and closing the gap.”
      </p>
    </div>
    <p>
      Nature and the environment are great places to look for balancing
      feedback. Number of predators and prey, the quality of habitat, and food
      availability are related variables that strive to maintain balanced
      populations in nature. Other examples include supply and demand feedback
      relationships in economics and the balancing mechanisms in the human body
      (systems like the endocrine and cardio vascular systems). Can you think of
      other scenarios where balancing feedback is central to what is going on in
      a system?
    </p>
  </div>
);
