import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Archetype</h3>

    <p>
      When have you encountered a “fixes that backfire” story? Use this
      archetype to reflect on that experience.
    </p>

    <p>
      Feel free to{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/fixes-that-backfire-blank-so.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        download a blank “fixes that backfire” template
      </a>{" "}
      and use it to tell your story as you respond to the questions.
    </p>

    <h4>1. Identify the problem symptom.</h4>

    <div className="textbox-with-img">
      <img
        className="small"
        src="images/courses/archetypes/01-fixes-that-backfire/symptom.svg"
        alt="Problem Symptom"
      />

      <div>
        <Field
          name="symptom"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
      </div>
    </div>

    <h4>
      2. Determine one or more fixes that were applied. What was the effect of
      the fix on the problem symptom?
    </h4>

    <div className="textbox-with-img">
      <img
        src="images/courses/archetypes/01-fixes-that-backfire/fix-loop.svg"
        alt="Fix Loop"
      />

      <div>
        <Field
          name="fix"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
      </div>
    </div>

    <h4>
      3. What were the consequences of those solutions? Did they make the
      problem better or worse?
    </h4>

    <div className="textbox-with-img">
      <img
        src="images/courses/archetypes/01-fixes-that-backfire/fixes-that-backfire-full.svg"
        alt="Unintended Consequences"
      />

      <div>
        <Field
          name="consequences"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
      </div>
    </div>

    <h3>Reflecting on Your Archetypical Story</h3>

    <h4>What is changing over time?</h4>

    <Field
      name="change-over-time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      Can you identify elements of balancing and reinforcing feedback as you
      tell the story?
    </h4>

    <Field
      name="identify-elements"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      Having applied this archetype to your particular problem symptom, how
      might you avoid quick-fix solutions and undesirable, unintended
      consequences in the future?
    </h4>

    <Field
      name="avoid-quick-fix"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      What new insights were gained about your situation by applying this
      archetype?
    </h4>

    <Field
      name="new-insights"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
