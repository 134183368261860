import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <p>
      Let’s start with a basic “goal and gap” balancing loop where coaching
      support is the focus. To help you make this scenario relevant to your
      setting, you could change the coaching reference to other types of
      support. For example, you might be interested in a customer service center
      or a new employee induction program. The great thing about good causal
      maps is that with slight modification, they can apply to a multitude of
      different settings. A Habit that helps us practice this ability to
      transfer our understanding of the dynamics and structure of one system to
      another is{" "}
      <strong>
        <a href="/courses/02-connections">
          Makes meaningful connections within and between systems
        </a>
      </strong>
      .
    </p>

    <img
      className="med-img lesson-img block"
      src="/images/courses/tools/09-bringing-loops-together/coaching1.svg"
      alt="Coaching loop"
    />

    <p>
      <strong>*</strong>This gap changes over time. When the # of people needing
      coaching continues to exceed the coaching availability, the gap will grow.
      Much like the supply and demand concept, the gap defines the difference
      between the supply of coaches and the demand for coaching support.
    </p>

    <p>
      It is a good practice to look for outlying arrow tails in maps that are
      not part of loops and try to incorporate them into loops. This suggestion
      is not critical practice, but it is good to explore the possibility of
      incorporating a single arrow or arrow string into a loop. In the above
      example, <strong>Coaching availability</strong> is an arrow tail. To
      attempt to close this loop, ask yourself this question:
    </p>

    <p>
      <em>
        What in the map causes <strong>Coaching availability</strong> to change?
        Or, are there other variables in the system that would cause coaching
        availability to change? If so, can we add them?
      </em>
    </p>

    <p>Here is one possible way to respond to these questions:</p>

    <img
      className="med-img lesson-img block"
      src="/images/courses/tools/09-bringing-loops-together/coaching2.svg"
      alt="Coaching loop"
    />

    <p>
      The arrow indicates that when recruitment and funding increase, coaching
      hires will increase, which will impact coaching availability. This loop is
      also a balancing loop that helps systems monitor support staff based on
      need.
    </p>
  </div>
);
