import * as React from "react";
import { Field } from "redux-form";

export const Exercise04 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Make a plan to introduce mental models to a small group or team.
      Suggestions to get you started:
    </p>
    <ul>
      <li>
        Offer a definition based on what you’ve learned in this lesson, use the
        Habit card, and/or provide examples.
      </li>
      <li>
        Use the iceberg to explore an issue and surface existing mental models.
      </li>
      <li>
        Facilitate a serious play exercise and debrief to help people become
        aware of the influence mental models have on their interpretations and
        day-to-day interactions.
      </li>
    </ul>
    <h4>What did you do? How did it go?</h4>
    <Field
      name="do-go"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What did you learn?</h4>
    <Field
      name="learn"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What would you do differently next time?</h4>
    <Field
      name="nest-time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
