import * as React from "react";
import { ToggleText } from "components/shared/toggle-text";

export const Lesson04 = () => (
  <div className="content">
    <p>
      The process of reviewing and discussing your stock-flow map is potentially
      far more valuable than the map itself.
    </p>
    <p>
      George Box, a British statistician who worked in the area of quality
      control said, “All models are wrong, but some models are useful.”
    </p>
    <p>
      It is highly unlikely that any attempt to model a system will ever be able
      to predict the exact behavior of that system. However, taking the time to
      draw out a map will likely result in additional understanding of the
      system and a better prediction of how that system will behave.
    </p>
    <p>
      Once you create your stock-flow map, what are some questions to ask
      yourself that could improve your understanding of your system?
    </p>
    <p>
      As you consider the following questions, you will need to reference this
      stock-flow map.
    </p>
    <img
      className="pollution-stock-flow"
      src="/images/courses/tools/03-stock-flow/pollution-stock-flow.svg"
      alt="Pollution Stock Flow Map"
    />
    <h4>What are the key stock(s) and flow(s) in the system?</h4>

    <ToggleText
      style={{ zIndex: 2 }}
      showText="Show Answer"
      hideText="Hide Answer"
      text={[
        { id: 1, text: "Stock: Pollution, Flows: Increasing and decreasing" },
      ]}
    />
    <h4>How can we name a variable (converter) to avoid qualifiers?</h4>

    <ToggleText
      style={{ zIndex: 1 }}
      showText="Show Answer"
      hideText="Hide Answer"
      text={[
        {
          id: 1,
          text: "This is a key element in understanding a stock-flow map. Chemical emissions is a variable that affects the rate at which pollution in the environment increases. Therefore, it is incorrect to label that converter “more chemical emissions.",
        },
      ]}
    />

    <h4>
      Does a converter affect how much is added to or how much is subtracted
      from the stock (accumulation)?
    </h4>

    <ToggleText
      style={{ zIndex: 0 }}
      showText="Show Answer"
      hideText="Hide Answer"
      text={[
        {
          id: 1,
          text: "An inflow adds and an outflow takes away or decreases the accumulation.  A common misconception is to think of inflow and outflow as positive or negative.  Converters connected to the inflow cause an increase in the stock. Converters connected to the outflow are variables that cause the accumulation in the stock to decrease.",
        },
      ]}
    />

    <p>
      Chemical emissions increase the pollution in the environment, while
      pollution cleanup reduces the accumulation of environmental pollution.
      Distinguishing between converters that most profoundly impact the inflow
      and which impact the outflow may seem very clear as you begin drawing your
      stock-flow map; however, that distinction can become blurry. The inflow
      increases the accumulation and the outflow decreases the accumulation,
      whether or not that change is perceived as good or bad.
    </p>
    <p>
      Vigilantly adhering to these two guidelines: Not using qualifiers and
      carefully considering whether a variable affects the inflow or outflow,
      will help avoid the trap of creating a stock-flow map that more closely
      resembles a list of opposites.
    </p>
    <p>
      Does the converter directly affect the flow or does it connect to
      something else first? In the environmental pollution example, pollution
      prevention controls most directly affect chemical emissions, which in turn
      impacts the inflow to pollution in the environment.
    </p>
    <p>
      Look for feedback by asking, “Does the stock affect other parts (flows or
      converters) of the map?” In this example, the amount of pollution in the
      environment (the stock) affects the need for pollution prevention controls
      and the need for pollution clean-up.
    </p>
    <p>
      It’s also important to draw the parts and connections in a way that is
      visually clear. The goal of a stock-flow map is to create a greater
      understanding of the system. Including too many elements and having too
      many lines that cross over each other in multiple places can be confusing.
      If your map feels like it is spiraling out of control, consider shrinking
      the boundaries of your system. A rapidly burgeoning map may also be an
      indicator that additional stocks are needed. Lesson 5 shows how to build
      stock-flow maps with multiple stocks.
    </p>
    {/* <img
    className="workplace-stock-flow"
    src="/images/courses/tools/03-stock-flow/workplace-stock-flow.svg"
    alt="Workplace Stock Flow Map"/> */}
    <div className="quote">
      <p className="quote-text">
        The process of stock-flow mapping does not assure you find the right
        answer, but rather increases the likelihood that you will think through
        the various scenarios of how the variable converters relate to the
        system of interest.
      </p>
    </div>
    {/* <p>For additional information on feedback, visit the <a href="">Feedback Course</a> in the Habits section.</p> */}
  </div>
);
