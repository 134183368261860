import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <p>
      Structures can impact the attainment of goals. Personal habits can be
      influenced by structures that either support or serve as barriers to
      personal goals. Think about the structures you pay attention to if you
      want to lose a few pounds. Diet and exercise are obvious structures that
      can lead to the attainment of your goal. Yet, sometimes less tangible
      structures like your daily routine, mood or attitude can influence the way
      you eat or your motivation to work out.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-well-being.svg"
      alt="Well-Being Example"
    />
    <p>
      For example, Raj has a desire to eat better; however, his daily stop at
      the donut shop is not likely to help him achieve his goal of healthier
      eating. The donut shop is currently on his way to work – its physical
      placement is a structure that encourages his daily stop. Psychologists
      recognize that to change human behavior, an individual, in this case Raj,
      has to want to make the change. His motivation and mindset will support or
      interfere with his actions to improve his eating habits. Raj needs to
      replace his current habit with a new behavior. So instead of driving by
      the donut shop each day, he can begin his day with a quick cardio workout
      before heading to work, or merely change his route to work and find a new
      place to stop that offers healthier eating choices. Adjusting the
      structure will help Raj develop a different habit and significantly
      influence his chance of successfully changing his behavior.
    </p>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      Systems’ structures can also focus on relational connections. The quality
      of professional relationships at work can certainly influence productivity
      and job satisfaction. The current tendency to work from home can influence
      workplace dynamics. Many organizations depend on co-workers using
      electronic means of communication and meeting. Whether you are connecting
      with people in face-to-face gatherings or maintaining interactions through
      phone and electronic conferencing, those varying structures can influence
      outcomes and the behavior of the team. It is important to pay attention to
      the structures that influence the quality of human interactions that
      develop successful working relationships.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      Structures that influence relationships can also be personal. For example,
      relationships among family members can produce a variety of dynamics.
      Adult family members who have strayed from siblings because of geography
      or varied interests may not talk with one another for months. Yet, those
      who live close by or those who work or socialize together may be in
      regular contact. Family traditions may also influence how different
      families build and maintain relationships. Regular Sunday dinners or
      summer reunions at the lake are predictable structures that contribute to
      families staying connected with one another. When physical gatherings or
      reunions are not possible, social media platforms, texting and email
      messaging are structures that help those separated by geography stay
      connected and current.
    </p>
  </div>
);
