import * as React from "react";
import { FileUpload } from "components/shared/file-upload";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      <b>System definition:</b> A system has two or more elements that interact
      to make a whole. Systems are everywhere. Some examples include cells,
      families, schools and office teams.
    </p>
    <h4>Identify a system of interest to you.</h4>
    <Field
      name="interest"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>List the parts of that system. Be as specific as you can.</h4>
    <Field
      name="parts"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What is the purpose or goal of your system of interest?</h4>
    <Field
      name="goal"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How do things change over time in the system?</h4>
    <Field
      name="things"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <Field
      name="connectionCircle"
      component={FileUpload}
      image
      instructions="Build a connection circle, like the one about the
      farmer and his plants. Use arrows to show how the parts of your system connect.
      Take a photo and upload it below."
    />
    <h4>What other systems are similar? How are they similar?</h4>
    <Field
      name="similar"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What new insights did you gain about your system of interest?</h4>
    <Field
      name="insights"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
