import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <p>
      In addition to the consideration of short and long-term consequences of
      the actions you take, do you also consider the unintended consequences of
      your decisions? Carefully thinking through possible consequences of
      decisions is a day-to-day habit of systems thinkers.
    </p>
    <p>
      The importance of reflection and the value of taking a moment to think
      through unintended consequences of decisions will save you time in the
      long-run.
    </p>
    <div className="quote">
      <p className="quote-text">
        “Without reflection, we go blindly on our way, creating more unintended
        consequences, and failing to achieve anything useful.”
      </p>
      <p className="quote-author"> — Margaret Wheatley, author</p>
    </div>
    <p>
      The following questions will help you during those reflective moments
      before you follow through with an important decision.
    </p>
    <h4>Who will be impacted by this action/decision?</h4>
    <h4>
      What possible results, both desirable and undesirable, will we see from
      this decision?
    </h4>
    <h4>
      Will others view the consequences of this decision differently, especially
      compared to my view of the results?
    </h4>
    <p>
      Unintended consequences are best viewed from both a short-term and
      long-term view. Jumping to a quick conclusion to save time can be costly
      when unintended consequences are not considered. Read through the
      following story and consider the system archetype, “Fixes that Backfire”
      used to illustrate the scenario.
    </p>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-family.svg"
      alt="Family Example"
    />
    <p>
      A young mother insists that her two children clean their room before going
      outside to play. Weighing the drudgery of cleaning and the excitement of
      meeting their friends outside, the children make a plan. Without the
      mother watching, the children quickly sweep clothes, toys and books under
      the bed, creating an open space that appears orderly and clean. Seemingly
      proud of their accomplishment, they call on their mother for an
      inspection.
    </p>
    <img
      className="lesson-img"
      src="/images/courses/habits/11-consequences/lesson-two-img.png"
      alt="Quick Fix"
    />
    <p>
      With an initial nod of approval, combined with skepticism based on the
      record-setting cleaning time, the mother allows her eager children to go
      outside to play. It doesn’t take long for the mother to realize that the
      closet and shelves are mostly bare. A quick check under the beds reveals
      the foiled plot. The children now have consequences much costlier than the
      time it would have taken them to clean their room properly in the first
      place. They have to endure the added consequences of not being allowed
      outside to play, in addition to extra cleaning duties for a week. This is
      an example of the systems archetype “Fixes that Backfire.”
    </p>
    <p>
      The children’s problem was that the desire to play outside is disrupted
      because of time needed for cleaning their room. The quick fix was shoving
      everything under the beds. What they had not considered was the unintended
      consequences of an angry mother, a bigger mess that would actually take
      them more time in the long run to arrange and organize, and extra cleaning
      duties as a consequence for their original deception.
    </p>
    <p>
      We are constantly faced with the consequences of our day-to-day decisions.
      Staying one step ahead by asking reflective questions and imagining
      possible consequences is the practiced Habit of a Systems Thinker.
    </p>
  </div>
);
