import * as React from "react";
import Helmet from "react-helmet";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const ResourcesHome = () => (
  <div>
    <Header />
    <div className="flow-container open-studio">
      <Helmet>
        <title>Systems Thinking Resources</title>
        <meta
          name="description"
          content="Online systems thinking webinars, workshops, and forums focused on an area of interest."
        />
        <meta property="og:title" content="Systems Thinking Courses" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:image:alt" content="" />
      </Helmet>
      <section className="photo-header resources-photo">
        <h1>Systems Thinking Resources</h1>

        {/* <img src='images/site/forum-icon-white.svg' /> */}
      </section>

      <section className="white resources-overview-container">
        <div className="templates">
          <h1>Templates</h1>

          <img src="images/resources/shared/icons-notext/templates-icon.svg" />

          <p>
            Here you will find downloadable templates and worksheets to help
            you, or others in a group you may be facilitating, apply systems
            thinking concepts to areas of interest. Templates and worksheets can
            be downloaded, printed and shared under the Creative Commons terms
            of use.
          </p>

          <div className="button-container">
            <a href="/resources/templates">
              <button>View Templates</button>
            </a>
          </div>
        </div>

        <div className="guides">
          <h1>Facilitation Guides</h1>

          <img src="images/resources/shared/icons-notext/facilitation-icon.svg" />

          <p>
            Facilitation guides will aid those looking to facilitate systems
            thinking workshops, learning sessions, and activities with groups of
            varying sizes and from various types of systems. Each facilitation
            guide has an intended audience (children through adults) — be sure
            to explore all the guides to see which will suit your needs best.
            Keep in mind that with a little creativity and planning, many can be
            adapted to best address the needs of your participants.
          </p>

          <div className="button-container">
            <a href="/resources/guides">
              <button>View Facilitation Guides</button>
            </a>
          </div>
        </div>

        <div className="lessonplans">
          <h1>Lesson Plans</h1>

          <img src="images/resources/shared/icons-notext/primary-icon.svg" />

          <p>
            Lesson plans offered have been produced and successfully used by
            educators with students in PreK-Secondary settings. When using the
            lesson plans, you may want to implement them as is, or modify them
            to better meet the needs of your students, the content your students
            will be learning, and the outcomes you desire.
          </p>

          <div className="button-container">
            <a href="/resources/lessons">
              <button>View Lesson Plans</button>
            </a>
          </div>
        </div>

        <div className="assessments">
          <h1>Assessments</h1>

          <img src="images/resources/shared/icons-notext/assessments-icon.svg" />

          <p>
            Assessments include rubrics and other tools to help facilitators,
            educators, and practitioners assess others or self-assess systems
            thinking capacity and skills.
          </p>

          <div className="button-container">
            <a href="/resources/assessments">
              <button>View Assessments</button>
            </a>
          </div>
        </div>

        <div className="webinars">
          <h1>Webinars & Videos</h1>

          <img src="images/resources/shared/icons-notext/webinar-icon.svg" />

          <p>
            Here you will find previously recorded webinars and videos. Webinars
            were facilitated by Waters Center for Systems Thinking staff and
            associates, and videos feature students and adults demonstrating and
            applying systems thinking concepts to real-life scenarios.
          </p>

          <div className="button-container">
            <a href="/resources/webinars">
              <button>View Webinars & Videos</button>
            </a>
          </div>
        </div>

        <div className="articles">
          <h1>Articles</h1>

          <img src="images/resources/shared/icons-notext/articles-icon.svg" />

          <p>
            Explore a variety of published articles relevant to your systems
            thinking learning and application. Featured articles have been
            written by both Waters Center for Systems Thinking leaders and
            outside authors.
          </p>

          <div className="button-container">
            <a href="/resources/articles">
              <button>View Articles</button>
            </a>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
);
