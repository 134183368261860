import * as React from "react";
import { Field } from "redux-form";

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Archetype</h3>

    <p>
      Now it's time to take a look at this archetype using your own personal
      experience. When have you encountered an Escalation archetype? Reflect on
      that experience below.
    </p>

    <p>
      Feel free to{" "}
      <a
        href="https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-blank-so.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        download a blank “escalation” template
      </a>{" "}
      and use it to tell your story as you respond to the questions.
    </p>

    <h4>
      1. Identify the two parties, organizations, groups or individuals in your
      story and choose one to have the initial upper hand.
    </h4>

    <div className="textbox-with-img">
      <img
        src="images/courses/archetypes/04-escalation/exercise2-1.svg"
        alt="A-B"
      />

      <Field
        name="resource-one"
        component="textarea"
        className="reflection-input-large"
        placeholder="Enter your response here."
      />
    </div>

    <h4>
      2. Choose the party who feels inferior relative to the other party. How
      can you describe their threatened feelings and the activity they engaged
      in as a result? How did the results of those actions generate power or
      advantage over the other party?
    </h4>

    <div className="textbox-with-img">
      <img
        src="images/courses/archetypes/04-escalation/exercise2-2.svg"
        alt="Escalation Two"
      />

      <div>
        <Field
          name="resource-alt"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
      </div>
    </div>

    <h4>3. Identify the second balancing loop.</h4>

    <div className="textbox-with-img">
      <img
        src="images/courses/archetypes/04-escalation/exercise2-3.svg"
        alt="Escalation Two"
      />

      <div>
        <Field
          name="resource-two"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />

        <h4>
          4. Using the 2nd balancing loop, describe the feelings of being
          threatened of the other party and the reciprocal activity they engaged
          in to gain results to recover positional power over their adversary.
        </h4>

        <Field
          name="resource-three"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />

        <h4>Tell the story:</h4>

        <Field
          name="resource-four"
          component="textarea"
          className="reflection-input-large"
          placeholder="Enter your response here."
        />
      </div>
    </div>

    <h3>Reflecting on Your Archetypical Story:</h3>

    <h4>What strategies would help break the escalation?</h4>

    <Field
      name="resource-five"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      What could either or both parties have done to avoid the escalation?
    </h4>

    <Field
      name="resource-six"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>
      Are there situations when the system encourages escalation? What are
      potential benefits and trade-offs?
    </h4>

    <Field
      name="resource-seven"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
