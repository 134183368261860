import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <img className="med-img lesson-img" src="images/site/habitcards-all.png" />

    <h2>Introduction:</h2>
    <p>
      Dive into this collection of newsletters designed to explore concepts
      embedded in the Habits of a Systems Thinker. The newsletters surface
      questions and provide activities that you can use with students and
      colleagues as all of you develop your capacity as systems thinkers.
    </p>

    <div className="quote">
      <p className="quote-text">
        “We are our own potters; for our habits make us, and we make our
        habits.”
      </p>
      <p className="quote-author"> – Frederick Langbridge</p>
    </div>

    <h2>Process:</h2>
    <p>
      As with the Habits themselves, there’s no “order” to the newsletters. Dig
      into the newsletters, focusing on the Habit that you and your students or
      colleagues may benefit from at the time.
    </p>
    <p>Consider using the newsletters as</p>
    <ul>
      <li>
        review after a learning session that included the Habits. (Auburn City
        Schools)
      </li>
      <li>
        process reinforcement for content taught in science class. (Auburn City
        Schools)
      </li>
      <li>part of a peer study group for older students or with colleagues.</li>
    </ul>

    <p className="centered">Links to newsletters can be found below:</p>

    <a
      href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-newsletters/big-picture.pdf"
      className="blue-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      Seeks to Understand the Big Picture
    </a>
    <a
      href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-newsletters/change-over-time.pdf"
      className="blue-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      Observes How Elements Within Systems Change Over Time, Generating Patterns
      and Trends
    </a>
    <a
      href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-newsletters/changes-perspectives.pdf"
      className="blue-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      Changes Perspectives to Increase Understanding
    </a>
    <a
      href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-newsletters/considers-issue.pdf"
      className="blue-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      Considers an Issue Fully and Resists the Urge to Come to a Quick
      Conclusion
    </a>
    <a
      href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-newsletters/consquences.pdf"
      className="blue-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      Considers Short-term, Long-term and Unintended Consequences of Actions
    </a>
    <a
      href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-newsletters/mental-models.pdf"
      className="blue-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      Considers How Mental Models Affect Current Reality and the Future
    </a>
    <a
      href="https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-newsletters/structure.pdf"
      className="blue-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      Recognizes that a System’s Structure Generates its Behavior
    </a>
  </div>
);
