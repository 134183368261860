import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-single"
        src="/images/resources/ladder-worksheet/ladder-worksheet.svg"
        alt="Ladder of Inference: Worksheet"
      />
    </div>
  </div>
);
