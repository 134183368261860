import * as React from "react";
import { Field } from "redux-form";

export const Lesson02 = () => (
  <div className="content">
    <p>
      Systems thinkers develop the ability to transfer understanding of one
      system by making connections to other very different systems that behave
      in similar ways. For example, a farmer cultivates crops, a systems thinker
      cultivates connections that promote learning. A growth mindset, the belief
      that intelligence is not purely innate, but can be developed through
      effort, can also be likened to planting the seeds of new knowledge into a
      fertilized mind ready to learn.
    </p>
    <p>
      Factors like curiosity, an inspiring teacher, or a good book serve as the
      sun, water and soil needed for growth and blossoming. A person’s ability
      to learn new concepts or skills and to transfer that learning to new
      situations is critical for cultivating knowledge. Learning happens when
      connections are made within and between systems.
    </p>
    <img
      className="lesson-img"
      src="/images/courses/habits/02-connections/toddler.jpg"
      alt="Toddler"
    />
    <div className="quote">
      <p className="quote-text">
        “Learning came alive for me and still does in those moments when I can
        see connections among different facts, findings or concepts.”
      </p>
      <p className="quote-author"> – Ellen Gallinsky, Mind in the Making</p>
    </div>
    <p>
      In order to see how connections are essential for learning, consider how
      humans develop language. From birth, children are able to produce a host
      of sounds, from very happy coos and sighs to urgent cries for help. As
      children develop, the sounds they make become more and more recognizable
      to people schooled in the native language. “Wah, wah” becomes understood
      as water. The request “wah, wah” is fulfilled with a verbal connection of,
      “Sure, you can have some water,” and the young speaker begins to connect
      those sounds to a system for having his needs met. Eventually the word
      “water” is used in a sentence, resulting in an even more rapid exchange of
      need fulfillment. Language development continues to become increasingly
      sophisticated. The child eventually categorizes water as a drinkable
      liquid, but also recognizes that it is the substance that fills rivers and
      pools, and washes dirty hands. Further sophistication leads to an
      understanding that water can be a noun, but it can also be used as a verb
      when the child is asked to “water the plant.” Over time this leads to an
      understanding of water issues such as drought, lack of access to clean
      drinking water or water rights contested by farmers and housing
      developers. This increased sophistication with language all comes from a
      series of connections and experiences.
    </p>
    <h4>
      Describe a time when learning came alive for you because of connections
      you were able to make.
    </h4>
    <Field
      name="time"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <p>
      Systems thinkers further their learning by applying their understanding of
      systems to multiple contexts. A piano student may start his training with
      a rigorous regimen of practicing scales and drills, but to derive
      satisfaction from his new learning, the aspiring musician must transfer
      those skills to playing music. Once he is comfortable with the basics of
      one instrument he can use those foundational skills to quickly take on
      another instrument.
    </p>
    <p>
      This type of connection, one that produces transfer, like from scales to
      musical pieces, can also take place between systems. A systems thinker
      looks to make connections between seemingly disparate systems. For
      instance, supply and demand of a popular product may follow the same
      oscillating pattern as the population of a species in a predator-prey
      relationship.
    </p>
    <h3>Applying New Learning</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      A sophisticated application of this Habit is the ability to take in large
      amounts of information and make meaningful connections in order to produce
      insight and understanding into your system of interest.
    </p>
    <p>
      Kim, the manager of a large accounting firm, is sent to a national
      conference. She spends five days learning from some great thinkers in her
      field about best practices in accounting and principles of leadership to
      help her become a more effective manager. The desired result is not just
      that Kim acquires the information, but also that she apply the new
      learning to her current work situation. When Kim returns to work, she
      makes the connections between the conference and her work environment in
      order to adapt the learning to her firm’s culture and translate her
      learning into actions that improve her performance and that of her team.
    </p>
    <div className="quote">
      <p className="quote-text">
        "Many believe effective networking is done face-to-face, building a
        rapport with someone by looking at them in the eye, leading to a solid
        connection and foundational trust.”
      </p>
      <p className="quote-author"> – Raymond Arroyo, journalist</p>
    </div>
  </div>
);
