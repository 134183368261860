import * as React from "react";
import { Field } from "redux-form";
import { ToggleText } from "components/shared/toggle-text";

export const Lesson01 = () => (
  <div className="content">
    <p>
      Causal loops are a helpful way to visually represent a system. When
      various loops are connected, the resulting causal map uncovers a story of
      how the system operates through its various causal connections. In the
      previous courses on reinforcing and balancing feedback, single loops were
      used to help teach the basics that include naming the key variable, using
      arrows to show causality and labels at arrowheads that describe the nature
      of the causal relationships. The behavior of most systems can seldom be
      represented by a single isolated loop, so now it is time to apply the
      basics to mapping systems of interest.
    </p>

    <p>
      Most people getting started with causal mapping find it fairly easy to
      identify and draw the reinforcing dynamics in systems. This is especially
      true when people are striving to impact change and have goals to increase
      achievement or grow profits or positively impact community engagement.
      Reinforcing feedback is a part of all systems, yet nothing can grow
      forever and reinforcing relationships always have balancing thresholds
      that slow down and balance growth. Let’s look at an example of a simple
      reinforcing loop and an accompanying balancing loop that contributes
      balance to the reinforcing growth dynamic.
    </p>

    <h3>Example #1: Volunteer Work as a Rewarding Balancing Act</h3>

    <p>
      The mental model of the loop below represents the following story about
      Regina, an active community-minded office manager:
    </p>

    <p>
      Regina is the type person who has trouble saying “no.” She volunteers at
      the Community Food Bank, Habitat for Humanity and delivers meals to
      elderly people who can no longer drive. She feels it is very important to
      help others and serve those who are in need in her community. The clear
      connection for Regina is that the{" "}
      <strong>time she spends volunteering</strong> is appreciated by others as
      she delivers more <strong>benefits to community members</strong> with
      every bit of time she helps out. The benefits Regina delivers cause her to{" "}
      <strong>feel altruistic</strong> which is an important value for her.
      These altruistic feelings cause Regina to continue to say “yes” to most
      requests to volunteer. Reinforcing Loop #1 tells Regina’s story.
    </p>

    <div className="flex">
      <img
        className="med-img"
        src="/images/courses/tools/09-bringing-loops-together/volunteering1.svg"
        alt="Volunteering loop"
      />

      <div className="exerpt">
        <h4>Loop Labels:</h4>

        <p>
          As a reminder, the symbol next to each arrowhead distinguishes the
          nature of the causal connection. You can choose 2 different ways to
          label arrowheads in a causal loop. You can use{" "}
          <strong>S and O</strong> or <strong>+ and -</strong> labels
        </p>

        <p>
          <strong>S or +</strong>: The causal relationship moves in the same
          direction or the effect adds to the cause (e.g. as cause goes up, the
          effect goes up or as the cause goes down, the effect goes down.)
        </p>

        <p>
          <strong>O or –</strong>: The causal relationship moves in the opposite
          direction or the effect moves inversely to the direction of the cause.
          (e.g. as the cause goes up, the effect goes down or as the cause goes
          down, the effect goes up.)
        </p>

        <p>
          In addition, Reinforcing loops have an <strong>R</strong> in the
          center and Balancing loops are labeled with a <strong>B</strong>.
        </p>

        <p>
          When there are more than one reinforcing or balancing loop in a map,
          it is good practice to place a number next to the <strong>R</strong>{" "}
          or the <strong>B</strong>
        </p>
      </div>
    </div>

    <div className="quote">
      <p className="quote-text">
        Notice that in this course we have used "+" or "-" to indicate the
        nature of the causal connection. In previous courses, we used "s" or
        "o." Both of these labeling mechanisms work in the same way and it is
        good practice to familiarize yourself with both options.
      </p>
    </div>

    <p>
      For those of you who spend time volunteering, you know you only have so
      many hours in a day, and only so much time to invest in delivering
      benefits to your community. There is a limit to what you can give,
      especially when you have demands from your job, so a balancing feedback
      loop can help illustrate that threshold.
    </p>

    <p>Here Regina tells the story of her map.</p>

    <img
      className="small-img lesson-img"
      src="/images/courses/tools/09-bringing-loops-together/volunteering2.svg"
      alt="Volunteering loop 2"
    />

    <p>
      <em>
        I love the <strong>time I spend volunteering</strong> because it is
        important for me to <strong>deliver benefits to my community</strong>.
        When I give back, it causes me to <strong>feel altruistic</strong> which
        motivates me even more to <strong>volunteer</strong> (R1).
      </em>
    </p>

    <p>
      <em>
        Sometimes my <strong>feelings of altruism</strong> from the{" "}
        <strong>time I spend volunteering</strong> cause me to work longer
        volunteer hours, which takes away from the{" "}
        <strong>attention I pay to my job</strong>. That lack of attention
        causes the <strong>quality of my work</strong> to suffer. When this
        quality diminishes, <strong>my co-workers complain</strong> about how
        much time I spend on my volunteer efforts. (B1)
      </em>
    </p>

    <p>
      Notice in B1 there are three arrowheads with inverse (-) labels affecting{" "}
      <strong>Attention to work</strong>. It is good practice to read around
      loops more than one time as you will discover that variables in balancing
      loop stories switch directions every time you talk through the loop. For
      example, variables in the balancing loop like{" "}
      <strong>Volunteer work</strong> will increase at times and balance out or
      slow down at times. You can also show this dynamic with a
      behavior-over-time graph.
    </p>

    <img
      className="small-img lesson-img block"
      src="/images/courses/tools/09-bringing-loops-together/volunteering-graph.svg"
      alt="Volunteering BOTG"
    />

    <h3>Example #2: Building the Courage to Try New Things</h3>

    <p>
      Have you ever been in a situation when you were asked to learn something
      new, take a risk and try something for the first time, or consider an
      opportunity that could just as easily fail as succeed?
    </p>

    <h4>Describe that scenario here:</h4>

    <Field
      name="scenarioOne"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <p>
      Consider the causal map below to help you think through your scenario.
    </p>

    <img
      className="med-img lesson-img block"
      src="/images/courses/tools/09-bringing-loops-together/try-new-things.svg"
      alt="Trying new things loop"
    />

    <h4>Tell the story of the loop:</h4>

    <Field
      name="scenarioTwo"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <ToggleText
      showText="Show Hint"
      hideText="Hide Hint"
      text={[
        {
          id: 1,
          text: "R1: When my courage to try new things is high, I am more willing to try new things which helps me see the potential benefits of trying new things. The potential benefits build my courage.",
        },
        {
          id: 2,
          text: "B1: When I have courage to try new things, I may be willing, but the risk for failure is also a strong consideration which ends up depleting my courage.",
        },
      ]}
    />
  </div>
);
