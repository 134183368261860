import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      Take a moment to reflect on challenges that you face in your work or
      personal life.
    </p>
    <h4>
      Is there a recuring issue that you have tried to address, but nothing ever
      changes? What would you like to see happen? Describe the challenge here.
    </h4>
    <Field
      name="recurring"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What specific indicators would you like to see change over time?</h4>
    <Field
      name="indicators"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What structures are in place to perpetuate the current system behavior?
    </h4>
    <Field
      name="structures"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What mental models could be surfaced in order for change to happen?</h4>
    <Field
      name="models"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How might the iceberg be helpful to address this challenge?</h4>
    <Field
      name="iceberg"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
