import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="article-container">
      <p>
        Working in systems thinking, we have the privilege of connecting with so
        many inspirational people. It’s these individuals and groups, with their
        extraordinary drive and passion for bettering the lives of others
        through systems thinking, that remind us each and every day what is
        possible.
      </p>

      <p>
        Barbie and Peter Reynolds, both UK citizens living in Afghanistan,
        certainly fit this description. For over 45 years, they have dedicated
        their lives to working for a more peaceful and prosperous wellbeing in
        the nation of Afghanistan. Through their company, Rebuild Consultants,
        established in 2009, they use a systems thinking approach with teachers
        and leaders to build a brighter future for the Afghani people.
      </p>

      <p>
        “We love this country. We love the people. There is huge potential and
        it starts with teaching people how to think,” Barbie says.
      </p>

      <p>
        This is especially true in a country where not thinking through an issue
        fully or having the tools to problem-solve could have life-altering
        consequences.
      </p>

      <p>
        “We see it all the time. Recently, a teacher of ours had a sore throat.
        She went to a doctor who said he thought it was a goiter. She sought a
        second opinion and that doctor diagnosed her with kidney failure. Of
        course, there is no correlation. However, kidneys can be sold and make
        people a lot of money here,” says Barbie. “Thankfully, she was able to
        think about these diagnoses rather than jump to the doctors’ incorrect
        conclusions.”
      </p>

      <p>
        Young children are a particular focus for Rebuild Consultants, as they
        have seen that children are curious about the world around them, they
        take in the perspectives of others and the different elements of their
        environment — in other words, children are natural systems thinkers.
      </p>

      <p>
        “Traditionally, a student’s identity in Afghanistan is tied to how
        “smart” they are. And smart essentially means how much they can
        memorize; in other words, it is rote-learning. Students go through their
        academic career memorizing facts and formulas without really learning.
        The present educational system and prevailing cultural mindset is
        antagonistic to questioning, innovative or critical thinking from
        differing perspectives.”
      </p>

      <p>With schooling like this, much potential is lost.</p>

      <p>
        “Peter and I attended a systems thinking Forum put on by the Waters
        Center a few years back. It was then that we realized – we need to focus
        on teachers. By starting with teachers and students we can begin to see
        a shift, and then, hopefully, business and government leaders will take
        notice and see the value a systems thinking mindset can bring and want
        to apply it to their work, policies and more,” says Barbie.
      </p>

      <p>
        The group has seen tremendous success in their current work. Teachers
        are fueled with enthusiasm at the opportunity to learn in a different
        way and this passion and excitement carries through to their students.
      </p>

      <p>
        In the spring of 2020, Waters Center Chief Learning Officer, Sheri
        Marlin, had the opportunity to speak at a Rebuild graduation for
        teachers who had completed systems thinking modules, including courses
        in the{" "}
        <a
          href="https://thinkingtoolsstudio.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Thinking Tools Studio
        </a>
        , that were translated from English to Dari.
      </p>

      <p>
        “It was nothing short of awe-inspiring. I got to hear from teachers
        first-hand about the impact of their systems thinking learning,” Sheri
        recounts.
      </p>

      <p>
        These accounts also spotlighted the important role that culture and
        cultural norms play in learning. One teacher shared how she had always
        been told what is “right” and “wrong,” but systems thinking has allowed
        her to look at issues objectively and come up with her own conclusions
        that are not deeply rooted in mental models of others.
      </p>

      <p>
        Another spoke of how she has always been told not to share her
        perspective when it is different from others. But she has now found her
        voice and understands the value that differing perspectives can bring.
      </p>

      <p>
        “We’ve really seen huge strides in awareness of self and others,” Barbie
        says. “There is a need – and desire – for systems thinking learning and
        we are determined to scale this work throughout the country. A culture
        based on citizens bringing positive and sustainable change in their own
        spheres of influence – in education, healthcare, business, the legal
        system, arts, media, politics, government and family – is within reach
        in Afghanistan.
      </p>

      <p>
        Rebuild Consultants offers 35 training courses as a part of their social
        and emotional education and leadership and management options. For more
        information on Rebuild, visit{" "}
        <a
          href="http://rebuildconsultants.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          rebuildconsultants.com
        </a>{" "}
        or contact{" "}
        <a href="mailto:barbie@rebuildconsultants.com">
          barbie@rebuildconsultants.com
        </a>
      </p>

      <p>
        View this video provided by Rebuild with one of their teachers, Zahra,
        describing her systems thinking experience.
      </p>

      <p>
        <iframe
          src="//www.youtube.com/embed/atLVwCRTyyk?rel=0"
          width="560"
          height="315"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
        />
      </p>
    </div>
  </div>
);
