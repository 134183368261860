import * as React from "react";
import { ToggleImages } from "components/shared/toggle-images";

export const Lesson01 = () => (
  <div className="content">
    <p>You might be in a “success to the successful” scenario when…</p>

    <div className="quote">
      <p className="quote-text">
        One group or individual is allocated more resources than another group
        or individual, increasing the likelihood of success for those receiving
        resources.
      </p>
    </div>

    <p>
      To help demonstrate this, think about the game of Monopoly®. All players
      begin the game with an equal amount of money. However, with the roll of
      the dice some players are the first to acquire more money and property,
      and as a result, can build hotels. They are then able to collect rent,
      increasing the amount of money in their bank account. This allows them to
      buy more property, build more hotels, collect more rent and grow their
      bank account even more. The other players’ success diminishes with each
      round of the game as their Monopoly® bank accounts decrease and resources
      become scarce. “As the rich get richer, the poor get poorer.”
    </p>

    <img
      className="lesson-img large-img block"
      src="/images/courses/archetypes/02-success-to-the-successful/success-to-the-successful-full.svg"
      alt="Success to the Successful"
    />

    <h3>The Story of this Archetype</h3>

    <div className="arch-components">
      <div className="arch-p-container">
        <p>
          The Success to the Successful (STS) archetype consists of two
          reinforcing loops that compete with each other. In one reinforcing
          loop, things get better and better for the “haves” (A) as they
          continually receive resources. In the other reinforcing loop, things
          get worse and worse for the “have nots” (B) as they continually lose
          out on resources that are going to A. Over time, the “haves” dominate
          resource allocation at the expense of the “have nots.”
        </p>

        <p>
          The Tools course on{" "}
          <a href="courses/07-cld-reinforcing">reinforcing feedback</a>{" "}
          describes this type of loop in more detail.
        </p>

        <p>
          <em>
            As you read each part of the story, press the “Show Next Part of
            Story” button below the graphic to see the story of the archetype
            unfold.
          </em>
        </p>

        <p>
          Now, let’s apply the Success to Successful archetype to our Monopoly®
          game scenario:
        </p>

        <p>
          <strong>Part 1:</strong> Player <strong>A</strong> increases her
          resources through the purchase of property and the collection of rent.
          As Player <strong>A</strong> accumulates resources, the money to
          Player <strong>A</strong> increases. You can read more about this type
          of causal relationship in the{" "}
          <a href="courses/07-cld-reinforcing">reinforcing feedback course</a>{" "}
          in the Tools section of the Studio.
        </p>

        <p>
          <strong>Part 2:</strong> As Player <strong>A</strong>’s money
          increases, her success in the game increases. As her success in the
          game increases, her allocation of the money increases. This creates a
          reinforcing loop.
        </p>

        <p>
          <strong>Part 3:</strong> Player <strong>B</strong> has a different
          experience. As Player <strong>A</strong> accumulates more money
          (resources), Player <strong>B</strong> gets fewer resources
          (money/hotels).
        </p>

        <p>
          <strong>Part 4:</strong> As Player <strong>B</strong>’s money
          decreases, his success in the game decreases. As his success in the
          game decreases, the allocation of his money decreases. This creates a
          reinforcing loop. Player <strong>B</strong>’s resources continue to
          diminish.
        </p>

        <p>
          As you can see, the experience of both Player <strong>A</strong> and
          Player <strong>B</strong> take the form of reinforcing loops.
        </p>
      </div>

      <div className="arch-build-container">
        <ToggleImages
          images={[
            {
              id: 1,
              image:
                "/images/courses/archetypes/02-success-to-the-successful/stts-step1.svg",
            },
            {
              id: 2,
              image:
                "/images/courses/archetypes/02-success-to-the-successful/stts-step2.svg",
            },
            {
              id: 3,
              image:
                "/images/courses/archetypes/02-success-to-the-successful/stts-step3.svg",
            },
            {
              id: 4,
              image:
                "/images/courses/archetypes/02-success-to-the-successful/stts-step4.svg",
            },
          ]}
        />
      </div>
    </div>

    <h3>Putting it all together</h3>

    <p>
      The image below shows the Success to the Successful archetype telling the
      story of the Monopoly® example:
    </p>

    <img
      className="lesson-img large-img block"
      src="/images/courses/archetypes/02-success-to-the-successful/monopoly-landscape.svg"
      alt="Success to the Successful"
    />

    <h3>Talk it Through</h3>
    <p>
      When we draw out the entire archetype, we have a story to tell about
      success to the successful.
    </p>

    <p>
      Begin with the resources allocated and talk it through, following the
      causal links.
    </p>

    <p>
      <em>
        “As the allocation of money to Player A increases, the amount of money
        Player A accumulates increases. As the amount of money Player A
        accumulates increases, Player A’s success increases. As Player A’s
        success increases, the allocation of money to Player A increases. As the
        allocation of money to Player A increases, the amount of money to Player
        B decreases. As the amount of money for Player B decreases, Player B’s
        success decreases. As Player B’s success decreases, the allocation money
        to Player B decreases.”
      </em>
    </p>

    <p>
      Both players experience a reinforcing loop. One player (Player A) has an
      increased chance of success because her chances of success escalate as she
      continually receives more resources. The other player (Player B) is
      adversely impacted as resources are allocated to the successful player,
      leaving fewer resources for him.
    </p>

    <a href="/courses/08-structure">
      <img
        className="lesson-img small-img"
        src="/images/shared/habit-cards/shadow/structure.png"
        alt="System structure generates its behavior"
      />
    </a>

    <p>
      In Monopoly®, the success of one and the lack of success to another can
      be attributed to the structure of the game as much as to skill, knowledge
      or talent. In other words, the structure of the game generates the outcome
      for the players. You can read more about this in the Habits course,{" "}
      <a href="courses/08-structure">
        "A Systems Thinker Recognizes that a System’s Structure Generates its
        Behavior"
      </a>
      .
    </p>

    <div className="exerpt">
      <p>
        <strong>Reflection:</strong> Consider a time when structure contributed
        to the increased success of some and decreased the chance of success for
        others.
      </p>
    </div>

    <p>
      Continue to Lesson 2 for more examples of stories that fit the Success to
      the Successful archetype.
    </p>
  </div>
);
