import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <p>
      Naming variables can be a little tricky. One practice that will help is to
      intentionally avoid using qualifiers or words that suggest the direction
      the variable should go. For example, “better performance” suggests that
      performance would increase. The word “better” is a qualifier that should
      be avoided in the loop because the arrow label will identify the direction
      that performance takes. Instead, try “quality of performance,” which does
      not suggest “good” or “bad.” The label “quality of performance” can
      clearly go up or down depending on the cause that affects the change. Here
      are some other examples of less effective variable labels (i.e. they have
      a qualifier) and their improvements.
    </p>
    <ul>
      <li>
        <strong>Highly valued</strong> vs. <strong>Level of value</strong>
      </li>
      <li>
        <strong>Tendency to be distracted</strong> vs.{" "}
        <strong>Level of distraction</strong>
      </li>
      <li>
        <strong>Depleted resources</strong> vs.{" "}
        <strong>Number of resources</strong>
      </li>
    </ul>
  </div>
);
