import * as React from "react";
import { Field } from "redux-form";

export const Exercise05 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <h4>
      Think about a time when you held an assumption about a person, situation
      or occurrence that was erroneous or far off from what was accurate.
      Describe the time and what information helped you revisit your assumptions
      and realize your initial misinterpretation.
    </h4>
    <Field
      name="situation"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How could reflection, inquiry and/or advocacy have helped you? If you
      could replay the situation, how would you think about it differently?
    </h4>
    <Field
      name="reflection"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
