import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="large-img"
        src="/images/resources/foldable-botg/foldable-botg-generic.png"
        alt="Foldable BOTG"
      />
    </div>
  </div>
);
