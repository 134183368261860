import * as React from "react";
import { Field } from "redux-form";

export const Exercise01 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>

    <p>
      Consider the following statements that we often think during reinforcing
      scenarios. Describe a situation familiar to you that matches each
      statement.
    </p>

    <h4>Things are getting out of control!</h4>

    <Field
      name="control"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>This is really snowballing!</h4>

    <Field
      name="snowball"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>I can’t keep up!</h4>

    <Field
      name="keep-up"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>We are really on a roll now!</h4>

    <Field
      name="on-a-roll"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />

    <h4>It’s spreading like wildfire!</h4>

    <Field
      name="wildfire"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
