import * as React from "react";

export const Lesson03s02 = () => (
  <div className="content">
    <h3>Rewriting the Story in Advance</h3>

    <p>
      This story helps us pay attention to the conditions that can cause
      escalation to develop. The best way to rewrite the story is to avoid
      getting into this dynamic in the first place. What single events or
      actions if left unchecked, perhaps causing misconceptions to develop, can
      catalyze the beginnings of escalating behaviors? What are potential
      consequences of actions that may cause people to feel threatened?
      Recognizing the patterns and trends of actions designed to gain the upper
      hand are worth recognizing. To learn more about this, check out the Habits
      course on{" "}
      <a href="/courses/06-patterns">recognizing patterns and trends</a>.
    </p>

    <p>
      How can you monitor relationships that have the potential to spur
      competitive actions that create a threatening atmosphere while individuals
      strive to gain the upper hand? Check the results of your actions and
      monitor and change as needed. Review the Habits course on{" "}
      <a href="/courses/15-successive-approximation">
        successive approximation
      </a>{" "}
      to dive deeper into this concept.
    </p>

    <p>
      Are feelings of superiority worth the pain and trouble of what it takes to
      get there, if at the expense of others? Consider the unintended
      consequences of taking certain actions, knowing that they might
      unconsciously initiate escalating cycles. Read more in the Habits course
      on{" "}
      <a href="/courses/11-consequences">
        short-term, long-term and unintended consequences
      </a>
      .
    </p>

    <p>
      However, if you can’t avoid the dynamic and you do find yourself in an
      escalation scenario, choose to withhold action that might generate further
      perceived threat. Or, facilitate a mutual agreement, like Trey and Ms.
      Hess, to cease actions. Dialogue is a tool to help reach mutual agreement.
      During dialogue sessions, try to change your perspective to increase
      understanding of the other’s point of view. More information can be found
      in the Habits course on{" "}
      <a href="/courses/04-perspectives">
        changing perspectives to increase understanding
      </a>
      . If neither party ceases the threatening action, then eventually one
      party or both will certainly lose out because the reinforcing dynamic
      cannot go on forever.
    </p>

    <div className="habits-row">
      <img
        className="lesson-img small-img right clear"
        src="/images/shared/habit-cards/shadow/change-over-time.png"
        alt="Change Over Time"
      />

      <img
        className="lesson-img small-img right clear"
        src="/images/shared/habit-cards/shadow/successive-approx.png"
        alt="Successive Approximation"
      />

      <img
        className="lesson-img small-img right clear"
        src="/images/shared/habit-cards/shadow/consequences.png"
        alt="Consequences"
      />

      <img
        className="lesson-img small-img right clear"
        src="/images/shared/habit-cards/shadow/perspectives.png"
        alt="Changes Perspectives"
      />
    </div>
  </div>
);
