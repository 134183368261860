import * as React from "react";
import { Field } from "redux-form";

const Systems = ["well-being", "workplace", "school", "community", "family"];

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>Select a sample system for this practice exercise.</p>
    <h4>My practice system is:</h4>
    <div className="system-select">
      {Systems.map((s) => (
        <div key={s} className={`system-select-item ${s}`}>
          <Field
            name="systemSelect"
            id={`ss-${s}`}
            component="input"
            type="radio"
            value={s}
          />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      ))}
    </div>
    <h4>
      How are current mental models contributing to this system’s goals or
      outcomes?
    </h4>
    <Field
      name="system-goals"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>How are they hindering progress?</h4>
    <Field
      name="hinder-progress"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How can I help others see the influence that mental models have on our
      decision-making?
    </h4>
    <Field
      name="decision-making"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
