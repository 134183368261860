import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-single"
        src="/images/resources/iceberg-scientific-phenomenon/iceberg-phenomenon.svg"
        alt="Iceberg: Scientific Phenomenon"
      />
    </div>
  </div>
);
