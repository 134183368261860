import * as React from "react";
import Helmet from "react-helmet";
import { Header } from "components/shared/header";
import { Footer } from "components/shared/footer";

export const VirtualForums = () => (
  <div>
    <Header />
    <div className="flow-container open-studio">
      <Helmet>
        <title>Building Pathways for Systems Thinkers - Virtual Forums</title>
        <meta
          name="description"
          content="Online systems thinking workshops focused on an area of interest."
        />
        <meta
          property="og:title"
          content="Building Pathways for Systems Thinkers"
        />
        <meta
          property="og:description"
          content="Virtual forums focused on an area of interest."
        />
        <meta
          property="og:image"
          content="https://thinkingtoolsstudio.org/images/site/ec-forum-logo-wide.png"
        />
        <meta
          property="og:url"
          content="https://thinkingtoolsstudio.org/events/virtualforums"
        />
        <meta
          name="twitter:image"
          content="https://thinkingtoolsstudio.org/images/site/studio-series-logo-tagline.png"
        />
        <meta
          name="twitter:image:alt"
          content="Virtual Forums brought to you by the Waters Center for Systems Thinking"
        />
      </Helmet>
      <section className="photo-header virtual-forums">
        <h1>Building Pathways for Systems Thinkers</h1>

        <img src="images/site/forum-icon-white.svg" />

        <h2>Virtual Forums</h2>
      </section>

      <section className="white stacked">
        <h3>
          Virtual Forum registration has moved to the Waters Center website.
          Please visit the{" "}
          <a href="https://waterscenterst.org/forums">Forums page</a> there to
          see upcoming events and registration.
        </h3>

        {/* <img
          src='images/site/sl-forum-title.svg'
          className='large-img'
          alt='Health System Forum'
        />

        <h3>
          Thursday, June 24, 2021
        </h3>

        <h3>
          8:00 a.m. – 3:30 p.m. PDT/ 11:00 a.m. – 6:30 p.m. EDT  (<a href='https://www.thetimezoneconverter.com/' target='_blamk'>Time converter</a>)
        </h3>

        <p>
          Over the past year, as a school leader, you have faced unprecedented challenges. You have read books, listened to podcasts and participated in virtual events to gain insights into how to best meet those challenges.  This Forum will help you build a bridge between this year’s learning and the plans you make to leverage student learning in SY ’21-’22.
        </p>

        <p>
          You will leave with hope, new ways of thinking and actionable next steps that will impact the way you lead at your school.  Join us if you are ready to utilize a systems thinking lens to reimagine your school leadership role on behalf of your students, staff and community in pursuit of learning for all students!
        </p>

        <a href='https://schoolleadership.wcfstevents.org/register' target='_blank' rel='noopener noreferrer'>
          <button className='orange big'>Register Now</button>
        </a> */}

        {/* <p className='centered'>
          Stay tuned for information about the next Forum. If you were a registered participant of the Early Childhood Forum, we invite you to create an account on the Forum platform to access resources and recordings until November 1.
        </p>

        <a href='https://earlychildhood.wcfstevents.org/create-account' target='_blank' rel='noopener noreferrer'>
          <button className='orange big'>Create Account</button>
        </a> */}
      </section>

      {/* <section className='grey split forum-summary'>
        <div>
          <h3>
            Who should attend?
          </h3>

          <p>
            Participants of the Forum will include:
          </p>

          <ul className='colordrops'>
            <li>
              School and district leaders
            </li>

            <li>
              School leadership teams
            </li>

            <li>
              Educators
            </li>

            <li>
              Anyone interested in making schools great places for ALL students to learn
            </li>
          </ul>
        </div>

        <div>
          <h3>
            Systems thinking school leaders...
          </h3>

          <ul className='colordrops'>
            <li>
              thrive in changing environments, as they have a keen understanding of the dynamic causal relationships among key parts of the system.
            </li>

            <li>
              take a step back from planned actions to anticipate both intended and unintended consequences.
            </li>

            <li>
              actively seek the perspectives of others and have strategies to utilize challenges as opportunities and productive disruption as a way forward.
            </li>

            <li>
              develop a deep understanding of their school system that considers the mental models or mindsets of all stakeholders, including students.
            </li>

            <li>
              utilize a thorough system understanding to identify areas of leverage that contribute to the achievement of desired outcomes.
            </li>

            <li>
              apply the Habits of a Systems Thinker consistently in decision-making, problem-solving, facilitating, collaborating and communicating.
            </li>
          </ul>
        </div>
      </section> */}

      {/* <section className='white stacked forum-agenda'>
        <img
          src='images/site/wc-box.svg'
          className='small-img'
        />

        <p>
          Each participant will be mailed a Forum toolkit full of resources and supplies to support learning during the event.
        </p>

        <a href='https://schoolleadership.wcfstevents.org/' target='_blank' rel='noopener noreferrer'>
          <button className='orange big'>More Information and Registration</button>
        </a>
      </section>

      <section className='white stacked'>
        <p>
          Need help with events? <a href='mailto:events@waterscenterst.org'>Email us</a> at events@waterscenterst.org
        </p>

        <div className='studio-series-cancellation'>
          <h4>
            Virtual Forum Cancellation, Substitutions & Refund Policy
          </h4>

          <ul>
            <li>
              Payment is required in full prior to the start of the Virtual Forum.
            </li>

            <li>
              Full refund will be given if cancellation is received at least 14 days prior to the start of the Virtual Forum.
            </li>

            <li>
              No refund will be given if cancellation is received less than 14 days prior to the Virtual Forum, but you will receive a credit toward a future Virtual Forum or you may send a substitute in your place in lieu of a credit.
            </li>
          </ul>
        </div>
      </section> */}
    </div>
    <Footer />
  </div>
);
