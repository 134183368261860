module.exports = [
  { id: "a-ftb", value: "Fixes that Backfire" },
  { id: "a-stts", value: "Sucess to the Successful" },
  { id: "a-ltgs", value: "Limits to Growth / Success" },
  { id: "a-e", value: "Escalation" },
  { id: "a-stb", value: "Shifting the Burden" },
  { id: "a-dg", value: "Drifing Goals" },
  { id: "a-totc", value: "Tragedy of the Commons" },
  { id: "a-aa", value: "Accidental Adversaries" },
  { id: "a-rr", value: "Repression & Revolution" },
];
