import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <img
      className="lesson-img small-img right clear"
      src="/images/shared/habit-cards/shadow/perspectives.png"
      alt="Changes Perspectives"
    />

    <p>
      If you find yourself in a “shifting the burden” scenario, you will likely
      want to find a way out so that you can begin working toward achieving a
      fundamental solution. Breaking a “shifting the burden” pattern usually
      requires acknowledging that you have become dependent on a quick fix.
      Recognize that you may be depending on reoccurring behaviors that don’t
      allow you to focus on fundamental solutions, but rather cause you to
      depend on quick fixes.
    </p>

    <p>
      When drawing out and talking through a “Shifting the Burden” archetype,
      think carefully about the problem symptom. Accurately identifying the
      appropriate problem symptom is an important part of telling the story and
      can help you understand your current situation. Is your attempt to find a
      solution driven by pain or inconvenience or is there a deeper concern that
      needs to be recognized and addressed?
    </p>

    <p>
      Whether a solution is symptomatic or fundamental often depends on one’s
      perspective. To explore solutions from various perspectives, consider the
      Habit,{" "}
      <a href="/courses/04-perspectives">
        changes perspectives to increase understanding
      </a>
      .
    </p>

    <h3>Rewriting the Story in Advance: Achieving a Fundamental Solution</h3>

    <img
      className="lesson-img small-img right clear"
      src="/images/shared/habit-cards/shadow/considers-issue.png"
      alt="Considers Issue Fully"
    />

    <p>
      Avoiding the trap of Shifting the Burden is most readily achieved by
      acknowledging that the initial solution was shortsighted. Going forward,
      make an effort to focus on fundamental solutions in order to minimize the
      results of side effects. By practicing the Habit of{" "}
      <a href="/courses/12-considers-issue">
        considering an issue fully and resisting the urge to come to a quick
        conclusion
      </a>
      , you’ll become more likely to focus on fundamental solutions, rather than
      relying on quick fixes.
    </p>
  </div>
);
