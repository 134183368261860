import * as React from "react";

export const Lesson02 = () => (
  <div className="content">
    <img
      className="lesson-img"
      src="/images/courses/habits/09-circular-nature/lesson-two-img.png"
      alt="Causal Loop"
    />
    <p>
      Systems thinkers use visual drawing tools called causal loop diagrams,
      also known as feedback loops, to show circular stories of causal
      relationships that exist between elements. In the flower-bee-arrows
      picture, both the bee and the flower represent the elements of the causal
      loop. Because the elements can change over time (number of bees or
      flowers) they are commonly referred to as variables.
    </p>
    <p>
      Causal loop diagrams (CLDs) use arrows drawn between elements or variables
      to illustrate causality. To create such a diagram, begin drawing the arrow
      from the cause and then point the arrowhead to the effect. When using the
      loops to explain relationships, the circular shape helps you see how there
      is no variable that begins or ends the causal story. Instead, the story
      continues as you talk around the loop multiple times.
    </p>
    <h4>
      Consider the saying, “Do unto others as you would have others do unto
      you.”
    </h4>
    <img
      id="treat-others"
      src="/images/courses/habits/09-circular-nature/treat-others.svg"
      alt="Causal Loop"
    />
    <p>
      This saying can be represented as a causal loop. The loop explains that
      the way you treat others impacts the way others treat you. And the way
      others treat you affects the way you treat others.
    </p>
    <p>
      To be more specific, if you were to choose kindness as a way of treating
      others, hopefully people will be kind to you in return, and when people
      are kind to you, you share kind gestures with others.
    </p>
    <p>
      Much like a snowball increases in size every time it rotates as it travels
      down a hill, the variables in this loop increase as the story continues to
      be told around the loop. This causal relationship grows with every pass
      around the circle.
    </p>
    <p>
      Because these two loop variables reinforce each other or cause one another
      to grow in this story, this type of causal loop is called reinforcing.
      Notice the “R” in the center of the loop that labels it as a reinforcing
      loop.
    </p>
    <p>
      Sometimes you will see an image of a snowball rolling down a hill as a
      label for a reinforcing loop.
    </p>
    <img
      id="kind-gestures"
      src="/images/courses/habits/09-circular-nature/kind-gestures.svg"
      alt="Causal Loop"
    />
    <p>
      The variables in this loop reinforce one another in a direct way. When a
      loop shows reinforcing growth, it is important to realize that the growth
      is not always considered a “good” thing. For example, if I were to
      mistreat people, then others may mistreat me, which would cause me to
      further mistreat others. This is still a reinforcing relationship,
      although not a desirable life situation.
    </p>
    <p>You will learn more about reinforcing loops later in this course.</p>
  </div>
);
