import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <div className="template-block">
      <img
        className="template-double"
        src="/images/resources/tragedy-of-the-commons/tragedy-of-the-commons-summary-so.svg"
        alt="Tragedy of the Commons Summary"
      />

      <img
        className="template-double"
        src="/images/resources/tragedy-of-the-commons/tragedy-of-the-commons-template-so.svg"
        alt="Tragedy of the Commons Template"
      />
    </div>
  </div>
);
