import * as React from "react";
import { Field } from "redux-form";

export const Exercise04 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      A systems thinker looks ahead and anticipates not only the immediate
      results of actions, but also the effects down the road. Think about a
      decision you are about to make that will lead to action. Read and respond
      to the following questions to help you consider both expected and
      unexpected consequences.
    </p>
    <h4>
      What are possible unintended consequences of your decision? They might
      involve reactions, attitudes, results or new challenges.
    </h4>
    <Field
      name="your-decision"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Identify the benefits and trade-offs of your decision. How will you
      minimize the impact of the trade-offs? What structures can you put into
      place (e.g. communication, safeguards, modified practices) to address the
      potential impact of trade-offs?
    </h4>
    <Field
      name="respond-quickly"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Will your decision involve short-term hardship to achieve long-term
      success? If so, what is your plan to minimize the challenges you expect to
      face in the short run? If not, describe the road to long-term success.
    </h4>
    <Field
      name="hardship"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
