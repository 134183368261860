import * as React from "react";
import { Field } from "redux-form";

const Systems = ["well-being", "workplace", "school", "community", "family"];

export const Exercise02 = () => (
  <div className="content">
    <h3>Practice the Habit</h3>
    <p>
      Use the sample systems to help you identify an important decision you need
      to make. Here are some examples to get you started:
    </p>
    <ul>
      <li>
        Well-being — analyzing the pros and cons of various health insurance
        plans
      </li>
      <li>
        Family — selecting the best long-term care options for an elderly parent
      </li>
      <li>Work — when to address an uncooperative co-worker</li>
      <li>School — choosing a college or preschool</li>
      <li>Community — how to vote on an upcoming ballot measure</li>
    </ul>
    <h4>
      Click on one of the icons below to select a sample system where you need
      to make an important decision:
    </h4>
    <div className="system-select">
      {Systems.map((s) => (
        <div key={s} className={`system-select-item ${s}`}>
          <Field
            name="systemSelect"
            id={`ss-${s}`}
            component="input"
            type="radio"
            value={s}
          />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      ))}
    </div>
    <h4>What is the decision that needs to be made?</h4>
    <Field
      name="decision-needs"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What is the timeline for making the decision?</h4>
    <Field
      name="timeline"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      Is the timeline reasonable? What are the potential consequences of acting
      too quickly? What are the potential consequences if the decision is
      delayed?
    </h4>
    <Field
      name="acting"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How can you manage the tension that may arise if the issue is not resolved
      immediately?
    </h4>
    <Field
      name="manage"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      What are the different perspectives that need to be taken into account
      when making this decision?
    </h4>
    <Field
      name="that-need"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>
      How can you help yourself and others be patient while living with an
      unresolved decision?
    </h4>
    <Field
      name="help-yourself"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
