import * as React from "react";
import { Field } from "redux-form";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>
    <p>
      Think of a situation where you took an action based on an interpretation
      of a situation. It could be an email response to a note you received, a
      schedule change you made based on something that surfaced unexpectedly, or
      a quick decision you had to make to address a particular incident.
    </p>
    <h4>
      Why was the action taken? If other actions could have been considered,
      what are some?
    </h4>
    <Field
      name="action"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What belief led to the action?</h4>
    <Field
      name="belief"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What assumptions were made? Were the assumptions valid?</h4>
    <Field
      name="assumptions"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h4>What data was noticed?</h4>
    <Field
      name="data"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
    <h3>Building Capacity with the Ladder of Inference</h3>
    <p>
      The tools and Habits of a Systems Thinker provide us with a common
      language and processes to make our thinking visible to everyone in our
      systems. The ladder of inference is particularly helpful when we need to
      analyze an issue before jumping to a quick conclusion.
    </p>
    <h3>When / Where Can You Use the Ladder in Systems?</h3>
    <p>
      Examine mental models with colleagues and supervisors in any system of
      interest. Additional uses in: data analysis, debrief and reflection,
      project development, problem solving and decision making.
    </p>
    <h3>When / Where Can You Use the Ladder in School Systems?</h3>
    <p>
      Professionally: Examine mental models with peers, colleagues, students,
      families, etc.
    </p>
    <p>
      Data Analysis: district/school, student groups, individual students,
      interventions, achievement, attendance, etc.
    </p>
    <p>
      Classroom: literary analysis, character development, interpretation of an
      author’s purpose, graphic organizer for writing, mathematical word
      problems, conflict resolution, restorative justice, etc.
    </p>
  </div>
);
