import * as React from "react";

export const Lesson03 = () => (
  <div className="content">
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Family Example"
    />
    <p>
      Systems thinkers consider multiple factors when making decisions and
      taking action. Some people make T-charts to weigh benefits and trade-offs
      of actions. Oftentimes this strategy is very effective for analyzing
      short-term consequences of decisions. For example, Judy has to consider
      the option of hiring a close, personal friend. See below how Judy
      organized the benefits and trade-offs of hiring her friend Maria to help
      her make a decision.
    </p>
    <table className="lesson-table">
      <tbody>
        <tr>
          <th>Benefits</th>
          <th>Trade-Offs</th>
        </tr>
        <tr>
          <td>Maria has the skills that I am looking for.</td>
          <td>
            My co-workers may think Maria will have an unfair advantage because
            we are friends.
          </td>
        </tr>
        <tr>
          <td>
            Maria’s experience will help contribute to our company’s success.
          </td>
          <td>
            Maria may see our friendship as a way to give her an edge in our
            company.
          </td>
        </tr>
        <tr>
          <td>
            Maria is a quick learner and team player—everyone likes Maria.
          </td>
          <td>Maria may assume that everyone likes her because I like her.</td>
        </tr>
      </tbody>
    </table>
    <p>
      In addition to a T-chart analysis, another strategy is reflecting on
      questions that will help play out a variety of scenarios. Scenario
      planning is a strategy to help you anticipate consequences of actions and
      decision-making.
    </p>
    <h4>
      Questions for Judy to think about when weighing the benefits and
      trade-offs of hiring Maria could be:
    </h4>
    <ul>
      <li>What if Maria underperforms?</li>
      <li>
        How will I (Judy) handle situations where I need to provide feedback,
        give directives or suggest improvement when supervising Maria?
      </li>
      <li>
        What would happen to our friendship if I have to fire or lay off Maria?
      </li>
      <li>
        If my employees react negatively to Maria, will I be forced to take
        sides?
      </li>
      <li>
        What other questions are important when looking at this hiring decision
        long-term?
      </li>
    </ul>
    <p>
      What advice would you give Judy, who wants to hire her competent friend
      Maria? If an interview committee hired Maria because of her
      qualifications, should Judy disclose the fact that she is a good friend?
      Having Maria as a friend should not hurt her chances of getting hired, but
      the personal relationship could impact the system, and there may be
      unintended consequences of this situation.
    </p>
    <p>
      Asking the kinds of questions that help surface consequences of actions
      should be common practice among all members of the system. Once the
      practice of asking questions that anticipate benefits and trade-offs of
      decisions is second nature, the system becomes more adaptable to changes
      in structure, relationships, and timing.
    </p>
  </div>
);
