import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Introduction: Creative Tension and the Importance of Gaps</h3>
    <p>The gap between vision and current reality can be a source of energy.</p>
    <p>
      If there is not a gap, there would be no need for any action to move
      toward the goal or vision. The gap is the source of creative energy. This
      energy source is called creative tension.
    </p>
    <p>
      This exercise helps participants explore the gap between current reality
      and a desired future and how that tension can move a system forward.
    </p>
    <h3>Exercise Description</h3>
    <p>
      Decide on a system context before doing this exercise. For students, the
      system context could be their classroom, their sports’ team or a club. For
      adults, it could be their school, their department/team, their state
      system, or organization. Specificity of context will enhance the power of
      this exercise. All participants should be parts of the system context or
      have some familiarity with the given system.
    </p>

    <img
      className="lesson-img med-img"
      src="/images/resources/creative-tension/rubberbands.jpg"
      alt="rubber bands"
    />

    <h3>Directions</h3>
    <ol>
      <li>
        Have people work in pairs with one person being A and the other person
        as B. Each pair has one large rubber band. You can also assign roles by
        saying the person who is holding the rubber band goes first as A or
        current state (see #2 just below).
      </li>
      <li>
        A holds the rubber band and has 1 minute to describe the current state
        of the system; encourage A to use adjectives and descriptive phrases to
        describe the given system from her/his perspective. During this minute,
        B is a quiet listener.
      </li>
      <li>
        When time is up, B faces A and takes an end of the rubber band while A
        is still holding it. B then has 1 minute to describe the desired state
        of the given system. Questions that B might answer to describe the
        desired state could be, “How would you describe this system in a close
        to perfect state? What would it look like, sound like, feel like?” As B
        describes the current state, B moves backward one small step with each
        indicator s/he shares about the desired state. The rubber band will
        stretch, creating tension.
      </li>
      <li>
        When time is up, instruct people to hold their positions and ask how
        they are feeling. Begin with A’s and ask how they are feeling. Most will
        say scared, anxious, worried as the tension in the rubber band could
        cause them to feel unsafe. Then ask A’s to take one small step toward B
        (the desired state) and ask if that changes how they feel. Typically, a
        sigh of relief is heard.
      </li>
      <li>
        As A’s continue to step toward B’s, the rubber band will slacken. When
        that happens, ask B’s to step backward as A’s step forward so the
        partners and rubber band system actually move across the room with a
        comfortable amount of tension on the rubber band.
      </li>
    </ol>

    <h3>Debrief</h3>
    <p>
      Systems thinkers see and capitalize on the energy generated by this
      creative tension that should pull the current state to desired state — but
      the start (first step) is often the most difficult as we work hard to
      maintain the systems that we are most comfortable with. Debrief what
      happens by engaging in an open dialogue. Encourage participants to share
      the emotions, feelings of anxiety about the stretched rubber band and the
      tendency to stay still or stuck without moving toward the desired state.
    </p>
    <p>
      <strong>It may be helpful to begin by asking the group:</strong>
    </p>
    <p>
      <em>
        What did you learn from your partner as he or she was describing the
        current or desired state of the system?
      </em>
    </p>
    <p>
      <strong>
        After some responses, indicate the number of people divided by half in
        the exercise and say,{" "}
      </strong>
    </p>
    <p>
      <em>
        We surfaced ____ number of views of the current state of this system
      </em>{" "}
      (insert name of system){" "}
      <em>
        and _______ number of views of the desired state of the system. If we
        were to collect those views, do you think they would all be the same?
        Why or why not?
      </em>{" "}
      (Highlight some Habits of a Systems Thinker – Big picture, Changing
      perspectives and Surfaces and tests assumptions).
    </p>
    <p>
      <strong>Next, ask:</strong>
    </p>

    <p>
      <em>
        How did it feel when <strong>A’s</strong> had the courage to take a
        small step? Did anyone do this naturally without being invited to do so?
      </em>{" "}
      (Typically, very few take a step without being invited to do so).
    </p>

    <p>
      <strong>Then, continue the debrief by saying,</strong>
    </p>

    <p>
      <em>
        Gaps are the difference between where we are and where we would like to
        be. In our exercise, it was the space and degree of tension between{" "}
        <strong>A</strong> and <strong>B</strong>. When we become fully aware of
        gaps – the initial response is usually inaction, reluctance to try
        something new, fear of failure and other emotions like anxiety. The
        perspective of, “it is better to do what we are used to doing rather
        than something new in order to address the gap” is also common. But, how
        did the one small step to alleviate the gap change how you felt about
        the gap between current and desired state? These small steps are small
        wins and are a good example of the Habit, Successive Approximation.
      </em>
    </p>

    <p>
      <strong>Now, ask:</strong>
    </p>
    <p>
      <em>
        What causal factors help people take small steps toward the desired
        state for the system?
      </em>
    </p>
    <img
      className="med-img"
      src="/images/resources/creative-tension/small-steps.png"
      alt="Water Hole"
    />

    <p>
      <em>What causal factors hold people back?</em>
    </p>

    <img
      className="med-img"
      src="/images/resources/creative-tension/hold-back.png"
      alt="Water Hole"
    />

    <p>
      <em>
        What connections can you make between this exercise and what is
        happening in the system you addressed?
      </em>
    </p>

    <p>
      Encourage people to talk with their partners, talk at small table groups
      or debrief as a large group.
    </p>

    <h3>Systems Thinking Tools to Illustrate Gaps and Creative Tension</h3>
    <p>
      This <strong>behavior-over-time graph</strong> shows how strategies to
      improve the system help minimize the gap over time. In the graphic shown,
      the time is tracked by benchmarks informed by indicators of success.
    </p>

    <img
      className="large-img"
      src="/images/resources/creative-tension/creative-tension-botg.svg"
      alt="Creative Tension BOTG"
    />

    <p>
      <em>This same concept can be shown with a simple causal loop map.</em>
    </p>

    <img
      className="lesson-img med-img"
      src="/images/resources/creative-tension/creative-tension-loop.svg"
      alt="Creative Tension Loop"
    />

    <p>There are two ways to close or minimize gaps in a system.</p>
    <ol>
      <li>
        The left loop shows how gaps can create emotional tension that can
        pressure people to decrease aspirations and lower the goals or vision.
        The upper loop relieves tension and closes the gap but only because the
        goals are lowered.
      </li>
      <li>
        The right loop illustrates how creative tension can inform actions that
        help the system achieve success and move closer to a vision. Assuming
        this will take time, the delay shows that actions take time to change
        and improve the current reality of a system, resulting in a smaller gap.
      </li>
    </ol>

    <h3>Additional Debrief Points to Connect to Leadership</h3>
    <p>
      A systems thinking leader is one who effectively utilizes creative tension
      to manage gaps.
    </p>
    <p>How?</p>
    <ul>
      <li>They work to minimize fear by appreciating gaps.</li>
      <li>They manage the size of gaps.</li>
      <li>They help build understanding of what is causing gaps.</li>
      <li>
        They facilitate the selection of strategies to address gaps (leverage).
      </li>
      <li>They monitor the emotional tension that can be caused by gaps.</li>
    </ul>

    {/* <p>
      <strong>Helpful next step:</strong>  The <a href=''>Floating Ball exercise</a> is an effective next step exercise to help small groups of people practice all of the above in a fun, low-risk environment.
    </p> */}
  </div>
);
