import * as React from "react";

export const Lesson01 = () => (
  <div className="content">
    <img
      className="lesson-img med-img"
      src="/images/courses/habits/03-big-picture/course-img.png"
      alt="Big Picture"
    />
    <p>
      Few experiences are more breathtaking than taking in the big picture view
      of a beautiful landscape. Depending on the time of day, patterns and
      colors vary as the sunlight casts shadows and brings depth to various
      elevations. When appreciating this sprawling view of the world, the
      various parts blend together as the colors of a sunset stand out and a
      bird takes flight from a faraway tree. Sometimes referred to as a 10,000
      meter view, this vantage point can build new perspectives and greater
      understanding of a system. Systems thinkers intentionally seek this big
      picture orientation and are able to balance that view with timely
      attention to details when needed.
    </p>
    <h3>Spectator View</h3>

    <img
      className="lesson-img clear"
      src="/images/courses/habits/03-big-picture/lesson-one-img2.png"
      alt="Big Picture"
    />

    <p>
      Have you ever watched a marching band perform during halftime of a
      sporting event or during the opening ceremony of the Olympics? The goal of
      the performing group is to play music and create patterns that spell out
      words and formations that only spectators can appreciate. Each performer
      plays an important role as the formation moves and changes to create
      entertaining images.
    </p>

    <p>
      The spectators in the stands have a big picture view of the performers on
      the field as their movements create geometric formations and artistic
      designs. The success of the performance is based on the individual paths
      of each performer and the interrelationships between the performing
      members. Are they lined up properly? Is the spacing equally divided? If
      one person falls out of step or fails to properly line up, the spectators
      attention can quickly move to the part of the group that is not aligned.
    </p>

    <p>
      Spectators with a big picture view easily notice slight irregularities,
      such as when performers are not quite in line or in step with their group.
    </p>

    <p>
      Band members on the field have a very different view while performing.
      While marching, musicians pay attention to surrounding members, the
      markings on the field, and the conductor. They do not have the advantage
      of seeing firsthand the big picture they make by working together; this is
      because they have to pay attention to the details of their surroundings.
      They have to imagine the formations they create that are best appreciated
      by a broader perspective.
    </p>

    <p>
      The same holds true when appreciating an orchestra or choral performance.
      Audience members listen to the blend of each instrumental section
      simultaneously playing their parts to produce the musical piece. At times,
      some instruments or solo voices are featured, but the beauty of the whole
      provides a synthesis of a musical system working together as one.
    </p>

    <h3 className="clear">Restaurant Review</h3>
    <img
      className="system-icon right"
      src="/images/courses/shared/icon-workplace.svg"
      alt="Workplace Example"
    />
    <p>
      Michael, a restaurant owner, read a negative online review of a customer’s
      dining experience. This disappointing message expressed concern over one
      of his servers. The message motivated him to seek out more details about
      the situation. In addition to sharing the complaint with his employee, he
      considered, “Was this complaint a one-time occurrence or more of a
      pattern?” If this mishap was one unfortunate incident, then the resulting
      actions would be quite different than if it had been recurring. After all,
      this server had worked in the restaurant for over three months, which was
      certainly long enough to assess competence. Michael felt he needed to seek
      the bigger picture based on the complaint.{" "}
      <a href="/courses/12-considers-issue">
        Resisting the urge to jump to a quick conclusion
      </a>{" "}
      and <a href="/courses/06-patterns">identifying patterns and trends</a> are
      complementary practices that help systems thinkers see the big picture.
    </p>
    <p>
      American biologist and President of the Institute for Systems Biology,
      Leroy Hood, states, “If you just focus on the smallest details, you never
      get the big picture right.”
    </p>
    <p>
      Michael could have easily chastised his employee based on one negative
      review, but in order to “get it right,” he gathered more information
      beyond this small detail.
    </p>
    <p>
      Paying attention to the big picture may also involve attention to a
      collection of details.
    </p>
    <div className="quote">
      <p className="quote-text">
        “We often put so much energy into the picture, we forget the pixels.”
      </p>
      <p className="quote-author">
        {" "}
        – Silvia Cartwright, former New Zealand Governor-General
      </p>
    </div>
    <h3>The Masterful Teacher</h3>
    <img
      className="system-icon"
      src="/images/courses/shared/icon-school.svg"
      alt="School Example"
    />
    <p>
      Students were overheard talking about their teacher, “She must have eyes
      in the back of her head!” No matter what they did, even when she appeared
      to be looking the other way, their teacher always knew what was going on.
      Ms. Sampson observed everything – she noticed every detail of each and
      every interaction, no matter the energy level of her students. At the same
      time, she was able to maintain a clear picture of the entire classroom and
      the degree to which children were behaving appropriately while engaged in
      learning. While working individually with Samantha, Ms. Sampson would
      notice and redirect a group of children who were admiring a new soccer
      ball that one student brought in for recess. She never missed anything
      that went on in her classroom. Ms. Sampson was able to maintain the
      balance between the detail of providing for individual students and the
      big picture of whole class engagement. This balancing act is a practiced
      skill that can increase understanding and influence in situations like
      busy classrooms. Teachers like Ms. Sampson are masterful in seeking to
      understand the big picture.
    </p>
    <p>
      Systems thinkers balance the big picture view with attention to detail.
      Much like the focus on the forest while appreciating each tree, systems
      thinkers hold both views. They maintain the big picture that includes the
      10,000-meter view, while also giving attention to detail. Systems thinkers
      take time and make efforts to capture a big picture view. It might mean
      scheduling some time away from the chaos of a busy office or active family
      and asking oneself a series of reflection questions.
    </p>
  </div>
);
