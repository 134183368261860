import * as React from "react";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { Router } from "react-router-dom";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { ScrollToTop } from "components/shared/scroll-top";
import { HabitCards } from "components/cards";
import { CoursesRouter } from "components/courses";
import { MemberHome } from "components/member-home";
import { ResourcesRouter } from "components/resources";
import { Support } from "components/support";
import { Terms } from "components/terms";
import { PrivacyPolicy } from "components/privacy-policy";
import { Home } from "components/site/home";
import { About } from "components/site/about";
import { Benefits } from "components/site/benefits";
import { Contact } from "components/site/contact";
import { Donate } from "components/site/donate";
import { Features } from "components/site/features";
import { LiveOnlineEvents, eventsReducer } from "components/events";
import {
  OpenStudio,
  OnlineWorkshops,
  VirtualForums,
  TeacherStudio,
} from "./components/events/event-catalogs";

// reducers
import { feedbackReducer } from "components/courses/course-parts/course-feedback";
import { answersReducer } from "components/shared/answers";
import { LoginPage, SignupPage, authReducer } from "components/auth";
import {
  ForgotPasswordPage,
  ResetPasswordPage,
  passwordReducer,
} from "components/forgot-password";
import { editReducer, EditProfile } from "components/edit-profile";
// import { eventsReducer } from 'components/events'

// css
import "css/global.scss";

// ---- Reducers -----
const allReducers = combineReducers({
  answers: answersReducer,
  auth: authReducer,
  editProfile: editReducer,
  eventRegisterForm: eventsReducer,
  password: passwordReducer,
  feedback: feedbackReducer,
  // 3rd party reducers
  form: formReducer,
});

// ---- Middleware -----
const history = createBrowserHistory();

const middleware = applyMiddleware(routerMiddleware(history), thunk);

// Add dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const finalMiddleware = composeEnhancers
  ? composeEnhancers(middleware)
  : middleware;

// --- Store ----
const store = createStore(allReducers, finalMiddleware);

export const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route
            path="/reset-password/:forgotToken"
            component={ResetPasswordPage}
          />
          <Route path="/edit-profile" component={EditProfile} />
          <Route path="/dashboard" component={MemberHome} />
          <Route path="/courses" component={CoursesRouter} />
          <Route path="/resources" component={ResourcesRouter} />
          <Route path="/support" component={Support} />
          <Route path="/cards" component={HabitCards} />
          <Route path="/terms" component={Terms} />
          <Route path="/our-privacy-policy" component={PrivacyPolicy} />
          <Route path="/about" component={About} />
          <Route path="/benefits" component={Benefits} />
          <Route path="/donate" component={Donate} />
          <Route path="/contact" component={Contact} />
          <Route path="/features" component={Features} />
          <Route path="/events/overview" component={LiveOnlineEvents} />
          <Route path="/events/openstudio" component={OpenStudio} />
          <Route path="/events/teacherstudio" component={TeacherStudio} />
          <Route path="/events/onlineworkshops" component={OnlineWorkshops} />
          <Route path="/events/virtualforums" component={VirtualForums} />
          <Route path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
    </Router>
  </Provider>
);
