import uuidv4 from "uuid/v4";
import Cookie from "js-cookie";

const gSession = {};

export function getSessionId() {
  if (!gSession.sessionId) {
    let sessionId = window.localStorage.getItem("tts-session-id");
    if (!sessionId) {
      // NOTE: it might not be the best design security-wise to allow ids to be generated on client-side
      // but, I don't really think something more complicated is necessary at the moment
      sessionId = uuidv4();
      window.localStorage.setItem("tts-session-id", sessionId);
      console.log("setting session id to: " + sessionId);
    } else {
      console.log("loaded existing session id: " + sessionId);
    }
    gSession.sessionId = sessionId;
  }
  return gSession.sessionId;
}

export function goTo(loc) {
  window.location.href = loc;
  return null;
}

export function goToDashboard() {
  return goTo("/dashboard");
}
export function goToLogin() {
  return goTo("https://waterscenterst.org/login");
}

export function getSessionAuth() {
  if (!gSession.auth) {
    const sessAuth = Cookie.get("jt");

    gSession.auth = !!sessAuth;
    gSession.user = !!sessAuth;
    gSession.accessToken = sessAuth;
  }

  return gSession;
}

export function sessionLogout() {
  const sessionId = window.localStorage.getItem("tts-session-id");
  const cookie = Cookie.get("jt");

  if (sessionId && cookie) {
    window.localStorage.clear("tts-session-id", sessionId);
    Cookie.remove("jt", { domain: ".waterscenterst.org" });
    goToLogin();
  }
}
