import * as React from "react";

export const Section01 = () => (
  <div className="content">
    <h3>Materials:</h3>
    <ul>
      <li>
        Book: <em>Hey, Little Ant</em> by Phillip and Hannah Hoose
      </li>

      <li>Chart paper</li>

      <li>Markers</li>
    </ul>

    <h3>Visual:</h3>

    <img
      src="images/resources/hey-little-ant/ladder-example.png"
      alt="ladder-example"
      className="med-img"
    />

    <h3>Lesson Structure:</h3>

    <p>
      Prepare ahead of time the ladder of inference on chart paper. Divide the
      top three sections between the two characters, Kid and Ant. Write in the
      following from the top, Action, Believe, and Notice. Write the question,
      What was going on around the characters? at the base of the ladder. Both
      characters can share this space.
    </p>

    <p>
      First Reading: Read <em>Hey, Little Ant</em> to the students. Second
      Reading: Reread the story. Stop throughout the story and ask the following
      questions and complete the ladder from bottom to top:
    </p>

    <ul>
      <li>
        What was going on around the characters? What do the characters see,
        hear, touch, taste or smell?
      </li>

      <li>What did the Kid notice? What did the Ant notice?</li>

      <li>What did the Kid believe? What did the Ant believe?</li>

      <li>What action did the Kid take? What action did the Ant take?</li>

      <li>
        What lesson is learned by the two characters? Discuss the systems
        thinking habit seeks to understand the big picture. Ask if the students
        had to change their perspectives to increase their understanding of how
        the kid and ant were thinking. What did they learn by changing their
        perspective?
      </li>
    </ul>

    <p>
      Additional ideas for kindergarten and first grade students: During the
      second reading, you can focus on just one character and fill in the ladder
      of inference except the last question about what lesson is learned. During
      a third reading, focus on the other character and fill in the ladder of
      inference. Then ask the set of concluding questions about what lesson is
      learned.
    </p>

    <p>
      For second and third grade students, you can focus on comparing and
      contrasting the characters. Discuss the systems thinking habit considers
      short-term, long-term, and unintended consequences of actions. What effect
      did consequences play on both characters in the story?{" "}
      <strong>
        Also, you can ask the students to consider how what the characters
        believed affected what they noticed as the story went on. This question
        incorporates the thinking represented by the arrow on the side of the
        ladder.
      </strong>
    </p>

    <p>
      Lesson Author: Lisa Johnston
      <br />
      <a href="mailto:johnson.lisa@lee.k12.al.us">
        johnston.lisa@lee.k12.al.us
      </a>
    </p>
  </div>
);
