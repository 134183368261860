import * as React from "react";
import { Field } from "redux-form";
import { FileUpload } from "components/shared/file-upload";

export const Exercise03 = () => (
  <div className="content">
    <h3>Practice the Tool</h3>

    <p>
      For this exercise, you will need to gather a small group of people within
      your system of interest.
    </p>

    <ol>
      <li>
        Consider an issue or desired improvement you would like to address in
        your community, your work or even your family.
      </li>

      <li>
        Gather several people to help you draw your loop to clarify and define
        the gap.
      </li>

      <li>
        Introduce the balancing goal and gap loop to your group. You can use
        examples from this course or choose your own.
      </li>

      <li>
        Engage the group in drawing the loop by describing the current state,
        desired state and some intervention or improvement strategies.
      </li>

      <li>
        Optional: you may want to refer to{" "}
        <a href="/resources/creative-tension/exercise-procedure">
          Creative Tension
        </a>{" "}
        in the Resources section of TTS.
      </li>
    </ol>

    <Field
      name="group-loop"
      component={FileUpload}
      image
      instructions="Take a photo of the loop your group drew during this exercise and upload it below."
    />

    <h4>
      Describe how this exercise went. Did you identify intervention or
      improvement strategies? If so, what are they?
    </h4>

    <Field
      name="review"
      component="textarea"
      className="reflection-input-large"
      placeholder="Enter your response here."
    />
  </div>
);
